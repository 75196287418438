import React, {useEffect, useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import UserInfo from './UserInfo';
import Navigation from './VerticleNav';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Scrollbar from '../../Scrollbar';
import useStyles from './AppSidebar.style';
import {connect, getStore, setStore} from "trim-redux";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import {Translate} from "../../../../config/Translate";
import ExpertInfo from "./UserInfo/ExpertInfo";
import {withRouter} from "react-router";

const AppSidebar = (props) => {

  const navCollapsed = props.navCollapsed;
  const classes = useStyles();
  let sidebarClasses = classes.sidebarStandard;
    const role_name = !!getStore().user ? getStore().user.role.name : null;
    const role_type = !!getStore().user ? getStore().user.role.type : null;
    const dashboard = props.dashboard;
    const [value, setValue] = useState(role_type === "admin"? 2 : dashboard === "expert" ? 0 : 1);
    useEffect(() => {
        setValue(props.dashboard === "expert" ? 0 : 1);
    }, [props.dashboard]);
    const handleChange = (event, newValue) => {
        const dashboard = props.dashboard;
        if(dashboard === "expert" && newValue ===1 && role_name === "expert"){
          setStore({
              dashboard:"user"
          })
            props.history.push(`/change-role/user`);
        }else if(dashboard === "user" && newValue ===0 && role_name === "user"){
            setStore({
                dashboard:"expert"
            })
            props.history.push(`/change-role/expert`);
        }else if(dashboard === "user" && newValue ===0 && role_name !== "user"){
            setStore({
                dashboard:"expert"
            })
            props.history.push(`/dashboard`);
        }else if(dashboard === "expert" && newValue ===1 && role_name !== "expert"){
            setStore({
                dashboard:"user"
            })
            props.history.push(`/dashboard`);
        }
        setValue(newValue);
    };
    return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={props.position}
          open={navCollapsed}
          onClose={(ev) => setStore({navCollapsed:!props.navCollapsed})}
          style={{position: 'absolute'}}>
          <Box height='100%' className={classes.drawerContainer}>
            <Box
              height='100%'
              width='100%'
              color='primary.contrastText'
              className={classes.sidebarBg}>
                <UserInfo />
              <Box>

                  <Tabs
                        value={value}
                        onChange={handleChange}
                        className="menu-tabs"
                        indicatorColor="primary"
                        variant="fullWidth"
                        textColor="primary"
                    >
                        <Tab label={Translate.expert} />
                        <Tab label={Translate.customer} />
                        {
                            role_type === "admin" ?
                                <Tab label={Translate.adminSmall} />
                                : null
                        }
                    </Tabs>

                </Box>
                {
                    value === 0 ? <ExpertInfo/> : null
                }
                <Scrollbar className={classes.drawerScrollAppSidebar}>
                {
                        value === 0 ? <Navigation type="expert"/> : value === 1 ? <Navigation type="customer"/> : value === 2 ? <Navigation type="admin"/> : null
                    }
                </Scrollbar>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height='100%' className={classes.drawerContainer}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <UserInfo />
              <Box>
              <Tabs
                  value={value}
                  onChange={handleChange}
                  className="menu-tabs"
                  indicatorColor="primary"
                  variant="fullWidth"
                  textColor="primary"
              >
                  <Tab label={Translate.expert} />
                  <Tab label={Translate.customer} />
                  {
                      role_type === "admin" ?
                          <Tab label={Translate.adminSmall} />
                      : null
                  }
              </Tabs>
            </Box>
              {
                value === 0 ? <ExpertInfo/> : null
              }
            <Scrollbar
              className={clsx(classes.scrollAppSidebar, 'scrollAppSidebar')}>
                {
                  value === 0 ?
                      <Navigation type="expert"/>

                      : value === 1 ?
                      <Navigation type="customer"/>
                      : value === 2 ?
                      <Navigation type="admin"/> : null
                }
            </Scrollbar>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

const mstp = state => ({
    navCollapsed: state.navCollapsed,
    dashboard: state.dashboard,
});
export default connect(mstp)(withRouter(AppSidebar));

AppSidebar.defaultProps = {
  variant: '',
  position: 'left',
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
