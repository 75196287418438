import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../../components/elements/FieldCounter";
import {setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import CategoryHolderPro from "../../../components/fields/CategoryHolderPro";
class MakeSkill extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            description:"",
        },
        errors:{},
        categories:[],
        loading: true,
        SubmitLoading: false,
        confirmed: true,
        id:this.props.match.params.id,
        editMode:false
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/skill/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description: !!response.data.description ? response.data.description : '',
                        },
                        confirmed: response.data.confirmed,
                    })
                    let categoriesTemp=[];
                    response.data.categories.map(item => {
                        categoriesTemp.push(item.id)
                        return true
                    })
                    this.setState({
                        categories: categoriesTemp,
                        loading: false
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 1000) {
                formIsValid = false;
                errors['description'] = Translate.maxLength50;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,id,editMode,confirmed,categories} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('confirmed',  confirmed ? 1 : 0);
        data.append('categories',  JSON.stringify(categories));


        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/skill/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.dataEdited : Translate.dataAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/manage-skills`);

                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  Translate.dataEditingFailed : Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleCategoryHolder(categories){
        let {errors} = this.state;
        this.setState({
            categories,
            errors
        })
    }
    render(){
        let {fields, errors,SubmitLoading,editMode,confirmed,categories} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? Translate.editSkill : Translate.makeSkill}>
            {

                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <AppCard
                                    title={editMode ? Translate.editSkill : Translate.makeSkill}
                                    action={
                                        <BreadCrumbs singleLevel firstText={editMode ? Translate.editSkill : Translate.makeSkill}/>
                                    }>
                                    {
                                        this.state.loading ? <AppCardLoaderFields/> :
                                            <Grid container spacing={2} justify="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    <Alert
                                                        severity="info"
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={confirmed}
                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                        value={confirmed}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                            />
                                                        }
                                                    >
                                                        {Translate.confirmationStatus}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="name"
                                                        label={Translate.skillName}
                                                        type="text"
                                                        id="name"
                                                        value={fields.name}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['name']}
                                                        helperText={errors['name']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        multiline
                                                        fullWidth
                                                        name="description"
                                                        label={Translate.similarWords}
                                                        placeholder={Translate.similarWords}
                                                        type="text"
                                                        id="description"
                                                        rows="4"
                                                        value={fields.description}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['description']}
                                                        helperText={errors['description']}
                                                    />
                                                    <FieldCounter length={fields.description.length} max={1000}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CategoryHolderPro limit={6} specialty  outPut={this.handleCategoryHolder.bind(this)} inPut={categories}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {editMode ? Translate.editSkill : Translate.makeSkill}
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                    }
                                </AppCard>
                            </Grid>
                        </Grid>

                }
            </AppAnimate>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(MakeSkill)));