import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import AddFile from "./Components/AddFile";
import { withSnackbar } from 'notistack';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import Typography from '@material-ui/core/Typography';
import moment from "jalali-moment";
import Role from "../../components/elements/Role";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LinkIcon from '@material-ui/icons/Link';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import {TableCount, TablePage} from "../../../config/Permissions";
import withWidth from "@material-ui/core/withWidth/withWidth";
import TextField from "@material-ui/core/TextField/TextField";

class FileManager extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        owner: 'self',
        type:  this.props.fileType !== undefined ? this.props.fileType : 'image',
        fileType : this.props.fileType !== undefined ? this.props.fileType : 'image',
        columns: [
            {
                field: 'source',
                editable: 'never',
                title: Translate.image,
                render: rowData =>(
                    rowData.type === 'image' ? <img className={this.classes.FMThumbnail} alt="avatar" src={rowData.thumbnail} /> : rowData.type === 'video' ? <OndemandVideoRoundedIcon style={{fontSize: 40,color: "#6d6d6d"}}/> : <DescriptionRoundedIcon style={{fontSize: 40,color: "#6d6d6d"}}/>
                )
            },
            {
                title: Translate.imageName,
                field: 'ImageName' ,
                editable: 'never',
                render: rowData =>(
                    rowData.ImageName+"."+rowData.extension
                )

            },
            { title: Translate.alt, field: 'alt',hidden: this.props.width === "sm" || this.props.width === "xs", },
            { title: Translate.ownerMedia,editable: 'never', field: 'owner',hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.lastUpdate,editable: 'never', field: 'lastUpdate',hidden: this.props.width === "sm" || this.props.width === "xs",},
            {
                title: Translate.link,editable: 'never',
                field: 'link',
                hidden : !this.props.page,
                render: rowData =>(
                    rowData.type === 'image' ? <IconButton color="primary" onClick={() => this.OpenDialog(rowData)}><LinkIcon /></IconButton> : <a href={rowData.source}><GetAppIcon color="primary" /></a>
                )
            },
        ],
        data: [],
        ChangeLoader:false,
        LinkDialog:false,
        links:null,
    };
    componentDidMount(){
    }
    OpenDialog(data){
        let links = []
        links[0] = `${defaultVariabls.uploads}/FileManager/${data.ImageName}_small.${data.extension}`;
        links[1] = `${defaultVariabls.uploads}/FileManager/${data.ImageName}_medium.${data.extension}`;
        links[2] = `${defaultVariabls.uploads}/FileManager/${data.ImageName}.${data.extension}`;
        this.setState({
            links,
            LinkDialog:true
        })
    }
    handleCloseDialog(){
        this.setState({
            LinkDialog:false
        })
    }
    handleClickLink(link){
        navigator.clipboard.writeText(link);
        this.setState({
            LinkDialog:false
        })
        this.props.enqueueSnackbar(Translate.linkCopied, {
            variant: 'info',
        });
    }
    addImage(data){
        const {handleSetImage} = this.props;
        if(handleSetImage !== undefined){
            handleSetImage(data)
        }
    }

    Changed(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    async onDeleteFile(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/file/remove`, data, config)
            .then(response => {
                if(response.data.hasError){
                    this.props.enqueueSnackbar(response.data.message, {
                        variant: 'error',
                    });
                }else{
                    if(hasNotif){
                        this.props.enqueueSnackbar(Translate.deleteFile, {
                            variant: 'success',
                        });
                    }
                }
                this.loadData();

                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.deleteFileFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    async onDeleteSelectedFiles(data){
        await data.map((row) =>(
            this.onDeleteFile(row.id,false)

        ));
        await this.loadData();
        await this.props.enqueueSnackbar(Translate.deleteSelectedFiles, {
            variant: 'success',
        });

    }
    onEditFile(file) {
        let data = new FormData();
        data.append('id', file.id);
        data.append('alt', file.alt);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/file/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.editFile, {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.editFileFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    handleChangeLoader(){
        this.setState({
            ChangeLoader:!this.state.ChangeLoader
        })
    }
    async handleChangeOwner(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let owner = target.value;
        await this.setState({
            owner
        });
        this.loadData();
    }
    async handleChangeType(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let type = target.value;
        await this.setState({
            type
        });
        this.loadData();
    }
    onSelected(data){
        data.map((row) =>(
            this.addImage(row)
        ));
    }
    render(){
        let {LinkDialog,links,fileType,type,owner} = this.state;
        let loginToken = localStorage.getItem('ACT');
        const data = new FormData();
        data.append('type', type);
        data.append('owner', owner);
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <React.Fragment>
                <>
                    <Role type="admin">
                        <Grid container spacing={2} direction="row" className={this.classes.filterContainer}>
                            <Grid item xs={12} md={3} lg={1}>
                                <Typography>
                                    <FilterListRoundedIcon/>
                                    {Translate.filters} :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}>
                                <TextField
                                    select
                                    fullWidth
                                    value={owner}
                                    onChange={this.handleChangeOwner.bind(this)}
                                >
                                    <MenuItem value='self'>{Translate.selfFiles}</MenuItem>
                                    <MenuItem value='admins'>{Translate.adminsFiles}</MenuItem>
                                    <MenuItem value='all'>{Translate.everyBodyFiles}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}>
                                <TextField
                                    select
                                    fullWidth
                                    value={type}
                                    onChange={this.handleChangeType.bind(this)}
                                >
                                    {
                                        fileType === 'image' || fileType === 'all' || fileType === 'document' ? <MenuItem value='image' >{Translate.image}</MenuItem> :null
                                    }
                                    {
                                        fileType === 'video' || fileType === 'all'  ? <MenuItem value='video'>{Translate.video}</MenuItem> :null
                                    }
                                    {
                                        fileType === 'document' || fileType === 'all'  ? <MenuItem value='document'>{Translate.document}</MenuItem> :null
                                    }
                                    {
                                        fileType === 'all'  ? <MenuItem value='all'>{Translate.allTypes}</MenuItem> :null
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    </Role>
                    <MaterialTable
                        tableRef={this.tableRef}
                        icons={tableIcons}
                        onRowClick={((evt, selectedRow) =>  this.addImage(selectedRow))}
                        title={<AddFile ChangeLoader={this.handleChangeLoader.bind(this)} Changed={this.Changed.bind(this)} />}
                        localization={DataTableLocalization}
                        columns={this.state.columns}
                        data={query =>
                            new Promise((resolve, reject) => {
                                    axios.post(`${defaultVariabls.url}/file/getAll?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, data, config)
                                        .then(response => {
                                            let data = [];
                                            response.data.data.map((row) =>(
                                                data.push({
                                                    thumbnail: `${defaultVariabls.uploads}/FileManager/${row.name}_small.${row.extension}`,
                                                    source: `${defaultVariabls.uploads}/FileManager/${row.name}.${row.extension}`,
                                                    ImageName: row.name,
                                                    name: row.name,
                                                    extension: row.extension,
                                                    alt: row.alt,
                                                    type: row.type,
                                                    owner: row.owner.first_name+" "+row.owner.last_name,
                                                    id: row.id,
                                                    lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                })
                                            ));
                                            resolve({
                                                data: data,
                                                page: response.data.current_page - 1,
                                                totalCount: response.data.total,
                                            })

                                        }).catch(error => {
                                        console.log(error.response)
                                    });
                                }
                            )
                        }
                        actions={this.props.width === "sm" || this.props.width === "xs" ? null : [
                            {
                                tooltip: Translate.deleteSelectedFiles,
                                icon: tableIcons.Delete,
                                onClick: (evt, data) => this.onDeleteSelectedFiles(data)
                            },
                            this.props.multiInsert ? {
                                tooltip: Translate.multiImport,
                                icon: tableIcons.insert ,
                                onClick: (evt, data) => this.onSelected(data)
                            } : null
                        ]}
                        options={{
                            selection:!(this.props.width === "sm" || this.props.width === "xs"),
                            pageSize: TablePage,
                            pageSizeOptions: TableCount
                        }}
                        editable={this.props.width === "sm" || this.props.width === "xs" ? null : {
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        this.onEditFile(newData);
                                        if (oldData) {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            });
                                        }
                                    }, 600);
                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        this.onDeleteFile(oldData.id);
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                }),
                        }}
                    />
                </>
                <Dialog onClose={this.handleCloseDialog.bind(this)}  open={LinkDialog}>
                    <DialogTitle>{Translate.ChooseImageLink}</DialogTitle>
                    {
                        LinkDialog ?
                            <List>
                                <ListItem button onClick={() => {this.handleClickLink(links[0])}}>
                                    <ListItemText style={{textAlign:"left"}} primary={links[0]} />
                                    <ListItemAvatar style={{direction:"ltr"}}>
                                        <Avatar src={links[0]} className={this.classes.small}/>
                                    </ListItemAvatar>
                                </ListItem>
                                <ListItem button onClick={() => {this.handleClickLink(links[1])}}>
                                    <ListItemText style={{textAlign:"left"}}  primary={links[1]} />
                                    <ListItemAvatar style={{direction:"ltr"}}>
                                        <Avatar src={links[1]}/>
                                    </ListItemAvatar>
                                </ListItem>
                                <ListItem  button onClick={() => {this.handleClickLink(links[2])}}>
                                    <ListItemText style={{textAlign:"left"}} primary={links[2]} />
                                    <ListItemAvatar style={{direction:"ltr"}}>
                                        <Avatar src={links[2]} className={this.classes.large}/>
                                    </ListItemAvatar>
                                </ListItem>
                            </List>
                            : null
                    }
                </Dialog>
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withSnackbar(withStyles(Styles)(connect(mstp)(FileManager))));
