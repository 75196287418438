import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {Translate} from "../../../../config/Translate";
import AppCard from "../../../components/layouts/components/AppCard";
import CustomerDetails from "./CustomerDetails";
import Grid from "@material-ui/core/Grid/Grid";
import HeaderTags from "../../../components/elements/HeaderTags";

class CustomerDetailsPage extends React.Component {

    render() {
        return (
            <Grid container spacing={2} alignItems="center" justify="center">
                <HeaderTags title={Translate.baseDetails}/>
                <Grid item xs={12} md={11}>
            <AppCard
                title={Translate.baseDetails}
                >
                <CustomerDetails/>
            </AppCard>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CustomerDetailsPage))));