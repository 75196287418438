import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import DeleteButton from "../../../../components/elements/DeleteButton";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {Translate} from "../../../../../config/Translate";
import EditExperienceDialog from "./EditExperienceDialog";

class SingleExperience extends React.Component {
    classes = this.props.classes
    state={
        experience:this.props.data,
    };
    handleDelete(){
        let {experience} = this.state;
        let data = new FormData();
        data.append('id', experience.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/experience/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render() {
        let {experience} = this.state;
        return (
            <Card variant="outlined" style={{boxShadow:'none',height:"100%",position:"relative"}}>
                <CardContent style={{marginBottom:30}}>
                    <Typography variant="subtitle2"  color="textPrimary" gutterBottom>
                        {experience.position}
                    </Typography>
                    <Typography  color="textSecondary" gutterBottom>
                        {experience.place}
                    </Typography>
                    <Typography  color="textSecondary" gutterBottom>
                        {Translate.durationSimple}: {experience.duration} {Translate.month}
                    </Typography>
                    {
                       !! experience.current  ?
                           <Typography  color="primary" varaint="body2" gutterBottom>
                               {Translate.nowWorkingHere}
                           </Typography>
                           : null
                    }

                    <Typography color="textSecondary">
                        {experience.description}
                    </Typography>
                </CardContent>
                <CardActions className="card_resume_action">
                    <EditExperienceDialog id={experience.id}  reload={() => this.props.reload()}/>
                    <DeleteButton handleDelete={this.handleDelete.bind(this)}/>
                </CardActions>
            </Card>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleExperience))));