import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import AppCardLoaderFields from "../../../../../components/Skeletons/AppCardLoaderFields";
import SingleInterest from "./SingleInterest";
import {Translate} from "../../../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import Title from "../../../../../components/elements/Title";

class ChooseInterests extends React.Component {
    state = {
        categories: null,
        user_interests: [],
        loading:true,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/categories/getInterests`, config)
                .then(response => {
                    this.setState({
                        categories: response.data,
                    })

                    this.getUserInterests();
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async getUserInterests(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/categories/getUserInterests`, config)
                .then(response => {
                    this.setState({
                        user_interests: response.data,
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {categories,loading,user_interests} = this.state;
        return (
                <>
                    {
                     loading ? <AppCardLoaderFields/>:
                         <Grid container spacing={2} justify="center" alignItems="center">
                             <Grid item xs={12} md={11}>
                                 <Grid container spacing={2}>
                                     <Grid item xs={12}>
                                         <Title align="left">{Translate.followYourInterests}</Title>
                                         <Typography variant="subtitle2"  paragraph>{Translate.interestsDetailsDescription}</Typography>
                                     </Grid>
                                 {
                                     categories.map((category,index) =>(
                                         <Grid  key={index} item xs={12} md={4} lg={3}>
                                             <SingleInterest category={category} active={user_interests.indexOf(category.id) !== -1 }/>
                                         </Grid>
                                     ))
                                 }
                                 </Grid>
                             </Grid>
                         </Grid>
                    }
                </>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ChooseInterests)));