import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import {passwordRegx} from "../../../../../config/Security";

class ChangePassword extends React.Component {
    state={
        fields: {
            newPassword: '',
            newPasswordConfirm: '',
        },
        errors:{},
        SubmitLoading:false,
        user:this.props.user
    };
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //Password
        if(validator.isEmpty(fields.newPassword)){
            formIsValid = false;
            errors['newPassword'] = Translate.emptyPassword;
        }else if(!passwordRegx.test(fields.newPassword)){
            formIsValid = false;
            errors['newPassword'] = Translate.weakPassword;
        }

        //Password_confirmation
        if(validator.isEmpty(fields.newPasswordConfirm)){
            formIsValid = false;
            errors['newPasswordConfirm'] = Translate.emptyField;
        }else if (fields.newPasswordConfirm !== fields.newPassword){
            formIsValid = false;
            errors['newPasswordConfirm'] = Translate.noMatchPassword;
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    handleRequest(){
        const {newPassword,newPasswordConfirm} = this.state.fields;
        let data = new FormData();
        data.append('userId', this.state.user.id);
        data.append('password', newPassword);
        data.append('password_confirmation', newPasswordConfirm);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        axios.post(`${defaultVariabls.url}/forgetPassword/changePassword`, data, config)
            .then(response =>{
                this.props.enqueueSnackbar(Translate.passwordChanged, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false,
                })
                this.props.makeLogin(response.data)
            })
            .catch(error =>{
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(Translate.currentPasswordError, {
                    variant: 'error',
                });
            });
    }
    handleSubmit(){
        this.setState({
            SubmitLoading:true
        })
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render() {
        const {fields,errors,SubmitLoading} = this.state;
        return (
            <form style={{width:'100%'}} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        value={fields.newPassword}
                        name="newPassword"
                        label={Translate.newPassword}
                        type="password"
                        margin="normal"
                        id="newPassword"
                        variant="outlined"
                        onChange={this.handleChange.bind(this)}
                        error={!!errors['newPassword']}
                        helperText={!!errors['newPassword'] ? errors['newPassword'] : Translate.passwordStrength}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        value={fields.newPasswordConfirm}
                        name="newPasswordConfirm"
                        label={Translate.newPasswordConfirm}
                        type="password"
                        variant="outlined"
                        margin="normal"
                        id="newPasswordConfirm"
                        onChange={this.handleChange.bind(this)}
                        error={!!errors['newPasswordConfirm']}
                        helperText={errors['newPasswordConfirm']}
                    />
                </Grid>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{width:"100%"}}
                    onClick = {this.handleSubmit.bind(this)}
                    loading = {SubmitLoading}
                >
                    {Translate.changePassword}
                </LoadingButton>
            </form>

        );

    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ChangePassword)));