import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import {defaultVariabls} from "../../../../../../config";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import axios from "axios";
import {setStore} from "trim-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
class SingleInterest extends React.Component {
    state = {
        category: this.props.category,
        active: this.props.active,
        loading:false,
    };
    classes = this.props.classes;

    async handleClick(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/categories/toggleInterest/${this.state.category.id}`, config)
                .then(response => {
                    this.setState({
                        loading:false,
                        active: !this.state.active
                    })

                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {category,active,loading} = this.state;
        return (
            <>
            <Card
                className={active ? "interest-holder active" : "interest-holder"}
                onClick={this.handleClick.bind(this)}
            >
            <CardActionArea>
                <CardMedia
                    className="interest-media"
                    image={`${defaultVariabls.website}/uploads/FileManager/${category.thumbnail.name}_medium.${category.thumbnail.extension}`}
                    title={category.name}
                />
                <CardContent className="interest-chooser">
                    <Typography gutterBottom variant="h5" component="h2">
                        {
                            loading ?
                                <CircularProgress size={20}/>
                                :
                                active ?
                                <IconButton>
                                    <CheckCircleRoundedIcon color="secondary" style={{fontSize:'2.5rem'}}/>
                                </IconButton>
                            : null
                        }

                        <br/>
                        {category.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(SingleInterest)));