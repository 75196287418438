import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    navItem: {
      height: 40,
      marginTop: 2,
      marginBottom: 2,
      width: '100%',
      paddingLeft: 17,
      paddingRight:24,

      [theme.breakpoints.up('xl')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },

      '& .nav-item-text': {
        // fontSize: 16,
        color: theme.palette.sidebar.textColor,

        [theme.breakpoints.up('xl')]: {
          marginTop: 4,
          marginBottom: 4,
          // fontSize: 18,
        },
      },

      '& .nav-item-icon': {
        color: theme.palette.sidebar.textColor,
      },

      '& .nav-item-icon-arrow': {
        color: theme.palette.sidebar.textColor,
      },

      '& .MuiIconButton-root': {
        marginRight: -16,
      },

      '&.open, &:hover, &:focus': {
        '& .nav-item-text': {
          color: (props) =>
            props.themeMode === '#313541',
        },

        '& .nav-item-icon': {
          color: (props) =>
            props.themeMode === '#313541',
        },

        '& .nav-item-icon-arrow': {
          color: (props) =>
            props.themeMode === '#313541',
        },
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    listIcon: {
      fontSize: 18,
      [theme.breakpoints.up('xl')]: {
        // fontSize: 20,
      },
    },
  };
});
export default useStyles;
