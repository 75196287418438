import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CurrencyFormat from "react-currency-format";
import {currencySymbol} from "../../../config/values";
import LoadingButton from "../../components/elements/LoadingButton";
import DepositMoneyDialog from "../wallet/DepositMoneyDialog";
import {updateRedux} from "../../../config/Security";
import AvatarViewer from "../../components/elements/AvatarViewer";
import Title from "../../components/elements/Title";

class PayOrder extends React.Component {

    state = {
        order:null,
        SubmitLoading:false,
        id:this.props.match.params.id,
        loading:true
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.loadData();

    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/order/getForPayment/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        order:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }

    async handleRequest(){
        let { id} = this.state;
        let data = new FormData();
        data.append('id', id);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/order/pay`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.orderPayed, {
                    variant: 'success',
                });
                updateRedux();
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.history.push(`/my-orders`);

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.orderPayingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        this.handleRequest();

    }
    render(){
        let {order,SubmitLoading} = this.state;
        let total_price,wallet_amount,can_pay_with_wallet;
        if(!!order){
            total_price = order.total_price;
            wallet_amount = this.props.user.wallet.amount;
            can_pay_with_wallet = wallet_amount >= total_price;
        }
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.payOrder}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={11}>
                            <Title align="left">{Translate.payOrder}</Title>
                            <Typography>{Translate.payOrderDetail}</Typography>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <AppCard
                                title={Translate.payOrder}
                                action={
                                    <BreadCrumbs  firstText={Translate.orders} firstLink="/my-orders" secondText={Translate.payOrder}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justify="center" alignItems="center" className="my-20">
                                            <Grid item xs={12} md={11}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <Grid container spacing={2} justify="center" alignItems="center">
                                                            {
                                                                order.products.map((product,key)=>(
                                                                    <React.Fragment index={key}>
                                                                        <Grid item xs={12}>
                                                                            <Typography>
                                                                                <IconButton>
                                                                                    <AvatarViewer style={{display:"inline-block"}} id={product.thumbnail} size="small"/>
                                                                                </IconButton>
                                                                                <strong>{product.name}</strong> / <CurrencyFormat value={!!product.discount_price ? product.discount_price : product.price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                                                                                {
                                                                                    !!product.discount_price ?
                                                                                        <>
                                                                                            &nbsp;(<CurrencyFormat value={product.price - product.discount_price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} /> {Translate.discount})
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                ))
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} className="text-center">
                                                        <div className="payOrderSumery">
                                                            <Typography paragraph color="primary">{Translate.totalPrice}: <strong><CurrencyFormat value={order.total_price } displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} /></strong></Typography>
                                                            <Typography style={{color:"#30ab4a"}} variant="body2">{Translate.myWalletBalance}: <strong><CurrencyFormat value={wallet_amount } displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} /></strong></Typography>
                                                            <LoadingButton
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={!can_pay_with_wallet}
                                                                styleForParent={{marginBottom:10}}
                                                                style={{width: "100%"}}
                                                                onClick={this.handleSubmit.bind(this)}
                                                                loading={SubmitLoading}
                                                            >
                                                                {Translate.payWithWallet}
                                                            </LoadingButton>
                                                            <DepositMoneyDialog fullwidth amount={order.total_price - wallet_amount} variant="outlined" redirect={`/order/pay/${order.id}`}/>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(PayOrder))));