import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import AppCardLoaderSidebar from "../../../../components/Skeletons/AppCardLoaderSidebar";
import SubTitle from "../../../../components/elements/SubTitle";
import Thumbnail from "../../../fileManager/Components/Thumbnail";
import PlanDetails from "./components/PlanDetails";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {currencySymbol, paymentTypes} from "../../../../../config/values";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Divider from "@material-ui/core/Divider/Divider";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";


class MakeProjectPlan extends React.Component {

    classes = this.props.classes
    state={
        fields: {
            title: '',
            description:'',
            payment_type:'',
            minPrice:'',
            minPriceR:'',
            maxPrice:'',
            maxPriceR:'',
        },
        errors:{},
        planDetails:[
            ["",""],
            ["",""],
        ],
        current:false,
        dynamicPrice:true,
        thumbnail:1,
        loading:!!this.props.id,
        SubmitLoading:false,
        id: !!this.props.id ? this.props.id : 0,
        editMode: !!this.props.id
    };
    componentDidMount(){
        if(!!this.props.id){
            this.loadData();
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/project_plan/get/${this.state.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        title: !!response.data.title ? response.data.title : '',
                        description: !!response.data.description ? response.data.description : '',
                        minPrice: !!response.data.min_price ? response.data.min_price : '',
                        minPriceR: !!response.data.min_price ? response.data.min_price : '',
                        maxPrice: !!response.data.max_price ? response.data.max_price : '',
                        maxPriceR: !!response.data.max_price ? response.data.max_price : '',
                        payment_type: !!response.data.payment_type ? response.data.payment_type : '',
                    },
                    dynamicPrice: !!response.data.max_price && !!response.data.min_price,
                    thumbnail: response.data.thumbnail.id,
                });

                let details = [];
                // eslint-disable-next-line
                response.data.details.map((item)=> {
                    details.push([item.name,item.pivot.value])
                })
                this.setState({
                    loading:false,
                    planDetails: details.length > 0 ? details : ["",""]
                })

            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        if(target.name === "minPrice") {
            fields.minPriceR = target.value.replace(/,/ig, '');
        }
        else if(target.name === "maxPrice"){
            fields.maxPriceR = target.value.replace(/,/ig, '');

        }else{
            fields[target.name] = target.value;
        }
        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)) {
            formIsValid = false;
            errors['title'] = Translate.emptyField;
        }
        //price
        if(!fields.minPriceR) {
            formIsValid = false;
            errors['minPrice'] = Translate.emptyField;
        }
        if(!!fields.maxPriceR && !!fields.minPriceR) {
            if(fields.maxPriceR < fields.minPriceR){
                formIsValid = false;
                errors['maxPrice'] = Translate.wrongPrice;
            }
        }
        //payment_type
        if(validator.isEmpty(fields.payment_type)) {
            formIsValid = false;
            errors['payment_type'] = Translate.emptyField;
        }
        if(!!fields.description ? fields.description.length > 150 : false){
            formIsValid = false;
            errors['description'] = Translate.maxLength150;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,id,thumbnail,planDetails} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('description', fields.description);
        data.append('min_price', fields.minPriceR);
        data.append('max_price', fields.maxPriceR);
        data.append('payment_type', fields.payment_type);
        data.append('plan_details', JSON.stringify(planDetails));
        data.append('thumbnail', thumbnail);
        data.append('id', !!id ? id : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/project_plan/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                this.props.handleDone();

            })
            .catch(error => {
                console.log(error.response);
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handlePlanDetails(data){
        this.setState({
            planDetails:data
        })
    }
    handleDynamicPrice(){
        let {dynamicPrice,fields} = this.state;
        fields.maxPrice = '';
        fields.maxPriceR = '';
        this.setState({
            dynamicPrice: !dynamicPrice,
            fields
        })
    }
    render() {
        const {errors,fields,SubmitLoading,thumbnail,planDetails,dynamicPrice} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2}  justify="space-around" >
                        <CssBaseline/>
                        <Grid item xs={12} md={8}>
                            <Grid container  spacing={2} justify="center" >
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        value={fields.title}
                                        fullWidth
                                        label={Translate.titleProject}
                                        name="title"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['title']}
                                        helperText={errors['title']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.description}
                                        fullWidth
                                        label={Translate.projectDescription}
                                        name="description"
                                        rows={5}
                                        multiline
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['description']}
                                        helperText={errors['description']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {
                                this.state.loading ? <AppCardLoaderSidebar/> :
                                    <>
                                        <SubTitle>{Translate.ThumbnailTitle}</SubTitle>
                                        <Thumbnail rounded ref={this.ThumbChild}
                                                   handleThumbImage={this.handleThumbImage.bind(this)}
                                                   initial={thumbnail}/>
                                    </>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SubTitle className="mb-15" align="left">{Translate.planDetails}</SubTitle>
                            <PlanDetails handlePlanDetails={this.handlePlanDetails.bind(this)} input={planDetails}/>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} md={5}>
                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <SubTitle className="mb-15" align="left">{Translate.planPricing}</SubTitle>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="payment_type"
                                        name="payment_type"
                                        select
                                        size="small"
                                        label={Translate.payment_type}
                                        value={fields.payment_type}
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['payment_type']}
                                        helperText={errors['payment_type']}
                                    >
                                        {paymentTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {
                                    fields.payment_type !== "free" ?
                                    <>
                                        <Grid item xs={12} md={dynamicPrice ? 6 : 12}>
                                            <CurrencyTextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                required
                                                currencySymbol={currencySymbol}
                                                decimalPlaces={0}
                                                value={fields.minPrice}
                                                label={dynamicPrice ? Translate.minPrice : Translate.price}
                                                name="minPrice"
                                                disabled={fields.payment_type === "free"}
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['minPrice']}
                                                helperText={errors['minPrice']}
                                            />
                                        </Grid>
                                        {
                                            dynamicPrice ?
                                                <Grid item xs={12} md={6}>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        currencySymbol={currencySymbol}
                                                        decimalPlaces={0}
                                                        value={fields.maxPrice}
                                                        label={Translate.maxPrice}
                                                        disabled={fields.payment_type === "free"}
                                                        name="maxPrice"
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['maxPrice']}
                                                        helperText={errors['maxPrice']}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                    </> : null
                                }


                                <Grid item xs={12} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={dynamicPrice}
                                                onChange={this.handleDynamicPrice.bind(this)}
                                                name="dynamicPrice"
                                            />
                                        }
                                        label={Translate.dynamicPriceDescription}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeProjectPlan))));