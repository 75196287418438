import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import {grades} from "../../../../../config/values";
import DeleteButton from "../../../../components/elements/DeleteButton";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {Translate} from "../../../../../config/Translate";
import EditEducationDialog from "./EditEducationDialog";

class SingleEducation extends React.Component {
    classes = this.props.classes
    state={
        education:this.props.data,
    };
    handleDelete(){
        let {education} = this.state;
        let data = new FormData();
        data.append('id', education.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/education/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render() {
        let {education} = this.state;
        return (
            <Card variant="outlined" style={{boxShadow:'none',height:"100%",border:'1px solid #cbcbcb',position:"relative"}}>
                <CardContent style={{marginBottom:30}}>
                    <Typography  color="textPrimary" gutterBottom>
                        {grades[education.grade]}
                    </Typography>
                    <Typography  color="textSecondary" gutterBottom>
                        {education.university.title}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {education.field}
                    </Typography>
                </CardContent>
                <CardActions className="card_resume_action">
                    <EditEducationDialog id={education.id}  reload={() => this.props.reload()}/>
                    <DeleteButton handleDelete={this.handleDelete.bind(this)}/>
                </CardActions>
            </Card>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleEducation))));