import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import AppCard from "../../../../components/layouts/components/AppCard";
import Details from "../../../user/components/Details";
import BookRoundedIcon from '@material-ui/icons/BookRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";

class DetailsResume extends React.Component {

    render() {
        return (
            <AppCard
                title={Translate.baseDetails}
                action={
                    <>
                    {
                       this.props.width === "xs" || this.props.width === "sm" ? null :
                           <IconButton>
                               <BookRoundedIcon color="primary"/>
                           </IconButton>
                    }
                    </>
                }>
                <Details purchase/>
            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(DetailsResume)))));