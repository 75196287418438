import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "./LoadingButton";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";

class AddYourSkillIfYouCantFind extends React.Component{
    state= {
        open: false,
        SubmitLoading:false,
        search:this.props.search,
        skill: this.props.search,
        error: '',

    }
    async componentDidUpdate(prevProps){
        if(this.state.search !== this.props.search){
            this.setState({
                skill: this.props.search,
                search: this.props.search
            })
            await this.props.reload();
        }
    }
    async handleSubmit(){
        await this.setState({
            SubmitLoading:true
        })
        let { skill} = this.state;
        let data = new FormData();
        data.append('name', skill);
        data.append('confirmed',0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/skill/makeByUsers`, data, config)
            .then(response => {
                this.setState({
                    skill:'',
                    error:'',
                    SubmitLoading:false,
                    open:false
                })
                this.props.reload();
            })
            .catch(error => {
                if(error.response.status === 401){
                    this.setState({
                        error: Translate.youCantAddMoreSkillsUntileConfirmation
                    })
                }else{
                    this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                        variant: 'error',
                    });
                }

                this.setState({
                    SubmitLoading: false,
                });
            });

    }
    handleChange(e){
        let target = e.target;
        let error = "";
        let skill = target.value;
        this.setState({
            skill,
            error
        });
    }
    render(){
        let {open,skill,error,SubmitLoading}= this.state
        return (
            <>
                <Typography
                    variant="body2"
                    className="mr-10 mt-10"
                >
                    {Translate.addYourskillIfYouCatFind}
                    <Button size="small" variant="outlined" color="primary" onClick={() => this.setState({open:true})}>
                        {Translate.addSkill}
                    </Button>
                </Typography>

                <Dialog maxWidth='sm' fullWidth open={open} onClose={() => this.setState({open:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{Translate.addSkill}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {Translate.addYourskillIfYouCatFind}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            required
                            value={skill}
                            label={Translate.skillName}
                            name="skill"
                            onChange={this.handleChange.bind(this)}
                            error = {!!error}
                            helperText={error}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({open:false})} color="primary">
                            {Translate.cancel}
                        </Button>
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {Translate.addSkill}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(AddYourSkillIfYouCantFind)));