import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Notifications from './Notifications';
import useStyles from './AppHeader.style';
import Logo from '../../../../files/img/logo-dark.png';
import HorizontalNav from './HorizontalNav';
import {connect, setStore} from "trim-redux";
import MyWallet from "../../elements/MyWallet";
import MyPoints from "../../elements/MyPoints";
import CustomerPoints from "../../elements/MyPoints/CustomerPoints";
import {defaultVariabls} from "../../../../config";
import {Translate} from "../../../../config/Translate";

const AppHeader = (props) => {
    const classes = useStyles();
    return (
        <>
            <AppBar className='app-bar' color='inherit'>
                <Toolbar className={classes.appToolbar}>
                    <Hidden lgUp>
                        <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='inherit'
                            aria-label='open drawer'
                            onClick={() => setStore({navCollapsed:!props.navCollapsed})}>
                            <MenuIcon className={classes.menuIcon} />
                        </IconButton>
                    </Hidden>

                    <Box
                        component="a"
                        href={defaultVariabls.website}
                        style={{
                        cursor: "pointer",
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'center',
                    }}>
                        <img
                            className={classes.LayoutLogo}
                            src={Logo}
                            alt={Translate.appName}
                            title={Translate.appName}
                        />
                    </Box>
                    <Hidden mdDown>
                        <Box className={classes.headerNav}>
                            <Box className={classes.headerContainer}>
                                <HorizontalNav />
                            </Box>
                        </Box>
                    </Hidden>
                    <Box className={classes.grow} />
                    <Box>
                        <Notifications />
                    </Box>
                    <Box>
                        <MyWallet />
                    </Box>
                    <Box>
                        {
                            props.dashboard === "user" ? <CustomerPoints/> : <MyPoints />
                        }
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};
const mstp = state => ({
    navCollapsed: state.navCollapsed,
    dashboard: state.dashboard,
});
export default connect(mstp)(AppHeader);
