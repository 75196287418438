import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import IconButton from "@material-ui/core/IconButton/IconButton";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";
class MakeTeachingDetails extends React.Component {

    classes = this.props.classes
    state={
        fields: {
            description:'',
            workTimeFrom:'',
            workTimeTo:'',
            thursdaysFrom:'',
            thursdaysTo:'',
            holidaysFrom:'',
            holidaysTo:'',
        },
        errors:{},
        loading:true,
        SubmitLoading:false,
    };
    componentDidMount(){
        this.loadData();

    }
    async loadData(){
        let {user} = this.props;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/service/get/${user.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        description: !!response.data.teaching_description ? response.data.teaching_description : '',
                        workTimeFrom:response.data.workTimeFrom,
                        workTimeTo:response.data.workTimeTo,
                        thursdaysFrom:response.data.thursdaysFrom,
                        thursdaysTo:response.data.thursdaysTo,
                        holidaysFrom:response.data.holidaysFrom,
                        holidaysTo:response.data.holidaysTo,
                    },
                    loading:false

                });

            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;

        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let errors = {};
        let formIsValid = true;
        let {fields} = this.state;
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 300) {
                formIsValid = false;
                errors['description'] = Translate.maxLength300;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields} = this.state;
        let data = new FormData();
        data.append('description', fields.description);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/service/makeTeaching`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }

    render() {
        const {errors,fields,SubmitLoading} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2}  justify="space-around" >
                        <CssBaseline/>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={fields.description}
                                fullWidth
                                placeholder={Translate.teachingMainDescription}
                                name="description"
                                rows={5}
                                multiline
                                onChange={this.handleChange.bind(this)}
                                onBlur={this.handleSubmit.bind(this)}
                                error = {!!errors['description']}
                                helperText={errors['description']}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                                SubmitLoading ?
                                                    <CircularProgress size={20}/>
                                                    : <IconButton>
                                                        <FieldCounterWithoutBar length={fields.description.length} max={300}/>
                                                    </IconButton>
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeTeachingDetails))));