import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import ProfileImage from "../../fileManager/Components/ProfileImage";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, getStore, setStore} from "trim-redux";
import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button/Button";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {Province} from "../../../components/elements/Province";
import FieldCounter from "../../../components/elements/FieldCounter";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import SpecialtiesField from "../../../components/fields/SpecialtiesField";
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import {updateRedux} from "../../../../config/Security";

class details extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            name: '',
            gender: '',
            email:'',
            mobile:'',
            first_name:'',
            last_name:'',
            address:'',
            postal_code:'',
        },
        lockEmail:false,
        lockMobile:false,
        errors:{},
        specialties:[],
        loading:true,
        SubmitLoading:false,
        lockProvince:false,
        province:null,
        cities: null,
        city:null,
    };
    componentDidMount(){
        this.loadData();
    }
    loginToUserAccount(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let {user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/loginToUserAccount/${user_id}`, config)
            .then(async response => {
                await localStorage.setItem('ADMIN_ACT',localStorage.getItem('ACT'));
                await localStorage.setItem('ACT',response.data.accessToken);
                await updateRedux();
                let user = await getStore('user')
                let username = user.email;
                let login_token = user.login_token;
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${login_token}`);
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                let specialties_temp = [];
                // eslint-disable-next-line
                    response.data.specialties.map((specialty) => {
                        specialties_temp.push(specialty.name)
                    })
                this.setState({
                    fields:{
                        name: response.data.name,
                        gender: response.data.gender,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        bio: !!response.data.bio ? response.data.bio : '',
                        headline: !!response.data.headline ? response.data.headline : '',
                        about:!!response.data.about ? response.data.about : '',
                        mobile:response.data.mobile,
                        email:response.data.email,
                    },
                    loading:false,
                    lockEmail: response.data.email !== null,
                    province:response.data.province,
                    city:response.data.city,
                    lockProvince: response.data.province !== null,
                    lockMobile:true,
                    specialties:specialties_temp,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,

        });
    }
    handChangeProvinceSimple(e){
        let target = e.target;
        this.setState({
            province:target.value,

        });
    }
    handChangeCitySimple(e){
        let target = e.target;
        this.setState({
            city:target.value,

        });
    }
    handleValidation(callback){
        let { fields,province,city,specialties } = this.state;
        let errors = {};
        let formIsValid = true;

        //first_name
        if(validator.isEmpty(fields.first_name)){
            formIsValid = false;
            errors['first_name'] = Translate.emptyName;
        }else if(!validator.isByteLength(fields.first_name,{min:0, max:255})){
            formIsValid = false;
            errors['first_name'] = Translate.maxLength255;
        }
        //last_name
        if(validator.isEmpty(fields.last_name)){
            formIsValid = false;
            errors['last_name'] = Translate.emptyLastName;
        }else if(!validator.isByteLength(fields.last_name,{min:0, max:255})){
            formIsValid = false;
            errors['last_name'] = Translate.maxLength255;
        }
        //bio
        if(!!fields.bio ? fields.bio.length > 90 : false){
                formIsValid = false;
                errors['bio'] = Translate.maxLength90;
        }
        //headline
        if(!!fields.headline ? fields.headline.length > 50 : false){
            formIsValid = false;
            errors['headline'] = Translate.maxLength50;
        }
        //province
        if(!province){
            formIsValid = false;
            errors['province'] = Translate.emptyField;
        }
        //city
        if(!city){
            formIsValid = false;
            errors['city'] = Translate.emptyField;
        }
        //specialties
        if(specialties.length === 0){
            formIsValid = false;
            errors['specialties'] = Translate.emptyField;
        }
        //about
        if(!!fields.about ? fields.about.length > 400 : false){
            formIsValid = false;
            errors['about'] = Translate.maxLength400;
        }

        let {purchase} = this.props;
        //headline
        if(!!purchase){
            if(!fields.headline){
                formIsValid = false;
                errors['headline'] = Translate.emptyField;
            }//bio
            if(!fields.bio){
                formIsValid = false;
                errors['bio'] = Translate.emptyField;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,city,province,specialties} = this.state;
        let data = new FormData();
        data.append('first_name', fields.first_name);
        data.append('last_name', fields.last_name);
        data.append('gender', fields.gender);
        data.append('bio', fields.bio);
        data.append('headline', fields.headline);
        data.append('about', fields.about);
        data.append('specialties',  JSON.stringify(specialties));

        if (city !== null ){
            if(city.name === undefined){
                data.append('city', city);
            }else {
                data.append('city', city.name);
            }
        }
        if (province !== null ){
            if(province.name === undefined){
                data.append('province', province);
            }else{
                data.append('province', province.name);
            }
        }
        let loginToken = localStorage.getItem('ACT');
/*
        let {manage} = this.props;
*/
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/set`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                console.log("useeer",response.data)
                this.setState({
                    SubmitLoading:false
                });
                this.refreshStore();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        dashboard : response.data.role.name === "user" ? "user" : "expert",
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handChangeProvince(province){
        if (province === null){
            this.setState({
                province,
                cities:null,
                city:null
            })
        }else{
            this.setState({
                province,
                cities: province.Cities,
            })
        }

    }
    handChangeCity(city){
        this.setState({
            city
        })
    }
    async handleChangeSpecialty(specialties){
        let {errors} = this.state;
        errors['specialties']= '';
        await this.setState({
            specialties,
            errors
        })
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    render() {
        const {errors,fields,lockEmail,lockMobile,province,city,lockProvince,SubmitLoading,specialties} = this.state;
        let {purchase,user} = this.props;

        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2} justify="center" alignItems="center">
                        <CssBaseline/>
                        <Grid item xs={12}>
                            <ProfileImage noTitle size={150} manage={this.props.manage} user_id={this.props.user_id}/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <Grid container spacing={2} direction="row">
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        value={fields.first_name}
                                        fullWidth
                                        id="first_name"
                                        label={Translate.first_name}
                                        name="first_name"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['first_name']}
                                        helperText={errors['first_name']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        value={fields.last_name}
                                        fullWidth
                                        id="last_name"
                                        label={Translate.last_name}
                                        name="last_name"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['last_name']}
                                        helperText={errors['last_name']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        value={fields.gender}
                                        fullWidth
                                        select
                                        id="gender"
                                        label={Translate.gender}
                                        name="gender"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['gender']}
                                        helperText={errors['gender']}
                                    >
                                        <MenuItem value="1">
                                            {Translate.male}
                                        </MenuItem>
                                        <MenuItem value="0">
                                            {Translate.female}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                {
                                    lockProvince ?
                                        <React.Fragment>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    label={Translate.province}
                                                    required
                                                    variant="outlined"
                                                    value={province}
                                                    onChange={this.handChangeProvinceSimple.bind(this)}
                                                    fullWidth
                                                    error = {!!errors['province']}
                                                    helperText={errors['province']}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    label={Translate.city}
                                                    required
                                                    variant="outlined"
                                                    value={city}
                                                    onChange={this.handChangeCitySimple.bind(this)}
                                                    fullWidth
                                                    error = {!!errors['city']}
                                                    helperText={errors['city']}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Grid item xs={12} md={4}>
                                                <Autocomplete
                                                    options={Province}
                                                    required
                                                    onChange={(event, value) => this.handChangeProvince(value)}
                                                    getOptionLabel={option => option.name}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label={Translate.province}
                                                            variant="outlined"
                                                            value={province}
                                                            margin="normal"
                                                            fullWidth
                                                            error = {!!errors['province']}
                                                            helperText={errors['province']}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Autocomplete
                                                    options={this.state.cities}
                                                    onChange={(event, value) => this.handChangeCity(value)}
                                                    getOptionLabel={option => option.name}
                                                    value={city}
                                                    required
                                                    disabled={this.state.cities === null}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label={Translate.city}
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            error = {!!errors['city']}
                                                            helperText={errors['city']}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                }
                                <Grid item xs={12} md={4}>
                                    <SpecialtiesField error={errors['specialties']} required input={specialties} handleChange={this.handleChangeSpecialty.bind(this)}/>
                                </Grid>
                                {
                                    !!purchase ?
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    value={fields.headline}
                                                    fullWidth
                                                    required
                                                    margin="normal"
                                                    id="headline"
                                                    label={Translate.headline}
                                                    name="headline"
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['headline']}
                                                    helperText={errors['headline']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.headline.length} max={50}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    value={fields.bio}
                                                    fullWidth
                                                    required
                                                    id="bio"
                                                    label={Translate.bio}
                                                    name="bio"
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['bio']}
                                                    helperText={errors['bio']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.bio.length} max={90}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    value={fields.about}
                                                    fullWidth
                                                    id="about"
                                                    multiline
                                                    margin="normal"
                                                    rows={4}
                                                    label={Translate.about}
                                                    name="about"
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['about']}
                                                    helperText={errors['about']}
                                                />
                                                <FieldCounter length={fields.about.length} max={400}/>

                                            </Grid>
                                        </>
                                        : null
                                }
                                {
                                    !!purchase ? null :
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    value={fields.mobile}
                                                    fullWidth
                                                    id="mobile"
                                                    disabled={lockMobile}
                                                    label={Translate.mobile}
                                                    name="mobile"
                                                    autoComplete="mobile"
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['mobile']}
                                                    helperText={errors['mobile'] ? errors['mobile'] : Translate.mobilSample}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    value={fields.email}
                                                    fullWidth
                                                    id="email"
                                                    disabled={lockEmail}
                                                    label={Translate.email}
                                                    name="email"
                                                    autoComplete="email"
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['email']}
                                                    helperText={errors['email']}
                                                />
                                            </Grid>
                                            {
                                                user.role.type === "admin" &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        startIcon={<LockOpenRoundedIcon/>}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => this.loginToUserAccount()}
                                                    >
                                                        {Translate.loginToUserAccount}
                                                    </Button>
                                                </Grid>
                                            }
                                        </>
                                }

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                            {
                                !!purchase ? null :
                                    <div className="text-center">
                                        {
                                            !this.props.manage ?
                                                <Button
                                                    color="primary"
                                                    component={Link}
                                                    to="/account/password">
                                                    {Translate.changePassword}
                                                </Button> : null

                                        }
                                    </div>
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));