import {Translate} from "./Translate";


export const RWN = {
    admin: Translate.admin,
    support: Translate.supportUser,
    expert: Translate.expert,
    user: Translate.user,
    both: Translate.both,
};

export const rolesWithName = [
    {
        value: "admin",
        type: "admin",
        name : Translate.admin
    },
    {
        value: "support",
        type: "admin",
        name : Translate.supportUser
    },
    {
        value: "expert",
        type: "normal",
        name : Translate.expert
    },
    {
        value: "user",
        type: "normal",
        name : Translate.user
    },
    {
        value: "both",
        type: "normal",
        name : Translate.both
    },

];
export const typesWithName = [
    {
        value: "admin",
        name : Translate.adminType
    },
    {
        value: "normal",
        name : Translate.normalType
    },
];

export const  getRoleName = (role) => {
    let roleName = '';
     rolesWithName.map(row => {
        if(row.value === role){
            roleName = row.name;
        }
        return true
    })
    return roleName;
}
export const TablePage = 10;
export const TableCount = [10,20,50,100,200];