import React from 'react';
import {ThemeProvider, withStyles} from '@material-ui/core/styles';
import { BrowserRouter as Router,Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import {connect, setStore} from 'trim-redux'
import {defaultVariabls} from './config';
import Login from "./class/pages/Authentication/Login";
import Loader from "./class/components/elements/Loader";
import {theme, RTL} from './config/design/theme';
import {Styles} from "./config/design/styles";
import {SnackbarProvider} from "notistack";
import PrivateRoute from "./class/components/elements/PrivateRoute";
import Layout from "./class/components/layouts/layout";
import Error404 from './class/pages/errors/404';
import FileManagerPage from "./class/pages/fileManager/FileManagerPage";
import MobileVerify from "./class/pages/Authentication/MobileVerify";
import ChangePassword from "./class/pages/user/ChangePassword";
import ForgetPassword from "./class/pages/Authentication/ForgetPassword";
import LoginWithToken from "./class/pages/Authentication/LoginWithToken";
import Register from "./class/pages/Authentication/Register";
import VerifyEmailPage from "./class/pages/Authentication/VerifyEmailPage";
import Permission from "./class/components/elements/Permission";
import {ErrorBoundary} from 'react-error-boundary'
import IconButton from "@material-ui/core/IconButton/IconButton";
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import {Translate} from "./config/Translate";
import MakeContent from "./class/pages/contents/MakeContent";
import Contents from "./class/pages/contents/Contents";
import CategoriesPage from "./class/pages/admin/categories/CategoriesPage";
import MakeCategory from "./class/pages/admin/categories/MakeCategory";
import CommentsPage from "./class/pages/admin/comments/CommentsPage";
import ManageAbout from "./class/pages/admin/pages/ManageAbout";
import ManageTerms from "./class/pages/admin/pages/ManageTerms";
import ManageContact from "./class/pages/admin/pages/ManageContact";
import TranslateFront from "./class/pages/admin/settings/TranslateFront";
import UsersPage from "./class/pages/admin/users/UsersPage";
import AddUserPage from "./class/pages/admin/users/AddUserPage";
import ActivityLog from "./class/pages/admin/users/UsersLogPage";
import ResumePage from "./class/pages/resume/ResumePage";
import UserDetails from "./class/pages/user/UserDetails";
import MakeSkill from "./class/pages/admin/skills/MakeSkill";
import SkillsPage from "./class/pages/admin/skills/SkillsPage";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import ServicesPage from "./class/pages/services/ServicesPage";
import WalletPage from "./class/pages/wallet/WalletPage";
import PointsPage from "./class/pages/points/PointsPage";
import Verification from "./class/pages/user/components/Verification";
import WalletsPage from "./class/pages/admin/wallets/WalletsPage";
import WalletHistoryPage from "./class/pages/admin/wallets/WalletHistoryPage";
import ManageVerificationsPage from "./class/pages/admin/users/ManageVerificationsPage";
import Shop from "./class/pages/shop/Shop";
import ProductsPage from "./class/pages/admin/products/ProductsPage";
import MakeProduct from "./class/pages/admin/products/MakeProduct";
import VariablesPage from "./class/pages/admin/variables/VariablesPage";
import MakeVariable from "./class/pages/admin/variables/MakeVariable";
import OrdersPage from "./class/pages/orders/OrdersPage";
import PayOrder from "./class/pages/orders/PayOrder";
import ManageOrdersPage from "./class/pages/admin/orders/ManageOrdersPage";
import ChangeRole from "./class/pages/errors/ChangeRole";
import CustomerDetailsPage from "./class/pages/customers/details/CustomerDetailsPage";
import Dashboard from "./class/pages/Dashboard/Dashboard";
import Opportunities from "./class/pages/opportunities/Opportunities";
import ManageOpportunities from "./class/pages/admin/opportunities/ManageOpportunities";
import InterestsPage from "./class/pages/resume/component/interests/InterestsPage";
import Logout from "./class/pages/Authentication/Logout";
import ManageUserPage from "./class/pages/admin/users/ManageUserPage";

class App extends React.Component{
    state ={
        loading:true
    };
    async componentDidMount(){

        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        dashboard : response.data.role.name === "user" ? "user" : "expert",
                    });
                    this.setState({
                        loading:false
                    })


                }).catch(error => {
                    setStore({
                        isAuth : false,
                        user : null,
                        loading:false
                    });
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    ErrorFallback({error}) {
        if(!!error){
            console.log(error)
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    message={Translate.errorHappens}
                    action={
                        <React.Fragment>
                            <IconButton  onClick={() => window.location.reload()}>
                                <CachedRoundedIcon color="secondary"/>
                            </IconButton>
                        </React.Fragment>
                    }
                />
            )
        }

    }
    classes = this.props.classes;
    render(){
        if(this.state.loading){
            return(
                <Loader/>
            )
        }else{
            return(
                <ThemeProvider theme={theme}>
                    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                        <RTL>
                            <ErrorBoundary FallbackComponent={this.ErrorFallback}>
                                <Router>
                                    <Switch>
                                        <Redirect exact from='/' to='/dashboard'/>
                                        <Route  path="/notingFound" >
                                            <Error404/>
                                        </Route>
                                        {/*Auth Routes*/}
                                        <Route path="/login">
                                            <Login />
                                        </Route>
                                        <Route exact path="/register">
                                            <Register />
                                        </Route>
                                        <Route exact path="/verifyEmail/:id/:code">
                                            <VerifyEmailPage />
                                        </Route>
                                        <Route path="/token-login">
                                            <LoginWithToken />
                                        </Route>
                                        <Route path="/forget">
                                            <ForgetPassword />
                                        </Route>

                                        <PrivateRoute path="/verify-mobile">
                                            <MobileVerify />
                                        </PrivateRoute>
                                        <Layout>
                                            <PrivateRoute exact path="/logout">
                                                <Logout/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/dashboard">
                                                <Dashboard/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/dashboard/admin">
                                                <Dashboard admin/>
                                            </PrivateRoute>
                                            <Permission rule="fileManagerPage">
                                                <PrivateRoute path="/file-manager">
                                                    <FileManagerPage/>
                                                </PrivateRoute>
                                            </Permission>
                                            <PrivateRoute path="/changePassword">
                                                <ChangePassword/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/opportunities" exact>
                                                <Opportunities/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/contents" exact>
                                                <Contents/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/contents/make" exact>
                                                <MakeContent new/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/contents/make/:id">
                                                <MakeContent />
                                            </PrivateRoute>
                                            <Permission rule="manageSettings">
                                                <PrivateRoute exact path="/manage/manage-categories">
                                                    <CategoriesPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/category">
                                                    <MakeCategory new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/category/:id">
                                                    <MakeCategory />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/manage-skills">
                                                    <SkillsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/skill">
                                                    <MakeSkill new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/skill/:id">
                                                    <MakeSkill />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/manage-comments">
                                                    <CommentsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/about">
                                                    <ManageAbout/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/terms">
                                                    <ManageTerms/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/contact">
                                                    <ManageContact/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/settings/translate-front">
                                                    <TranslateFront/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/users/view/:type?">
                                                    <UsersPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/users/add">
                                                    <AddUserPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/user/:id">
                                                    <ManageUserPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/activities/:query?">
                                                    <ActivityLog/>
                                                </PrivateRoute>
                                            </Permission>
                                            <Permission rule="managePayments">
                                                <PrivateRoute exact path="/manage/orders">
                                                    <ManageOrdersPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/manage/opportunities" exact>
                                                    <ManageOpportunities/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/wallets">
                                                    <WalletsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/wallet/:id">
                                                    <WalletHistoryPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/verifications">
                                                    <ManageVerificationsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/products">
                                                    <ProductsPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/create/product" exact>
                                                    <MakeProduct new/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/edit/product/:id">
                                                    <MakeProduct />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/manage-variables">
                                                    <VariablesPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/variable">
                                                    <MakeVariable new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/variable/:id">
                                                    <MakeVariable />
                                                </PrivateRoute>
                                            </Permission>
                                            <PrivateRoute exact path="/resume/:section">
                                                <ResumePage/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/service/:section">
                                                <ServicesPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/account/password">
                                                <ChangePassword/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/account/details">
                                                <UserDetails/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/account/verification">
                                                <Verification/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/wallet/view">
                                                <WalletPage/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/points/view">
                                                <PointsPage/>
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/shop">
                                                <Shop/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/my-orders">
                                                <OrdersPage myOrders />
                                            </PrivateRoute>
                                            <PrivateRoute path="/order/pay/:id">
                                                <PayOrder/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/interests">
                                                <InterestsPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/details">
                                                <CustomerDetailsPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/change-role/:type">
                                                <ChangeRole/>
                                            </PrivateRoute>
                                        </Layout>

                                        {/*Errors Routes*/}
                                        <Route component={Error404} />
                                    </Switch>
                                </Router>
                            </ErrorBoundary>

                        </RTL>
                    </SnackbarProvider>
                </ThemeProvider>
            )
        }

    }

}
const mstp = state => ({
    user: state.user
});
export default withStyles(Styles)(connect(mstp)(App));
