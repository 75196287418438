import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import AppCard from "../../../../components/layouts/components/AppCard";
import ChooseInterests from "./components/ChooseInterests";
import IconButton from "@material-ui/core/IconButton/IconButton";
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import withWidth from "@material-ui/core/withWidth/withWidth";

class Interests extends React.Component {

    render() {
        return (
            <AppCard
                title={Translate.interestsDetails}
                action={
                    <>
                        {
                            this.props.width === "xs" || this.props.width === "sm" ? null :
                                <IconButton>
                                    <FavoriteRoundedIcon color="primary"/>
                                </IconButton>
                        }
                    </>

                }>
                <ChooseInterests/>
            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Interests)))));