import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {Translate} from "../../../../config/Translate";
import {Link} from 'react-router-dom';
import {connect} from "trim-redux";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import Button from "@material-ui/core/Button/Button";

class PleaseConfirmYourInformation extends React.Component {
    state = {
        loading:false,
        confirmed:this.props.user.confirmed,
    };

    classes = this.props.classes;
    render(){
        let {customer_completed} = this.props.user;
        return (
            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                {
                    customer_completed ? null :
                        <Grid item xs={12}>
                            <Alert
                                severity="warning"
                                action={
                                    <Button
                                        component={Link}
                                        to="/details"
                                        style={
                                            {
                                                backgroundColor:"#ff9800",
                                                color:"#fff",
                                                boxShadow:"none"
                                            }
                                        }
                                        variant="contained">
                                        {Translate.completeDetails}
                                    </Button>
                                }
                            >
                                <AlertTitle>{Translate.dearUserAttention}</AlertTitle>
                                {Translate.yourUserAccountIsNotCompleted} <strong>{Translate.thanks}</strong>
                            </Alert>
                        </Grid>
                }
            </Grid>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withStyles(Styles)(connect(mstp)(PleaseConfirmYourInformation));