import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {Translate} from "../../../config/Translate";
class CheckForHavingProfile extends React.Component{
    open = this.props.new && this.props.user.role.type === "admin";
    state= {
        dialogOpen: !this.props.user.completed && this.open
    };
    classes = this.props.classes;
    render(){
        return (
            <>
                <Dialog
                    open={this.state.dialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {Translate.createProfile}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {Translate.createProfileDetailsCheck}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button component={Link} to="/resume/details" color="primary">
                            {Translate.createProfile}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CheckForHavingProfile))));