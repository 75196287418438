import {Translate} from "../Translate";

const routesConfig = [
    {
        id: 'dashboards',
        title: Translate.dashboard,
        type: 'item',
        for:['customer','expert'],
        icon:'dashboard',
        url: '/dashboard',
        exact:true
    },
    {
        id: 'admin_dashboard',
        title: Translate.dashboard,
        type: 'item',
        for:['admin'],
        icon:'dashboard',
        url: '/dashboard/admin',
    },
    {
        id: 'resume',
        title: Translate.resumeManagement,
        for:['expert'],
        icon:'contact_page',
        type: 'collapse',
        children: [
            {
                id: 'baseDetails',
                title: Translate.baseDetails,
                type: 'item',
                icon: 'book',
                url: '/resume/details',
            },
            {
                id: 'skillsDetails',
                title: Translate.skillsDetails,
                icon: 'tungsten',
                type: 'item',
                url: '/resume/skills',
            },
            {
                id: 'portfoliosDetails',
                title: Translate.portfoliosDetails,
                icon: 'color_lens',
                type: 'item',
                url: '/resume/portfolio',
            },
            {
                id: 'experiencesDetails',
                title: Translate.experiencesDetails,
                icon: 'work',
                type: 'item',
                url: '/resume/experiences',
            },
            {
                id: 'educationsDetails',
                title: Translate.educationsDetails,
                icon: 'school',
                type: 'item',
                url: '/resume/educations',
            },

            {
                id: 'interestsDetails',
                title: Translate.interestsDetails,
                icon: 'favorite',
                type: 'item',
                url: '/resume/interests',
            },
        ],
    },
    {
        id: 'service',
        title: Translate.services,
        for:['expert'],
        icon:'playlist_add_check',
        type: 'collapse',
        children: [
            {
                id: 'projects',
                title: Translate.projectsDetails,
                type: 'item',
                icon: 'laptop',
                url: '/service/project',
            },
            {
                id: 'teaching',
                title: Translate.teaching,
                type: 'item',
                icon: 'local_library',
                url: '/service/teaching',
            },
            {
                id: 'jobrequest',
                title: Translate.jobrequest,
                type: 'item',
                icon: 'work',
                url: '/service/job',
            },
            {
                id: 'teamUp',
                title: Translate.teamUp,
                icon: 'people',
                type: 'item',
                url: '/service/team',
            },
            {
                id: 'consultation',
                title: Translate.consultation,
                type: 'item',
                icon: 'headset_mic',
                url: '/service/consultation',
            },
        ],
    },

    {
        id: 'settings',
        title: Translate.settings,
        type: 'collapse',
        icon:'engineering',
        for:['admin'],
        roles: ['admin','support'],
        permission: 'manageSettings',
        children: [
            {
                id: 'manageCategories',
                title: Translate.manageCategories,
                type: 'item',
                icon: 'category',
                url: '/manage/manage-categories',
                exact:true
            },
            {
                id: 'manageSkills',
                title: Translate.manageSkills,
                type: 'item',
                icon: 'stars',
                url: '/manage/manage-skills',
                exact:true
            },
            {
                id: 'manageComments',
                title: Translate.manageComments,
                type: 'item',
                icon: 'comments',
                url: '/manage/manage-comments',
                exact:true
            },
            {
                id: 'translateFront',
                title: Translate.translateFront,
                type: 'item',
                icon: 'translate',
                url: '/settings/translate-front',
                exact:true
            },
        ],
    },
    {
        id: 'managePages',
        title: Translate.managePages,
        type: 'collapse',
        icon: 'description',
        roles: ['admin'],
        for: ['admin'],
        permission: 'manageSettings',
        children: [
            {
                id: 'manageAbout',
                title: Translate.manageAbout,
                type: 'item',
                icon: 'info',
                url: '/manage/pages/about',
                exact:true
            },
            {
                id: 'manageTerms',
                title: Translate.manageTerms,
                type: 'item',
                icon: 'rule',
                url: '/manage/pages/terms',
                exact:true
            },
            {
                id: 'manageContact',
                title: Translate.manageContact,
                type: 'item',
                icon: 'contact_page',
                url: '/manage/pages/contact',
                exact:true
            },
        ],
    },
    {
        id: 'usersManagement',
        title: Translate.usersManagement,
        type: 'collapse',
        icon: "people",
        roles: ['admin','support'],
        for: ['admin'],
        permission: 'manageSettings',
        children: [
            {
                id: 'allUsers',
                title: Translate.allUsers,
                type: 'item',
                icon: 'group',
                url: '/manage/users/view/all',
                exact:true
            },
            {
                id: 'usersActivity',
                title: Translate.usersActivity,
                type: 'item',
                icon: 'post_add',
                url: '/manage/activities',
                exact:true
            },
        ],
    },
    {
        id: 'paymentManagement',
        title: Translate.paymentManagement,
        type: 'collapse',
        icon: 'account_balance_wallet',
        roles: ['admin','support'],
        for: ['admin'],
        permission: 'managePayments',
        children: [
            {
                id: 'manageOpportunities',
                title: Translate.manageOpportunities,
                type: 'item',
                icon: 'connect_without_contact',
                url: '/manage/opportunities',
                exact:true
            },
            {
                id: 'manageOrders',
                title: Translate.manageOrders,
                type: 'item',
                icon: 'shopping_basket',
                url: '/manage/orders',
                exact:true
            },
            {
                id: 'manageVerifications',
                title: Translate.manageVerifications,
                type: 'item',
                icon: 'verified_user',
                url: '/manage/verifications',
                permission: 'managePayments',
                exact:true
            },
            {
                id: 'manageWallets',
                title: Translate.manageWallets,
                type: 'item',
                icon: 'account_balance_wallet',
                url: '/manage/wallets',
                permission: 'managePayments',
                exact:true
            },
            {
                id: 'productsList',
                title: Translate.manageProducts,
                type: 'item',
                icon: 'shopping_cart',
                url: '/manage/products',
            },
            {
                id: 'manageVariables',
                title: Translate.manageVariables,
                type: 'item',
                icon: 'edit_attributes',
                url: '/manage/manage-variables',
                exact:true
            },
        ],
    },

    {
        id: 'contents',
        title: Translate.contents,
        type: 'collapse',
        for: ['expert','admin'],
        icon: 'article',
        children: [
            {
                id: 'addContent',
                title: Translate.addContent,
                type: 'item',
                icon: 'create',
                url: '/contents/make',
                exact:true
            },
            {
                id: 'viewContent',
                title: Translate.viewContent,
                type: 'item',
                icon: 'view_list',
                url: '/contents',
                exact:true
            },
        ],
    },
    {
        id: 'details_customer',
        title: Translate.baseDetails,
        type: 'item',
        for:["customer"],
        icon: 'book',
        url: '/details',
    },
    {
        id: 'interests_customer',
        title: Translate.interestsDetails,
        type: 'item',
        for:["customer"],
        icon: 'favorite',
        url: '/interests',
    },
    {
        id: 'account',
        title: Translate.account,
        icon: 'settings',
        type: 'collapse',
        children: [
            {
                id: 'settings',
                title: Translate.settings,
                type: 'item',
                icon: 'settings',
                url: '/account/details',
            },
            {
                id: 'password',
                title: Translate.changePassword,
                icon: 'password',
                type: 'item',
                url: '/account/password',
            },{
                id: 'verification',
                title: Translate.verificationDetails,
                icon: 'credit_card',
                type: 'item',
                url: '/account/verification',
            },
        ],
    },
    {
        id: 'opportunities',
        for: ['expert','customer'],
        title: Translate.opportunities,
        type: 'item',
        icon: 'connect_without_contact',
        url: '/opportunities',
    },
    {
        id: 'userOrders',
        title: Translate.shop,
        icon:"shopping_basket",
        type: 'collapse',
        children: [
            {
                id: 'shop',
                title: Translate.buyOpportunity,
                type: 'item',
                icon: 'shopping_cart',
                url: '/shop',
            },
            {
                id: 'orders',
                for: ['expert','customer'],
                title: Translate.viewOrders,
                type: 'item',
                icon: 'inventory',
                url: '/my-orders',
            },

        ],
    },
    {
        id: 'fileManager',
        title: Translate.fileManager,
        type: 'item',
        roles: ['admin','support'],
        for: ['admin'],
        icon: 'image',
        url: '/file-manager',
    },
    {
        id: 'logout',
        title: Translate.logOut,
        type: 'item',
        icon: 'power_settings_new',
        url: '/logout',
    },
];
export default routesConfig;
