import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import University from "../../../../components/fields/University";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {educationGrades} from "../../../../../config/values";
import {updateRedux} from "../../../../../config/Security";

class MakeEducation extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            field: '',
            grade:'',
        },
        university:null,
        errors:{},
        loading:!!this.props.id,
        SubmitLoading:false,
        id: !!this.props.id ? this.props.id : 0,
        editMode: !!this.props.id
    };
    componentDidMount(){
        if(!!this.props.id){
            this.loadData();
        }
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/education/get/${this.state.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        field: response.data.field,
                        grade: response.data.grade,
                    },
                    loading:false,
                    university:response.data.university.title
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let { fields,university } = this.state;
        let errors = {};
        let formIsValid = true;

        //field
        if(validator.isEmpty(fields.field)){
            formIsValid = false;
            errors['field'] = Translate.emptyField;
        }
        //grade
        if(!fields.grade){
            formIsValid = false;
            errors['grade'] = Translate.emptyField;
        }
        if(!university){
            formIsValid = false;
            errors['university'] = Translate.emptyField;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,university,id} = this.state;
        let data = new FormData();
        data.append('field', fields.field);
        data.append('grade', fields.grade);
        data.append('university', university);
        data.append('id', !!id ? id : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        console.log(fields,university)
        axios.post(`${defaultVariabls.url}/education/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                updateRedux();
                this.props.handleDone();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handleUniversity(university){
        let {errors} = this.state;
        errors['university'] = '';
        this.setState({
            university,
            errors
        })
    }
    render() {
        const {errors,fields,university,SubmitLoading,editMode} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2} justify="center" alignItems="center">
                        <CssBaseline/>
                        <Grid item xs={12}>

                            {
                                editMode ?
                                    <TextField
                                        variant="outlined"
                                        required
                                        value={university}
                                        fullWidth
                                        disabled
                                        label={Translate.university}
                                        name="field"
                                    />
                                    :
                                    <University
                                        required
                                        input={university}
                                        handleChange={this.handleUniversity.bind(this)}
                                        error={errors['university']}
                                    />

                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                required
                                label={Translate.grade}
                                value={fields.grade}
                                name="grade"
                                error = {!!errors['grade']}
                                helperText={errors['grade']}
                                onChange={this.handleChange.bind(this)}
                            >
                                {
                                    educationGrades.map((row,key) => (
                                        <MenuItem value={row.value} key={key}>
                                            {row.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                value={fields.field}
                                fullWidth
                                label={Translate.field}
                                name="field"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['field']}
                                helperText={errors['field']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeEducation))));