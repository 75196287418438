import React from 'react';
import {connect} from "trim-redux";
import {JSONEditor} from 'react-json-editor-viewer';
import Grid from "@material-ui/core/Grid/Grid";
import Container from "@material-ui/core/Container/Container";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import Button from "@material-ui/core/Button/Button";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {Translate} from "../../../../config/Translate";
import AppCard from "../../../components/layouts/components/AppCard";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";

class TranslateFront extends React.Component {
    classes = this.props.classes;
    state = {
        data: null,
        loading:true
    }
    componentDidMount(){
        this.loadData();
    }
    onJsonChange(key, value, parent, data){
        this.setState({
            data
        })
    }
    async loadData(){
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.get(`${defaultVariabls.url}/jsons/translate_back`, config)
            .then(response => {
                let data = response.data
                this.setState({
                    data,
                    loading:false
                })

            }).catch(error => {
                console.log(error.response)
            })
    }
    async handleSave(){
        let data = new FormData();
        data.append('lang', this.props.locale);
        data.append('data', JSON.stringify(this.state.data));
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/settings/changeBackTranslate`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.allChangesSaved, {
                    variant: 'success',
                });
                window.location.reload();

            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.allChangesSavingFailed, {
                    variant: 'error',
                });
            });
    }
    render(){
        let {data,loading} = this.state;
        if (loading) {
            return (
                <AppCardLoaderFields/>
            )
        }else{
            return(
                <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.translateFront}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <AppCard
                                title={ Translate.translateFront}
                                action={
                                    <BreadCrumbs singleLevel firstText={Translate.translateFront}/>
                                }>
                                <Container maxWidth="xl">
                                    <Grid container spacing={2} direction="row" justify="center">
                                        <Grid item xs={12}>
                                            <Alert severity="warning">{Translate.translateFrontDesc}</Alert>
                                        </Grid>
                                        <Grid item xs={12} md={8} className="jsonEditor">
                                            <JSONEditor
                                                data={data}
                                                onChange={this.onJsonChange.bind(this)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="large"
                                                style={{position:'sticky',top:150}}
                                                onClick={this.handleSave.bind(this)}
                                            >
                                                {Translate.setDetails}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </AppCard>
                        </Grid>
                    </Grid>
                </AppAnimate>
            )
        }


    }
}
const mstp = state => ({
    locale: state.locale,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(TranslateFront))));