import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import ServicesManagement from "./ServicesManagement";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";

class ServicesPage extends React.Component {
    classes = this.props.classes;
    state= {
        section :this.props.match.params.section
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.setState({
                section :this.props.match.params.section
            })
        }
    }
    render(){
        return (
            <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} md={11}>
                    <ServicesManagement section={this.state.section}/>
                </Grid>
            </Grid>
        );
    }
}
export default withRouter(withSnackbar(withStyles(Styles)(ServicesPage)));
