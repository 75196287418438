import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import {withSnackbar} from "notistack";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {Translate} from "../../../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";

class PlanDetails extends React.Component {
    classes = this.props.classes;
    state={
        planDetails:[
            ['','']
        ],
        errors:[]
    };
    componentDidMount(){
        if(this.props.input){
            this.setState({
                planDetails: this.props.input,
            })
        }
    }
    handleValidation(){


    }
    handleChangePlanDetail(e){
        let {planDetails,errors} = this.state;
        let target = e.target;
        errors[`planDetail${parseInt(target.name)}`] = "";
        planDetails[parseInt(target.name)][0] = target.value;
        this.setState({
            planDetails
        })
        if(planDetails.length > 0){
            this.props.handlePlanDetails(planDetails);
        }
    }
    handleChangeValue(e){
        let {planDetails,errors} = this.state;
        let target = e.target;
        errors[`value${parseInt(target.name)}`] = "";
        planDetails[parseInt(target.name)][1] = target.value;
        this.setState({
            planDetails
        })
        if(planDetails.length > 0){
            this.props.handlePlanDetails(planDetails);
        }
    }
    addPlanDetail() {
        let {planDetails} = this.state;
        if(planDetails[planDetails.length-1][0].length !== 0 && planDetails[planDetails.length-1][1].length !== 0){
            planDetails.push(["",""]);
            this.setState({
                planDetails
            })
        }else{
            this.props.enqueueSnackbar(Translate.fillPrevFieldFirst, {
                variant: 'warning',
            });
        }
    }
    async handleRemoveField(index) {
        let {planDetails} = this.state;
        planDetails.splice(index, 1)
        await this.setState({
            planDetails
        })
        await this.props.handlePlanDetails(planDetails);
        this.handleValidation()
    }
    render() {
        let {planDetails,errors} = this.state;
        return(
            <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">

                {
                    planDetails.map((planDetail,index) =>(
                        <Grid key={index} style={{position:"relative"}} item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        value={planDetails[index][0]}
                                        fullWidth
                                        size="small"
                                        id={`planDetail${index}`}
                                        label={Translate.planDetail}
                                        name={index}
                                        onChange={this.handleChangePlanDetail.bind(this)}
                                        onBlur={this.handleValidation.bind(this)}
                                        error = {!!errors[`planDetail${index}`]}
                                        helperText={errors[`planDetail${index}`]}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        variant="outlined"
                                        value={planDetails[index][1]}
                                        fullWidth
                                        size="small"
                                        id={`value${index}`}
                                        label={Translate.value}
                                        name={index}
                                        onChange={this.handleChangeValue.bind(this)}
                                        onBlur={this.handleValidation.bind(this)}
                                        error = {!!errors[`value${index}`]}
                                        helperText={errors[`value${index}`]}
                                    />
                                </Grid>
                            </Grid>
                            {
                                index > 0 ?
                                    <IconButton
                                        className={this.classes.PassVisibilityIcon}
                                        onClick={() => this.handleRemoveField(index)}
                                    >
                                        <CloseRoundedIcon/>
                                    </IconButton>
                                    : null
                            }
                        </Grid>
                    ))
                }
                {
                    planDetails.length > 9 ? null :
                        <Grid item xs={12}>
                            <Button
                                onClick ={this.addPlanDetail.bind(this)}
                                color="primary"
                                size="small"
                                className="float-left"
                                startIcon={<AddRoundedIcon/>}
                            >
                                {Translate.addRow}
                            </Button>

                        </Grid>
                }

            </Grid>
        )
    }
}

export default withRouter(withSnackbar(withStyles(Styles)(PlanDetails)));