import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import HowToGetPoints from "./HowToGetPoints";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import {Translate} from "../../../../config/Translate";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Button from "@material-ui/core/Button/Button";
import CloseIcon from '@material-ui/icons/Close';

class HowToGetPointsButton extends React.Component {
    state={
        open:false
    }
    classes = this.props.classes;
    handleClose (){
        this.setState({open:false});
    }
    render(){
        let {open} = this.state;
        let {small} = this.props;
        return (
            <>
                {
                    small ?
                        <Tooltip title={Translate.howToGetPoints} arrow placement="top">
                            <IconButton color="primary" variant="contained" onClick={() => this.setState({open:true})}>
                                <InfoRoundedIcon/>
                            </IconButton>
                        </Tooltip>
                        :
                        <Button
                            onClick={() => this.setState({open:true})}
                            color="primary"
                            variant="outlined"
                            size="small"
                            startIcon={<InfoRoundedIcon/>}
                        >
                            {Translate.howToGetPoints}
                        </Button>
                }


                <Dialog maxWidth="xs" fullWidth onClose={this.handleClose.bind(this)} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose.bind(this)}>
                        {Translate.howToGetPoints}
                    </DialogTitle>
                    <IconButton aria-label="close" className="modal-close-btn"  onClick={this.handleClose.bind(this)}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <HowToGetPoints/>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(HowToGetPointsButton)));