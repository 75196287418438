import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';

export const Translate = {
    /********Specific*******/
    appName: "ایران خبره",
    headLine: "شبکه ارتباطی متخصصان و کارفرمایان کشور",
    expert: "متخصص",
    experts: "متخصصان",
    customer: "خدمات گیرنده",
    leaderboard: "جدول امتیازات",
    searchPlaceholder:'جستجوی متخصص‌ها...',
    resume: "رزومه",
    portfo: "نمونه کارها",
    soon: "بزودی...",
    services: "خدمت‌ها",
    planDetail: "ویژگی",
    planDetails: "ویژگی‌های پلن شما",
    planPricing: "قیمت‌گذاری",
    createAPlanToTurnOn: "حداقل یک تعرفه ایجاد کنید.",
    createAConsultationToTurnOn: "حداقل یک زمینه مشاوره ایجاد کنید.",
    createAJobToTurnOn: "حداقل یک شرط استخدام ایجاد کنید.",
    createATeachingPlanToTurnOn: "حداقل یک دوره آموزشی ایجاد کنید.",
    projects: "پروژه‌ها",
    pendingProjects: "پروژه‌های در انتظار تایید",
    pendingSkill: "در انتظار تایید...",
    confirmedProjects: "پروژه‌های تایید شده",
    doneProjects: "پروژه‌های انچام شده",
    teaching: "تدریس خصوصی",
    jobrequest: "استخدام",
    teamUp: "تیم سازی",
    consultation: "مشاوره",
    moneyManagement: "مدیریت مالی",
    studentsList: "لیست شاگردان",
    contents: "مقاله‌ها",
    help: "راهنما",
    addContent: "ایجاد مقاله جدید",
    viewContent: "مشاهده مقاله‌های قبلی",
    makeContent: "انتشار مقاله",
    editContent: "ویرایش مقاله",
    contentTitle: "عنوان",
    contentAdded: "مقاله‌ی شما ایجاد شد.",
    contentAddingFailed: "در ایجاد مقاله مشکلی به وجود آمده است.",
    contentEdited: "مقاله‌ی شما ویرایش شد.",
    contentEditingFailed: "در ویرایش مقاله مشکلی به وجود آمده است.",
    deleteContent: "مقاله‌ حذف شد.",
    deleteContentFailed: "در حذف مقاله مشکلی به وجود آمده است.",
    yourContentIsConfirmed: "محتوا شما به عنوان محتوای با کیفیت تایید شده است.",
    yourContentIsNotConfirmed: "محتوای شما از نظر کیفیت هنوز تایید نشده است.",
    resumeManagement: "مدیریت رزومه",
    resumeManagementDetails: "با تکمیل رزومه خود، شانس دیده شدن خود را افزایش دهید!",
    completeYourDetails: " اطلاعات تکمیلی خود را اضافه کنید تا پروفایل شما ساخته شود!",
    servicesDetails: "با تکمیل  اطلاعات مربوط به خدمات خود، شانس دیده شدن خود را افزایش دهید!",
    nowYouCanAddServices: "پروفایل شما ایجاد شد، لطفا رزومه خود را تکمیل کرده و خدمات خود را بیفزایید!",
    addService: "افزودن خدمت",
    baseDetails: "اطلاعات تکمیلی",
    completeDetails: "تکمیل اطلاعات",
    addYourBaseDetails: "ایجاد پروفایل",
    dearExpertAttention: "متخصص عزیز توجه نمایید!",
    dearUserAttention: "خدمت گیرنده عزیز توجه نمایید!",
    yourAccountIsNotCompleted: "اطلاعات شما هنوز تکمیل نشده است، لطفا آن‌ها را تکمیل کنید تا پروفایل شما ایجاد شود.",
    yourUserAccountIsNotCompleted: "اطلاعات شما هنوز تکمیل نشده است، لطفا آن‌ها را تکمیل کنید تا از تمامی خدمات سایت استفاده کنید.",
    thanks: "با تشکر!",
    educationsDetails: "سوابق تحصیلی",
    viewExperts: "مشاهده متخصصان",
    viewExpertsDetails: "در لیست متخصصان جستجو کنید و متخصص مدنظر خود را بیابید.",
    chooseSmartExpert: "انتخاب هوشمند متخصص",
    chooseSmartExpertDetails: "ویژگی مد نظر خود را وارد کنید تا لیستی از بهترین متخصصان آن زمینه برای شما ایجاد شود.",
    addEducation: "افزودن تحصیلات",
    editEducation: "ویرایش تحصیلات",
    educationLeft: "سابقه تحصیلی قابل ایجاد است.",
    projectLeft: "تعرفه خدمات قابل ایجاد است.",
    consultationLeft: "زمینه مشاوره قابل ایجاد است.",
    jobLeft: "شرایط استخدام قابل ایجاد است.",
    teachingLeft: "دوره قابل ایجاد است.",
    specialty_limit: "زمینه ‌کاری قابل افزودن است.",
    experienceLeft: "سابقه شغلی قابل ایجاد است.",
    portfoliosLeft: "نمونه کار قابل ایجاد است.",
    skillsLeft: "مهارت قابل افزودن است.",
    noSkillsLeft: "تمامی مهارت‌ها افزوده شد!",
    noSpecialtyLeft: "تمامی زمینه‌‌های‌ کاری افزوده شد!",
    addEducationsDetails: "سوابق تحصیلی خود را اضافه کنید!",
    addEducationsDetailsLong: "شما می‌توانید از این بخش سوابق تحصیلی خود را اضافه کنید، تا در صفحه پروفایل شما نمایش داده شود. ",
    addExperiencesDetails: "سوابق شغلی خود را اضافه کنید!",
    addExperiencesDetailsLong: "شما می‌توانید از این بخش سوابق شغلی خود را اضافه کنید، تا در صفحه پروفایل شما نمایش داده شود. ",
    addPortfoliosDetailsLong: "شما می‌توانید از این بخش نمونه کار خود را اضافه کنید، تا در صفحه پروفایل شما نمایش داده شود. ",
    addExperience: "افزودن سابقه شغلی",
    addInterest: "افزودن علاقه‌مندی",
    editExperience: "ویرایش سابقه",
    experiencesDetails: "سوابق شغلی",
    experienceDescription: "توضیحات بیشتر",
    addPortfoliosDetails: "نمونه کار خود را اضافه کنید!",
    addPortfolio: "افزودن نمونه کار",
    editPortfolio: "ویرایش نمونه کار",
    portfoliosDetails: "نمونه کارها",
    portfolioDescription: "توضیحات بیشتر",
    titlePortfolio: "نام نمونه کار",
    skillsDetails: "مهارت‌ها",
    specialtyDetails: "زمینه فعالیت",
    specialtyAndSkillsDetails: "تخصص‌ و مهارت‌ها",
    SkillsDetailsDescription: "مهارت‌های خود را جستجو و انتخاب کرده و برای هر یک در قسمت مهارت‌‌های من سطح خود را مشخص نمایید!",
    specialtyDetailsDescription: "تخصص (دسته‌بندی فعالیت) خود را جستجو و انتخاب نمایید!",
    interestsDetails: "‌علاقه‌مندی‌ها",
    manageInterests: "مدیریت علاقه‌مندی‌ها",
    youCanManageYoutInterests: "شما می‌توانید در این بخش مقالات مرتبط به زمینه‌های مورد علاقه خود را مشاهده کنید! ",
    nextStep: "مرحله بعد",
    prevStep: "مرحله قبل",
    followYourInterests: "‌علاقه‌هایتان خود را دنبال کنید!",
    interestsDetailsDescription: "علاقه‌مندی‌های خود را انتخاب کنید، تا در پروفایل شما نمایش داده شود و همچنین از اتفاقات مربوط به آن‌‌ها با خبر شوید!",
    headline: "عنوان فعالیت",
    bio: "مختصری از خود",
    company_headline: "عنوان فعالیت",
    company_bio: "مختصری از کسب و کار",
    company_id: "شماره ملی شرکت",
    national_id: "کد ملی شما",
    company_start_year: "سال شروع فعالیت",
    company_start_year_sample: "برای مثال 1399",
    about: "درباره من",
    field: "رشته / گرایش",
    grade: "پایه تحصیلی",
    university: "دانشگاه",
    disable_project: "دریافت پروژه غیر فعال است",
    enable_project: "امکان دریافت پروژه فعال است",
    addProjectsDetails: "تعرفه انجام پروژه خود را اضافه کنید!",
    addProject: "افزودن تعرفه",
    editProject: "ویرایش تعرفه",
    projectsDetails: "انجام پروژه",
    numberOfCalls: "تعداد تماس‌ها",
    projectsDetailsLong: "شما می‌توانید از این بخش شرایط دریافت پروژه را ذکر کرده، همچنین برای هر نوع خدمت تعرفه جداگانه ایجاد کنید!",
    projectsDetailsTitle: "شرایط انجام پروژه را درج کنید",
    areYouReadyToGetProject: "آیا آمادگی انجام پروژه دارید؟",
    projectsDetailsHelp: "در این قسمت می‌توانید شرایط خود را برای دریافت و انجام پروژه ذکر کنید. تعرفه‌های خود را برای انجام پروژه‌ها تعیین کنید.",
    projectsDetailsHelp2: "به راحتی مشتریان خود را افزایش دهید و ارتباط مستقیم داشته باشید.",
    areYouReadyToGetConsultation: "آیا آمادگی ارائه مشاوره دارید؟",
    consultationsDetailsHelp: "در این قسمت می‌توانید شرایط خود را برای ارائه خدمات مشاوره‌ای ذکر کنید. تعرفه‌های خود را برای ارائه خدمات تعیین کنید.",
    consultationsDetailsHelp2: "به راحتی مراجعین خود را افزایش دهید و ارتباط مستقیم داشته باشید.",
    areYouReadyToGetTeam: "آیا آمادگی تیم سازی دارید؟",
    teamsDetailsHelp: "در این قسمت می‌توانید شرایط خود را برای تیم سازی ذکر کنید.",
    teamsDetailsHelp2: "به راحتی دایره افراد متخصص خود را افزایش دهید و ارتباط مستقیم داشته باشید.",
    areYouReadyToGetJob: "آیا آمادگی استخدام شدن دارید؟",
    jobsDetailsHelp: "در این قسمت می‌توانید شرایط خود را برای دریافت و پیشنهادات استخدام ذکر کنید. شرایط خود را برای استخدام شدن تعیین کنید.",
    jobsDetailsHelp2: "به راحتی درخواست‌های همکاری خود را افزایش دهید و ارتباط مستقیم داشته باشید.",
    areYouReadyToGetTeaching: "آیا آمادگی تدریس خصوصی دارید؟",
    teachingsDetailsHelp: "در این قسمت می‌توانید شرایط خود را برای تدریس ذکر کنید. دوره‌های خود را برای انجام تدریس تعیین کنید.",
    teachingsDetailsHelp2: "به راحتی شاگردان خود را افزایش دهید و ارتباط مستقیم داشته باشید.",
    projectDescription: "توضیحات بیشتر",
    projectMainDescription: "درباره شرایط انجام پروژه، مانند شیوه پرداخت،‌ نحوه پیش‌برد کار، مزایای رقابتی خود و... در این قسمت توضیح دهید.",
    titleProject: "نام تعرفه",
    disable_job: "امکان استخدام غیر فعال است",
    enable_job: "امکان دریافت پیشنهاد استخدام فعال است",
    addJobsDetails: "شرایط استخدام شدن خود را اضافه کنید!",
    addJob: "افزودن شرایط استخدام",
    editJob: "ویرایش شرایط استخدام",
    jobsDetails: "استخدام",
    jobDescription: "توضیحات بیشتر",
    jobMainDescription: "توضیحاتی راجع به نحوه ارائه کار، ضمانت، شرایط استخدام و... ارائه دهید. ",
    titleJob: "سمت مورد نظر",
    disable_consultation: "امکان انجام مشاوره غیر فعال است",
    enable_consultation: "امکان انجام مشاوره فعال است",
    addConsultationsDetails: "شرایط انجام مشاوره خود را اضافه کنید!",
    addConsultation: "افزودن زمینه مشاوره",
    editConsultation: "ویرایش زمینه مشاوره",
    consultationsDetails: "مشاوره",
    consultationDescription: "توضیحات بیشتر",
    consultationMainDescription: "توضیحات راجع به نحوه ارائه مشاوره، زمان خالی، شرایط مشاوره و... ",
    titleConsultation: "زمینه مشاوره",
    disable_team: "امکان تیم سازی غیر فعال است",
    enable_team: "امکان دریافت پیشنهاد تیم سازی فعال است",
    teamsDetails: "تیم سازی",
    teamMainDescription: "توضیحات راجع به نحوه همکاری، زمان همکاری، توقعات و... ",
    courses: "سرفصل‌های تدریسی",
    course: "سرفصل‌ تدریسی",
    disable_teaching: "امکان تدریس خصوصی غیر فعال است",
    enable_teaching: "امکان تدریس خصوصی فعال است",
    addTeachingsDetails: "دوره‌های تدریس خصوصی خود را اضافه کنید!",
    addTeaching: "افزودن دوره",
    editTeaching: "ویرایش دوره",
    teachingsDetails: "تدریس خصوصی",
    teachingDescription: "توضیحات بیشتر",
    teachingMainDescription: "راجع به نحوه ارائه دروس، محل تدریس، نوع پرداخت و... توضیحات دهید! ",
    titleTeaching: "نام دوره",
    moveToUser: "ایجاد اکانت کارفرمایی",
    moveToExpert: "ایجاد اکانت متخصص",
    moveToExpertDescription: "شما به عنوان کارفرما فعالیت داشته اید، آیا می‌خواهید خود را به عنوان متخصص نیز ثبت نام کنید؟",
    moveToUserDescription: "شما به عنوان متخصص فعالیت داشته اید، آیا می‌خواهید خود را به عنوان کارفرما نیز ثبت نام کنید؟",
    doYouNeedExpertAccount: "در پلتفرم ایران خبره امکان فعالیت به صورت کارفرما و متخصص فراهم شده است، در صورتی که می‌خواهید به عنوان متخصص نیز فعالیت داشته باشید، هم‌اکنون ثبت نام کرده،‌ اظلاعات خود را وارد کنید و در لیست متخصصان ما نمایش داده شوید.",
    doYouNeedUserAccount: "در پلتفرم ایران خبره امکان فعالیت به صورت کارفرما و متخصص فراهم شده است، در صورتی که می‌خواهید به عنوان کارفرما نیز فعالیت داشته باشید، هم‌اکنون ثبت نام کرده،‌ اظلاعات خود را وارد کنید و به عنوان کارفرما از خدمات متخصصان بهره‌مند شوید.",
    roleUpgraded: "سطح دسترسی شما ارتقا پیدا کرد!",
    type_person: "شخصیت حقیقی",
    type_company: "شخصیت حقوقی",
    company_type: "نوع کسب و کار",
    company_name: "نام کسب و کار",

    /*********Global**********/


    chooseColor: "انتخاب رنگ",
    account: "حساب کاربری",
    viewMore: "بیشتر بخوانید",
    male: "مرد",
    female: "زن",
    time_duration: "مدت زمان",
    value: "توضیح",
    gender: "جنسیت",
    payment_type: "نحوه پرداخت",
    payment_type_job: "نحوه فعالیت",
    teaching_price: "هزینه (ساعتی)",
    priceConsultation: "هزینه مشاوره",
    minPrice: "شروع قیمت",
    maxPrice: "حداکثر قیمت",
    dynamicPriceDescription: "قیمت به صورت بازه‌ای",
    addRow: "افزودن ردیف",
    slug: "آدرس",
    manageProducts: "مدیریت محصولات",
    manageVariables: "مدیریت ویژگی محصولات",
    manageVariablesOrder: "مدیریت  ترتیب ویژگی‌ها",
    chooseVariables: "انتخاب ویژگی‌ها",
    makeVariable: "ایجاد ویژگی",
    editVariable: "ویرایش ویژگی",
    variableName: "نام ویژگی",
    variables: "ویژگی‌ها",
    variable: "ویژگی",
    addNewVariable: "افزودن ویژگی",
    addNewVariableDescription: "برای افزودن ویژگی دکمهEnter را بزنید!",
    addYourvariableIfYouCatFind: "اگر ویژگی مد نظر خود را نمی‌یابید آن را ایجاد کنید.",
    addVariable: "افزودن ویژگی",
    headLineMakeYourFirstProduct: "محصولات خود را ایجاد کنید، محتوای آن‌ها را بهبود دهید و از ویژگی های دونا لذت ببرید!",
    productDetails: "اطلاعات محصول",
    myProducts: "محصولات من",
    opportunity: "فرصت همکاری",
    buyOpportunity: "خرید فرصت همکاری",
    buyOpportunityDetails: "با خرید فرصت همکاری امکان اشتراک گذاری اطلاعات تماس خود را با متقاضیان خدمت خود افزایش دهید.",
    opportunities: "فرصت‌های همکاری",
    opp: "فرصت‌",
    customer_opportunity: "فرصت  دعوت به همکاری",
     makeOrder: "افزودن به سبدخرید",
    removeFromCart: "حذف از سبد",
    cart: "سبد خرید",
    buyer: "خریدار",
    emtyCart: "سبدخرید خالی است!",
    editProduct: "ویرایش محصول",
    orderedProducts: "محصولات خریداری شده!",
    makeProduct: "ایجاد محصول جدید",
    purchaseProduct: "سفارش محصول",
    resumeOrder: "ادامه سفارش",
    productName: "نام محصول",
    technicalName: "نام فنی",
    productBio: "توضیح کوتاه",
    productDescription: "توضیحات",
    product: "محصول",
    quantity: "تعداد",
    viewProduct: "مشاهده محصول",
    viewArticle: "مشاهده مقاله",
    interestedArticles: "مقالات مورد علاقه",
    article: "مقاله",
    viewOrders: "مشاهده سفارش‌ها",
    viewUserDetails: "اطلاعات کاربر",
    manageOrders: "مدیریت سفارش‌ها",
    manageOpportunities: "مدیریت فرصت‌ها",
    reportAViolation: "اعلام تخلف",
    sendingReportDone: "توضیحات تخلف برای مدیریت ارسال شد.",
    describeViolation: "توضیح تخلف",
    describeViolationLong: "لطفا تخلف روی داده را با ذکر دقیق جزئیات مورد نیاز برای ما ارسال کنید تا در اسرع وقت رسیدگی شود.",
    send: "ارسال",
    shopOrders: "فروش‌ها",
    viewShopOrders: "مشاهده فروش‌ها",
    products: "محصولات",
    productsList: "لیست محصولات",
    productOwner: "مالک محصول",
    manageComments: "مدیریت کامنت‌ها",
    comments: "کامنت‌ها",
    commentForReplayDescription : "اگر قصد پاسخ به کامنتی را دارید از اینجا انتخاب کنید",
    description : "توضیح",
    noProduct:"محصولی وجود ندارد",
    chooseProductForComment : "انتخاب محصول",
    commentEdited:"کامنت ویرایش شد",
    commentAdded:"کامنت ایجاد شد",
    commentAddingFailed:"افزودن کامنت با مشکل مواجه شد.",
    commentEditingFailed:"ویرایش کامنت با مشکل مواجه شد.",
    commentRemoved: "کامنت با موفقیت حذف شد",
    dataRemoved: "اطلاعات با موفقیت حذف شد",
    dataRemovingFailed: "حذف اطلاعات با مشکل رو به رو شد.",
    commentRemovingFailed: "حذف کامنت با مشکل رو به رو شد.",
    deleteSelectedComments: "کامنت‌های انتخاب شده حذف شدند.",
    deleteSelectedUsers: "کاربران انتخاب شده حذف شدند.",
    role: "سطح دسترسی",
    allRoles: "تمام سطوح دسترسی",
    allUserTypes: "تمام انواع کاربران",
    editComment: "ویرایش کامنت",
    addComment: "افزودن کامنت",
    parentCommentDesc : "شما درحال پاسخ دادن به کامنت زیر هستید:",
    productRating:"امتیاز محصول",
    confirmStatus:"وضعیت تایید",
    removeComment: "حذف کامنت",
    translateFront: "ترجمه فرانت",
    translateFrontDesc: "در صورتی که تغییرات را با زدن دکمه ثبت اطلاعات انجام داده‌اید و تغییرات در این قسمت مشاهده نشد، از قسمت فرانت بررسی نمایید.",

    thankYou: "با تشکر!",
    settings: "تنظیمات",
    exit: "خروج از سایت",
    myAccount: "حساب کاربری",
    makeDraft: "ذخیره پیش‌نویس",
    userPanel: "پنل کاربری",
    draft: "پیش‌نویس",
    published: "منتشر شده",
    someFieldsHasError: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی کنید",
    search: "جستجو",
    author: "نویسنده",
    choose: "انتخاب",
    updateTime: "بروزرسانی",
    filters: "فیلتر‌ها",
    province: "استان",
    city: "شهر",

    thumbnail: "تصویر",
    publishStatus: "وضعیت انتشار",
    keywordsLabel: "عبارات کلیدی",
    similarWords: "عبارات مشابه",
    allKeywordsAreTyped: "امکان افزودن کلمات کلیدی بیشتر ندارید. ",
    keywordsDescription: "تگ‌های محصول خود را بنویسید. (برای مثال، طراحی مارکت پلیس، سئو کلاه سیاه و...)",
    seoDescription: "توضیح کوتاه",
    linkPlaceholder:"یک آدرس اینترنتی وارد کنید",
    addImage: "افزودن تصویر",
    imageName: "نام فایل",
    ownerMedia:"ناشر",
    linkCopied:"لینک کپی شد.",
    uploadSuccess: "آپلود با موفقیت انجام شد",
    chooseFile:"انتخاب فایل",
    chooseFileForPortfo:"فایل نمونه کار",
    chooseFileForPortfoDesc:"در صورت نیاز فایلی از نمونه‌کار خود را در این قسمت به اشتراک بگذارید",
    editFile:"فایل با موفقیت ویرایش شد",
    editFileFailed:"ویرایش فایل با مشکل رو به رو شد.",
    tableAdded:"جدول ایجاد شد",
    tableAddingFailed:"افزودن جدول با مشکل مواجه شد.",
    deleteTable:"جدول با موفقیت حذف شد",
    deleteTableFailed:"حذف جدول با مشکل رو به رو شد.",
    tableEdited:"جدول ویرایش شد.",
    tableEditingFailed:"ویرایش جدول با مشکل مواجه شد.",
    selfFiles: "فایل‌های من",
    adminsFiles: "فایل‌های مدیران سایت",
    everyBodyFiles: "فایل‌های همه",
    uploading: "درحال آپلود...",
    uploadDone: "فایل آپلود شد.",
    allTypes: "همه فرمت‌ها",
    image: "تصویر",
    video: "ویدیو",
    document: "سند",
    do: "انجام",
    chooseVideo:"انتخاب ویدیو",
    multiImport:"انتخاب رسانه‌ها",
    filesTable: "فایل‌های ذخیره شده",
    fileManager: "رسانه‌ها",
    lastUpdate: "آخرین بروزرسانی",
    userDetail: "اطلاعات کاربر",
    usersManagement: "مدیریت کاربران",
    paymentManagement: "حسابداری",
    allUsers: "مشاهده کاربران",
    usersActivity: "فعالیت‌ کاربران",
    addUser: "افزودن کاربر",
    editUser: "ویرایش کاربر",
    editDetails: "ویرایش پروفایل",
    logoSize600: "سایز مناسب 600x600px می‌باشد.",
    thumbnailSizeArticle: "سایز مناسب 1200x800px می‌باشد.",
    logo: "لوگو فروشگاه",
    cover: "کاور فروشگاه",
    chooseMedia: "انتخاب رسانه",
    submitErrorFile:"فایل ارسالی مشکل دارد.",
    storageIsFull:"فضای ذخیره سازی شما پر‌ شده است!",
    imageSizeWidth700: "عرض مناسب تصویر 700px می‌باشد.",
    userAvatarSet:"تصویر پروفایل شما بروز شد.",
    profileImage: "تصویر پروفایل",
    viewProfile: "مشاهده پروفایل",
    allResumePartsAreCompleted: "تمام بخش‌های رزومه تکمیل است",
    createProfile: "ایجاد پروفایل",
    profileCreated: "پروفایل ایجاد شده!",
    addServices: "افزودن خدمات",
    createContent: "تولید محتوا",
    createProfileDetails: "پروفایل شما ایجاد نشده است!",
    createProfileDetailsCheck: "پروفایل شما ایجاد نشده است. لطفا ابتدا پروفایل خود را بسازید!",
    firstCreateProfile: "ابتدا پروفایل خود را ایجاد کنید!",
    completeResumeDetails: "رزومه خود را تکمیل کنید!",
    addServicesDetails: "خدمات خود را اضافه کنید!",
    createContentDetails: "شانس دیده شدن خود را افزایش دهید!",
    expertsGuidLine: "راهنمای مسیر",
    expertsGuidLineDescription: "گام به گام پیش بروید و با ساخت یک پروفایل حرفه‌ای، برندسازی کنید و خدمات خود را بفروشید!",
    completeResume: "تکمیل رزومه",
    userAvatarSetFailed:"در تغییر تصویر پروفایل مشکلی به وجود آمده است.",
    loadingThumbnailFailed: "در بارگذاری تصویر مشکلی پیش آمده",
    ChooseImageLink:" برای کپی لینک، سایز مورد نظر را انتخاب کنید.",
    deleteFile:"فایل با موفقیت حذف شد",
    deleteFileFailed:"حذف فایل با مشکل رو به رو شد.",
    deleteSelectedFiles:"فایل ها با موفقیت حذف شدند",
    alt: "متن جایگزین (alt)",
    chooseImage: "انتخاب تصویر",
    emptyImage: "تصویری انتخاب کنید!",
    descriptionPlaceHolder: "محتوای مقاله خود را بنویسید",
    words:"کلمه",
    characters:"کاراکتر",
    uploadImage: "آپلود رسانه",
    ThumbnailTitle: "تصویر شاخص",
    loading: "درحال بارگذاری...",
    login: "ورود",
    homePage: "صفحه اصلی",
    contactPage: "پشتیبانی",
    rulesPage: "قوانین استفاده",
    aboutPage: "دوناگرام چیست؟‌",
    shop: "فروشگاه‌",
    shopDetail: "در این قسمت می‌توانید فرصت‌های همکاری مورد نظر خود را خریداری کنید!",
    shopDetailLongTitle: "فرصت همکاری چیست؟",
    shopDetailLong: "هر فرصت همکاری معادل یک ارتباط مستقیم است (با مشتری یا با متخصص). اگر متخصص هستید، می‌توانید با شارژ فرصت همکاری، خود را در معرض دید قرار دهید تا مشتریان مستقیم با  شما تماس بگیرند. اگر کارفرما هستید می‌توانید تخصص مورد نظر خود را جستجو کرده و از میان متخصصان با تعدای ( متناسب با فرصت همکاری) تماس کرده و از آن‌ها دعوت به همکاری نمایید.",
    expertShop: "فروشگاه متخصص‌ها",
    employerShop: "فروشگاه کارفرمایان",
    chooseTypeOfShop: "انتخاب نوع فروشگاه",
    orLoginWith: "ورود با",
    backToHomePage: "بازگشت به صفحه اصلی",
    emailOrMobile: "ایمیل یا موبایل",
    first_name: "نام",
    last_name: "نام‌خانوادگی",
    username: "نام کاربری",
    usernameHelper: "حداقل ۵ کاراکتر، شامل حروف، اعداد انگلیسی و نقطه",
    userName: "نام و نام‌خانوادگی",
    mobile: "موبایل",
    mobileSample: "مثل 09123456789",
    email: "ایمیل",
    profile: "پروفایل",
    verifyEmail: "تایید ایمیل",
    password: "رمزعبور",
    comment: "کامنت",
    passwordConfirm: "تکرار رمزعبور",
    passwordStrength:"حداقل 5 کاراکتر، شامل عدد و حروف انگلیسی",
    passwordChanged:"رمزعبور با موفقیت تغییر کرد.",
    currentPasswordError:"رمزعبور فعلی صحیح نیست.",
    currentPassword:"رمزعبور فعلی",
    register: "ثبت‌نام",
    dontHaveAccount: "ثبت‌نام نکرده‌اید؟",
    haveAccount: "ثبت‌نام کرده‌اید؟",
    loginError:"ایمیل یا رمزعبور اشتباه است.",
    forgetPass:"رمزعبور خود را فراموش کرده‌اید؟",
    loginWithToken:"ورود با رمز یکبار مصرف",
    dashboard:"داشبورد",
    emptyUsername: "نام کاربری خود را وارد کنید.",
    emptyTableCells: "جدول خالی است.",
    wrongUsername: "نام کاربری اشتباه است.",
    NotFreeUsername: "این نام کاربری قبلا انتخاب شده.",
    existEmail: "این ایمیل قبلا ثبت شده.",
    existMobile: "این شماره موبایل قبلا ثبت شده.",
    emptyEmail: "ایمیل خود را وارد کنید.",
    emptyMobile: "شماره موبایل خود را وارد کنید.",
    wrongEmail: "فرمت ایمیل اشتباه است.",
    wrongMobile: "فرمت شماره موبایل اشتباه است.",
    wrongPrice: "قیمت گذاری اشتباه است",
    final_price: "هزینه کل",
    transferPrice: "هزینه ارسال",
    transfer_type: "نحوه ارسال",
    wrongSheba: "شماره شبا اشتباه است. (۲۴ عدد)",
    emptyPassword: "رمز عبور را وارد کنید.",
    weakPassword: "رمز عبور ضعیف است.",
    notEqualPassword: "رمز عبور و تکرار آن یکسان نیست.",
    lessPost: "نوشته‌ی پست کم است",
    maxLength: "نوشته طولانی است",
    SelectFromSelect:"انتخاب کنید",
    emptyField:'فیلد خالی است.',
    fillPrevFieldFirst:'ابتدا فیلد قبل را پر کنید.',
    emptyName: "نام ضروری است",
    emptyLastName: "نام خانوادگی ضروری است",
    noMatchPassword: "تاییده با رمز عبور یکسان نیست",
    emptyTagName:"نام برچسب ضروری است",
    emptyBrandName:"نام برند ضروری است",
    wrongCode:"شماره اشتباه است",
    wrongDate:"تاریخ اشتباه است",
    maxLength60:"طول این فیلد نباید بیش از ۶۰ کاراکتر باشد.",
    maxLength70:"طول این فیلد نباید بیش از ۷۰ کاراکتر باشد.",
    maxLength150:"طول این فیلد نباید بیش از ۱۵۰ کاراکتر باشد.",
    maxLength50:"طول این فیلد نباید بیش از ۵۰ کاراکتر باشد.",
    maxLength90:"طول این فیلد نباید بیش از ۹۰ کاراکتر باشد.",
    maxLength255:"طول این فیلد نباید بیش از ۲۵۵ کاراکتر باشد.",
    maxLength300:"طول این فیلد نباید بیش از ۳۰۰ کاراکتر باشد.",
    maxLength400:"طول این فیلد نباید بیش از ۴۰۰ کاراکتر باشد.",
    maxLength500:"طول این فیلد نباید بیش از ۵۰۰ کاراکتر باشد.",
    maxLength1000:"طول این فیلد نباید بیش از ۱۰۰۰ کاراکتر باشد.",
    maxLength4000:"طول این فیلد نباید بیش از ۴۰۰۰ کاراکتر باشد.",
    emptyEmailOrMobile:"ایمیل یا شماره موبایل خود را وارد کنید.",
    dear:"عزیز",
    errorHappens:"در بارگذاری سایت خطایی رخ داده است!",
    allChangesSaved:"تمام تغییرات ذخیره شد.",
    saveChanges:"ذخیره تغییرات",
    allChangesSavingFailed:"خطایی رخ داده است.",
    registerSuccess: "کاربر با موفقیت ثبت شد.",
    registerError: "خطا در ایجاد کاربر",
    cantLoadData:"در بارگیری مطالب مشکلی است.",
    fileSizeWeak:"کیفیت فایل پایین است.",
    imageSize250kb:"حجم تصویر باید کمتر از ۲۵۰kb باشد.",
    fileSize500kb:"حجم تصویر باید کمتر از ۵۰۰kb باشد.",
    fileSize100mb:"حجم تصویر باید کمتر از ۱۰۰mb باشد.",
    fileSize1mb:"حجم فایل باید کمتر از ۱mb باشد.",
    fileSize20mb:"حجم فایل باید کمتر از ۲۰mb باشد.",
    makingTokenError:"در ایجاد کد تایید مشکلی به وجود آمده است. ",
    youLoggedOff:"شما از پنل خارج شدید",
    mobileVerified:"شماره موبایل شما تایید شد.",
    wrongToken:"کد تایید اشتباه است. ",
    mobileVerify: "تایید موبایل",
    verifyMobile: "تایید شماره موبایل",
    sendTokenText1:"رمز یکبارمصرف شما به شماره",
    sendTokenText2:"ارسال شد.",
    sendTokenTextResend:"رمز یکبار مصرف مجددا برای شما ارسال شد.",
    sendTokenText3:"زمان باقی مانده تا ارسال مجدد پیامک",
    logOut: "خروج از پنل",
    confirm: "تایید",
    token:"کد تایید",
    forgetPassword: "فراموشی رمزعبور",
    invalidMobileNumber:"این شماره در سیستم وجود ندارد",
    recoverPassword:"بازیابی رمزعبور",
    recoverPasswordText:"برای بازیابی رمز عبور، شماره موبایل خود را وارد کنید.",
    sendAgain:"ارسال دوباره",
    changePassword: "تغییر رمزعبور",
    newPassword:"رمزعبور جدید",
    newPasswordConfirm:"تکرار رمزعبور جدید",
    loginWithUserPass:"ورود با اطلاعات کاربری",
    addYourNumberFirst:"برای ورود به سایت، شماره موبایل خود را وارد کنید.",
    connectionError:'اینترنت شما قطع است.',
    copyright: " تمام حقوق محفوظ است © ",
    unreadNotifications: "اعلان‌های خوانده نشده",
    readNotifications: "اعلان‌های خوانده شده",
    noNotifications: "اعلانی وجود ندارد!",
    nothingFound: "صفحه وجود ندارد!",
    notFoundPage: "صفحه وجود ندارد!",
    setDetails: "ثبت اطلاعات",
    detailsSet:"اطلاعات ثبت شد.",
    detailsSetFailed:"ثبت اطلاعات با مشکل مواجه شد.",
    detailsGetFailed:"دریافت اطلاعات با مشکل مواجه شد.",
    mobilSample:"نمونه: 09121234567",
    confirmationStatus:"وضعیت تایید",
    contentConfirmationStatus:"کیفیت محتوا",
    confirmed:"تایید شده",
    unConfirmed:"در انتظار تایید",
    admin: "مدیر سایت",
    adminSmall: "مدیر",
    supportUser: "مسئول پشتیبانی",
    support: "پشتیبانی",
    adminType: "مدیران سایت",
    normalType: "سایر کاربران",
    usersLog: "فعالیت‌های کاربران",
    someFieldsHasErrorPleaseCheckThem: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی نمایید",
    duplicateName: 'این برند متعلق به شخص دیگری است.',
    dataAdded: "اطلاعات شما ایجاد شد.",
    dataAddingFailed: "در ایجاد اطلاعات مشکلی به وجود آمده است.",
    dataEdited: "اطلاعات شما ویرایش شد.",
    dataEditingFailed: "در ویرایش اطلاعات مشکلی به وجود آمده است.",
    deleteData: "اطلاعات حذف شد.",
    deleteDataFailed: "در حذف این اطلاعات مشکلی به وجود آمده است.",
    makeCategory: "ایجاد دسته‌بندی",
    editCategory: "ویرایش دسته‌بندی",
    categoryName: "نام دسته",
    categoryParentName: "دسته والد",
    categories: "دسته‌بندی‌ها",
    pleaseChooseCategoriesFirst: "لطفا ابتدا دسته‌بندی را انتخاب کنید!",
    category: "دسته‌بندی‌",
    chooseCategory: "انتخاب دسته‌بندی‌",
    chooseSubCategory: "انتخاب زیر دسته‌",
    chooseMainCategory: "انتخاب دسته‌ اصلی",
    manageCategories: "مدیریت دسته‌بندی‌ها",
    manageSkills: "مدیریت مهارت‌ها",
    mySkills: "مهارت‌های من",
    skillLevel1: "مبتدی",
    skillLevel2: "کاربلد",
    skillLevel3: "حرفه‌ای",
    searchForSkills: "جستجوی مهارت...",
    managePages: "مدیریت صفحات",
    manageAbout: "صفحه درباره‌ما",
    manageTerms: "صفحه قوانین مقررات",
    manageContact: "صفحه تماس با ما",
    pageDetails: "اطلاعات صفحه",
    skillIsNotConfirmed: "در انتظار تایید",
    youCantAddMoreSkillsUntileConfirmation: "امکان افزودن مهارت بیشتر قبل از تایید موارد قبلی وجود ندارد!",
    manageSkillsOrder: "مدیریت  ترتیب مهارت‌ها",
    manageCategorysOrder: "مدیریت  ترتیب دسته‌ها",
    chooseSkills: "انتخاب مهارت‌ها",
    makeSkill: "ایجاد مهارت",
    editSkill: "ویرایش مهارت",
    skillName: "نام مهارت",
    skills: "مهارت‌ها",
    skill: "مهارت",
    addNewSkill: "افزودن مهارت",
    addNewSkillDescription: "برای افزودن مهارت دکمه Enter را بزنید!",
    addYourskillIfYouCatFind: "اگر مهارت مد نظر خود را نمی‌یابید آن را ایجاد کنید.",
    noSkillFound: "مهارتی یافت نشد!",
    addSkill: "افزودن مهارت",
    user: "کارفرما",
    both: "متخصص-کارفرما",
    realUser: "کاربر",
    duplicateInstagram: 'این اکانت متعلق به شخص دیگری است.',
    markAsRead: 'خوانده شد!',
    dataFetchingError: 'اطلاعات مورد نظر یافت نشد!',
    youAreInSomeOneAccount: "شما در حساب کاربری فرد دیگری هستید!",
    backToMyAccount: "بازگشت",
    loginToUserAccount: "ورود به اکانت این کاربر",
    userShouldConfirmMobileFirst: "ابتدا باید شماره موبایل کاربر تایید شود!",
    activity: 'فعالیت',
    gallery: 'سایر تصاویر',
    removeImage: 'حذف تصویر',
    remove: 'حذف',
    removeRequest: 'حذف درخواست',
    discountMoreThanPrice:"میزان تخفیف از کل قیمت بیشتر است",
    discount_percent:"درصد تخفیف",
    discount_price:"قیمت با تخفیف",
    discount:"تخفیف",
    factor:"فاکتور",
    bill:"صورت حساب",
    price:"قیمت",
    priceJob:"حداقل دستمزد",
    totalPrice:"قیمت کل",
    cancel:"لغو!",
    youDontHavePermission:"شما دسترسی مجاز ندارید!",
    pressEnterAfterEachKeyword:"پس از نوشتن هر عبارت دکمه Enter را بزنید!",
    addMoreKeywords:"مقاله باید بین ۳ تا ۵ عبارت کلیدی داشته باشد.",
    addCategories:"مقاله باید حداقل یک دسته‌بندی داشته باشد.",
    chooseBeginDate:"تاریخ شروع",
    chooseEndDate:"تاریخ پایان",
    chooseDate:"انتخاب تاریخ",
    doFilter:"اعمال فیلتر",
    removeFilter:"حذف فیلتر",
    noLogFound:"فعالیتی یافت نشد!",
    roleHelper:"انتخاب سطح دسترسی کاربر",
    goBackToPanel:"بازگشت به پنل",
    emailVerified:"ایمیل تایید شد!",
    emailNotVerified:"ایمیل تایید نشد!",
    visitsChart:"نمودار بازدید‌ از پروفایل شما",
    googleAnalytics:"گوگل آنالیتیکس",
    liveUsers:"کاربران آنلاین",
    newProducts:"محصولات جدید",
    noProductsFound:"محصولی یافت نشد!",
    newShops:"فروشگاه‌های جدید",
    newRegistrations:"ثبت‌نام‌های جدید",
    todayAllUnRegisteredUsers:"بازدید‌های امروز",
    transactionType:"نوع تراکنش",
    depositMoney:"شارژ کیف پول",
    depositMoneyDescription:"مقدار مورد نظر برای شارژ کیف پول خود را در این قسمت وارد کنید.",
    withdrawMoney:"برداشت از کیف پول",
    withdrawMoneyDescription:"مقدار مورد نظر برای برداشت از کیف پول خود را در این قسمت وارد کنید.",
    deposit:"شارژ",
    withdraw:"برداشت",
    amount:"مبلغ",
    status:"وضعیت",
    not_payed:"در انتظار پرداخت",
    orderPayed:"مبلغ سفارش پرداخت شد.",
    orderPayingFailed:"در پرداخت مبلغ مشکلی وجود دارد!",
    pending:"در انتظار واریز",
    myWallet: "کیف پول",
    fromWallet: "پرداخت از کیف پول",
    onlinePayment: "پرداخت آنلاین",
    orders: "سفارش‌ها",
    orderSummary: "خلاصه سفارش‌ها",
    payOrder: "پرداخت از کیف پول",
    payOrderDetail: "در این بخش می‌توانید کیف پول خود را شارژ کنید و پرداخت را از طریق کیف پول انجام دهید.",
    payWithWallet: "پرداخت از کیف پول",
    purchase: "پرداخت",
    withdrawRequest: "درخواست واریز",
    transactionTrackingCode: "کد پیگیری",
    postal_code: "کد پستی",
    postalInfo: "اطلاعات پستی",
    pleaseCompleteYourPostalInfo: "لطفا اطلاعات پستی خود را تکمیل کنید!",
    postalInfoDescription: "لطفا اطلاعات پستی خود را در این قسمت وارد کنید، دکمه ثبت اطلاعات را بزنید و سپس اقدام به پرداخت کنید.",
    address: "آدرس",
    addRefrenceIdFirst: "ابتدا کد پیگیری را وارد کنید!",
    myWalletBalance: "موجودی کیف پول",
    myPoints: "امتیازات من",
    howToGetPoints: "آموزش نحوه امتیاز‌گیری",
    howToPay: "نحوه پرداخت",
    point: "امتیاز",
    point_title: "امتیاز",
    point_count: "تعداد",
    point_badge: "نماد",
    point_time: "زمان دریافت",
    congratulation: "تبریک!",
    getPoints: "امتیاز، دریافت کردید.",
    balance: "موجودی",
    pleaseVerifyFirst: "برای برداشت پول اطلاعاتتان را تکمیل کنید!",
    verificationDescription: "برای انجام تراکنش‌های آنلاین با سایت نیاز به تایید اطلاعات بانکی می‌باشد.",
    verificationDetails: "اطلاعات بانکی",
    verificationDataSets: "اطلاعات بانکی ثبت شد!",
    manageVerifications: "مدیریت اطلاعات بانکی",
    ManageUserVerification: "مدیریت اطلاعات بانکی کاربر",
    bank_name: "نام بانک",
    viewUserBankInfo: "مشاهده اطلاعات بانکی کاربر",
    sheba: "شماره شبا",
    melli_card_image: "تصویر کارت ملی",
    credit_card_image: "تصویر کارت بانکی",
    activeStatus: "وضعیت",
    active: "فعال",
    deactive: "غیر فعال",
    manageWallets: "مدیریت کیف پول‌ها",
    manageUserWallet: "مدیریت تراکنش‌های کاربر",
    yourBankInfoIsActive: "اطلاعات بانکی شما تایید شده و امکان انجام تراکنش برای شما فراهم است.",
    yourBankInfoIsInchecking: "اطلاعات بانکی شما در دست بررسی است.",
    payed: "پرداخت شد",
    is_payed: "پرداخت شده",
    areYouSure: "آیا از انجام این کار مطمئنید؟",
    isInterest: "آیا این دسته یک علاقه‌مندی است؟",
    isSpecialty: "آیا این دسته یک تخصص است؟",
    interest: "علاقه‌مندی",
    specialty: "تخصص",
    youRichedThelimit: "امکان افزودن موارد بیشتر وجود ندارد!",
    yes: "بله",
    no: "خیر",
    deleteIt: "پاک شود",
    position: "سمت",
    place: "محل فعالیت",
    duration: "مدت فعالیت (ماه)",
    durationSimple: "مدت فعالیت",
    month: "ماه",
    from: "زمان شروع",
    to: "زمان پایان",
    workdays:"روزهای کاری",
    thursdays:"پنج‌شنبه‌ها",
    holidays:"جمعه‌ها و روزهای تعطیل",
    nowWorkingHere: " هم‌ا‌‌کنون در این محل فعالیت دارم.",

};

export const DataTableLocalization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} از {count}',
        labelRowsSelect: "ردیف",
        labelRowsPerPage: "تعداد ردیف‌ها در هر صفحه",
        firstAriaLabel: "صفحه اول",
        firstTooltip: "صفحه اول",
        previousAriaLabel: "صفحه قبل",
        previousTooltip: "صفحه قبل",
        nextAriaLabel: "صفحه بعد",
        nextTooltip: "صفحه بعد",
        lastAriaLabel: "صفحه آخر",
        lastTooltip: "صفحه آخر",
    },
    toolbar: {
        nRowsSelected: '{0} ردیف انتخاب شده',
        searchTooltip: 'جستجو',
        searchPlaceholder: 'جستجو',
        exportTitle: "دریافت فایل خروجی",
        exportAriaLabel: "دریافت فایل خروجی",
        exportCSVName: "دریافت خروجی CSV",
        exportPDFName: "",

    },
    header: {
        actions: <SettingsIcon/>
    },
    body: {
        emptyDataSourceMessage:  'چیزی برای نمایش وجود ندارد!',
        addTooltip: 'افزودن',
        deleteTooltip: 'پاک کن',
        editTooltip: 'ویرایش سریع',

        filterRow: {
            filterTooltip: 'فیلتر',
        },
        editRow:{
            deleteText: 'آیا از حذف این ردیف مطمئنید؟',
            cancelTooltip: 'لغو',
            saveTooltip: 'ذخیره',
        }
    }
};
