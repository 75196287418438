import React from 'react';
import {Icon, ListItem, ListItemText} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Badge from '../Badge';
import AppNavLink from '../AppNavLink';
import Box from '@material-ui/core/Box';
import useStyles from './VerticalItem.style';
import {connect, setStore} from "trim-redux";

const VerticalItem = ({level, item,dashboard,user}) => {
    const classes = useStyles({level});
    const no_link = ( dashboard === "expert" && user.role.name === "user" ) || ( dashboard === "user" && user.role.name === "expert" );
    return (
        <>
            {
                item.url_type === "external" ?
                    <ListItem
                        button
                        component={!!no_link ? "" : "a"}
                        href={!!no_link ? "" : item.url}
                        target="_blank"
                        onClick={()=>setStore({
                            navCollapsed:false
                        })}
                        style={{ paddingRight: 17*(level+1)}}
                        activeClassName='active'
                        className={clsx(classes.navItem,"nav-item")}
                        exact={item.exact}>
                        {item.icon && (
                            <Box component='span' mr={3}>
                                <Icon
                                    className={clsx(classes.listIcon, 'nav-item-icon')}
                                    color='action'>
                                    {item.icon}
                                </Icon>
                            </Box>
                        )}
                        <ListItemText
                            primary={item.title}
                            classes={{primary: 'nav-item-text'}}
                        />
                        {item.count && (
                            <Box mr={1} clone>
                                <Badge count={item.count} color={item.color} />
                            </Box>
                        )}
                    </ListItem>
                    :
                    <ListItem
                        button
                        component={!!no_link ? "" : AppNavLink}
                        to={!!no_link ? "" : item.url}
                        onClick={()=>setStore({
                            navCollapsed:false
                        })}
                        activeClassName='active'
                        style={{ paddingRight: 17*(level+1)}}
                        className={clsx(classes.navItem, 'nav-item')}
                        exact={item.exact}>
                        {item.icon && (
                            <Box component='span' mr={3}>
                                <Icon
                                    className={clsx(classes.listIcon, 'nav-item-icon')}
                                    color='action'>
                                    {item.icon}
                                </Icon>
                            </Box>
                        )}
                        <ListItemText
                            primary={item.title}
                            classes={{primary: 'nav-item-text'}}
                        />
                        {item.count && (
                            <Box mr={1} clone>
                                <Badge count={item.count} color={item.color} />
                            </Box>
                        )}
                    </ListItem>
            }

        </>

    );
};

VerticalItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        url: PropTypes.string,
    }),
};

VerticalItem.defaultProps = {};

const mstp = state => ({
    navCollapsed: state.navCollapsed,
    dashboard: state.dashboard,
    user: state.user,
});
export default connect(mstp)(withRouter(React.memo(VerticalItem)));
