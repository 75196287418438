import React from 'react';
import {connect} from "trim-redux";

class Role extends React.Component {
    render (){
        const { children,isAuth,user,role,type} = this.props;
        return (
            isAuth ? (
                (user.role.name === role || role === undefined ) && user.role.type === type ? (
                    children
                ) : (
                    null
                )
            ) : (
                null
            )
        )
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default connect(mstp)(Role);
