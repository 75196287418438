import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import topMenuRoutesConfig from '../../../../../config/routes/TopMenuRoutesConfig';
import {Translate} from "../../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import {ListItem} from "@material-ui/core";
import {defaultVariabls} from "../../../../../config";
import {Link} from "react-router-dom";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";

const HorizontalNav = (props) => {
  return (
    <List className='navbarNav' style={{color:"rgb(73, 80, 87)"}}>
      {topMenuRoutesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <HorizontalGroup item={item} nestedLevel={0} />
          )}

          {item.type === 'collapse' && (
            <HorizontalCollapse item={item} nestedLevel={0} />
          )}

          {item.type === 'item' && (
            <HorizontalItem item={item} nestedLevel={0} />
          )}

          {item.type === 'divider' && (
            <Box my={5} clone>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
      <ListItem>
          {
              props.user.role.name !== "user" ?
                  <>
                      {
                          !!props.user.completed ?
                              <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  style={{whiteSpace:"nowrap"}}
                                  component="a"
                                  href={`${defaultVariabls.website}/${props.user.name}`}
                              >
                                  {Translate.viewProfile}
                              </Button> :
                              <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  style={{whiteSpace:"nowrap"}}
                                  component={Link}
                                  to="/resume/details"
                              >
                                  {Translate.createProfile}
                              </Button>
                      }
                  </> : null
          }
      </ListItem>
    </List>
  );
};
const mstp = state => ({
    user: state.user,
});
export default withSnackbar(connect(mstp)(HorizontalNav));
