import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {Styles} from "../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import {withRouter} from "react-router-dom";
import {connect, getStore} from "trim-redux";
import {getRoleName} from "../../../config/Permissions";
import {Translate} from "../../../config/Translate";
import BookRoundedIcon from '@material-ui/icons/BookRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import WbIncandescentRoundedIcon from '@material-ui/icons/WbIncandescentRounded';
import Experiences from "./component/experiences/Experiences";
import Educations from "./component/educations/Educations";
import Portfolios from "./component/portfolios/Portfolios";
import ColorLensRoundedIcon from '@material-ui/icons/ColorLensRounded';
import AppAnimate from "../../components/AppAnimate";
import DetailsResume from "./component/details/DetailsResume";
import Interests from "./component/interests/Interests";
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SkillsPage from "./component/skills/SkillsPage";
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import Button from "@material-ui/core/Button/Button";
import Title from "../../components/elements/Title";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {defaultVariabls} from "../../../config";
import Alert from "@material-ui/lab/Alert/Alert";
import {Link} from 'react-router-dom';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
class ResumeManagement extends React.Component{
    classes = this.props.classes;
    state={
        tabValue:"user",
    }
    handleTabChange = (event, newValue) => {
        this.props.history.push(`/resume/${newValue}`);
        this.setState({
            tabValue: newValue
        })
    };
    handleNextStep(){
        let {tabValue} = this.state;
        if(tabValue === "details"){
            this.props.history.push(`/resume/skills`);
            this.setState({
                tabValue: "skills"
            })
        }
        else if(tabValue === "skills"){
            this.props.history.push(`/resume/portfolio`);
            this.setState({
                tabValue: "portfolio"
            })
        }
        else if(tabValue === "portfolio"){
            this.props.history.push(`/resume/experiences`);
            this.setState({
                tabValue: "experiences"
            })
        }
        else if(tabValue === "experiences"){
            this.props.history.push(`/resume/educations`);
            this.setState({
                tabValue: "educations"
            })
        }else if(tabValue === "educations"){
            this.props.history.push(`/resume/interests`);
            this.setState({
                tabValue: "interests"
            })
        }

    }
    handlePrevStep(){
        let {tabValue} = this.state;
        if(tabValue === "skills"){
            this.props.history.push(`/resume/details`);
            this.setState({
                tabValue: "details"
            })
        }
        else if(tabValue === "experiences"){
            this.props.history.push(`/resume/portfolio`);
            this.setState({
                tabValue: "portfolio"
            })
        }else if(tabValue === "portfolio"){
            this.props.history.push(`/resume/skills`);
            this.setState({
                tabValue: "skills"
            })
        }else if(tabValue === "educations"){
            this.props.history.push(`/resume/experiences`);
            this.setState({
                tabValue: "experiences"
            })
        }else if(tabValue === "interests"){
            this.props.history.push(`/resume/educations`);
            this.setState({
                tabValue: "educations"
            })
        }
    }
    componentDidMount(){
        let user = getStore().user;
        let roleName = getRoleName(user.role.name);
        this.setState({
            tabValue: this.props.section,
            roleName
        })
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.setState({
                tabValue: this.props.section
            })
        }
    }
    getStepContent() {
        let pathname = this.props.location.pathname;
        switch (pathname) {
            case "/resume/details":
                return <DetailsResume/>;
            case "/resume/educations":
                return <Educations/>;
            case "/resume/experiences":
                return <Experiences/>;
            case "/resume/portfolio":
                return <Portfolios/>;
            case "/resume/skills":
                return <SkillsPage/>;
                case "/resume/interests":
                return <Interests/>;
            default:
                return 'Unknown step';
        }
    }
    render(){
        let {tabValue} = this.state;
        let {user} = this.props;

        return (
            <>
                <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.resumeManagement}>
                    <Grid container spacing={2} className="resume-holder">
                        <Grid item xs={12} className="header-back">
                            <Title align="left">{Translate.resumeManagement}</Title>
                            <Typography style={{marginLeft:20}}>{!!user.completed ? Translate.resumeManagementDetails : Translate.completeYourDetails}</Typography>
                            {
                                this.props.width === "xs" ?
                                    <Tooltip title={Translate.viewProfile} arow>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            component="a"
                                            target="_blank"
                                            href={`${defaultVariabls.website}/${user.name}`}
                                            disabled={user.completed === 0}
                                            className="absolute-left"
                                            variant="contained"
                                        >
                                            <RecentActorsRoundedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Button
                                        size="small"
                                        color="primary"
                                        className="mt-10 absolute-left"
                                        variant="contained"
                                        component="a"
                                        target="_blank"
                                        disabled={user.completed === 0}
                                        href={`${defaultVariabls.website}/${user.name}`}
                                        startIcon={<RecentActorsRoundedIcon/>}
                                    >
                                        {Translate.viewProfile}
                                    </Button>
                            }

                        </Grid>
                        <Grid item xs={12}>
                                <div className={this.classes.ProviderManagerRoot}>
                            <Tabs
                                style={{marginBottom:20}}
                                value={tabValue}
                                onChange={this.handleTabChange}
                                textColor="secondary"
                                variant={this.props.width !== "xs" ? "" : 'scrollable'}
                                scrollButtons={this.props.width !== "xs" ? "" : 'auto'}
                                aria-label="Tab"
                                centered={this.props.width !== "xs"}
                            >
                                <Tab value="details"
                                     label={
                                         <div className="custom_tabs">
                                             <IconButton className='custom_tabs_icon_button'>
                                                 <BookRoundedIcon />
                                             </IconButton>
                                             <Typography component="h3">{Translate.baseDetails}</Typography>
                                         </div>
                                     }
                                />
                                <Tab value="skills"
                                     label={
                                         <div className="custom_tabs">
                                             <IconButton className='custom_tabs_icon_button'>
                                                 <WbIncandescentRoundedIcon />
                                             </IconButton>
                                             <Typography component="h3">{Translate.skillsDetails}</Typography>
                                         </div>
                                     }
                                />
                                <Tab value="portfolio"
                                     label={
                                         <div className="custom_tabs">
                                             <IconButton className='custom_tabs_icon_button'>
                                                 <ColorLensRoundedIcon />
                                             </IconButton>
                                             <Typography component="h3">{Translate.portfoliosDetails}</Typography>
                                         </div>
                                     }
                                />
                                <Tab value="experiences"
                                     label={
                                         <div className="custom_tabs">
                                             <IconButton className='custom_tabs_icon_button'>
                                                 <WorkRoundedIcon />
                                             </IconButton>
                                             <Typography component="h3">{Translate.experiencesDetails}</Typography>
                                         </div>
                                     }
                                />
                                <Tab value="educations"
                                     label={
                                         <div className="custom_tabs">
                                             <IconButton className='custom_tabs_icon_button'>
                                                 <SchoolRoundedIcon />
                                             </IconButton>
                                             <Typography component="h3">{Translate.educationsDetails}</Typography>
                                         </div>
                                     }
                                />

                                <Tab value="interests"
                                     label={
                                         <div className="custom_tabs">
                                             <IconButton className='custom_tabs_icon_button'>
                                                 <FavoriteRoundedIcon />
                                             </IconButton>
                                             <Typography component="h3">{Translate.interestsDetails}</Typography>
                                         </div>
                                     }
                                />
                            </Tabs>
                            <Grid container spacing={2} alignItems="center" justify="center" >
                                <Grid item xs={12} className="mt-20">
                                    {this.getStepContent()}
                                </Grid>
                                <Grid item xs={12} style={{margin:"0 8px"}} className="header-back">
                                    <Grid container>
                                        <Grid item xs={6} style={{textAlign:"right"}}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={tabValue === "details"}
                                                startIcon={<ChevronRightRoundedIcon/>}
                                                onClick={this.handlePrevStep.bind(this)}
                                            >
                                                {Translate.prevStep}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign:"left"}}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={tabValue === "interests"}
                                                endIcon={<ChevronLeftRoundedIcon/>}
                                                onClick={this.handleNextStep.bind(this)}
                                            >
                                                {Translate.nextStep}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </AppAnimate>
                {
                    !!user.completed &&
                    !user.service.job &&
                    !user.service.project &&
                    !user.service.teaching &&
                    !user.service.team &&
                    !user.service.consultation ?
                        <Alert
                            className="fixed-alert"
                            severity="warning"
                            action={
                                <>
                                {
                                    this.props.width === "xs" || this.props.width === "sm" ?
                                        <IconButton
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            component={Link}
                                            to="/services/project"
                                        >
                                            <PlaylistAddCheckRoundedIcon/>
                                        </IconButton>
                                        :
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            size="small"
                                            startIcon={<PlaylistAddCheckRoundedIcon/>}
                                            component={Link}
                                            to="/service/project"
                                        >

                                            {Translate.addService}
                                        </Button>

                                }
                                </>
                            }
                        >
                            {Translate.nowYouCanAddServices}
                        </Alert>
                        : null
                }
                </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(ResumeManagement))));