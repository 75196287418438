import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import Divider from "@material-ui/core/Divider/Divider";
import SubTitle from "../../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import SingleSkill from "./SingleSkill";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import SearchSkills from "./SearchSkills";
import Typography from "@material-ui/core/Typography/Typography";
import AppCard from "../../../../components/layouts/components/AppCard";
import IconButton from "@material-ui/core/IconButton/IconButton";
import WbIncandescentRoundedIcon from '@material-ui/icons/WbIncandescentRounded';
import { skills_limit} from "../../../../../config/values";
import Hidden from "@material-ui/core/Hidden/Hidden";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {updateRedux} from "../../../../../config/Security";

class Skills extends React.Component {

    classes = this.props.classes;
    state ={
        loading:true,
        skills: [],
    }
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/skills/getUserSkills/${this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    skills:response.data,
                    loading:false
                })
                updateRedux();
            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        let {skills,loading} = this.state;
        return (
            <AppCard
                title={Translate.skillsDetails}
                action={
                    <>
                        {
                            this.props.width === "xs" || this.props.width === "sm" ? null :
                                <IconButton>
                                    <WbIncandescentRoundedIcon color="primary"/>
                                </IconButton>
                        }
                    </>

                }>
                <Grid container spacing={2} justify="center" alignItems="center"  style={{minHeight:300,paddingBottom:100}}>
                    <CssBaseline/>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            {Translate.SkillsDetailsDescription}
                                        </Typography>
                                        {
                                            loading ? null :
                                                (skills_limit - skills.length) === 0 ?
                                                    <Typography varaint="body2" color="secondary" style={{marginTop:5}}>{Translate.noSkillsLeft}</Typography>
                                                    :
                                                    <Typography varaint="body2" color="secondary" style={{marginTop:5}}><strong>{skills_limit - skills.length}</strong> {Translate.skillsLeft}</Typography>
                                        }
                                        <SearchSkills disabled={skills_limit - skills.length === 0} reload={() => this.loadData()}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                            <Grid item xs={12} md={4} className="text-left">
                                <img className="max-width" src="../assets/images/skill.png" alt="work" loading="lazy"/>
                            </Grid>
                            </Hidden>
                            {
                                skills.length > 0 ?
                                    <>

                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SubTitle align="left">{Translate.mySkills}</SubTitle>
                                            {
                                                loading ?
                                                    <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} alignItems="center" >
                                                        {
                                                            skills.map((skill,index) => (
                                                                <Grid item xs={12} md={6} lg={4} key={index}>
                                                                    <SingleSkill data={skill} reload={this.loadData.bind(this)}/>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                            }

                                        </Grid>
                                    </> : null
                            }


                        </Grid>
                    </Grid>
                </Grid>
            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Skills)))));