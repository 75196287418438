import React from 'react';
import {connect} from "trim-redux";
import {Translate} from "../../../../../../config/Translate";
import Grid from "@material-ui/core/Grid/Grid";
import LaptopRoundedIcon from '@material-ui/icons/LaptopRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded';
import {withSnackbar} from "notistack";
import AppCard from "../../../../../components/layouts/components/AppCard";
import SubTitle from "../../../../../components/elements/SubTitle";
import ToggleService from "../../../../services/components/ToggleService";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Link} from 'react-router-dom';

const ExpertInfo = (props) => {
    const {user} = props;
    return (
        <>
            {
                !!user ?
                    <Grid container  alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs >
                                    <AppCard className={!!user.service.project ? "" : "disabled-card"}>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs={12} className="text-center">
                                                <IconButton component={Link} to="/service/project">
                                                    <LaptopRoundedIcon
                                                        style={{fontSize:50}}
                                                        color={!!user.service.project ? "primary" : ""}
                                                    />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SubTitle>{Translate.projectsDetails}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12} className="text-center">
                                                <ToggleService widget service="project" disabled={false} error={Translate.createAPlanToTurnOn}/>
                                            </Grid>
                                        </Grid>
                                    </AppCard>
                                </Grid>
                                <Grid item xs>
                                    <AppCard className={!!user.service.teaching ? "" : "disabled-card"}>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs={12} className="text-center">
                                                <IconButton component={Link} to="/service/teaching">
                                                <LocalLibraryRoundedIcon
                                                    style={{fontSize:50}}
                                                    color={!!user.service.teaching ? "primary" : ""}
                                                />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SubTitle>{Translate.teachingsDetails}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12} className="text-center">
                                                <ToggleService widget service="teaching" disabled={false} error={Translate.createATeachingPlanToTurnOn}/>
                                            </Grid>
                                        </Grid>
                                    </AppCard>
                                </Grid>
                                <Grid item xs>
                                    <AppCard className={!!user.service.job ? "" : "disabled-card"}>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs={12} className="text-center">
                                                <IconButton component={Link} to="/service/job">
                                                    <WorkRoundedIcon
                                                        style={{fontSize:50}}
                                                        color={!!user.service.job ? "primary" : ""}
                                                    />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SubTitle>{Translate.jobsDetails}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12} className="text-center">
                                                <ToggleService widget service="job" disabled={false} error={Translate.createAJobToTurnOn}/>
                                            </Grid>
                                        </Grid>
                                    </AppCard>
                                </Grid>
                                <Grid item xs>
                                    <AppCard className={!!user.service.team ? "" : "disabled-card"}>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs={12} className="text-center">
                                                <IconButton component={Link} to="/service/team">
                                                    <PeopleRoundedIcon
                                                        style={{fontSize:50}}
                                                        color={!!user.service.team ? "primary" : ""}
                                                    />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SubTitle>{Translate.teamsDetails}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12} className="text-center">
                                                <ToggleService widget service="team"/>
                                            </Grid>
                                        </Grid>
                                    </AppCard>
                                </Grid>
                                <Grid item xs>
                                    <AppCard className={!!user.service.consultation ? "" : "disabled-card"}>
                                        <Grid container alignItems="center" justify="center">
                                            <Grid item xs={12} className="text-center">
                                                <IconButton component={Link} to="/service/consultation">
                                                <HeadsetMicRoundedIcon
                                                    style={{fontSize:50}}
                                                    color={!!user.service.consultation ? "primary" : ""}
                                                />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SubTitle>{Translate.consultationsDetails}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12} className="text-center">
                                                <ToggleService widget service="consultation" disabled={false} error={Translate.create}/>
                                            </Grid>
                                        </Grid>
                                    </AppCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
            }
        </>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withSnackbar(connect(mstp)(ExpertInfo));
