import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {DataTableLocalization, Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, setStore} from "trim-redux";
import Loader from "../../../components/elements/Loader";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {TableCount, TablePage} from "../../../../config/Permissions";
import AddBox from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button/Button";
import AddTable from "./AddTable";
import Typography from "@material-ui/core/Typography/Typography";
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditTableDialog from "./EditTableDialog";
class TableBuilder extends React.Component {
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    state = {
        ETableDialog: false,
        editingTable: null,
        columns: [
            { title: Translate.tableName,editable: 'never', field: 'name'},
            {
                field: 'shortcode',
                title: Translate.shortcode,
                render:
                        rowData =>
                            <Typography>
                                {rowData.shortcode}
                                <IconButton
                                color="primary"
                                    onClick={()=> this.setTable(rowData)}
                                >
                                    <PublishRoundedIcon/>
                                </IconButton>
                            </Typography>
            },
            { title: Translate.lastUpdate,editable: 'never', field: 'lastUpdate'},
        ],
        data: [],
        loading:true,
        ChangeLoader:false,
        addView: false
    };
    componentDidMount(){
        this.loadData();
    }
    Changed(){
        this.loadData();
    }
    async editTable(data){
        await this.setState({
            editingTable: data.id,
        })
        await this.setState({
            ETableDialog:true,
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/tables/view`, config)
                .then(response => {
                    let data = [];
                    response.data.map((row) =>(
                        data.push({
                            name: row.name,
                            shortcode: row.shortcode,
                            id: row.id,
                            lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                        })
                    ));
                    this.setState({
                        data: data,
                        loading:false,
                        ChangeLoader:false
                    })

                }).catch(error => {
                    this.setState({
                        loading:false,
                        ChangeLoader:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false,
                ChangeLoader:false
            });
            this.setState({
                loading:false,
                ChangeLoader:false
            })
        }
    }
    onDeleteFile(id) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/table/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.deleteTable, {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.deleteTableFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    handleChangeLoader(){
        this.setState({
            ChangeLoader:!this.state.ChangeLoader
        })
    }
    async handleChangeOwner(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let owner = target.value;
        await this.setState({
            owner
        });
        this.loadData();
    }
    toggleView(){
        this.setState({
            addView: !this.state.addView
        })
    }
    setTable(data){
        this.props.setTable(data);
    }
    cancleOperation= () => {
        this.setState({
            ETableDialog:false,
        })
    }
    reload(){
        this.setState({
            loading:true,
            ChangeLoader:true,

        })
        this.loadData()
    }
    render(){
        let {ChangeLoader,ETableDialog,editingTable} = this.state;
        return (
            <React.Fragment>
                {
                    this.state.loading ? <Loader/> :
                        this.state.addView ? <AddTable setTable={this.setTable.bind(this)}/> :

                        <React.Fragment>
                            {
                                ChangeLoader ? <LinearProgress  style={{marginBottom:20}}/> : null
                            }
                            <MaterialTable
                                icons={tableIcons}
                                onRowClick={((evt, selectedRow) =>  this.editTable(selectedRow))}
                                title={<Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.toggleView.bind(this)}
                                        startIcon={<AddBox />}
                                        >
                                            {Translate.AddTable}
                                        </Button>
                                }
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={this.state.data}
                                options={{
                                    selection:true,
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount
                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDeleteFile(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                            />
                        </React.Fragment>
                }
                {
                    ETableDialog ? <EditTableDialog cancleOperation={this.cancleOperation} table={editingTable} reload={this.reload.bind(this)}/> : null
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withStyles(Styles)(connect(mstp)(TableBuilder)));
