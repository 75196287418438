import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import Points from "./Points";


class ShopsPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.myPoints}>
                <Grid container spacing={2} justify="center" >
                    <Grid item xs={12}>
                        <AppCard
                            title={ Translate.myPoints}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.myPoints}/>
                            }>
                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs={12} md={7}>
                                    <Points/>
                                </Grid>
                                <Grid item xs={12} md={1} />
                                <Grid item xs={12} md={4}>
                                    <img className="max-width" src="../assets/images/cup2.png" alt="cup" loading="lazy"/>
                                </Grid>
                            </Grid>

                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(ShopsPage));
