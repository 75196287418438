import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {Styles} from "../../../../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import {withRouter} from "react-router-dom";
import {connect} from "trim-redux";
import {Translate} from "../../../../../../config/Translate";
import AppCard from "../../../../../components/layouts/components/AppCard";
import Typography from "@material-ui/core/Typography/Typography";
import Title from "../../../../../components/elements/Title";
import {Link} from 'react-router-dom';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import {defaultVariabls} from "../../../../../../config";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {updateRedux} from "../../../../../../config/Security";
class ExpertsGuidLine extends React.Component{
    classes = this.props.classes;
    state={

    };
    componentDidMount(){
        updateRedux();
    }
    render(){
        let {user} = this.props;
        console.log(user);
        return (

            <AppCard>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Title align="left">{Translate.expertsGuidLine}</Title>
                        <Typography paragraph>{Translate.expertsGuidLineDescription}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard className="guidline-single" component={Link} to="/resume/details">
                            <img width={40} src="../assets/images/profile.png" alt="profile" loading="lazy"/>
                            <Title component={Link} to="/resume/details">{Translate.createProfile}</Title>
                            <Typography className="text-center" variant="body2" component={Link} to="/resume/details">{user.completed ? Translate.profileCreated : Translate.createProfileDetails}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            user.completed ?
                                <Typography component="a" target="_blank" href={`${defaultVariabls.website}/${user.name}`} className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <CheckCircleRoundedIcon fontSize="small" className="simple-success-icon"/>
                                    </IconButton>
                                    {Translate.viewProfile}
                                </Typography>
                                : null
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard className="guidline-single"  component={Link} to="/resume/skills">
                            <img width={40} src="../assets/images/resume.png" alt="resume" loading="lazy"/>
                            <Title component={Link} to="/resume/skills">{Translate.completeResume}</Title>
                            <Typography variant="body2" component={Link} to="/resume/skills">{Translate.completeResumeDetails}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            !user.skills_count ||
                            !user.interests_count ||
                            !user.portfolios_count ||
                            !user.educations_count ||
                            !user.experiences_count ?
                                <Typography className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <ErrorRoundedIcon fontSize="small" className="simple-error-icon"/>
                                    </IconButton>
                                    {
                                        !user.skills_count ? <Link to="/resume/skills">{Translate.skills} </Link> : null
                                    }
                                    {
                                        !user.portfolios_count ? <Link to="/resume/portfolio">{Translate.portfoliosDetails} </Link> : null
                                    }
                                    {
                                        !user.experiences_count ? <Link to="/resume/experiences">{Translate.experiencesDetails} </Link> : null
                                    }
                                    {
                                        !user.educations_count ? <Link to="/resume/educations">{Translate.educationsDetails} </Link> : null
                                    }
                                    {
                                        !user.interests_count ? <Link to="/resume/interests">{Translate.interestsDetails} </Link> : null
                                    }
                                </Typography>
                                :
                                <Typography component="a" target="_blank" href={`${defaultVariabls.website}/${user.name}`} className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <CheckCircleRoundedIcon fontSize="small" className="simple-success-icon"/>
                                    </IconButton>
                                    {Translate.allResumePartsAreCompleted}
                                </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard component={user.completed ? Link : 'div'} to="/service/project" className={user.completed ? "guidline-single" : "disabled-appCard guidline-single"}>
                            <img width={40} src="../assets/images/maintenance.png" alt="services" loading="lazy"/>
                            <Title>{Translate.addServices}</Title>
                            <Typography  variant="body2">{Translate.addServicesDetails}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            user.completed ?
                                null:
                                <Typography component={Link} to="/resume/details" className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <ErrorRoundedIcon fontSize="small" className="simple-error-icon"/>
                                    </IconButton>
                                    {Translate.firstCreateProfile}
                                </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard component={user.completed ? Link : 'div'} to="/contents/make" className={user.completed ? "guidline-single" : "disabled-appCard guidline-single"}>
                            <img width={40} src="../assets/images/content.png" alt="content" loading="lazy"/>
                            <Title>{Translate.createContent}</Title>
                            <Typography variant="body2">{Translate.createContentDetails}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            user.completed ?
                                null:
                                <Typography component={Link} to="/resume/details" className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <ErrorRoundedIcon fontSize="small" className="simple-error-icon"/>
                                    </IconButton>
                                    {Translate.firstCreateProfile}
                                </Typography>
                        }
                    </Grid>
                </Grid>
            </AppCard>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(ExpertsGuidLine))));