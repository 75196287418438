import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import SingleProject from "./SingleProjectPlan";
import AddProjectDialog from "./AddProjectPlanDialog";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../../config/Translate";
import AppCard from "../../../../components/layouts/components/AppCard";
import Divider from "@material-ui/core/Divider/Divider";
import MakeProjectDetails from "./MakeProjectDetails";
import ToggleService from "../ToggleService";
import {updateRedux} from "../../../../../config/Security";
import Title from "../../../../components/elements/Title";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {projects_limit} from "../../../../../config/values";

class Projects extends React.Component {
    classes = this.props.classes
    state={
        projects:[],
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/project_plans/get/${this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    projects:response.data,
                    loading:false
                })
                updateRedux();
            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        const {projects,loading} = this.state;
        return (
            <AppCard
                title={Translate.projectsDetails}
                action={
                    <ToggleService service="project" disabled={projects.length === 0} error={Translate.createAPlanToTurnOn}/>
                }>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justify="center" alignItems="center" style={{minHeight:300,paddingBottom:100}}>
                            <CssBaseline/>
                            <Grid item xs={12} md={11}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={9}>
                                        <Hidden smUp>
                                            <img width={80} src="../assets/images/project.png" alt="project" loading="lazy"/>
                                        </Hidden>
                                        <Title align="left" style={{marginTop:10,fontSize:"20px !important"}}>{Translate.areYouReadyToGetProject}</Title>
                                        <Typography>{Translate.projectsDetailsHelp}</Typography>
                                        <Typography paragraph>{Translate.projectsDetailsHelp2}</Typography>
                                        <MakeProjectDetails/>
                                    </Grid>
                                    <Hidden smDown>
                                    <Grid item xs={12} md={3} className="text-center">
                                        <img className="max-width" src="../assets/images/project.png" alt="project" loading="lazy"/>
                                    </Grid>
                                    </Hidden>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                        <AddProjectDialog length={projects.length} disabled={projects.length >= projects_limit} reload={this.loadData.bind(this)}/>
                                    {
                                        projects.map((project,index) => (
                                            <Grid item xs={12} md={6} lg={4} key={index}>
                                                <SingleProject data={project} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                        ))
                                    }
                                    <Grid item xs={12}>
                                        <ToggleService service="project" disabled={projects.length === 0} error={Translate.createAPlanToTurnOn}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Projects))));