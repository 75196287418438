import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Details from "./components/Details";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import {Translate} from "../../../config/Translate";
import AppCard from "../../components/layouts/components/AppCard";
import AppAnimate from "../../components/AppAnimate";

class UserDetails extends React.Component {
    render() {
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.myAccount}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.myAccount}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.myAccount}/>
                            }>
                            <Details/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
export default withStyles(Styles)(UserDetails);