import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {setStore} from "trim-redux";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import {connect} from "trim-redux";
import ShowSingleProduct from "./ShowSingleProduct";

class ShowProducts extends React.Component {

    state = {
        loading: true,
        products:[],
        category:this.props.category,
        type:this.props.type,

    };
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            category:this.props.category,

            type:this.props.type,
            loading: true,
        })
        this.loadData();
    }
    componentDidUpdate(prevProps){
        if(this.state.category !== this.props.category){
            this.setState({
                category:this.props.category,
                loading: true,
            })
            this.loadData();
        }
        if(this.state.type !== this.props.type){
            this.setState({
                type:this.props.type,
                loading: true,
            })
            this.loadData();
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let {category,type}= this.state;
            await axios.get(`${defaultVariabls.url}/products/show/${type}/${!!category ? category : null}`, config)
                .then(response => {
                    this.setState({
                        products: response.data,
                        loading:false

                    })
                }).catch(error => {
                    console.log(error.response);
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {loading,products}  =this.state;
        return (
            <>
                {
                    loading  ?  <AppCardLoaderFields/> :
                        <>
                            {
                                products.length > 0 ?
                                    <>
                                        {
                                            products.map((item,key)=> (
                                                <Grid item xs={12} md={6} lg={4} index={key}>
                                                    <ShowSingleProduct product={item} />
                                                </Grid>
                                                )
                                            )
                                        }
                                        </>
                                    : <Typography>{Translate.noProductsFound}</Typography>
                            }
                            </>
                }
                </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ShowProducts))));
