import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {DataTableLocalization, Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {getStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import Button from "@material-ui/core/Button/Button";
import {Link, withRouter} from 'react-router-dom';
import AddBox from "@material-ui/icons/AddBox";
import { withSnackbar } from 'notistack';
import {typesWithName,rolesWithName, RWN, TableCount, TablePage} from "../../../../../config/Permissions";
import moment from "jalali-moment";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import TextField from "@material-ui/core/TextField/TextField";


class Users extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    type = this.props.match.params.type;
    state = {
        columns: [
            {
                field: 'url',
                editable: 'never',
                title: Translate.image,
                render: rowData => <img alt="avatar" src={rowData.url} className={this.classes.FMThumbnail}/>
            },
            { title: Translate.userName, field: 'name', editable: 'never' },
            { title: Translate.email, field: 'email', editable: 'never' },
            {
                title: Translate.role,
                field: 'role',
                lookup: RWN,
            },
            { title: Translate.lastUpdate,editable: 'never', field: 'lastUpdate'},

        ],
        data: [],
        loading:false,
        role:"all",
        type: this.type ? this.type : "all",
        ChangeLoader:false
    };
    componentDidMount(){
        this.loadData();
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            let newtype = this.props.match.params.type;
            await this.setState({
                type: newtype ? newtype : "all",
                role:"all",
                ChangeLoader:true
            })
            await this.loadData();
        }
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDeleteUser(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/removeUser`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.deleteData, {
                        variant: 'success',
                    });
                }
                this.loadData();
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    async onDeleteSelectedUsers(data){
        await data.map((row) =>(
            this.onDeleteUser(row.id,false)
        ));
        await this.setState({
            ChangeLoader:true
        })
        await this.loadData();
        await this.props.enqueueSnackbar(Translate.deleteSelectedUsers, {
            variant: 'success',
        });

    }
    onEditUser(user) {
        let authUser = getStore().user;
        if(authUser.role.permission.users){
            let data = new FormData();
            data.append('id', user.id);
            data.append('name', user.name);
            data.append('role', user.role);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/quickEditUser`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(Translate.detailsSet, {
                        variant: 'success',
                    });
                    this.loadData();
                    return true
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                        variant: 'error',
                    });
                    return false
                });
        }else{
            this.props.enqueueSnackbar(Translate.youDontHavePermission, {
                variant: 'error',
            });
            this.loadData();
        }

    }
    handleRowClick(data){
        this.props.history.push(`/manage/user/${data.id}`);
    }

    async handleChangeRole(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let role = target.value;
        await this.setState({
            role
        });
        this.loadData();
    }
    async handleChangeType(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let type = target.value;
        this.props.history.push(`/manage/users/view/${type}`)

        await this.setState({
            type,
            role:'all'
        });
        this.loadData();
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let {role,type} = this.state;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        const data = new FormData();
        data.append('role', role);
        data.append('type', type);
        return (
                <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <Grid container spacing={2} direction="row" className={this.classes.filterContainer}>
                                            <Grid item xs={12} md={3} lg={1}>
                                                <Typography>
                                                    <FilterListRoundedIcon/>
                                                    {Translate.filters}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={2}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    value={type}
                                                    onChange={this.handleChangeType.bind(this)}
                                                    >
                                                        {
                                                            typesWithName.map((row,index) => (
                                                                <MenuItem key={index} value={row.value}>{row.name}</MenuItem>
                                                            ))
                                                        }
                                                        <MenuItem value='all'>{Translate.allUserTypes}</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={2}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    value={role}
                                                    onChange={this.handleChangeRole.bind(this)}
                                                    >
                                                        {
                                                            rolesWithName.map((row,index) => (
                                                                row.type === type ?
                                                                    <MenuItem key={index} value={row.value}>{row.name}</MenuItem>
                                                                    : null
                                                            ))
                                                        }
                                                        <MenuItem value='all'>{Translate.allRoles}</MenuItem>
                                                    </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MaterialTable
                                            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                            tableRef={this.tableRef}
                                            icons={tableIcons}
                                            onRowClick={((evt, selectedRow) =>  this.handleRowClick(selectedRow))}
                                            title={
                                                <Link to='/manage/users/add'>
                                                    {
                                                        this.props.width !== "xs" ?
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={<AddBox/>}
                                                            >
                                                                {Translate.addUser}
                                                            </Button>
                                                            :
                                                            <IconButton color="primary">
                                                                <AddBox/>
                                                            </IconButton>
                                                    }
                                                </Link>
                                            }

                                            localization={DataTableLocalization}
                                            columns={this.state.columns}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    axios.post(`${defaultVariabls.url}/getUsers/withFilter?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`,data, config)
                                                        .then(response => {
                                                            let data = [];
                                                            // eslint-disable-next-line
                                                            response.data.data.map((row) => {
                                                                data.push({
                                                                    name: row.first_name+" "+row.last_name,
                                                                    email: row.email,
                                                                    role: !!row.role ? row.role.name : null,
                                                                    url: `${defaultVariabls.uploads}/FileManager/${row.avatar.name}_small.${row.avatar.extension}`,
                                                                    id: row.id,
                                                                    lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                                })
                                                            });
                                                            resolve({
                                                                data: data,
                                                                page: response.data.current_page - 1,
                                                                totalCount: response.data.total,
                                                            })
                                                        }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                })
                                            }
                                            options={{
                                                selection: true,
                                                pageSize: TablePage,
                                                pageSizeOptions: TableCount,
                                            }}
                                            actions={[
                                                {
                                                    tooltip: Translate.deleteSelectedUsers,
                                                    icon: tableIcons.Delete,
                                                    onClick: (evt, data) => this.onDeleteSelectedUsers(data)
                                                }
                                            ]}
                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            this.onEditUser(newData);
                                                            if (oldData) {
                                                                this.setState(prevState => {
                                                                    const data = [...prevState.data];
                                                                    data[data.indexOf(oldData)] = newData;
                                                                    return { ...prevState, data };
                                                                });
                                                            }
                                                        }, 600);
                                                    }),
                                                onRowDelete: oldData =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            this.onDeleteUser(oldData.id);
                                                            this.setState(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                        }, 600);
                                                    }),
                                            }}

                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                    }
                </>
        );
    }
}

export default  withWidth()(withSnackbar(withRouter(withStyles(Styles)(Users))));