import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Container from "@material-ui/core/Container/Container";
import Box from "@material-ui/core/Box/Box";
import Copyright from "../elements/Copyright";
import {Styles} from "../../../config/design/styles";
import CheckInternetConnection from "../elements/CheckInternetConnection";
import ScrollToTop from "../elements/ScrollToTop";
import AppHeader from "./components/AppHeader";
import AppSidebar from "./components/AppSidebar";
import {connect} from "trim-redux";
import {withRouter} from 'react-router-dom';
import withWidth from "@material-ui/core/withWidth/withWidth";
import CheckSimulation from "../elements/CheckSimulation";

class Layout extends React.Component{
    classes = this.props.classes;

    componentDidMount(){
        let { isAuth,user} = this.props;
        if(user === null){
            this.props.history.push('/login')
        }
        if (isAuth){
            if(user.mobile_confirmed === 0){
                this.props.history.push('/verify-mobile')
            }
            this.setState({
                loading:false
            })
        }else{
            this.setState({
                loading:false
            })
        }
    }
    render(){
        const children = this.props.children;
        return(
            <div className={this.classes.root} style={{paddingTop:60}}>
                <CssBaseline />
                <AppHeader/>
                <AppSidebar/>
                <main className={this.classes.content} id="container">
                    <Container maxWidth="lg" className={this.classes.container} style={{marginBottom:80}}>
                        {
                            children
                        }
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </Container>
                </main>
                <ScrollToTop/>
                <CheckSimulation/>
            </div>
        )
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withStyles(Styles)(withRouter(connect(mstp)(Layout))));
