import React from 'react';
import {Translate} from '../../../../config/Translate';
import {withSnackbar} from "notistack";
import Thumbnail from "./Thumbnail";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";

import SubTitle from "../../../components/elements/SubTitle";
import {AvatarSkeleton} from "../../../components/Skeletons/AvatarSkeleton";
import {connect, setStore} from "trim-redux";


class ProfileImage extends React.Component {
    state={
        thumbnail: 2,
        initial: 2,
        loading:true
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {manage,user_id} = this.props;
        await axios.get(`${defaultVariabls.url}/user/avatar/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                let avatar = response.data.avatar;

                if(avatar !== null){
                    this.setState({
                        initial: avatar.id,
                        thumbnail: avatar.id,
                        loading:false
                    })
                }else{
                    this.setState({
                        loading:false
                    })
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.loadingThumbnailFailed, {
                    variant: 'error',
                });
            });
    }
    async handleThumbImage(id){
        await this.setState({
            thumbnail: id,
        })
        this.handleRequest();
    }
    loadUser(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        dashboard : response.data.role.name === "user" ? "user" : "expert",
                    });
                    this.setState({
                        loading:false
                    })
                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                    loading:false
                });
                this.setState({
                    loading:false
                })
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    handleRequest(){
        let { thumbnail,initial} = this.state;
        if(thumbnail > 1 && initial !==thumbnail){
            let data = new FormData();
            data.append('avatar', thumbnail);
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let {manage,user_id} = this.props;
            axios.post(`${defaultVariabls.url}/user/avatar/set/${manage ? user_id : this.props.user.id}`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(Translate.userAvatarSet, {
                        variant: 'success',
                    });
                    this.loadUser();
                    this.setState({
                        loading:false
                    })
                })
                .catch(error => {
                    this.props.enqueueSnackbar(Translate.userAvatarSetFailed, {
                        variant: 'error',
                    });
                });

        }
    }
    render() {
        let {loading} = this.state;
        return (
            <div>
                {
                    this.props.noTitle ? null : <SubTitle> {Translate.profileImage}</SubTitle>
                }
                {
                    loading ?
                        <AvatarSkeleton size={this.props.size}/>
                        :
                        <React.Fragment>
                            <Thumbnail avatar
                                       size={this.props.size}
                                       handleThumbImage={this.handleThumbImage.bind(this)}
                                       initial={this.state.initial}/>
                        </React.Fragment>
                }
            </div>
        )
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(connect(mstp)(ProfileImage));