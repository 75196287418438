import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {FieldSkeleton} from "../Skeletons/FieldSkeleton";
import ChipInput from "material-ui-chip-input";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../config/Translate"
import FieldCounterWithoutBar from "../elements/FieldCounterWithoutBar";
class Keywords extends React.Component {
    classes = this.props.classes
    state={
        keywords: [],
        error:null,
        loading:true,
    };
    componentDidMount(){
        if(this.props.input){
            this.setState({
                keywords: this.props.input
            })
        }
        this.setState({
            error: this.props.error,
            loading:false
        })
    }

    componentDidUpdate(oldProps){
        if(this.state.error !== this.props.error){
            this.setState({
                error:this.props.error,
            })
        }
    }
    async handleAddChip(keyword){
        let keywords = this.state.keywords;
        keyword = keyword.split(' ').slice(0,5).join(' ');
        if(keywords.length < 5){
            keywords.push(keyword)
        }
        await this.setState({
            keywords
        })
        await this.props.handleChange(keywords);
    }
    async handleDeleteChip(chip, index){
        let keywords = this.state.keywords;
        await keywords.splice(index,1);
        await this.setState({
            keywords
        })
        await this.props.handleChange(keywords);
    }
    render() {
        let {loading,error,keywords} = this.state;
        return(
            <React.Fragment>
                {
                    loading ?
                        <FieldSkeleton/>
                        :
                        <>
                        <ChipInput
                            value={keywords}
                            onAdd={(chip) => this.handleAddChip(chip)}
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                            fullWidth
                            required={this.props.required}
                            label={Translate.keywordsLabel}
                            variant={"outlined"}
                            error = {!!error}
                            helperText={error}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FieldCounterWithoutBar length={keywords.length} max={5}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                            {
                                keywords.length === 5 ?
                                    <Typography color="error" className="mt-15">
                                        {Translate.allKeywordsAreTyped}
                                    </Typography>
                                    : null

                            }

                        </>
                }
            </React.Fragment>
        )
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Keywords))));