import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import HeaderTags from "./HeaderTags";

const useStyles = makeStyles( theme => ({
    backDrop: {
        background: 'rgba(225,225,225,0.3)',

    },
}));
export default function Loader() {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backDrop} open={true}>
            <HeaderTags title={Translate.loading}/>
            <CircularProgress />
        </Backdrop>
    );
}