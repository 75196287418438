import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import Interests from "./Interests";

class InterestsPage extends React.Component {

    render() {
        return (
            <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} md={11}>
                    <Interests/>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(InterestsPage))));