import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import SingleExperience from "./SingleExperience";
import AddExperienceDialog from "../experiences/AddExperienceDialog";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../../config/Translate";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AppCard from "../../../../components/layouts/components/AppCard";
import {updateRedux} from "../../../../../config/Security";
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import Title from "../../../../components/elements/Title";
import {experiences_limit} from "../../../../../config/values";
import withWidth from "@material-ui/core/withWidth/withWidth";

class Experiences extends React.Component {
    classes = this.props.classes
    state={
        experiences:[],
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/experiences/get/${this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    experiences:response.data,
                    loading:false
                })
                updateRedux();

            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        const {experiences,loading} = this.state;
        return (
            <AppCard
                title={Translate.experiencesDetails}
                action={
                    <>
                        {
                            this.props.width === "xs" || this.props.width === "sm" ? null :
                                <IconButton>
                                    <WorkRoundedIcon color="primary"/>
                                </IconButton>
                        }
                    </>
                }>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} alignItems="center" justify="center"  style={{paddingTop:20,minHeight:300,paddingBottom:100}}>
                            <Grid item xs={12} md={11}>
                                <Grid container spacing={2}>
                                    <CssBaseline/>
                                    {
                                        experiences.length === 0 ?
                                            <Grid item xs={12} style={{textAlign:"center"}}>
                                                <img width={120} src="../assets/images/work.png" alt="work" loading="lazy"/>
                                                <Title paragraph>{Translate.addExperiencesDetails}</Title>
                                                <Typography paragraph align="center">{Translate.addExperiencesDetailsLong}</Typography>
                                                <AddExperienceDialog first disabled={experiences.length >= experiences_limit} length={experiences.length} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        experiences.map((experience,index) => (
                                            <Grid item xs={12} md={6} lg={4} key={index}>
                                                <SingleExperience data={experience} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                        ))
                                    }
                                    {
                                        experiences.length === 0 ? null :
                                            <Grid item xs={12} md={6} lg={4}>
                                                <AddExperienceDialog disabled={experiences.length >= experiences_limit} length={experiences.length} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Experiences)))));