import {create} from "jss";
import rtl from "jss-rtl";
import IranSans from "../../files/fonts/IRANSansWeb.woff2";
import { createMuiTheme } from '@material-ui/core/styles';
import {faIR} from "@material-ui/core/locale";
import React from "react";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const iranSans = {
    fontFamily: 'IranSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('IranSans'),
    local('IranSans'),
    url(${IranSans}) format('woff2')`
};
const breakpoints = createBreakpoints({});

export function RTL(props) {
    return (
        <StylesProvider jss={jss}>
            {props.children}
        </StylesProvider>
    );
}
export const theme = createMuiTheme({
    spacing: 8,
    palette: {
        background: {
            paper: '#FFFFFF',
            default: '#F4F7FE',
            grey: '#eff2f9',
        },
        primary: {
            main: '#328be0',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ffb63e',
        },
        thirdColor: {
            main: '#9bd093'
        },
        fourthColor:{
            main: "#613de9"
        },
        sidebar: {
            bgColor: '#313541',
            textColor: '#808183',
        },
        gray: {
            50: '#fafafa',
            100: '#f7fafc',
            200: '#edf2f7',
            300: '#E0E0E0',
            400: '#c5c6cb',
            500: '#A8A8A8',
            600: '#666666',
            700: '#4a5568',
            800: '#201e21',
            900: '#1a202c',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161',
        },
    },
    status: {
        danger: 'orange',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    typography: {
        fontFamily: 'IranSans, Tahoma',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [iranSans],
            },
        },
        MuiFormLabel: {
            asterisk: {
                color:'#ffb63e',
            }
        },
        MuiTypography: {
            h1: {
                fontSize: 22,
            },
            h2: {
                fontSize: 20,
            },
            h3: {
                fontSize: 18,
            },
            h4: {
                fontSize: 16,
            },
            h5: {
                fontSize: 14,
            },
            h6: {
                fontSize: 14,
            },
            subtitle1: {
                fontSize: 14,
            },
            subtitle2: {
                fontSize: 16,
            },
            body1: {
                fontSize: 14,
            },
            body2: {
                fontSize: 12,
            },
        },
        MuiToggleButton: {
            root: {
                borderRadius: 5,
            },
        },
        MuiCardLg: {
            root: {
                borderRadius:5
            },
        },
        MuiCard: {
            root: {
                borderRadius: 5,
                boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
                '& .MuiCardContent-root:last-child': {
                    paddingBottom: 16,
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 5,
                boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
                [breakpoints.down('md')]: {
                    paddingTop: '8px !important',
                    paddingBottom: '8px !important',
                },
            },
        },
    },
    direction: 'rtl',
}, faIR);