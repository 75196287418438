import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../config/Translate";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol} from "../../../config/values";
import CurrencyFormat from "react-currency-format";
import DepositMoneyDialog from "./DepositMoneyDialog";
import Button from "@material-ui/core/Button/Button";
import WithdrawMoneyDialog from "./WithdrawMoneyDialog";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import Grid from "@material-ui/core/Grid/Grid";
class Wallet extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'type',
                editable: 'never',
                filtering: false,
                title: Translate.transactionType,
                lookup: {
                    "deposit" :
                        <Typography style={{color: "#38bf84"}}><IconButton size="small">{this.props.width === "xs" ? null : <AddRoundedIcon style={{color:"#38bf84"}}/>} </IconButton> {Translate.deposit}</Typography>
                    , "withdraw":
                        <Typography style={{color:"#BF8230"}}><IconButton size="small">{this.props.width === "xs" ? null : <RemoveRoundedIcon style={{color:"#BF8230"}}/>} </IconButton> {Translate.withdraw}</Typography>
            },
            },
            { title: Translate.amount,
                field: 'amount',
                filterPlaceholder: Translate.search,
                render: rowData => <CurrencyFormat value={rowData.amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
            },
            { title: Translate.transactionTrackingCode,
                hidden: this.props.width === "sm" || this.props.width === "xs",
                field: 'refrence',
                filterPlaceholder: Translate.search,
            },
            { title: Translate.description,
                hidden: this.props.width === "sm" || this.props.width === "xs",
                field: 'description',
                filterPlaceholder: Translate.search,
            },
            { title: Translate.status,
                hidden: this.props.width === "sm" || this.props.width === "xs",
                editable: 'never',
                field: 'status',
                filterPlaceholder: Translate.choose,
                lookup: {
                    "confirmed" :
                        <Typography style={{color: "#38bf84"}}>{Translate.confirmed}</Typography>
                    , "pending":
                        <Typography color="textSecondary">{Translate.pending}</Typography>
                    , "not_payed":
                        <Typography color="textSecondary">{Translate.not_payed}</Typography>
                },
            },
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate'
                ,hidden: this.props.width === "sm" || this.props.width === "xs",
            },
            { title: Translate.activity,
                field: 'activity',
                render: rowData => rowData.status === "not_payed" && rowData.type === "deposit" ?
                   <>
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => window.open(`${defaultVariabls.website}/wallet/chargeFromId/${rowData.id}`,'_self')}
                    >{Translate.purchase}</Button>
                       <Button
                           onClick={() =>this.handleRemoveItem(rowData.id)}
                           startIcon={<RemoveCircleRoundedIcon size="small"/>}
                           color="secondary"
                           size="small"
                       >
                           {Translate.remove}
                       </Button>
                       </>
                    : rowData.status === "not_payed" || rowData.status === "pending" ?
                        <Button
                            onClick={() =>this.handleRemoveItem(rowData.id)}
                            startIcon={<RemoveCircleRoundedIcon size="small"/>}
                            color="secondary"
                            size="small"
                        >
                            {Translate.remove}
                        </Button>
                        : null
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRemoveItem(id){
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/wallet/removeHistory`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.loadData();
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                detailPanel={this.props.width !== "sm" && this.props.width !== "xs" ? null : [{
                                    render: rowData => {
                                        return (
                                            <Grid container spacing={1} className="my-10" justify="center" alignItems="center">
                                                <Grid item xs={11}>
                                                    <Typography>{Translate.transactionTrackingCode}: <strong>{rowData.refrence}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography>{Translate.description}: <strong>{rowData.description}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography>{Translate.status}: <strong>{Translate[rowData.status]}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography>{Translate.updateTime}: <strong>{rowData.lastUpdate}</strong></Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    },
                                }
                                ]}
                                title={
                                    <>
                                        <DepositMoneyDialog reload={this.loadData.bind(this)}/>
                                        <WithdrawMoneyDialog reload={this.loadData.bind(this)}/>
                                    </>
                                }
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/wallet/myHistory?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        amount: row.amount,
                                                        id: row.id,
                                                        description: row.description,
                                                        status: row.status,
                                                        type: row.type,
                                                        refrence: row.refrence_id,
                                                        lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                    search:false,
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Wallet)))));