import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect, setStore} from "trim-redux";
import IconButton from "@material-ui/core/IconButton/IconButton";
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
class ShowSingleProduct extends React.Component {

    state = {
        id:this.props.id,
    };
    async handleRemove(){
        let {id} = this.state;
        let cart = this.props.cart;
        let temp_cart = [];
        // eslint-disable-next-line
        await cart.map((item)=>{
            if(item.id !== id) temp_cart.push(item)
        })

        setStore({
            cart:temp_cart,
        });
        this.props.reload();
    }
    render(){
        return (
            <IconButton color="secondary" size="small" onClick={this.handleRemove.bind(this)}>
                <RemoveCircleOutlineRoundedIcon fontSize="small"/>
            </IconButton>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    cart: state.cart
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ShowSingleProduct))));
