import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import {Box, CardHeader, makeStyles} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import withWidth from "@material-ui/core/withWidth/withWidth";

const useStyles = makeStyles((theme) => ({
    link: {
        fontSize: 14,
    },
    textTruncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color:theme.palette.primary.main,
        fontSize:17,
        [theme.breakpoints.down('sm')]: {
            margin:'5px 0 0 ',
        },
    },
    cardHeader: {
        paddingLeft: 20,
        paddingRight: 20,
        borderBottom: "1px solid #ddd",
        paddingBottom: 4,
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column',
            alignItems:'baseline'
        },

        '& .MuiCardHeader-action': {
            marginTop: 0,
            marginRight: 0,
        },
    },
}));

const AppCard = ({
                     title,
                     titleStyle,
                     headerStyle,
                     contentStyle,
                     action,
                     actionStyle,
                     footer,
                     footerPosition,
                     footerStyle,
                     children,
                     width,
                     className,
                     component,
                     to,
                     target,

                     ...rest
                 }) => {
    const classes = useStyles();
    return (
        <Box display='flex' flexDirection='column'  {...rest} clone>
            <Card className={className} component={component} to={to} target={target}>
                {title || action ? (
                    <CardHeader
                        className={classes.cardHeader}
                        style={{
                            ...headerStyle,
                        }}
                        title={
                            <Box
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                justifyContent='space-between'>
                                {typeof title === 'object' ? (
                                    title
                                ) : (
                                    <Box
                                        component='h3'
                                        className={classes.textTruncate}
                                        color='primary'
                                        alignSelf='flex-start'
                                        fontWeight={700}
                                        fontSize={16}
                                        {...titleStyle}>
                                        {title}
                                    </Box>
                                )}
                            </Box>
                        }
                        action={
                            typeof action === 'object' ? (

                                <Box component='span' ml='auto' mt={2} mr={2} {...actionStyle}>
                                    {action}
                                </Box>
                            ) : (
                                <Box component='span' ml='auto' mt={2} mr={2} {...actionStyle}>
                                    <Link
                                        href='#'
                                        color='secondary'
                                        component='button'
                                        underline='none'
                                        className={classes.link}>
                                        <Box component='span'>{action}</Box>
                                    </Link>
                                </Box>
                            )
                        }
                    />
                ) : null}
                <CardContent
                    style={{
                        paddingLeft: width === 'xs' ? 10 : 20,
                        paddingRight: width === 'xs' ? 10 : 20,
                        height: '100%',
                        visibility:"visible !important",
                        ...contentStyle,
                    }}>
                    {children}
                </CardContent>
                {footer ? (
                    <CardActions
                        style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: 16,
                            ...footerStyle,
                        }}>
                        {typeof footer === 'object' ? (
                            footer
                        ) : (
                            <Box
                                component='span'
                                ml={footerPosition === 'right' ? 'auto' : 0}>
                                <Link
                                    color='secondary'
                                    component='button'
                                    underline='none'
                                    className={classes.link}>
                                    {footer}
                                </Link>
                            </Box>
                        )}
                    </CardActions>
                ) : null}
            </Card>
        </Box>
    );
};

export default withWidth()(AppCard);

AppCard.prototype = {
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

AppCard.defaultProps = {
    footerPosition: 'left',
};
