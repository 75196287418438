import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../config/design/styles";
import {Translate} from "../../../../config/Translate";
import FileManagerDialog from "./FileManagerDialog";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import { withSnackbar } from 'notistack';
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
class FileChooserSingle extends React.Component{
    state = {
        FileManagerPending: false,
        loading:true,
        file: '',
        init: this.props.initial,
        id: this.props.initial
    };
    classes = this.props.classes;
    size = this.props.size ? this.props.size: 245;
    componentDidMount(){
        if(this.props.initial > 0){
            this.loadData()

        }else{
            this.setState({
                loading:false
            })
        }
    }

    loadData(){
        let id = this.state.init;
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/file/get`, data, config)
            .then(response => {
                this.setState({
                    file:response.data,
                    init: id,
                    id: response.data.id,
                    loading:false
                })
                this.props.onChange(response.data.id)
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.loadingThumbnailFailed, {
                    variant: 'error',
                });
            });
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }
    setFile = (data) => {
        this.setState({
            file: data,
            id: data.id
        })
        this.props.onChange(data.id)
        this.toggleOperation();
    }
    async removeDataSheet() {
        await this.setState({
            file: '',
            id: 0
        });
        this.props.onChange(0)

    }
    render(){
        let {FileManagerPending,file,id,loading} = this.state;
        return (
            <>
                {
                    loading ? null :
                        <>
                            <Grid container direction="row">
                                <Button
                                    startIcon={<AddBoxRoundedIcon />}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    onClick={this.toggleOperation.bind(this)}
                                >
                                    {Translate.chooseFile}
                                </Button>
                            </Grid>
                            {
                                id > 0  ?
                                    <>
                                        <Grid container spacing={2} direction="row" >
                                            <Grid item xs={6} style={{marginBottom:15}}>
                                                <Typography varirant="body2">
                                                    <IconButton  size="small" onClick={() => this.removeDataSheet(file.id)} aria-label={Translate.removeImage}>
                                                        <DeleteRoundedIcon fontSize="small"/>
                                                    </IconButton>
                                                    <a dir="ltr"  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={`${defaultVariabls.uploads}/FileManager/${file.name}.${file.extension}`}>
                                                        {file.name}.{file.extension}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </> : null
                            }
                            {
                                FileManagerPending ? <FileManagerDialog fileType="all" size={this.props.size} cancleOperation={this.toggleOperation} setData={this.setFile}/> : null
                            }
                            </>
                }

            </>
        );
    }
}
export default withSnackbar(withStyles(Styles)(FileChooserSingle));