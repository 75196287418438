import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import {Translate} from "../../../config/Translate";
import CurrencyFormat from "react-currency-format";
import {currencySymbol} from "../../../config/values";
import LoadingButton from "../../components/elements/LoadingButton";
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
class ShowSingleProduct extends React.Component {

    state = {
        loading: false,
        product:this.props.product,
        cart:this.props.cart,
        SubmitLoading:false,
        in_cart: false,
        in_cart_key: null
    };
    async componentDidMount(){
        let {cart} = this.props;
        let {product} = this.props;
        // eslint-disable-next-line
        await cart.map((item,key)=>{
            if(product.id === item.id){
                this.setState({
                    in_cart:true,
                    in_cart_key:key,
                })
            }
        })
    }
    async componentDidUpdate(prevProps){
        if(this.state.cart !== this.props.cart){
            this.setState({
                cart: this.props.cart,
                in_cart:false,
                in_cart_key:null,
            })
            let {cart} = this.props;
            let {product} = this.props;
            // eslint-disable-next-line
            await cart.map((item,key)=>{
                if(product.id === item.id){
                    this.setState({
                        in_cart:true,
                        in_cart_key:key,
                    })
                }
            })
        }
    }
    classes = this.props.classes;
    async handleSubmit(){
        await this.setState({
            SubmitLoading:true,
        });
        let cart = this.props.cart;
        let temp_cart = [];
        // eslint-disable-next-line
        await cart.map((item,key)=>{
            temp_cart.push(item)
        });

        let{product,in_cart,in_cart_key} = this.state;
        if(in_cart){
            temp_cart.splice(in_cart_key, 1);
            await this.setState({
                in_cart: false,
                in_cart_key: null
            })
        }else{
            let detail = {
                id: product.id,
                name: product.name,
                total_price: !!product.discount_price ? product.discount_price : product.price,
                price: product.price,
                discount_price: product.discount_price,
                quantity: 1,
            };
            await temp_cart.push(detail);
            await this.setState({
                in_cart: true,
            })
        }
        setStore({
            cart:temp_cart,
        });
        await this.setState({
            SubmitLoading:false,
        })
    }
    render(){
        let {product,SubmitLoading,in_cart}  =this.state;
        return (
            <Card style={{height:"100%",position:"relative",textAlign:"center"}}>
                    <CardContent style={{marginBottom:50,textAlign:"right",borderTop:"10px solid",borderColor:product.color}}>
                        <Typography gutterBottom variant="h5" component="h2" className="product-title" style={{color:product.color}}>
                            {product.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className="product-desc">
                            {product.bio}
                        </Typography>
                        <Typography className="price_cart">
                            <CurrencyFormat className={product.discount_price ? "old-price" : null} value={product.price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                            <br/>
                            {
                                product.discount_price ?
                                    <CurrencyFormat value={product.discount_price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                                    : null
                            }
                        </Typography>
                    </CardContent>
                <CardActions style={{position: "absolute",bottom: 0,width:"100%",boxShadow:"rgba(205,205,205,0.2) 0px -2px 10px 0px"}}>
                    <LoadingButton
                        size="small"
                        styleForParent={{margin:"0 auto",}}
                        color={in_cart ? "" : "primary"}
                        variant={in_cart ? "outlined": 'contained'}
                        startIcon={in_cart ? <RemoveCircleOutlineRoundedIcon/> : <ShoppingBasketRoundedIcon/>}
                        loading = {SubmitLoading}
                        onClick={this.handleSubmit.bind(this)}
                    >
                        {in_cart ? Translate.removeFromCart : Translate.makeOrder}
                    </LoadingButton>
                </CardActions>
            </Card>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    cart: state.cart
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ShowSingleProduct))));
