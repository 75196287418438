import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import {Translate} from "../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import Thumbnail from "../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import AllInboxRoundedIcon from '@material-ui/icons/AllInboxRounded';
import Keywords from "../../components/fields/keywords"
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../components/elements/FieldCounter";
import Typography from "@material-ui/core/Typography/Typography";
import CategoryHolderPro from "../../components/fields/CategoryHolderPro";
import {setStore} from "trim-redux";
import AppCardLoader from "../../components/Skeletons/AppCardLoader";
import AppCardLoaderSidebar from "../../components/Skeletons/AppCardLoaderSidebar";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import {connect} from "trim-redux";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {updateRedux} from "../../../config/Security";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Hidden from "@material-ui/core/Hidden/Hidden";
import CheckForHavingProfile from "../../components/elements/CheckForHavingProfile";

class MakeContent extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            title:"",
            description:"",
            slug:"",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: 1,
        errors:{},
        keywords:[],
        categories:[],
        loading: true,
        SubmitLoading: false,
        SubmitDraftLoading: false,
        publish: false,
        realPublish: false,
        confirmed: false,
        id:this.props.match.params.id,
        editMode:false,
        author:null,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/content/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            title: response.data.title,
                            slug: response.data.slug,
                            description: !!response.data.description ? response.data.description : '' ,
                        },
                        thumbnail: response.data.thumbnail.id,
                        confirmed: response.data.confirmed,
                        publish: response.data.publish,
                        realPublish: response.data.publish,
                        author: response.data.author,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },

                    })
                    let categoriesTemp=[];
                    let keywordsTemp=[];
                    response.data.categories.map(item => {
                        categoriesTemp.push(item.id)
                        return true
                    })
                    response.data.keywords.map(item => {
                        keywordsTemp.push(item.name);
                        return true
                    });
                    this.setState({
                        categories: categoriesTemp,
                        keywords: keywordsTemp,
                        loading: false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields,content,keywords,categories } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)){
            formIsValid = false;
            errors['title'] = Translate.emptyField;
        }
        if(!content.HTML){
            formIsValid = false;
            errors['editor'] = Translate.emptyField;
        }else if(content.HTML.length < 5){
            formIsValid = false;
            errors['editor'] = Translate.emptyField;
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        //keywords
        if(keywords.length  < 3) {
            formIsValid = false;
            errors['keywords'] = Translate.addMoreKeywords;
        }
        //categories
        if(categories.length  === 0) {
            formIsValid = false;
            errors['categories'] = Translate.addCategories;
        }
        if(validator.isEmpty(fields.description)){
            formIsValid = false;
            errors['description'] = Translate.emptyField;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
                SubmitDraftLoading:false
            })
        }
        return callback(formIsValid)
    }
    handleValidationDraft(callback){
            let { fields } = this.state;
            let errors = {};
            let formIsValid = true;

            //title
            if(validator.isEmpty(fields.title)){
                formIsValid = false;
                errors['title'] = Translate.emptyField;
            }

            if(!formIsValid){
                this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                    variant: 'warning',
                });
                this.setState({
                    errors,
                    SubmitLoading:false,
                    SubmitDraftLoading:false
                })
            }
            return callback(formIsValid)
        }

    handleRequest(){
        let { fields,thumbnail,confirmed,content,keywords,categories,publish,id,editMode} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('confirmed', confirmed ? 1 : 0);
        data.append('description', fields.description);
        data.append('thumbnail', thumbnail);
        data.append('categories',  JSON.stringify(categories));
        data.append('keywords',  JSON.stringify(keywords));
        data.append('publish',  publish ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/content/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.contentEdited : Translate.contentAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                updateRedux();
                if(!editMode){
                    this.props.history.push(`/contents/make/${response.data.id}`);
                }else{
                    this.loadData();
                }

            })
            .catch(error => {
                this.props.enqueueSnackbar(editMode ?  Translate.contentEditingFailed : Translate.contentAddingFailed, {
                    variant: 'error',
                });
                console.log(error.response)
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    async handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        await this.setState({
            publish: true
        })
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    async handleSubmitDraft(e){
        this.setState({
            SubmitDraftLoading: true,
        })
        e.preventDefault();
        await this.setState({
            publish: false
        })
        this.handleValidationDraft((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });

    }
    handleOutPut(code){
        let {errors} = this.state;
        errors['editor'] = '';
        this.setState({
            content:code,
            errors
        })
    }
    handleCategoryHolder(categories){
        let {errors} = this.state;
        errors['categories'] = '';
        this.setState({
            categories,
            errors
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleKeywords(keywords){
        let {errors} = this.state;
        errors['keywords'] = '';
        this.setState({
            keywords,
            errors
        })
    }
    render(){
        let {fields,author, errors,confirmed,SubmitLoading,SubmitDraftLoading,keywords,thumbnail,categories,content,editMode,publish,realPublish} = this.state;
        let {user} = this.props;
        return (
            <>
                {
                    user.role.type !== "admin" &&
                    <CheckForHavingProfile new={!editMode}/>
                }

                <div className="mb-15">
                    <BreadCrumbs  firstText={Translate.contents} firstLink="/contents" secondText={editMode ? Translate.editContent : Translate.makeContent}/>
                </div>
                <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.makeContent}>
                    {


                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={8} lg={9}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12}>
                                        <AppCard
                                            title={editMode ? Translate.editContent : Translate.makeContent}
                                            action={
                                                <>
                                                {
                                                    this.props.width === "xs" || this.props.width === "sm" ? null :
                                                        <IconButton><AllInboxRoundedIcon color="primary"/></IconButton>
                                                }
                                                </>
                                            }>
                                            {
                                                this.state.loading ? <AppCardLoader/> :
                                                    <Grid container spacing={2} justify="center" alignItems="center">
                                                        <Grid item xs={12}>
                                                            {
                                                                !editMode ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        {
                                                                            !! user.role.permission.manageBlogs ?
                                                                                <>
                                                                                    <Alert
                                                                                        severity="info"
                                                                                        action={
                                                                                            <FormControlLabel
                                                                                                style={{marginRight:10}}
                                                                                                control={
                                                                                                    <Switch
                                                                                                        checked={confirmed}
                                                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                                                        value={confirmed}
                                                                                                        color="primary"
                                                                                                    />
                                                                                                }
                                                                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        {Translate.confirmationStatus}
                                                                                    </Alert>
                                                                                </>
                                                                                :
                                                                                confirmed ?
                                                                                    <Alert variant="filled" severity="success">
                                                                                        {Translate.yourContentIsConfirmed}
                                                                                    </Alert>
                                                                                    :
                                                                                    <Alert variant="filled" severity="warning">
                                                                                        {Translate.yourContentIsNotConfirmed}
                                                                                    </Alert>
                                                                        }
                                                                    </>

                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                variant="outlined"
                                                                name="title"
                                                                label={Translate.contentTitle}
                                                                type="text"
                                                                id="title"
                                                                value={fields.title}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['title']}
                                                                helperText={errors['title']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <RichEditor error={errors['editor']} ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                multiline
                                                                fullWidth
                                                                required
                                                                name="description"
                                                                label={Translate.seoDescription}
                                                                placeholder={Translate.seoDescription}
                                                                type="text"
                                                                id="description"
                                                                rows="4"
                                                                value={fields.description}
                                                                onChange={this.handleChange.bind(this)}
                                                                onClick={this.handleClickDescription.bind(this)}
                                                                error = {!!errors['description']}
                                                                helperText={errors['description']}
                                                            />
                                                            <FieldCounter length={fields.description.length} max={150}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{Translate.keywordsLabel}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Keywords
                                                                required
                                                                error={errors['keywords']}
                                                                input={keywords}
                                                                handleChange={this.handleKeywords.bind(this)}
                                                            />
                                                            <Typography
                                                                variant="body2"
                                                                style={{marginTop: 25, fontWeight: 700}}
                                                            >
                                                                {Translate.keywordsDescription}
                                                                <br/>
                                                            </Typography>
                                                            <Typography>
                                                                {Translate.pressEnterAfterEachKeyword}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Hidden smDown>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {editMode ? publish ?  Translate.editContent : Translate.makeContent : Translate.makeContent}
                                        </LoadingButton>
                                    </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2} >
                                    <Hidden smDown>
                                    <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        style={{width: "100%"}}
                                        styleForParent={{marginBottom:0,marginTop:0}}
                                        onClick={this.handleSubmit.bind(this)}
                                        loading={SubmitLoading}
                                        startIcon={editMode ? realPublish ?  <EditRoundedIcon/> : <PublishRoundedIcon/> : <PublishRoundedIcon/>}
                                    >
                                        {editMode ? realPublish ?  Translate.editContent : Translate.makeContent : Translate.makeContent}
                                    </LoadingButton>
                                        {
                                            !realPublish?
                                                <LoadingButton
                                                    color="primary"
                                                    style={{float:"right"}}
                                                    size="small"
                                                    styleForParent={{margin:"5px 0"}}
                                                    startIcon={<DraftsRoundedIcon/>}
                                                    onClick={this.handleSubmitDraft.bind(this)}
                                                    loading={SubmitDraftLoading}
                                                >
                                                    {Translate.makeDraft}
                                                </LoadingButton>
                                                : null
                                        }
                                    {
                                        !!editMode && !!realPublish  ?
                                            <Button
                                                component="a"
                                                target="_blank"
                                                size="small"
                                                startIcon={<PageviewRoundedIcon/>}
                                                href={`${defaultVariabls.website}/article/${fields.slug}`}
                                                color="primary"
                                            >
                                                {Translate.viewArticle}
                                            </Button>
                                            : null
                                    }
                                    </Grid>
                                    </Hidden>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                            this.state.loading ? <AppCardLoaderSidebar/> :
                                                <>
                                                    <SubTitle align="left">{Translate.ThumbnailTitle}</SubTitle>
                                                    <Thumbnail rounded ref={this.ThumbChild}
                                                               handleThumbImage={this.handleThumbImage.bind(this)}
                                                               initial={thumbnail}/>
                                                </>
                                                }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                            this.state.loading ? <AppCardLoaderSidebar/> :
                                                <CategoryHolderPro limit={4} error={errors['categories']} outPut={this.handleCategoryHolder.bind(this)} inPut={categories}/>
                                            }
                                        </AppCard>
                                    </Grid>
                                    {
                                        !!editMode ?
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderSidebar/> :
                                                            <>
                                                                <SubTitle align="left">{Translate.author}</SubTitle>
                                                                <Typography>{author.first_name} {author.last_name}</Typography>
                                                            </>

                                                    }
                                                </AppCard>
                                            </Grid>
                                            : null
                                    }
                                    <Hidden smUp>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                style={{width: "100%"}}
                                                styleForParent={{marginBottom:0,marginTop:0}}
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={SubmitLoading}
                                                startIcon={editMode ? realPublish ?  <EditRoundedIcon/> : <PublishRoundedIcon/> : <PublishRoundedIcon/>}
                                            >
                                                {editMode ? realPublish ?  Translate.editContent : Translate.makeContent : Translate.makeContent}
                                            </LoadingButton>
                                            {
                                                !realPublish?
                                                    <LoadingButton
                                                        color="primary"
                                                        style={{float:"right"}}
                                                        size="small"
                                                        styleForParent={{margin:"5px 0"}}
                                                        startIcon={<DraftsRoundedIcon/>}
                                                        onClick={this.handleSubmitDraft.bind(this)}
                                                        loading={SubmitDraftLoading}
                                                    >
                                                        {Translate.makeDraft}
                                                    </LoadingButton>
                                                    : null
                                            }
                                            {
                                                !!editMode && !!realPublish  ?
                                                    <Button
                                                        component="a"
                                                        target="_blank"
                                                        size="small"
                                                        startIcon={<PageviewRoundedIcon/>}
                                                        href={`${defaultVariabls.website}/article/${fields.slug}`}
                                                        color="primary"
                                                    >
                                                        {Translate.viewArticle}
                                                    </Button>
                                                    : null
                                            }
                                        </Grid>
                                    </Hidden>

                                </Grid>
                            </Grid>
                        </Grid>

                    }
                </AppAnimate>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeContent)))));
