import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import AddBox from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import MakePortfolio from "./MakePortfolio";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import ColorLensRoundedIcon from '@material-ui/icons/ColorLensRounded';
import Typography from "@material-ui/core/Typography/Typography";
import {portfolios_limit} from "../../../../../config/values";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Grid from "@material-ui/core/Grid/Grid";

class AddPortfolioDialog extends React.Component {
    state = {
        open:false,
    };
    handleDone(){
        this.setState({
            open:false,
        })
        this.props.reload();
    }
    render(){
        let {open} = this.state;
        let {disabled,first,length} = this.props;
        return(
            <>
                {
                    disabled ? null :
                        <>
                            {
                                first ?
                                    <>
                                        <Button
                                            disabled={disabled}
                                            variant={!!this.props.variant ? this.props.variant : "contained"}
                                            color="primary"
                                            size="small"

                                            startIcon={<AddBox/>}
                                            onClick={() => this.setState({open: !this.state.open})}
                                        >
                                            {Translate.addPortfolio}
                                        </Button>
                                        <Typography varaint="body2" color="secondary"
                                                    style={{marginTop: 5}}><strong>{portfolios_limit - length}</strong> {Translate.portfoliosLeft}
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <Card variant="outlined" style={{
                                            boxShadow: 'none',
                                            height: "100%",
                                            border: 0,
                                            background: "#efefef",
                                            position: 'relative',
                                            minHeight: 100
                                        }}>
                                            <CardContent>
                                                <Grid container spacing={2} alignItems="center" justify="center">
                                                    <Grid item xs={12} style={{textAlign: "center"}}>
                                                        <div className="make_center_in_card">
                                                            <Typography
                                                                paragraph>{Translate.addPortfoliosDetails}</Typography>
                                                            <Button
                                                                disabled={disabled}
                                                                variant={!!this.props.variant ? this.props.variant : "contained"}
                                                                color="secondary"
                                                                size="small"
                                                                startIcon={<AddBox/>}
                                                                onClick={() => this.setState({open: !this.state.open})}
                                                            >
                                                                {Translate.addPortfolio}
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                            </CardContent>
                                        </Card>
                                        <Typography align="center" varaint="body2" color="secondary"
                                                    style={{marginTop: 5}}><strong>{portfolios_limit - length}</strong> {Translate.portfoliosLeft}
                                        </Typography>
                                    </>
                            }
                        </>
                }
                <Dialog  fullWidth maxWidth="md"  open={open} onClose={() => this.setState({open: !this.state.open})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <IconButton>
                            <ColorLensRoundedIcon color="primary"/>
                        </IconButton>
                        {Translate.addPortfolio}</DialogTitle>
                    <IconButton aria-label="close" className="modal-close-btn"  onClick={() => this.setState({open: !this.state.open})}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <MakePortfolio handleDone={this.handleDone.bind(this)}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(AddPortfolioDialog)));