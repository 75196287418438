import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ManageUserVerificationDialog from "./components/ManageUserVerificationDialog";

class ManageVerifications extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'melli_card_image',
                editable: 'never',
                filtering: false,
                title: Translate.melli_card_image,
                render: rowData => <img alt="avatar" src={rowData.melli_card_image} className={this.classes.FMThumbnail}/>
            },
            {
                field: 'user',
                editable: 'never',
                filtering: false,
                title: Translate.userName,
            },
            {
                field: 'bank_name',
                editable: 'never',
                filtering: false,
                title: Translate.bank_name,
            },
            {
                field: 'sheba',
                editable: 'never',
                filtering: false,
                title: Translate.sheba,
            },
            {
                field: 'confirmed',
                editable: 'never',
                filtering: false,
                title: Translate.confirmationStatus,
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{Translate.confirmed}</Typography>
                    ,
                    0:
                        <Typography style={{color:"#BF8230"}}>{Translate.unConfirmed}</Typography>
                },
            },
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate'},
        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        openModal:false,
        id:null,
    };
    loadData(){
        this.setState({
            openModal:false,
            id:null,
        })
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRowClick(data){
        this.setState({
            openModal:true,
            id: data.id,
        })
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                onRowClick={((evt, selectedRow) =>  this.handleRowClick(selectedRow))}

                                title={<> </>}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/users/getAllVerifications?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        melli_card_image: `${defaultVariabls.uploads}/FileManager/${row.melli_card.name}_small.${row.melli_card.extension}`,
                                                        id: row.id,
                                                        user: row.user.first_name+" "+row.user.last_name,
                                                        confirmed: row.user.verified,
                                                        sheba: row.sheba,
                                                        bank_name: row.bank_name,
                                                        lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                }}
                            />
                            <ManageUserVerificationDialog open={this.state.openModal} id={this.state.id} reload={this.loadData.bind(this)}/>

                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManageVerifications)))));