import {makeStyles} from '@material-ui/core';
const useStyles = makeStyles((theme) => {
  return {
    container: {
      maxHeight: '100vh',
      paddingLeft: 30,
      paddingTop: 30,
      width: '16rem',
      [theme.breakpoints.up('xl')]: {
        width:  '22.8rem',
      },
    },
    drawerContainer: {
      maxHeight: '100vh',
        position:"relative",
      paddingLeft: "0 !important",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      boxShadow: "-20px 0 20px 13px #d3d7e9",
      width: '17rem',
      [theme.breakpoints.up('xl')]: {
        width: '21.6rem',
      },
    },
    sidebarBg: {
      overflow: 'hidden',
      backgroundColor: 'white'
    },
    scrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 8,
      height: 'calc(100vh - 315px) !important'
    },
    drawerScrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20,
      height: 'calc(100vh - 58px) !important',
    },

    sidebarModern: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
      borderRadius: 30,
    },
    sidebarStandard: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
    },
  };
});
export default useStyles;
