import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import {withSnackbar} from "notistack";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {Translate,} from "../../../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";

class Courses extends React.Component {
    classes = this.props.classes;
    state={
        courses:[''],
        errors:[]
    };
    componentDidMount(){
        if(this.props.input){
            this.setState({
                courses: this.props.input,
            })
        }
    }
    handleValidation(){


    }
    handleChangeCourse(e){
        let {courses,errors} = this.state;
        let target = e.target;
        errors[`course${parseInt(target.name)}`] = "";
        let course = target.value;
        courses[parseInt(target.name)] = course;
        this.setState({
            courses
        })
        if(course.length > 0){
            this.props.handleCourses(courses);
        }
    }
    addCourse() {
        let {courses} = this.state;
        if(courses[courses.length-1].length !== 0){
            courses.push("");
            this.setState({
                courses
            })
        }else{
            this.props.enqueueSnackbar(Translate.fillPrevFieldFirst, {
                variant: 'warning',
            });
        }
    }
    async handleRemoveField(index) {
        let {courses} = this.state;
        courses.splice(index, 1)
        await this.setState({
            courses
        })
        await this.props.handleCourses(courses);
        this.handleValidation()
    }
    render() {
        let {courses,errors} = this.state;
        return(
            <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">

                {
                    courses.map((course,index) =>(
                        <Grid key={index} style={{position:"relative"}} item xs={12}>
                            <TextField
                                variant="outlined"
                                value={courses[index]}
                                fullWidth
                                size="small"
                                id={`course${index}`}
                                label={Translate.course}
                                name={index}
                                onChange={this.handleChangeCourse.bind(this)}
                                onBlur={this.handleValidation.bind(this)}
                                error = {!!errors[`course${index}`]}
                                helperText={errors[`course${index}`]}
                            />
                            {
                                index > 0 ?
                                    <IconButton
                                        className={this.classes.PassVisibilityIcon}
                                        onClick={() => this.handleRemoveField(index)}
                                    >
                                        <CloseRoundedIcon/>
                                    </IconButton>
                                    : null
                            }
                        </Grid>
                    ))
                }
                {
                    courses.length > 9 ? null :
                        <Grid item xs={12}>
                            <Button
                                onClick ={this.addCourse.bind(this)}
                                color="primary"
                                size="small"
                                className="float-left"
                                startIcon={<AddRoundedIcon/>}
                            >
                                {Translate.addRow}
                            </Button>

                        </Grid>
                }
            </Grid>
        )
    }
}

export default withRouter(withSnackbar(withStyles(Styles)(Courses)));