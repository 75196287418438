import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import AppAnimate from "../../../../components/AppAnimate";
import {Translate} from "../../../../../config/Translate";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import UserInformations from "../expert/components/UserInformations";
import PleaseConfirmYourInformationCustomer from "../../components/PleaseConfirmYourInformationCustomer";
import CustomerServices from "./components/CustomerServices";
import InterestedArticles from "../../components/InterestedArticles";

class CustomerDashboard extends React.Component {
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);

    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.dashboard}>
                <Box pt={{xl: 4}} clone>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} md={11}>
                            <PleaseConfirmYourInformationCustomer/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <CustomerServices/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <UserInformations/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <InterestedArticles/>
                        </Grid>
                    </Grid>
                </Box>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    dashboard: state.dashboard,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CustomerDashboard))));