import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import AppCardLoaderSidebar from "../../../../components/Skeletons/AppCardLoaderSidebar";
import SubTitle from "../../../../components/elements/SubTitle";
import Thumbnail from "../../../fileManager/Components/Thumbnail";
import Courses from "./components/Courses";
import {currencySymbol} from "../../../../../config/values";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Divider from "@material-ui/core/Divider/Divider";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";


class MakeTeachingPlan extends React.Component {

    classes = this.props.classes
    state={
        fields: {
            title: '',
            description:'',
            payment_type:'',
            minPrice:'',
            minPriceR:'',
            maxPrice:'',
            maxPriceR:'',
        },
        errors:{},
        courses:[''],
        current:false,
        dynamicPrice:true,
        thumbnail:1,
        loading:!!this.props.id,
        SubmitLoading:false,
        id: !!this.props.id ? this.props.id : 0,
        editMode: !!this.props.id
    };
    componentDidMount(){
        if(!!this.props.id){
            this.loadData();
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/teaching_plan/get/${this.state.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        title: !!response.data.title ? response.data.title : '',
                        description: !!response.data.description ? response.data.description : '',
                        minPrice: !!response.data.min_price ? response.data.min_price : '',
                        minPriceR: !!response.data.min_price ? response.data.min_price : '',
                        maxPrice: !!response.data.max_price ? response.data.max_price : '',
                        maxPriceR: !!response.data.max_price ? response.data.max_price : '',
                        payment_type: !!response.data.payment_type ? response.data.payment_type : '',
                    },
                    dynamicPrice: !!response.data.max_price && !!response.data.min_price,
                    thumbnail: response.data.thumbnail.id,
                });

                let courses = [];
                // eslint-disable-next-line
                response.data.courses.map((item)=> {
                    courses.push(item.name)
                })
                this.setState({
                    loading:false,
                    courses: courses.length > 0 ? courses : [""]
                })

            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        if(target.name === "minPrice") {
            fields.minPriceR = target.value.replace(/,/ig, '');
        }
        else if(target.name === "maxPrice"){
            fields.maxPriceR = target.value.replace(/,/ig, '');

        }else{
            fields[target.name] = target.value;
        }
        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)) {
            formIsValid = false;
            errors['title'] = Translate.emptyField;
        }
        //price
        if(!fields.minPriceR) {
            formIsValid = false;
            errors['minPrice'] = Translate.emptyField;
        }
        if(!!fields.description ? fields.description.length > 150 : false){
            formIsValid = false;
            errors['description'] = Translate.maxLength150;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,id,thumbnail,courses} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('description', fields.description);
        data.append('min_price', fields.minPriceR);
        data.append('courses', JSON.stringify(courses));
        data.append('thumbnail', thumbnail);
        data.append('id', !!id ? id : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/teaching_plan/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                this.props.handleDone();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleCourses(data){
        this.setState({
            courses:data
        })
    }

    render() {
        const {errors,fields,SubmitLoading,thumbnail,courses} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2}  justify="space-around" >
                        <CssBaseline/>
                        <Grid item xs={12} md={8}>
                            <Grid container  spacing={2} justify="center" >
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        value={fields.title}
                                        fullWidth
                                        label={Translate.titleTeaching}
                                        name="title"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['title']}
                                        helperText={errors['title']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.description}
                                        fullWidth
                                        label={Translate.teachingDescription}
                                        name="description"
                                        rows={5}
                                        multiline
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['description']}
                                        helperText={errors['description']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {
                                this.state.loading ? <AppCardLoaderSidebar/> :
                                    <>
                                        <SubTitle>{Translate.ThumbnailTitle}</SubTitle>
                                        <Thumbnail rounded ref={this.ThumbChild}
                                                   handleThumbImage={this.handleThumbImage.bind(this)}
                                                   initial={thumbnail}/>
                                    </>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SubTitle className="mb-15" align="left">{Translate.courses}</SubTitle>
                            <Courses handleCourses={this.handleCourses.bind(this)} input={courses}/>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} md={5}>
                            <SubTitle className="mb-15" align="left">{Translate.planPricing}</SubTitle>
                            <CurrencyTextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                required
                                currencySymbol={currencySymbol}
                                decimalPlaces={0}
                                value={fields.minPrice}
                                label={Translate.teaching_price}
                                name="minPrice"
                                disabled={fields.payment_type === "free"}
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['minPrice']}
                                helperText={errors['minPrice']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeTeachingPlan))));