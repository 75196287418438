import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate/index";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../../components/elements/FieldCounter";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../../components/fields/LinearCategoryHolder/index";
import VariablesChooser from "../../../components/fields/VariablesChooser/index";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {currencySymbol} from "../../../../config/values";
import { ColorPicker } from 'material-ui-color';
import Typography from "@material-ui/core/Typography/Typography";

class MakeProduct extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            slug:"",
            bio:"",
            transfer_type:"",
            description:"",
            howToSell:false,
            price:'',
            priceR:'',
            discount_price:'',
            discount_priceR:'',
            transfer_price:'',
            transfer_priceR:'',
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: 1,
        editor_input_text: '',
        errors:{},
        categories:[],
        categoriesName:[],
        variables:[],
        variables_res:[],
        loading: true,
        SubmitLoading: false,
        publish: true,
        confirmed: false,
        color: '#328be0',
        slug: null,
        id:this.props.match.params.id,
        editMode:false,
        percent:''
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChangePercent(e){
        let percent = e.target.value;
        let fields = this.state.fields;
        let price = this.state.fields.priceR;
        if(!!price){
            fields['discount_priceR'] = price - (price*percent/100);
            fields['discount_price'] = price - (price*percent/100);
        }
        if(parseInt(percent) === 0){
            fields.discount_price = '';
            fields.discount_priceR = '';
            percent = ''
        }
        this.setState({
            percent,
            fields
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/product/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            slug: response.data.slug,
                            bio: response.data.bio,
                            transfer_type: response.data.transfer_type,
                            description: !!response.data.description ? response.data.description : '',
                            price: response.data.price,
                            priceR: response.data.price,
                            howToSell: response.data.online,
                            discount_price: response.data.discount_price === null ? "" : response.data.discount_price,
                            discount_priceR: response.data.discount_price === null ? "" : response.data.discount_price,
                            transfer_price: response.data.transfer_price === null ? "" : response.data.transfer_price,
                            transfer_priceR: response.data.transfer_price === null ? "" : response.data.transfer_price,
                        },
                        thumbnail: response.data.thumbnail.id,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        publish: response.data.publish,
                        confirmed: response.data.confirmed,
                        percent : response.data.discount_price > 0 ? Math.max(0, Math.floor((response.data.price - response.data.discount_price)*100/response.data.price)) : '',
                        variables_res: response.data.variables,
                        slug: response.data.slug,
                        color: response.data.color,
                    });
                    let categoriesTemp=[];
                    let categoriesNameTemp=[];
                    response.data.categories.map(item => {
                        categoriesTemp.push(item.id)
                        categoriesNameTemp.push(item.name)
                        return true
                    })

                    this.setState({
                        categories: categoriesTemp,
                        categoriesName: categoriesNameTemp,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields,percent} = this.state;
        let target = e.target;
        if(target.name === "howToSell"){
            fields[target.name] = target.checked
        }else if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
            if(!!fields.discount_priceR)
                percent = Math.max(0, Math.floor((fields.priceR - fields.discount_priceR)*100/fields.priceR));

        }else if (target.name === "discount_price") {
            fields.discount_priceR = target.value.replace(/,/ig, '');
            if(!!fields.priceR)
                percent = Math.max(0, Math.floor((fields.priceR - fields.discount_priceR)*100/fields.priceR));
            if(parseInt(fields.discount_priceR) >= parseInt(fields.priceR)){
                fields.discount_price = '';
                fields.discount_priceR = '';
                percent = ''
            }
        }else if (target.name === "transfer_price") {
            fields.transfer_priceR = target.value.replace(/,/ig, '');

        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
            percent

        });
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }
        //slug
        if(validator.isEmpty(fields.slug)){
            formIsValid = false;
            errors['slug'] = Translate.emptyField;
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        //price
        if(!fields.priceR){
            formIsValid = false;
            errors['price'] = Translate.emptyField;
        }else{
            if(fields.discount_priceR !== null){
                if(fields.discount_priceR > 0){
                    if(fields.discount_priceR > fields.priceR){
                        formIsValid = false;
                        errors['discount_price'] = Translate.discountMoreThanPrice;
                    }
                }
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,color,content,thumbnail,variables,editMode,categories,confirmed,publish} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('slug', fields.slug);
        data.append('color', !!color.css ? color.css.backgroundColor : "");
        data.append('bio', fields.bio);
        data.append('transfer_type', fields.transfer_type);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('thumbnail', thumbnail);
        data.append('price', fields.priceR);
        data.append('transfer_price', fields.transfer_priceR);
        data.append('online', fields.howToSell ? 1 : 0);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('publish', publish ? 1 : 0);
        data.append('discount_price', fields.discount_priceR);
        data.append('variables', JSON.stringify(variables));
        data.append('categories', JSON.stringify(categories));

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/product/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/products`);
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  Translate.contentEditingFailed : Translate.contentAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        this.setState({categories})
    }
    handleVariablesHolder(variables){
        this.setState({variables})
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    render(){
        let {fields,percent,color, errors,SubmitLoading,thumbnail,categories,categoriesName,content,editMode,confirmed,editor_input_text} = this.state;
        const {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? Translate.editProduct : Translate.makeProduct}>
            {

                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={8} lg={9}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12}>
                                        <AppCard
                                            title={editMode ? Translate.editProduct : Translate.makeProduct}
                                            action={
                                                <BreadCrumbs singleLevel firstText={this.props.edit ? Translate.editProduct : Translate.makeProduct}/>
                                            }>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>
                                                        <Grid item xs={12}>
                                                            {
                                                                !editMode ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        {
                                                                            !! user.role.permission.managePayments ?
                                                                                <>
                                                                                    <Alert
                                                                                        severity="info"
                                                                                        action={
                                                                                            <FormControlLabel
                                                                                                style={{marginRight:10}}
                                                                                                control={
                                                                                                    <Switch
                                                                                                        checked={confirmed}
                                                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                                                        value={confirmed}
                                                                                                        color="primary"
                                                                                                    />
                                                                                                }
                                                                                                label={confirmed ? Translate.published : Translate.draft}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        {Translate.publishStatus}
                                                                                    </Alert>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>

                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                name="name"
                                                                variant="outlined"
                                                                label={Translate.productName}
                                                                placeholder={Translate.productName}
                                                                type="text"
                                                                id="name"
                                                                value={fields.name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['name']}
                                                                helperText={errors['name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                name="slug"
                                                                variant="outlined"
                                                                label={Translate.technicalName}
                                                                placeholder={Translate.technicalName}
                                                                type="text"
                                                                id="slug"
                                                                value={fields.slug}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['slug']}
                                                                helperText={errors['slug']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                name="bio"
                                                                variant="outlined"
                                                                label={Translate.productBio}
                                                                placeholder={Translate.productBio}
                                                                type="text"
                                                                id="bio"
                                                                value={fields.bio}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['bio']}
                                                                helperText={errors['bio']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} >
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                currencySymbol={currencySymbol}
                                                                decimalPlaces={0}
                                                                value={fields.price}
                                                                id="price"
                                                                label={Translate.price}
                                                                name="price"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['price']}
                                                                helperText={errors['price']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} md={2}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={percent}
                                                                fullWidth
                                                                id="percent"
                                                                label={Translate.discount_percent}
                                                                name="percent"
                                                                autoComplete="percent"
                                                                onChange={this.handleChangePercent.bind(this)}
                                                                error = {!!errors['percent']}
                                                                helperText={errors['percent']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9} md={4}>
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                fullWidth
                                                                currencySymbol={currencySymbol}
                                                                decimalPlaces={0}
                                                                value={fields.discount_price}
                                                                id="discount_price"
                                                                label={Translate.discount_price}
                                                                name="discount_price"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['discount_price']}
                                                                helperText={errors['discount_price']}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <LinearCategoryHolder
                                                                outPut={this.handleCategoryHolder.bind(this)}
                                                                inPut={categoriesName} editMode={categoriesName.length === 0 ? false : editMode}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <VariablesChooser
                                                                outPut={this.handleVariablesHolder.bind(this)}
                                                                categories={categories}
                                                                input={this.state.variables_res}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{Translate.productDescription}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <RichEditor inputText={editor_input_text} ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            multiline
                                                            fullWidth
                                                            name="description"
                                                            label={Translate.seoDescription}
                                                            placeholder={Translate.seoDescription}
                                                            type="text"
                                                            id="description"
                                                            rows="4"
                                                            value={fields.description}
                                                            onChange={this.handleChange.bind(this)}
                                                            onClick={this.handleClickDescription.bind(this)}
                                                            error={!!errors['description']}
                                                            helperText={errors['description']}
                                                        />
                                                        <FieldCounter length={fields.description.length} max={150}/>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {editMode ? Translate.editProduct : Translate.makeProduct}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2} alignItems="center" justify="center">
                                    <Grid item xs={12} style={{paddingTop: 0}}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            styleForParent={{marginBottom:10}}
                                            style={{width: "100%"}}
                                            onClick={this.handleSubmit.bind(this)}
                                            loading={SubmitLoading}
                                        >
                                            {editMode ? Translate.editProduct : Translate.makeProduct}
                                        </LoadingButton>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <SubTitle>{Translate.ThumbnailTitle}</SubTitle>
                                                            <Thumbnail rounded ref={this.ThumbChild}
                                                                       handleThumbImage={this.handleThumbImage.bind(this)}
                                                                       initial={thumbnail}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                            <Typography className="mt-10">{Translate.chooseColor}</Typography>
                                            <ColorPicker
                                                variant="outlined"
                                                fullWidth
                                                label={Translate.chooseColor}
                                                value={color}
                                                onChange={color => this.setState({color})}
                                            />
                                        </AppCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeProduct))));