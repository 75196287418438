import React from 'react';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import {Translate} from "../../../../config/Translate";
import AppCard from "../../../components/layouts/components/AppCard";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {Link, withRouter} from "react-router-dom";
import Box from "@material-ui/core/Box/Box";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";

class InterestedArticles extends React.Component {
    classes = this.props.classes;
    state = {
        loading:true,
        articles:[],
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/contents/getInterested`, config)
            .then(response => {
                this.setState({
                    articles:response.data,
                    loading:false
                })
                console.log(response.data)
            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render(){
        let {articles,loading} = this.state;
        return (
            <AppCard
                title={Translate.interestedArticles}>
                {
                    loading ? <AppCardLoaderFields/>:
                        <>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                    {Translate.youCanManageYoutInterests}
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        component={Link}
                                        to={"/interests"}
                                    >
                                        {Translate.manageInterests}
                                    </Button>
                                    </Typography>
                                </Grid>
                                {
                                    !!articles ? articles.map((article,index)=>(
                                        <Grid item xs={12} md={4}>
                                            <AppCard
                                                key={index}
                                                height={1}
                                                component="a"
                                                target="_blank"
                                                href={`${defaultVariabls.website}/article/${article.slug}`}
                                                className='article-card'>
                                                <Box display='flex' alignItems='center'>
                                                    <Box mr={3} clone>
                                                        <Avatar variant="rounded" className="article-avatar" src={`${defaultVariabls.website}/uploads/FileManager/${article.thumbnail.name}_small.${article.thumbnail.extension}`}/>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography component='h3' variant='inherit' color='inherit'>
                                                            {article.title}
                                                        </Typography>
                                                        <Box mt={0.5} component='p'>
                                                            {Translate.author}: <strong><a href={`${defaultVariabls.website}/${article.author.name}`}>{article.author.first_name+" "+article.author.last_name}</a></strong>
                                                        </Box>
                                                        {/*<Box mt={0.5} className="mb-0" component='p'>
                                                            {
                                                                article.categories.map((category,index)=>(
                                                                    <Button
                                                                        component="a"
                                                                        size="small"
                                                                        color="primary"
                                                                        href={`${defaultVariabls.website}/articles/${category.slug}`}>
                                                                        {category.name}
                                                                    </Button>
                                                                ))
                                                            }
                                                        </Box>*/}
                                                        <Box mt={0} className="mb-0 text-left" component='p'>
                                                            <Button
                                                                size="small"
                                                                color="secondary"
                                                                variant="outlined"
                                                                component="a"
                                                                target="_blank"
                                                                href={`${defaultVariabls.website}/article/${article.slug}`}
                                                            >
                                                                {Translate.viewMore}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </AppCard>
                                        </Grid>
                                    )) : null
                                }
                            </Grid>
                        </>
                }
            </AppCard>
        );
    }
}
const mstp = state => ({
    dashboard: state.dashboard,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(InterestedArticles))));
