import React from 'react';
import {connect} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import {Link} from 'react-router-dom';
import {withSnackbar} from "notistack";
import Button from "@material-ui/core/Button/Button";
import PhoneInTalkRoundedIcon from '@material-ui/icons/PhoneInTalkRounded';

const OpportunityCounter = (props) => {
    const user = props.user;
    const dashboard = props.dashboard;
    const expert_dashboard = dashboard === "expert";
    return (
        <>
            <Button
                component={Link}
                to="/shop"
                size="small"
                startIcon={<PhoneInTalkRoundedIcon/>}
                color="secondary"
            >
                {expert_dashboard ? Translate.opportunity : Translate.customer_opportunity}
                {":  "}
                {expert_dashboard ? user.service.opportunity : user.service.customer_opportunity}
            </Button>
        </>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withSnackbar(connect(mstp)(OpportunityCounter));
