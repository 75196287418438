import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect} from "trim-redux";
import {updateRedux} from "../../../../config/Security";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import Alert from "@material-ui/lab/Alert/Alert";
import {Translate} from "../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button/Button";

class ToggleService extends React.Component {
    state = {
        service: this.props.service,
        disabled: this.props.disabled,
        error: this.props.error,
        toggle: false,
        loading:true,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            toggle: this.props.user.service[this.props.service]
        })
        this.loadData();
    }
    componentDidUpdate(prevProps){
        if(this.state.toggle !== this.props.user.service[this.props.service]){
            this.setState({
                toggle: this.props.user.service[this.props.service]
            })
        }
    }
    async loadData(){
        let {user} = this.props;
        let {service} = this.state;
        this.setState({
            toggle : user.service[service],
            loading:false
        })
    }
    async handleRequest(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/service/toggle/${this.state.service}`, config)
            .then(response => {
                var result = updateRedux();
                this.setState({
                    toggle: !this.state.toggle,
                });
                if(!!result){
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(error =>
            {
                console.log(error)
                this.props.enqueueSnackbar(this.state.error, {
                    variant: 'warning',
                    action:(
                        <Button
                            component="a"
                            href={`${defaultVariabls.React}/service/${this.state.service}`}
                        >
                            {Translate.do}
                            </Button>
                    )
                });
                this.setState({
                    loading:false
                })
            });
    }
    async handleChange(){
        let{error} = this.state;
        let{disabled} = this.props;

        if(disabled){
            this.props.enqueueSnackbar(error, {
                variant: 'warning',
            });
        }else{
            await this.setState({
                loading:true
            })
            await this.handleRequest();
        }
    }
    render(){
        let {loading,toggle,service} = this.state;
        let{widget} = this.props;
        return (
            <>
                {
                    widget ?
                        <>
                            {
                                loading ?
                            <CircularProgress size={30} style={{marginLeft:30}}/> :
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={toggle}
                                        onChange={this.handleChange.bind(this)}
                                        name="toggle"
                                    />
                                }
                                label={toggle ? Translate.active : Translate.deactive}
                            />
                            }
                        </> :
                        <Alert
                            className="mt-10"
                            severity={toggle ? "success" : "error"}
                            action={
                                loading ?
                                    <CircularProgress size={25} style={{marginLeft:30}}/> :
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={toggle}
                                                onChange={this.handleChange.bind(this)}
                                                name="toggle"
                                            />
                                        }
                                    />
                            }
                        >
                            <Typography
                                style={toggle ? {color: "#4caf50"} : {color: "#f44336"}}
                            >
                                {toggle ? Translate[`enable_${service}`] : Translate[`disable_${service}`]}
                            </Typography>
                        </Alert>
                }

                </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ToggleService))));
