import React from 'react';
import TextField from '@material-ui/core/TextField';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import {withSnackbar} from "notistack";
import {connect, setStore} from "trim-redux";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {Translate} from "../../../config/Translate";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
class University extends React.Component {
    
    classes = this.props.classes
    state={
        universities: [],
        university:null,
        defaults:null,
        error:null,
        loading:true,
    };
    async componentDidMount(){
        if(this.props.input){
            this.setState({
                university: this.props.input
            })
        }
        await this.setState({
            error: this.props.error,
        })
        await this.loadData('nothing');
    }

    async componentDidUpdate(oldProps){
        if(this.state.error !== this.props.error){
            this.setState({
                error:this.props.error,
            })
        }
    }
    async loadData(query = 'nothing'){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/universities/get/${query}`, config)
                .then(response => {
                    this.setState({
                        universities: response.data,
                        loading:false,
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleChange(university){
        await this.setState({
            university: university,
            error:''
        })
        await this.props.handleChange(university);
    }
    async handleChangeInput(input){
        await this.setState({
            loading:true
        });
        await this.loadData(input);
        await this.setState({
            loading:false
        });
    }
    render() {
        let {loading,universities,error,university} = this.state;
        return(
            <Autocomplete
                defaultValue={university}
                onChange={(event, value) => this.handleChange(value)}
                onInputChange={(event, newInputValue) => this.handleChangeInput(newInputValue)}
                options={universities.map((option) => option.title)}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={Translate.university}
                        variant="outlined"
                        fullWidth
                        required={this.props.required}
                        name="universityName"
                        error = {!!error}
                        helperText={error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        )
    }
}
const mstp = state => ({
    user: state.user,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(University))));