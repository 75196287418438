import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {
    Icon,
    List,
    ListItem,
    ListItemText,
} from "@material-ui/core";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect, setStore} from "trim-redux";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import Divider from "@material-ui/core/Divider/Divider";


class HowToGetPoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: null,
            loading: true,
        };
    }
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {dashboard} = this.props;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/points/quickViewTypes/${dashboard}`, config)
                .then(response => {
                    this.setState({
                        types:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }

    classes = this.props.classes;
    render(){
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <List>
                                    {this.state.types.map((item, index) => (
                                        <>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar style={{background:"#fefefe"}}>
                                                    <Icon style={item.negative ? {color:"#ff1400"} : {color:"#00d35b"}} fontSize="small">{item.icon}</Icon>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography style={item.negative ? {color:"#ff1400"} : {color:"#00d35b"}}>{item.negative ? item.count : "+"+item.count} {Translate.point}</Typography>}
                                                secondary={item.title}
                                            />
                                        </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                        ))}
                                        
                                </List>
                            </Grid>
                        </Grid>
                }
            </>

        );
    }
}
const mstp = state => ({
    dashboard: state.dashboard,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(HowToGetPoints))));