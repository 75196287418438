import Typography from "@material-ui/core/Typography/Typography";
import Link from "@material-ui/core/Link/Link";
import React from "react";
import {defaultVariabls} from "../../../config/index";
import {Translate} from "../../../config/Translate";
import moment from "jalali-moment";

export default function Copyright(props) {
    return (
        <Typography variant="body2" color={props.light ? "secondary" : "textSecondary" } align="center">
            {Translate.copyright}
            <Link color="inherit" href={defaultVariabls.React}>
                {Translate.appName}
            </Link>{' '}
            {moment().locale('fa').format('YYYY')}{'.'}
        </Typography>
    );
}