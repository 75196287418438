import React from 'react';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import Fab from "@material-ui/core/Fab/Fab";
class ScrollToTop extends React.Component{
    state = {
        scroll : 0
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        this.setState({
            scroll: scrolled,
        })
    }
    render(){
        let {scroll} = this.state;
        return (
            <React.Fragment>
                {
                    !!scroll ?
                        <Fab
                            size="medium"
                            color="primary"
                            style={{position:'fixed',bottom:50,left:20}}
                            onClick = {
                                () =>
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth"
                                    })
                            }
                        >
                            <ExpandLessRoundedIcon/>
                        </Fab>
                        : null
                }

            </React.Fragment>

        );
    }
}
export  default ScrollToTop