import {fade, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appToolbar: {
        paddingLeft: 20,
        paddingRight: 20,
        height: 56,
        [theme.breakpoints.up('sm')]: {
            height: 60,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
    headerContainer:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 1720,
        },
    },
    headerNav: {
        color: theme.palette.primary.contrastText,
        '& .navbarNav': {
            display: 'flex',
            padding: 0,
            marginLeft: -16,
            marginRight: -16,
            [theme.breakpoints.up('lg')]: {
                marginLeft: -20,
                marginRight: -20,
            },
        },
        '& .navItem': {
            width: 'auto',
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 20,
                paddingRight: 20,
            },
            '&.active': {
                color: '#000',
                '& .navLinkIcon': {
                    color: theme.palette.secondary.main,
                },
            },
        },
        '& .navLinkIcon': {
            marginRight: 10,
            color: theme.palette.primary.contrastText,
            fontSize: 20,
        },
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuIcon: {
        width: 40,
        height: 40,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: 5,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuItemRoot: {
        padding: 0,
    },
    pointer: {
        cursor: 'pointer',
    },
    middleRoot: {
        verticalAlign: 'middle',
        display: 'inline-block',
    },
    LayoutLogo:{
        height:36,
        marginRight:10
    },
}));
export default useStyles;
