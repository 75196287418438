import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {Translate} from "../../../../../config/Translate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddCommentDialog extends React.Component{
    state = {
        open: true,
        product_id:null,
        replay_to:null
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            product_id: this.props.replay_product,
            replay_to: this.props.replay_comment,
        })
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            await this.setState({
                product_id: this.props.replay_product,
                replay_to: this.props.replay_comment,
            })
        }
    }
    handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancleOperation();
    };
    render(){
        let {open} = this.state;
        return(
            <div>
                <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {Translate.manageComments}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding:20}}>
{/*
                        <AddComment replay_comment={this.state.replay_to} replay_product={this.state.product_id} dialog handleDone={this.handleClose.bind(this)}/>
*/}
                    </div>
                </Dialog>
            </div>
        )
    }
}
export default  withStyles(Styles)(AddCommentDialog)