import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import LinearCategory from "./components/LinearCategory";
import Breadcrumbs from "@material-ui/core/Breadcrumbs/Breadcrumbs";
import Typography from "@material-ui/core/Typography/Typography";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SubTitle from "../../elements/SubTitle";
import {Translate} from "../../../../config/Translate";
class LiearCategoryHolder extends React.Component {
    state = {
        categories: null,
        loading:true,
        catL1:null,
        catL2:null,
        catL3:null,
        catL1_name:null,
        catL2_name:null,
        catL3_name:null,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.props.editMode){
            this.setState({
                catL1_name:this.props.inPut[0],
                catL2_name:this.props.inPut[1],
                catL3_name:this.props.inPut[2],
            })
        }
    }

    async handleDone1stLevel(input,value){
            await this.setState(
                {
                    catL1:input,
                    catL1_name: value,
                    catL2:null,
                    catL2_name:null,
                    catL3:null,
                    catL3_name:null,
                }
            )
        let {catL1} = this.state;
        let categories = [catL1]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);

    }
    async handleDone2ndLevel(input,value){
        await this.setState({catL2:input,catL2_name:value,catL3:null,catL3_name:null})
        let {catL1,catL2} = this.state;
        let categories = [catL1,catL2]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);
    }
    async handleDone(input,value){
         await this.setState(
             {
                 catL3:input,
                 catL3_name:value
             })
        let {catL1,catL2,catL3} = this.state;
        let categories = [catL1,catL2,catL3]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);
    }
    render(){
        let {catL1,catL2,catL3,catL1_name,catL2_name,catL3_name} = this.state;
        let {editMode} = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubTitle style={{marginBottom:0}} color="secondary" align="left">{Translate.chooseCategory}</SubTitle>
                </Grid>
                {
                    !editMode ?
                        <>
                            <Grid item xs={12} md={12}>
                                <LinearCategory level={1} input={catL1} handleChange={this.handleDone1stLevel.bind(this)} product/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LinearCategory level={2} input={catL2} prevLevel={catL1} handleChange={this.handleDone2ndLevel.bind(this)}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LinearCategory level={3} input={catL3} prevLevel={catL2} handleChange={this.handleDone.bind(this)}/>
                            </Grid>
                        </> : null
                }

                <Grid item xs={12}>
                    <Breadcrumbs separator={<ArrowBackRoundedIcon style={{marginRight:5,marginLeft:5}} color="secondary" fontSize="small" />} aria-label="breadcrumb">
                        {
                            catL1_name ?
                                <Typography>
                                    {catL1_name}
                                </Typography>
                            : null
                        }
                        {
                            catL2_name ?
                                <Typography>
                                    {catL2_name}
                                </Typography>
                                : null
                        }
                        {
                            catL3_name ?
                                <Typography>
                                    {catL3_name}
                                </Typography>
                                : null
                        }
                    </Breadcrumbs>
                </Grid>
            </Grid>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(LiearCategoryHolder)));