import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import SingleConsultation from "./SingleConsultationPlan";
import AddConsultationDialog from "./AddConsultationPlanDialog";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../../config/Translate";
import AppCard from "../../../../components/layouts/components/AppCard";
import Divider from "@material-ui/core/Divider/Divider";
import MakeConsultationDetails from "./MakeConsultationDetails";
import ToggleService from "../ToggleService";
import {updateRedux} from "../../../../../config/Security";
import Title from "../../../../components/elements/Title";
import Hidden from "@material-ui/core/Hidden/Hidden";

class Consultations extends React.Component {
    classes = this.props.classes
    state={
        consultations:[],
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/consultation_plans/get/${this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    consultations:response.data,
                    loading:false
                })
                updateRedux();

            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        const {consultations,loading} = this.state;
        return (
            <AppCard
                title={Translate.consultationsDetails}
                action={
                    <ToggleService service="consultation" disabled={consultations.length === 0} error={Translate.createAConsultationToTurnOn}/>
                }>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justify="center" alignItems="center" style={{minHeight:300,paddingBottom:100}}>
                            <CssBaseline/>
                            <Grid item xs={12} md={11}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={9}>
                                        <Hidden smUp>
                                            <img width={80} src="../assets/images/Consultation.png" alt="Consultation" loading="lazy"/>
                                        </Hidden>
                                        <Title align="left" style={{marginTop:10,fontSize:"20px !important"}}>{Translate.areYouReadyToGetConsultation}</Title>
                                        <Typography>{Translate.consultationsDetailsHelp}</Typography>
                                        <Typography paragraph>{Translate.consultationsDetailsHelp2}</Typography>
                                        <MakeConsultationDetails/>
                                    </Grid>
                                    <Hidden smDown>
                                        <Grid item xs={12} md={3}>
                                            <img className="max-width" src="../assets/images/Consultation.png" alt="consultation" loading="lazy"/>
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                        <AddConsultationDialog length={consultations.length} disabled={consultations.length > 5} reload={this.loadData.bind(this)}/>
                                    {
                                        consultations.map((project,index) => (
                                            <Grid item xs={12} md={6} lg={4} key={index}>
                                                <SingleConsultation data={project} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                        ))
                                    }
                                    <Grid item xs={12}>
                                        <ToggleService service="consultation" disabled={consultations.length === 0} error={Translate.createAConsultationToTurnOn}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Consultations))));