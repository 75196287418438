import React from "react";
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withRouter} from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import {Styles} from "../../../../config/design/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {connect} from "trim-redux";
import CountUp from "react-countup";
import {Link} from "react-router-dom";
import clsx from "clsx";
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import {withSnackbar} from "notistack";

class MyPoints extends React.Component {
    classes = this.props.classes;
    state = {
        old_point: this.props.user.point,
        increasing: false,
        decreasing: false,
    }
    async componentDidUpdate(prevProps){
        if(!!this.props.user){
            if((this.state.old_point < this.props.user.point) && !this.state.increasing){
                 let text = Translate.congratulation +" "+(parseInt(this.props.user.point)-parseInt(this.state.old_point))+" "+Translate.getPoints;
                await this.props.enqueueSnackbar(text, {
                    variant: 'success',
                });
                this.setState({
                    increasing:true
                })
                setTimeout(() => {
                    this.setState({
                        increasing:false,
                        old_point: this.props.user.point
                    })
                }, 2000);

            }
            if((this.state.old_point > this.props.user.point) && !this.state.decreasing){
                this.setState({
                    decreasing:true
                })
                setTimeout(() => {
                    this.setState({
                        decreasing:false,
                        old_point: this.props.user.point
                    })
                }, 2000);
            }
        }

    }
    render(){
        let {point} = !!this.props.user ? this.props.user : 0 ;
        let {old_point,increasing,decreasing} = this.state;
        return (
            <>
                <Tooltip title={Translate.myPoints} arrow>
                    <Button
                        component={Link}
                        to="/points/view"
                        className={
                            clsx(
                                this.classes.walletBtn,
                                increasing ? "active-point"  : null,
                                decreasing ? "decreasing-point"  : null,
                            )}
                        startIcon={<EmojiEventsOutlinedIcon/>}
                    >
                        {
                            this.props.width !== "xs" ?
                                <CountUp
                                    start={old_point}
                                    end={point}
                                    suffix={" " + Translate.point}
                                />
                                : null
                        }

                    </Button>
                </Tooltip>
            </>
        );
    }

}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withRouter(withStyles(Styles)(connect(mstp)(withSnackbar(MyPoints)))));