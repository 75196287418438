import React from 'react';
import {connect} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import {Link} from 'react-router-dom';
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LaptopRoundedIcon from '@material-ui/icons/LaptopRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded';
import {withSnackbar} from "notistack";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const ExpertInfo = (props) => {
    const {user,dashboard} = props;
    const no_link = ( dashboard === "expert" && user.role.name === "user" ) || ( dashboard === "user" && user.role.name === "expert" );

    return (
        <>
            {
              !!user ?
                  <Grid container  alignItems="center" justify="center" className={no_link ? " no-link mt-10 expert_info" : "mt-10 expert_info"}>
                      <Grid item xs={12}>
                          <Grid container justify="center" alignItems="center">
                              <Grid item xs >
                                  <Tooltip
                                      arrow
                                      title={Translate.projectsDetails}
                                  >
                                      <IconButton
                                          component={no_link ? 'span' : Link}
                                          to="/service/project"
                                      >
                                          <LaptopRoundedIcon
                                              fontSize="small"
                                              color={!!user.service.project ? "primary" : ""}
                                          />
                                      </IconButton>
                                  </Tooltip>
                              </Grid>
                              <Grid item xs>
                                  <Tooltip
                                      arrow
                                      title={Translate.teachingsDetails}
                                  >
                                      <IconButton
                                          component={no_link ? 'span' : Link}
                                          to="/service/teaching"
                                      >
                                          <LocalLibraryRoundedIcon
                                              fontSize="small"
                                              color={!!user.service.teaching? "primary" : ""}

                                          />
                                      </IconButton>
                                  </Tooltip>
                              </Grid>
                              <Grid item xs>
                                  <Tooltip
                                      arrow
                                      title={Translate.jobsDetails}
                                  >
                                      <IconButton
                                          component={no_link ? 'span' : Link}
                                          to="/service/job"
                                      >
                                          <WorkRoundedIcon
                                              fontSize="small"
                                              color={!!user.service.job ? "primary" : "inherit"}

                                          />
                                      </IconButton>
                                  </Tooltip>
                              </Grid>
                              <Grid item xs>
                                  <Tooltip
                                      arrow
                                      title={Translate.teamsDetails}
                                  >
                                      <IconButton
                                          component={no_link ? 'span' : Link}
                                          to="/service/team"
                                      >
                                          <PeopleRoundedIcon
                                              fontSize="small"
                                              color={!!user.service.team ? "primary" : "inherit"}

                                          />
                                      </IconButton>
                                  </Tooltip>
                              </Grid>
                              <Grid item xs>
                                  <Tooltip
                                      arrow
                                      title={Translate.consultationsDetails}
                                  >
                                      <IconButton
                                          component={no_link ? 'span' : Link}
                                          to="/service/consultation"

                                      >
                                          <HeadsetMicRoundedIcon
                                              fontSize="small"
                                              color={!!user.service.consultation ? "primary" : "inherit"}

                                          />
                                      </IconButton>
                                  </Tooltip>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
                  : null
            }
        </>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withSnackbar(connect(mstp)(ExpertInfo));
