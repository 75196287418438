import React from 'react';
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import {withSnackbar} from "notistack";
import AppCard from "../../../../../components/layouts/components/AppCard";
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import Avatar from "@material-ui/core/Avatar/Avatar";
import {Translate} from "../../../../../../config/Translate";
import CurrencyFormat from "react-currency-format";
import {currencySymbol} from "../../../../../../config/values";
import PhoneInTalkRoundedIcon from '@material-ui/icons/PhoneInTalkRounded';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import CountUp from "react-countup";
import {Link} from 'react-router-dom';

const UserInformations = (props) => {
    const {user} = props;
    let wallet_amount = !!props.user ? props.user.wallet.amount : 0;
    const dashboard = props.dashboard;
    let {customer_point,point} = !!props.user ? props.user : 0 ;

    const expert_dashboard = dashboard === "expert";
    return (
        <>
            {
                !!user ?
                    <Grid container  alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs>
                                    <AppCard
                                        component={Link}
                                        to="/shop"
                                        height={1}
                                        className='card-hover'>
                                        <Box display='flex' alignItems='center'>
                                            <Box mr={3} clone>
                                                <Avatar className="user_info_avatar opp"><PhoneInTalkRoundedIcon/></Avatar>
                                            </Box>
                                            <Box flex={1}>
                                                <Typography style={{whiteSpace:"nowrap"}} component='h3' variant='inherit' color='inherit'>
                                                    {expert_dashboard ? Translate.opportunity : Translate.customer_opportunity}
                                                </Typography>
                                                <Box mt={0.5} component='p'>
                                                    {expert_dashboard ? user.service.opportunity : user.service.customer_opportunity} {Translate.opp}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AppCard>
                                </Grid>
                                <Grid item xs>
                                    <AppCard
                                        height={1}
                                        component={Link}
                                        to="/wallet/view"
                                        className='card-hover'>
                                        <Box display='flex' alignItems='center'>
                                            <Box mr={3} clone>
                                                <Avatar className="user_info_avatar wallet"><AccountBalanceWalletRoundedIcon/></Avatar>
                                            </Box>
                                            <Box flex={1} >
                                                <Typography mt={0.5} style={{whiteSpace:"nowrap"}} component='h3' variant='inherit' color='inherit'>
                                                    {Translate.myWallet}
                                                </Typography>
                                                <Box mt={0.5} component='p'>
                                                    <CurrencyFormat value={wallet_amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AppCard>
                                </Grid>
                                <Grid item xs>
                                    <AppCard
                                        height={1}
                                        component={Link}
                                        to="/points/view"
                                        className='card-hover'>
                                        <Box display='flex' alignItems='center'>
                                            <Box mr={3} clone>
                                                <Avatar className="user_info_avatar point"><EmojiEventsOutlinedIcon/></Avatar>
                                            </Box>
                                            <Box flex={1}>
                                                <Typography style={{whiteSpace:"nowrap"}} component='h3' variant='inherit' color='inherit'>
                                                    {Translate.myPoints}
                                                </Typography>
                                                <Box mt={0.5} component='p'>
                                                    <CountUp
                                                        end={expert_dashboard ? point : customer_point}
                                                        suffix={" " + Translate.point}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AppCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
            }
        </>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withSnackbar(connect(mstp)(UserInformations));
