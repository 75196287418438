
export const howToSale = ["روش فروش ۵","روش فروش ۴","روش فروش ۳","روش فروش ۲","روش فروش ۱"];

export function toEnglishDigits(str) {

    let  e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function(t) {
        return t.charCodeAt(0) - e;
    });
    return str;
}

export function price(x) {
    if(!!x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }else {
        return x
    }
}
export const grades = ["","کاردانی","کارشناسی","کارشناسی ارشد","دکتری","فوق دکتری"]
export const educationGrades = [
    {
        value: 1,
        name : "کاردانی"
    },
    {
        value: 2,
        name : "کارشناسی"
    },
    {
        value: 3,
        name : "کارشناسی ارشد"
    },
    {
        value: 4,
        name : "دکتری"
    },
    {
        value: 5,
        name : "فوق دکتری"
    },
];
export const paymentTypes = [
    {
        value: "hourly",
        name : "ساعتی"
    },
    {
        value: "monthly",
        name : "ماهانه"
    },
    {
        value: "static",
        name : "ثابت"
    },
    {
        value: "free",
        name : "رایگان"
    },
];
export const paymentTypesToShow = {
        hourly : "ساعتی",
        monthly : "ماهانه",
        static : "ثابت",
        free : "رایگان",
    };

export const jobPaymentTypes = [
    {
        value: "fullTime",
        name : "تمام‌وقت"
    },
    {
        value: "teleworking",
        name : "دورکاری"
    },
    {
        value: "halfTime",
        name : "پاره‌وقت"
    },
    {
        value: "hourly",
        name : "ساعتی"
    },
];
export const jobPaymentTypesToShow = {
    fullTime : "تمام‌وقت",
    teleworking : "دورکاری",
    halfTime : "پاره‌وقت",
    hourly : "ساعتی",
};
export const consultationPaymentTypes = [
    {
        value: "oneHour",
        name : "یک ساعت"
    },
    {
        value: "halfHour",
        name : "نیم ساعت"
    },
    {
        value: "quarter",
        name : "پانزده دقیقه"
    }
];
export const consultationPaymentTypesToShow = {
    oneHour : "یک ساعت",
    halfHour : "نیم ساعت",
    quarter : "پانزده دقیقه",
};
export const currencySymbol = 'تومان';

export const educations_limit = 6;
export const experiences_limit = 6;
export const portfolios_limit = 6;
export const skills_limit = 10;
export const specialty_limit = 2;
export const projects_limit = 3;
export const teachings_limit = 3;
export const jobs_limit = 3;
export const consultation_limit = 3;
export const start_years = [1400,1399,1398,1397,1396,1395,1394,1393,1392,1391,1390,1389,1388,1387,1386,1385,1384,1383,1382,1381,1380,1379,1378,1377,1376,1375,1374,1373,1372,1371,1370,1369,1368,1367,1366,1365,1364,1363,1362,1361,1360,1359,1358,1357,1356,1355,1354,1353,1352,1351,1350];