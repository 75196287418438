import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        navItem: {
            height: 40,
            marginTop: 2,
            marginBottom: 2,
            cursor: 'pointer',
            textDecoration: 'none !important',
            paddingLeft: 24 ,
            '&.nav-item-header': {
                textTransform: 'uppercase',
            },
            '&.active': {
                backgroundColor: "#fcfdff",
                pointerEvents: 'none',
                transition: '.15s cubic-bezier(0.4,0.0,0.2,1)',
                borderLeft:"5px solid #328be0",
                '& .nav-item-text': {
                    color: theme.palette.primary.main,
                },
                '& .nav-item-icon': {
                    color: theme.palette.primary.main,
                },
            },

            '&:hover, &:focus': {
                '& .nav-item-text': {
                    color: theme.palette.primary.main
                },

                '& .nav-item-icon': {
                    color:  theme.palette.primary.main
                },

                '& .nav-item-icon-arrow': {
                    color: theme.palette.primary.main
                },
            },
            '& .nav-item-icon': {
                color: theme.palette.sidebar.textColor,
            },
            '& .nav-item-text': {
                color: theme.palette.sidebar.textColor,
            },
        },
        listIcon: {
            fontSize: 18,
            [theme.breakpoints.up('xl')]: {
                // fontSize: 20,
            },
        },
        listItemText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
});
export default useStyles;

