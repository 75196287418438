import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import {Translate} from "../../../config/Translate";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {Icon} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import HowToGetPointsButton from "./points_manual/HowToGetPointsButton";

class Points extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            { title: Translate.point_title,
                field: 'icon',
                filterPlaceholder: Translate.search,
                render:  rowData =>
                    <Typography>
                        <IconButton>
                        <Icon style={rowData.negative ? {color:"#ff1400"} : {color:"#00d35b"}} fontSize="small" color="inherit">{rowData.icon}</Icon>
                        </IconButton>
                         {rowData.title}
                    </Typography>
            },

            { title: Translate.point_count,
                editable: 'never',
                field: 'count',
                render:  rowData =>
                    <Typography style={rowData.negative ? {color:"#ff1400"} : {color:"#00d35b"}}>
                        {rowData.count}
                    </Typography>,
                filterPlaceholder: Translate.search,
            },
            { title: Translate.point_time,
                editable: 'never',
                filtering: false, field: 'lastUpdate',
                hidden: this.props.width === "xs",
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {dashboard} = this.props;
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={<HowToGetPointsButton/>}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/points/myPoints/${dashboard === "user" ? "user" : 'expert' }?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        id: row.id,
                                                        count: row.type.negative ? row.count : "+"+row.count,
                                                        name: row.type.name,
                                                        title: row.type.title,
                                                        negative: row.type.negative,
                                                        icon: row.type.icon,
                                                        description: row.type.description,
                                                        lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                    search:false,
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    dashboard: state.dashboard,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Points)))));