import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

function AppCardLoaderFields() {
    return (
        <Grid container spacing={2} justify="center" alignItems="center" style={{height:400,textAlign:"center"}}>
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}

export default withStyles(Styles)(AppCardLoaderFields);