import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import {Translate} from "../../../../config/Translate";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded';
import Typography from "@material-ui/core/Typography/Typography";
class TableGenerator extends React.Component {
    state = {
        data: this.props.input ? this.props.input : [],
        tr: 0,
        td: 0,
        heading:this.props.edit ? this.props.heading : 1,
        build: this.props.edit
    };
    classes = this.props.classes;
    componentDidMount(){
        let {data} = this.state;
        if(this.props.edit){
            let i,j =0 ;
            for(i = 0;i < data.length;i++){
                for(j=0;j<data[i].length;j++){
                }
            }
            this.setState({
                tr:i,
                td:j
            })
        }
    }
    async build (){
        let {tr,td,data,heading} = this.state;
        if (tr < 2){
            tr = 2
        }else if(tr > 100){
            tr = 100
        }
        if (td < 2){
            td = 2
        }else if(td > 100){
            td = 100
        }
        for(let i = 0 ; i< tr; i++){
            let temp =[];
            for(let j = 0 ; j<td ; j++){
                temp.push("");
            }
            data.push(temp)
        }
        this.setState({
            data,
            build:true,
            tr,
            td
        })
        await this.props.build()
        await this.props.setData(data,heading);
    }
    async handleChange(e,i,j){
        let {data,heading} = this.state;
        data[i][j] = e.target.value;
        await this.setState({data})
        await this.props.setData(data,heading);
    }
    async addRow(){
        let {tr,td,data,heading} = this.state;
        tr++;
        let tempData= [];
        for(let i = 0 ; i< tr; i++){
            let temp =[];
            for(let j = 0 ; j<td ; j++){
                temp.push(data[i] !== undefined ? data[i][j] : "");
            }
            tempData.push(temp)
        }
        this.setState({
            data:tempData,
            tr
        })
        await this.props.setData(data,heading);
    }
    async addColumn(){
        let {tr,td,data,heading} = this.state;
        td++;
        let tempData= [];
        for(let i = 0 ; i< tr; i++){
            let temp =[];
            for(let j = 0 ; j<td ; j++){
                temp.push(data[i] !== undefined ? data[i][j] : "");
            }
            tempData.push(temp)
        }
        this.setState({
            data:tempData,
            td
        })
        await this.props.setData(data,heading);
    }
    handleChangeTr(e){
        let tr = e.target.value;
        this.setState({
            tr
        })
    }
    handleChangeTd(e){
        let td = e.target.value;
        this.setState({
            td
        })
    }
    async toggleHeading(){
        let {data} = this.state;
        await this.setState({
            heading: !this.state.heading
        })
        await this.props.setData(data,this.state.heading);
    }
    render(){
        let {data,build,tr,td,heading} = this.state;
        return (
            <React.Fragment>
                {
                    build ?
                        <React.Fragment>
                            <FormControlLabel
                                style={{marginLeft:10,marginBottom:10}}
                                control={<Switch checked={heading} onChange={this.toggleHeading.bind(this)} />}
                                label={heading? Translate.withHeading : Translate.withoutHeading}
                            />
                            <Button
                                style={{marginLeft:10,marginBottom:10}}
                                color="primary"
                                variant="outlined"
                                onClick={this.addRow.bind(this)}
                                startIcon={<SwapVertRoundedIcon/>}
                            >
                                {Translate.addRows}
                            </Button>
                            <Button
                                style={{marginLeft:10,marginBottom:10}}
                                onClick={this.addColumn.bind(this)}
                                color="primary"
                                variant="outlined"
                                startIcon={<SwapHorizRoundedIcon/>}
                            >
                                {Translate.addColumns}
                            </Button>
                            <table className="table-generator">
                                {
                                    data.map((row,index) =>(
                                        <tr>
                                            {
                                                row.map((item,index2) =>(
                                                    (
                                                        heading && index === 0 ?

                                                        <th key={index+index2}>
                                                            <TextField
                                                                fullWidth
                                                                type="text"
                                                                placeholder={Translate.tableTdPlaceholder}
                                                                size="large"
                                                                value={data[index][index2]}
                                                                onChange={(e) => this.handleChange(e,index,index2)}
                                                            />
                                                        </th> :
                                                        <td key={index+index2}>
                                                            <TextField
                                                                fullWidth
                                                                type="text"
                                                                size="large"
                                                                placeholder={Translate.tableTdPlaceholder}
                                                                value={data[index][index2]}
                                                                onChange={(e) => this.handleChange(e,index,index2)}
                                                            />
                                                        </td>
                                                    )

                                                ))
                                            }

                                        </tr>
                                    ))
                                }
                            </table>
                        </React.Fragment> :
                        <Grid container spacing={2} direction="row" alignItems="center" justify="center">
                            <Grid item xs={12}>
                                <Typography align="center" color="textSecondary" variant="body2">{Translate.chooseTableStructure}</Typography>
                            </Grid>
                            <Grid item md={5} lg={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="large"
                                    variant="outlined"
                                    label={Translate.rows}
                                    name="tr"
                                    value={tr}
                                    onChange={this.handleChangeTr.bind(this)}
                                />
                            </Grid>
                            <Grid item md={5} lg={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="large"
                                    variant="outlined"
                                    label={Translate.columns}
                                    name="td"
                                    value={td}
                                    onChange={this.handleChangeTd.bind(this)}
                                />
                            </Grid>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                <Button
                                    onClick={this.build.bind(this)}
                                    color="primary"
                                    variant="contained"
                                    startIcon={<AddCircleOutlineIcon/>}
                                >
                                    {Translate.buildTable}
                                </Button>
                            </Grid>
                        </Grid>
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(TableGenerator)));