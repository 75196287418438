import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {Translate} from "../../../../config/Translate";
import EditTable from "./EditTable";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class EditTableDialog extends React.Component{
    state = {
        open: true
    };
    classes = this.props.classes;

    handleSetTable(data){
        this.props.reload();
        this.props.cancleOperation();
    }
    handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancleOperation();
    };
    render(){
        let {open} = this.state;
        return(
            <React.Fragment>
                {
                    this.props.table !== null ?
                        <div>
                            <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                                <AppBar className={this.classes.appBarDialog}>
                                    <Toolbar>
                                        <Typography variant="h6" className={this.classes.titleDialog}>
                                            {Translate.EditTable}
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <div style={{padding:20}}>
                                    <EditTable table={this.props.table} setTable={this.handleSetTable.bind(this)}/>
                                </div>
                            </Dialog>
                        </div>
                        : null
                }
            </React.Fragment>
        )
    }
}
export default  withStyles(Styles)(EditTableDialog)