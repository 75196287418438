import React from 'react';
import {Box, withStyles} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AppCard from "../../../components/layouts/components/AppCard";
import {Translate} from "../../../../config/Translate";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import CurrencyFormat from "react-currency-format";
import {currencySymbol} from "../../../../config/values";
import RemoveFromCartButton from "./RemoveFromCartButton";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import LoadingButton from "../../../components/elements/LoadingButton";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon from '@material-ui/icons/Payment';
import {defaultVariabls} from "../../../../config";
import axios from "axios";
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Badge from "@material-ui/core/Badge/Badge";

class OrderSummary extends React.Component {
    state = {
        cart: this.props.cart,
        loading: false,
        howToPay: "online",
        can_wallet:true,
        SubmitLoading:false
    }
    componentDidMount(){
        let wallet = this.props.user.wallet.amount;
        if(wallet >= this.getTotalPrice()){
            this.setState({
                howToPay: "wallet",
                can_wallet:true
            })
        }else{
            this.setState({
                howToPay: "online",
                can_wallet:false
            })
        }
        this.setState({
            cart: this.props.cart,
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.cart !== this.props.cart){
            this.setState({
                cart: this.props.cart,
            })
            let wallet = this.props.user.wallet.amount;
            if(wallet >= this.getTotalPrice()){
                this.setState({
                    howToPay: "wallet",
                    can_wallet:true
                })
            }else{
                this.setState({
                    howToPay: "online",
                    can_wallet:false
                })
            }
        }
    }
    getTotalPrice(){
        let total_price = 0;
        let {cart} = this.props;
        // eslint-disable-next-line
        cart.map((item)=> {
            total_price += item.total_price
        });

        return total_price;
    }
    reload(){
        this.setState({
            loading:true
        })
        setTimeout(() => {
            this.setState({
                loading:false
            })
        }, 500);
    }
    handleChange = (event) => {
        this.setState({
            howToPay: event.target.value
        });
    };
    handleSubmit(){
        let {howToPay} = this.state;
        this.setState({
            SubmitLoading:true
        });
        let {cart} = this.props;
        let product_ids = [];
        // eslint-disable-next-line
        cart.map((item)=>{
            product_ids.push(item.id);
        });
        let data = new FormData();
        data.append('products', JSON.stringify(product_ids));
        data.append('howToPay', howToPay);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/order/make`, data, config)
            .then(response => {
                this.setState({
                    SubmitLoading: false,
                    order_id: response.data.id,
                    order_modal:true
                });
                let {howToPay} = this.state;
                if(howToPay === "wallet"){
                    this.props.history.push(`/order/pay/${response.data.id}`);
                }else{
                    window.open(`${defaultVariabls.website}/wallet/pay_order/${response.data.id}${"?r=/my-orders"}`,'_self')

                }

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading: false,
                });
            });

    }
    render(){
        let {cart} = this.props;
        let {loading,howToPay,SubmitLoading} = this.state;
        return (
            <>
                <AppCard
                    className={cart.length > 0 ? null : "checkout-card-no-border"}
                    title={
                        <Box fontSize={16} fontWeight={700}>
                            <Badge badgeContent={this.props.cart.length} color="primary" anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}><IconButton><ShoppingBasketRoundedIcon/></IconButton>
                            </Badge>
                                {Translate.cart}
                        </Box>
                    }>
                    {
                        loading ?
                            <AppCardLoaderFields/> :
                            <>
                                {
                                    cart.length > 0 ?
                                        <>
                                            {
                                                cart.map((item,key)=>(
                                                    <Box
                                                        index={key}
                                                        display='flex'
                                                        justifyContent='space-between'
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        className="cart_items_holder"
                                                        mt={2}
                                                        mb={4}>
                                                        <Box color='text.secondary'><RemoveFromCartButton id={item.id} reload={this.reload.bind(this)}/> {item.name}</Box>
                                                        <Box style={{position: "relative",top: 4}}>
                                                            <CurrencyFormat value={item.total_price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                            <Divider />

                                            <Box
                                                display='flex'
                                                justifyContent='space-between'
                                                fontSize={14}
                                                fontWeight={400}
                                                my={2}>
                                                <Box color='text.secondary'>{Translate.final_price}:</Box>
                                                <Box>
                                                    <CurrencyFormat value={this.getTotalPrice()} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <Grid container  className="mt-15">
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel  component="legend">{Translate.howToPay}</FormLabel>
                                                        <RadioGroup  aria-label="paymentType" name="paymentType" value={howToPay} onChange={this.handleChange.bind(this)}>
                                                            <FormControlLabel value="wallet" control={<Radio />} label={Translate.fromWallet} />
                                                            <FormControlLabel value="online" control={<Radio />} label={Translate.onlinePayment} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Typography align="center">
                                                <img width={120} src="../assets/images/empty.png" alt="empty" loading="lazy"/>
                                                <br/>{Translate.emtyCart}
                                                </Typography>
                                        </>
                                }
                            </>
                    }

                </AppCard>
                {
                    cart.length > 0 ?
                        <LoadingButton
                            style={{width:"100%",padding:10,borderTopLeftRadius:0,borderTopRightRadius:0}}
                            styleForParent={{marginTop:0}}
                            size="small"
                            color={"primary"}
                            variant={'contained'}
                            startIcon={howToPay === "wallet" ? <AccountBalanceWalletIcon/> : <PaymentIcon/>}
                            loading = {SubmitLoading}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            {howToPay === "wallet" ? Translate.fromWallet : Translate.onlinePayment}
                        </LoadingButton> : null

                }

            </>
        );
    }

};

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    cart: state.cart
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(OrderSummary))));
