import React from 'react';
import Helmet from "react-helmet/es/Helmet";
import {Translate} from "../../../config/Translate";

export default function HeaderTags(props) {
    return (
        <Helmet>
            <title>{Translate.appName} | {props.title}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
    );
}
