
export const defaultVariabls = {
    url: "https://irankhebre.com/api",
    uploads: "https://irankhebre.com/uploads",
    React:"https://panel.irankhebre.com",
    website: "https://irankhebre.com",
    root: "irankhebre.com"
};

/*export const defaultVariabls = {
    url: "http://localhost:8000/api",
    uploads: "http://localhost:8000/uploads",
    React:"http://localhost:3000",
    website: "http://127.0.0.1:8000",
    root: "localhost",
};*/


