import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import {setStore, getStore, connect} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {secret} from "../../../config/Security";
import Box from "@material-ui/core/Box/Box";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "../../components/elements/LoadingButton";
import HeaderTags from "../../components/elements/HeaderTags";
import AuthLayout from "../../components/layouts/AuthLayout";
import PassField from "../../components/fields/PassField";
import queryString from "query-string";

class Login extends React.Component {
    constructor(props){
        super(props);
        if(this.props.isAuth){
            this.props.history.push("/");
        }
        const values = queryString.parse(this.props.location.search);
        let redirect = values.r;
        this.state={
            fields: {
                username:'',
                password:''
            },
            errors:{},
            SubmitLoading:false,
            success:false,
            anchorEl:null,
            redirect: !!redirect ? redirect : null

        };
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //Username
        if(validator.isEmpty(fields.username)){
            formIsValid = false;
            errors['username'] = Translate.emptyEmailOrMobile;
        }
        //Password
        if(validator.isEmpty(fields.password)){
            formIsValid = false;
            errors['password'] = Translate.emptyPassword;
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    async handleRequest(){

        const {username, password} = this.state.fields;

        let data = new FormData();
        data.append('secret', secret);
        data.append('username', username);
        data.append('password', password);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/login`, data, config)
            .then(response =>{
                localStorage.setItem('ACT',response.data.access_token);
                localStorage.setItem('RET',response.data.refresh_token);
            })
            .catch(error =>{
                console.log(error.response)
                this.setState({
                    SubmitLoading:false

                })
                this.props.enqueueSnackbar(Translate.loginError, {
                    variant: 'error',
                });
            });
        const loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        dashboard : response.data.role.name === "user" ? "user" : "expert",
                    });
                    this.setState({
                        SubmitLoading:false,
                        success:true
                    })
                }).catch(error => {
                    console.log(error.response)
                    setStore({
                        isAuth : false,
                        user : null,
                    });
                    this.setState({
                        SubmitLoading:false

                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                SubmitLoading:false
            })
        }
        if (getStore().isAuth){
            let code = getStore().user.login_token;
            if(this.state.redirect){
                window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${code}?r=${this.state.redirect}`);

            }else {
                window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${code}`);

            }
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render() {
        const {classes} = this.props;
        const {errors,SubmitLoading,success} = this.state;
        return (
                <AuthLayout>
                    <HeaderTags title={Translate.login}/>

                    <Grid item md={10} lg={8}>
                        <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                            <strong> {Translate.login}</strong>
                        </Box>

                        <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                            <Grid container spacing={1} justify="center" alignItems='center'>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        variant="outlined"
                                        id="username"
                                        label={Translate.emailOrMobile}
                                        placeholder={Translate.emailOrMobile}
                                        name="username"
                                        autoComplete="username"
                                        onChange={this.handleChange.bind(this)}
                                        autoFocus
                                        error = {!!errors['username']}
                                        helperText={errors['username']}
                                    />
                                </Grid>
                                <Grid item xs={12}>

                                    <PassField
                                        margin="normal"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        name="password"
                                        label={Translate.password}
                                        placeholder={Translate.password}
                                        type="password"
                                        id="password"
                                        onChange={this.handleChange.bind(this)}
                                        autoComplete="current-password"
                                        error = {!!errors['password']}
                                        helperText={errors['password']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Link to="/forget" variant="body2">

                                                <Typography align="left" className="withCursor" variant="body2" color="primary">
                                                    <strong>
                                                        {Translate.forgetPass}
                                                    </strong>
                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Link to="/token-login" variant="body2">
                                                <strong>
                                                    <Typography align="right" className="withCursor" variant="body2" color="primary">
                                                        <strong>
                                                            {Translate.loginWithToken}
                                                        </strong>
                                                    </Typography>
                                                </strong>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        style={{width:"100%"}}
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                        success = {success}
                                    >
                                        {Translate.login}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>

                        {/*<Box
                            mb={3}
                            display='flex'
                            flexDirection={{xs: 'column', sm: 'row'}}
                            justifyContent={{sm: 'center'}}
                            alignItems={{sm: 'center'}}>
                            <Box component='span' fontSize={14} mr={1}>
                                {Translate.orLoginWith}
                            </Box>
                            <Box display='inline-block'>
                                <IconButton>
                                    <img
                                        src={Google}
                                        alt='Login with Google'
                                        className={classes.socialLogin} />
                                </IconButton>
                            </Box>
                        </Box>*/}

                        <Box  fontSize={14}>
                            <Box component='span' mr={2} fontWeight={600}
                            >
                                {Translate.dontHaveAccount}
                            </Box>
                            <Box
                                component='span'
                                color='primary.main'
                                fontWeight={600}
                                className={classes.pointer}>
                                <Link to="/register">
                                    {Translate.register}
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </AuthLayout>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Login))));