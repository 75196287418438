import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import {Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import SearchBar from "material-ui-search-bar";
import grey from "@material-ui/core/colors/grey";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import SingleSearchResultSkill from "./SingleSearchResultSkill";
import AddYourSkillIfYouCantFind from "../../../../components/elements/AddYourSkillIfYouCantFind";
import Typography from "@material-ui/core/Typography/Typography";
class SearchSkills extends React.Component {
    classes = this.props.classes
    state={
        search: '',
        typing: false,
        loading: true,
        typingTimeout: 0,
        skills:[],
    };
    componentDidMount(){
        this.loadData();
    }
    changeName = (value) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: value,
            typing: false,
            loading: true,
            typingTimeout: setTimeout(function () {
                self.loadData();
            }, 200)
        });
    }
    reload(){
        this.loadData();
        this.props.reload()
    }
    async loadData(){
        let query = this.state.search;
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/skills/search/${!!query ? query : 'nothing'}`, config)
            .then(response => {
                this.setState({
                    skills:response.data,
                    loading:false
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading:false
                })
            });
    }

    render() {
        let {skills,loading,search} = this.state;
        return (
            <Grid container spacing={2} alignItems="center" style={{marginTop:10}}>
                <Grid item xs={12}>
                    <SearchBar
                        placeholder={Translate.searchForSkills}
                        value={this.state.search}
                        onChange={(newValue) => this.changeName(newValue )}
                        onRequestSearch={()=> this.loadData()}
                        closeIcon={loading ? <CircularProgress size={25} color="inherit" /> : <ClearRoundedIcon style={{ color: grey[500] }} />}
                        searchIcon={loading ? <CircularProgress size={25} color="inherit" /> : <SearchRoundedIcon style={{ color: grey[500] }} />}
                    />
                    {
                        !loading && search.length > 1 ?
                            <AddYourSkillIfYouCantFind search={search} reload={this.reload.bind(this)}/>
                        : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <div style={{minHeight:130}}>
                    {
                        loading ? null :
                            skills.map((skill,index) => (
                                <SingleSearchResultSkill disabled={this.props.disabled} skill={skill} key={index} reload={() => this.props.reload()} />
                                ))
                    }
                        {
                            !loading && skills.length === 0 ?
                                <Typography>
                                    {Translate.noSkillFound}
                                </Typography> : null
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SearchSkills))));