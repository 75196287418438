import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, setStore} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ProfileImage from "../../fileManager/Components/ProfileImage";
import SpecialtiesField from "../../../components/fields/SpecialtiesField";

class CustomerDetails extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            company_name: '',
            company_type:'',
            company_start_year:'',
            company_headline:'',
            company_bio:'',
            company_id:'',
        },
        errors:{},
        specialties:[],
        loading:true,
        SubmitLoading:false,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                let specialties_temp = [];
                // eslint-disable-next-line
                response.data.specialties.map((specialty) => {
                    specialties_temp.push(specialty.name)
                })
                this.setState({
                    fields:{
                        company_name:!!response.data.company_name ? response.data.company_name : '',
                        company_type:!!response.data.company_type ? response.data.company_type : '',
                        company_start_year:!!response.data.company_start_year ? response.data.company_start_year : '',
                        company_bio: !!response.data.company_bio ? response.data.company_bio : '',
                        company_headline: !!response.data.company_headline ? response.data.company_headline : '',
                        company_id:!!response.data.company_id ? response.data.company_id : '',
                    },
                    loading:false,
                    specialties:specialties_temp,

                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let { fields,specialties } = this.state;
        let errors = {};
        let formIsValid = true;

        //company_name
        if(validator.isEmpty(fields.company_name)){
            formIsValid = false;
            errors['company_name'] = Translate.emptyName;
        }else if(!validator.isByteLength(fields.company_name,{min:0, max:255})){
            formIsValid = false;
            errors['company_name'] = Translate.maxLength255;
        }
        //company_type
        if(!fields.company_type){
            formIsValid = false;
            errors['company_type'] = Translate.emptyField;
        }
        //company_id
        if(!!fields.company_id ? (fields.company_id.length > 11 || fields.company_id.length < 10)  : false){
            formIsValid = false;
            errors['company_id'] = Translate.wrongCode;
        }
        if(!fields.company_id){
            formIsValid = false;
            errors['company_id'] = Translate.emptyField;
        }
        //specialties
        if(specialties.length === 0){
            formIsValid = false;
            errors['specialties'] = Translate.emptyField;
        }
        //company_start_year
        if(!!fields.company_start_year ? (fields.company_start_year > 1400 || fields.company_start_year < 1200) : false){
            formIsValid = false;
            errors['company_start_year'] = Translate.wrongDate;
        }
        if(!fields.company_start_year){
            formIsValid = false;
            errors['company_start_year'] = Translate.emptyField;
        }
        //company_headline
        if(!!fields.company_headline ? fields.company_headline.length > 50 : false){
            formIsValid = false;
            errors['company_headline'] = Translate.maxLength50;
        }
        if(!fields.company_headline){
            formIsValid = false;
            errors['company_headline'] = Translate.emptyField;
        }
        //company_bio
        if(!!fields.company_bio ? fields.company_bio.length > 90 : false){
            formIsValid = false;
            errors['company_bio'] = Translate.maxLength90;
        }
        if(!fields.company_bio){
            formIsValid = false;
            errors['company_bio'] = Translate.emptyField;
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,specialties} = this.state;
        let data = new FormData();
        data.append('company_name', fields.company_name);
        data.append('company_type', fields.company_type);
        data.append('company_bio', fields.company_bio);
        data.append('company_headline', fields.company_headline);
        data.append('company_start_year', fields.company_start_year);
        data.append('company_id', fields.company_id);
        data.append('specialties',  JSON.stringify(specialties));

        let loginToken = localStorage.getItem('ACT');
        /*
                let {manage} = this.props;
        */
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/customer/set`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                this.refreshStore();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        dashboard : response.data.role.name === "user" ? "user" : "expert",
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    async handleChangeSpecialty(specialties){
        let {errors} = this.state;
        errors['specialties']= '';
        await this.setState({
            specialties,
            errors
        })
    }
    render() {
        const {errors,fields,SubmitLoading,specialties} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2} justify="center" alignItems="center">
                        <CssBaseline/>
                        <Grid item xs={12}>
                            <ProfileImage noTitle size={150} manage={this.props.manage} user_id={this.props.user_id}/>
                        </Grid>
                        <Grid item xs={12}  style={{marginTop:20}}>
                            <Grid container spacing={2} direction="row">
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        value={fields.company_name}
                                        fullWidth
                                        id="company_name"
                                        label={Translate.company_name}
                                        name="company_name"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['company_name']}
                                        helperText={errors['company_name']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        variant="outlined"
                                        value={fields.company_type}
                                        fullWidth
                                        required
                                        id="company_type"
                                        label={Translate.company_type}
                                        name="company_type"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['company_type']}
                                        helperText={errors['company_type']}
                                    >
                                        <MenuItem value="person">
                                            {Translate.type_person}
                                        </MenuItem>
                                        <MenuItem value="company">
                                            {Translate.type_company}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        margin="normal"
                                        value={fields.company_id}
                                        fullWidth
                                        id="company_id"
                                        label={fields.company_type === "company" ? Translate.company_id : Translate.national_id }
                                        name="company_id"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['company_id']}
                                        helperText={errors['company_id']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.company_start_year}
                                        fullWidth
                                        margin="normal"
                                        placeholder={Translate.company_start_year_sample}
                                        type="number"
                                        required
                                        id="company_start_year"
                                        label={Translate.company_start_year}
                                        name="company_start_year"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['company_start_year']}
                                        helperText={errors['company_start_year']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <SpecialtiesField error={errors['specialties']} required input={specialties} handleChange={this.handleChangeSpecialty.bind(this)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.company_headline}
                                        fullWidth
                                        id="company_headline"
                                        required
                                        label={Translate.company_headline}
                                        name="company_headline"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['company_headline']}
                                        helperText={errors['company_headline']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.company_headline.length} max={50}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.company_bio}
                                        fullWidth
                                        id="company_bio"
                                        label={Translate.company_bio}
                                        name="company_bio"
                                        required
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['company_bio']}
                                        helperText={errors['company_bio']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.company_bio.length} max={90}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {Translate.setDetails}
                        </LoadingButton>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CustomerDetails))));