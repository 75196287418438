import React from 'react';
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import {withSnackbar} from "notistack";
import AppCard from "../../../../../components/layouts/components/AppCard";
import {Translate} from "../../../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import {defaultVariabls} from "../../../../../../config";
const CustomerServices = (props) => {
    return (
        <>
            <Grid container spacing={2}  alignItems="center" justify="center">
                <Grid item xs={12} md={6}>
                    <AppCard
                        component="a"
                        target="_blank"
                        className="customer-services-holder normal"
                        href={`${defaultVariabls.website}/experts`}
                    >
                        <Grid container spacing={2} alignItems="center" style={{height:300}}>
                            <Grid item xs={8}>
                                <Typography variant="h3">{Translate.viewExperts} </Typography>
                                <Typography>{Translate.viewExpertsDetails} </Typography>
                            </Grid>
                            <Grid item xs={4} className="text-center">
                                <img  src="assets/images/12.png" alt="11" loading="lazy"/>
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>

                <Grid item xs={12} md={6} style={{position:"relative"}}>
                    <AppCard
                        component="a"
                        target="_blank"
                        className="customer-services-holder smart"
                        href={`${defaultVariabls.website}/experts`}
                    >
                        <Grid container spacing={2} alignItems="center" style={{height:300}}>
                            <Grid item xs={8}>
                                <Typography variant="h3">{Translate.chooseSmartExpert} </Typography>
                                <Typography>{Translate.chooseSmartExpertDetails} </Typography>
                            </Grid>
                            <Grid item xs={4} className="text-center">
                                <img  src="assets/images/11.png" alt="11" loading="lazy"/>
                            </Grid>
                        </Grid>
                    </AppCard>
                    <div className="soon">
                        <span>{Translate.soon}</span>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withSnackbar(connect(mstp)(CustomerServices));
