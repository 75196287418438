/* eslint-disable no-use-before-define */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {Translate} from "../../../config/Translate";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect, setStore} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {specialty_limit} from "../../../config/values";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class SpecialtiesField extends React.Component {
    state={
        specialties: [],
        specialty:[],
        defaults:null,
        error:null,
        loading:true,
    };
    async componentDidMount(){
        if(this.props.input){
            this.setState({
                specialty: this.props.input
            })
        }
        await this.setState({
            error: this.props.error,
        })
        await this.loadData();
    }

    async componentDidUpdate(oldProps){
        if(this.state.error !== this.props.error){
            this.setState({
                error:this.props.error,
            })
        }
    }
    async loadData(query = ''){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/specialties/getForChooser`, config)
                .then(response => {
                    this.setState({
                        specialties: response.data,
                        loading:false,
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleChange(specialty){
        await this.setState({
            specialty: specialty,
            error:''
        })
        await this.props.handleChange(specialty);

    }
    render(){
        let {loading,specialties,error,specialty} = this.state;
        return (
            <>
                {
                    loading ? <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label={Translate.specialtyDetails }
                        error = {!!error}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                </React.Fragment>
                            ),
                        }}
                    />
                        :
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            fullWidth
                            size="small"
                            getOptionDisabled={() => specialty.length >= specialty_limit}
                            onChange={(event, value) => this.handleChange(value)}
                            limitTags={1}
                            loading={loading}
                            defaultValue={specialty}
                            disableCloseOnSelect
                            options={specialties.map((option) => option.name)}
                            renderOption={(option, { selected }) => (
                                <li className="auto-li">
                                    <Checkbox
                                        icon={icon}
                                        disabled={specialty.length >= specialty_limit}
                                        checkedIcon={checkedIcon}
                                        style={{ marginLeft: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label={Translate.specialtyDetails }
                                    error = {!!error}
                                    helperText={!!error ? error : specialty_limit-specialty.length > 0 ? specialty_limit-specialty.length+" "+Translate.specialty_limit : Translate.noSpecialtyLeft}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                }

                </>

        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SpecialtiesField))));