import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BackupIcon from '@material-ui/icons/Backup';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import axios from "axios/index";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";

class AddFile extends React.Component {
    state = {
        loading:false,
        percentCompleted:0,
        uploadText:'',
        disable:false
    }
    classes = this.props.classes;
    handleuploadSuccess(){
        this.setState({
            uploadText:Translate.uploadDone
        })
        this.props.ChangeLoader();
        setTimeout(() => {
            this.setState({
                percentCompleted:0,
                uploadText:'',
                disable:false
            })
        }, 5000);
    }
    onUploadImage = (event) => {
        event.preventDefault();

        this.setState({
            loading:true,
            disable:true
        })
        let canUpload = true;
        const length = event.target.files.length;
        for (let i = 0 ; i < length ; i++){
            const file = event.target.files[i];
            let FileSize = file.size / 1024;
            let name = file.name;
            let lastDot = name.lastIndexOf('.');
            let extension = name.substring(lastDot + 1);
            if(extension === "png" || extension === "jpg" || extension === 'jpeg' || extension === 'webp'){
                if (FileSize > 250) {
                    this.props.enqueueSnackbar(Translate.imageSize250kb, {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }else if (FileSize < 2){
                    this.props.enqueueSnackbar(Translate.fileSizeWeak, {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }
            }else if(extension === "xlsx" || extension === "xls" || extension === 'doc' || extension === 'docs' || extension === 'pdf'){
                if (FileSize > 1024) {
                    this.props.enqueueSnackbar(Translate.fileSize1mb, {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }else if (FileSize < 5){
                    this.props.enqueueSnackbar(Translate.fileSizeWeak, {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }
            }else if(extension === "mp4" || extension === "mkv" || extension === 'avi' || extension === 'mov'){
                if (FileSize > 20480) {
                    this.props.enqueueSnackbar(Translate.fileSize20mb, {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }else if (FileSize < 50){
                    this.props.enqueueSnackbar(Translate.fileSizeWeak, {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }
            }
            if(canUpload){
                const data = new FormData();
                data.append('image', file);
                let loginToken = localStorage.getItem('ACT');
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    },
                    onUploadProgress: (progressEvent) => {
                        this.setState({
                            uploadText:Translate.uploading
                        })
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        if(percentCompleted === 100){
                            this.handleuploadSuccess();
                        }
                        this.setState({
                            percentCompleted: percentCompleted,
                        })
                    }
                };
                axios.post(`${defaultVariabls.url}/file/add`, data, config)
                    .then(response => {
                        this.props.enqueueSnackbar(Translate.uploadSuccess, {
                            variant: 'success',
                        });
                        this.props.Changed();
                    })
                    .catch(error => {
                        if(error.response.status === 403){
                            this.props.enqueueSnackbar(Translate.storageIsFull, {
                                variant: 'warning',
                            });
                        }else{
                            console.log(error.response)
                            this.props.enqueueSnackbar(Translate.submitErrorFile, {
                                variant: 'error',
                            });
                        }

                        this.props.ChangeLoader();
                    });

            }
        }

        this.setState({
            loading:false
        })
    };
    render(){
        let {percentCompleted,uploadText,disable} = this.state;
        return(
            <React.Fragment>
            <h2 style={{display:"inline"}}>
                <input
                    className={this.classes.FileManagerInput}
                    id="FileManager_Input"
                    type="file"
                    name="image"
                    multiple
                    onChange={this.onUploadImage.bind(this)}
                />
                <label htmlFor="FileManager_Input">
                    {
                        this.props.width === "sm" || this.props.width === "xs" ?
                            <IconButton
                                variant="contained"
                                color="primary"
                                component="span"
                                disabled={disable}
                            >
                                <BackupIcon />
                            </IconButton>
                            :
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                disabled={disable}
                                startIcon={<BackupIcon />}
                            >
                                {Translate.uploadImage}
                            </Button>
                    }

                </label>
            </h2>
                <div className="uploadProgress">
                    <CircularProgress size={35} thickness={4} variant="static" value={percentCompleted}/>
                    <span className={ uploadText === Translate.uploadDone ? "success" : null}>{uploadText}</span>
                </div>
            </React.Fragment>
    )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(AddFile)));