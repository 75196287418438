import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {Translate} from "../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import SubTitle from "../../elements/SubTitle";
import AppCardLoaderFields from "../../Skeletons/AppCardLoaderFields";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect, setStore} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import AddYourVariableIfYouCantFind from "../../elements/AddYourVariableIfYouCantFind";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
class VariablesChooser extends React.Component {
    state = {
        categories: this.props.categories,
        input: this.props.input,
        loading:false,
        variables:[],
        values:[],
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(this.state.categories.length > 0){
            this.setState({
                loading:true
            })
            if(this.state.input.length > 0){
                await this.loadDefaults();
            }
            await this.loadData()
        }
    }

    async componentDidUpdate(prevProps){
        if(this.state.categories !== this.props.categories){
            this.setState({
                categories: this.props.categories
            })
            await this.loadData();
        }
    }
    async loadDefaults(){
        let {input,values} = this.state;
        await input.map((item)=> {
            values[item.id] = !!item.pivot ? item.pivot.value : null;
            return true
        })
        await this.setState({
            values
        })
        await this.handleDone();

    }
    async loadData(){
        let {categories} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('categories', JSON.stringify(categories));
            await axios.post(`${defaultVariabls.url}/variables/getByCategories`,data, config)
                .then(response => {
                    this.setState({
                        variables:response.data,
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {values} = this.state;
        let target = e.target;
        values[target.name] = target.value;
        this.setState({
            values
        });
    }

    async handleDone(){
        let {values} = this.state;
        await this.props.outPut(values);
    }
    handleClickonVariable(id){
        let can_go = this.props.user.role.permission["manageSettings"];
        if(can_go){
            window.open(`${defaultVariabls.React}/edit/variable/${id}`);
        }
    }
    render(){
        let {categories,loading,variables,values}= this.state;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SubTitle style={{marginBottom:0}} color="secondary" align="left">{Translate.chooseVariables}</SubTitle>
                    </Grid>
                    {
                        loading ?
                            <AppCardLoaderFields/> :
                            categories.length > 0 ?
                                variables.length > 0 ?
                                    <>
                                        {
                                            variables.map((variable,index) =>(
                                                <Grid item xs={12} md={6} key={index}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                name={variable.id}
                                                                variant={"outlined"}
                                                                label={variable.name}
                                                                placeholder={variable.name}
                                                                value={values[variable.id]}
                                                                InputProps={!variable.confirmed ? {
                                                                    endAdornment: <InputAdornment position="end">
                                                                        <Tooltip title={Translate.variableIsNotConfirmed} arrow>
                                                                            <IconButton onClick={() => this.handleClickonVariable(variable.id)}>
                                                                                <InfoRoundedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </InputAdornment>,
                                                                }: null}
                                                                onChange={this.handleChange.bind(this)}
                                                                onBlur={this.handleDone.bind(this)}
                                                                type="text"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }

                                        <Grid item xs={12}>
                                            <AddYourVariableIfYouCantFind reload={this.loadData.bind(this)} category={categories[categories.length-1]}/>
                                        </Grid>
                                    </>
                                    : null
                                :
                                <Grid item xs={12}>
                                    <Typography color="textSecondary">{Translate.pleaseChooseCategoriesFirst}</Typography>
                                </Grid>
                    }
                </Grid>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user
});

export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(VariablesChooser))));