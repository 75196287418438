import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import WalletHistory from "./WalletHistory";
import {withRouter} from "react-router";


class WalletHistoryPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.manageUserWallet}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={ Translate.manageUserWallet}
                            action={
                                <BreadCrumbs  firstText={Translate.manageWallets} firstLink="/manage/wallets" secondText={Translate.manageUserWallet}/>
                            }>
                            <WalletHistory id={this.props.match.params.id}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withRouter(withSnackbar(withStyles(Styles)(WalletHistoryPage)));
