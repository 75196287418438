import {Translate} from "../Translate";
import {defaultVariabls} from "../index";

const topMenuRoutesConfig = [
  {
    id: 'homePage',
    title: Translate.homePage,
    type: 'item',
    url: `${defaultVariabls.website}`,
  },
  {
    id: 'experts',
    title: Translate.experts,
    type: 'item',
    url: `${defaultVariabls.website}/experts`,
  },
    {
    id: 'articles',
    title: Translate.contents,
    type: 'item',
    url: `${defaultVariabls.website}/articles`,
  },
    {
    id: 'support',
    title: Translate.support,
    type: 'item',
    url: `${defaultVariabls.website}/contact`,
  },
    {
        id: 'help',
        title: Translate.help,
        type: 'item',
        url: `${defaultVariabls.website}/help`,
    },
];
export default topMenuRoutesConfig;
