import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";

class AdminDashboard extends React.Component {
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);

    render(){
        return (
            <p>
                Hello From admin dashboard
            </p>
        );
    }
}
const mstp = state => ({
    dashboard: state.dashboard,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AdminDashboard))));