import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import {setStore, getStore, connect} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';
import {withSnackbar} from "notistack";
import LoadingButton from "../../components/elements/LoadingButton";
import {passwordRegx, secret, usernameRegx} from "../../../config/Security";
import HeaderTags from "../../components/elements/HeaderTags";
import AuthLayout from "../../components/layouts/AuthLayout";
import Box from "@material-ui/core/Box/Box";
import PassField from "../../components/fields/PassField";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import queryString from "query-string";

class Register extends React.Component {
    constructor(props){
        super(props);
        if(getStore().isAuth){
            this.props.history.push("/");
        }
        const values = queryString.parse(this.props.location.search)
        let redirect = values.r;
        this.state={
            fields: {
                first_name: '',
                last_name: '',
                email:'',
                mobile:'',
                nameofuser:'',
                role:'expert',
                password:'',
                password_confirmation: '',
            },
            errors:{},
            SubmitLoading:false,
            success:false,
            redirect: !!redirect ? redirect : null

        };
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.nameofuser)){
            formIsValid = false;
            errors['nameofuser'] = Translate.emptyField;
        }else if(!usernameRegx.test(fields.nameofuser)){
            formIsValid = false;
            errors['nameofuser'] = Translate.wrongUsername;
        }
        //last_name
        if(validator.isEmpty(fields.last_name)){
            formIsValid = false;
            errors['last_name'] = Translate.emptyLastName;
        }
        //first_name
        if(validator.isEmpty(fields.first_name)){
            formIsValid = false;
            errors['first_name'] = Translate.emptyName;
        }

        //mobile
        if(!validator.isNumeric(fields.mobile) || fields.mobile.length !== 11){
            formIsValid = false;
            errors['mobile'] = Translate.wrongMobile;
        }

        //Email
        if(validator.isEmpty(fields.email)){
            formIsValid = false;
            errors['email'] = Translate.emptyEmail;
        }else if(!validator.isEmail(fields.email)){
            formIsValid = false;
            errors['email'] = Translate.wrongEmail;
        }

        //Password
        if(validator.isEmpty(fields.password)){
            formIsValid = false;
            errors['password'] = Translate.emptyPassword;
        }else if(!passwordRegx.test(fields.password)){
            formIsValid = false;
            errors['password'] = Translate.weakPassword;
        }
        //Password_confirmation
        if(validator.isEmpty(fields.password_confirmation)){
            formIsValid = false;
            errors['password_confirmation'] = Translate.emptyField;
        }else if (fields.password_confirmation !== fields.password){
            formIsValid = false;
            errors['password_confirmation'] = Translate.noMatchPassword;
        }

        if(!!formIsValid){
            //check Email and Mobile
            let data = new FormData();
            data.append('email', fields.email);
            data.append('mobile', fields.mobile);
            data.append('name', fields.nameofuser);
            const loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            const request = async () => {
                await axios.post(`${defaultVariabls.url}/register/checkAuth`, data, config)
                    .then(response => {
                        let error = response.data;
                        if(!!error.email) {
                            formIsValid = false;
                            errors['email'] = error.email;
                        }
                        if(!!error.mobile) {
                            formIsValid = false;
                            errors['mobile'] = error.mobile;
                        }
                        if(!!error.name) {
                            formIsValid = false;
                            errors['nameofuser'] = error.name;
                        }
                    }).catch(error => {

                    });
                await this.setState({errors},() =>{
                    if(!formIsValid){
                        this.setState({
                            SubmitLoading:false
                        })
                    }
                    return callback(formIsValid)
                });
            };
            request();
        }else{
            this.setState({errors},() =>{
                if(!formIsValid){
                    this.setState({
                        SubmitLoading:false
                    })
                }
                return callback(formIsValid)
            });
        }
    }
    async handleRequest(){
        const {first_name,last_name,email,mobile,nameofuser, password,password_confirmation,role} = this.state.fields;

        let data = new FormData();
        data.append('secret', secret);
        data.append('first_name', first_name);
        data.append('last_name', last_name);
        data.append('email', email);
        data.append('mobile', mobile);
        data.append('name', nameofuser);
        data.append('password', password);
        data.append('password_confirmation', password_confirmation);
        data.append('role', role);
        data.append('avatar', "2");
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/register`, data, config)
            .then(response =>{
                localStorage.setItem('ACT',response.data.access_token);
                localStorage.setItem('RET',response.data.refresh_token);
            })
            .catch(error =>{
                console.log(error.response)
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(Translate.registerError, {
                    variant: 'error',
                });
            });
        const loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });
                    this.setState({
                        SubmitLoading:false,
                        success:true
                    })

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(Translate.RegisterError, {
                    variant: 'error',
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                SubmitLoading:false
            })
        }
        if (this.props.isAuth){
            let code = this.props.user.login_token;
            if(this.state.redirect){
                window.location.replace(`${defaultVariabls.website}/loginFromPanel/${email}/${code}?r=${this.state.redirect}`);
            }else {
                window.location.replace(`${defaultVariabls.website}/loginFromPanel/${email}/${code}`);
            }
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render() {
        const {classes} = this.props;
        const {errors,SubmitLoading,success} = this.state;
        return (
            <AuthLayout>
                <HeaderTags title={Translate.register}/>
                <Grid item md={10} lg={8}>
                    <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                        <strong> {Translate.register}</strong>
                    </Box>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                        <Grid container spacing={1} justify="center" alignItems='center'>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="first_name"
                                    variant="outlined"
                                    label={Translate.first_name}
                                    placeholder={Translate.first_name}
                                    name="first_name"
                                    onChange={this.handleChange.bind(this)}
                                    autoFocus
                                    error = {!!errors['first_name']}
                                    helperText={errors['first_name']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="last_name"
                                    variant="outlined"
                                    label={Translate.last_name}
                                    placeholder={Translate.last_name}
                                    name="last_name"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['last_name']}
                                    helperText={errors['last_name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    label={Translate.username}
                                    placeholder={Translate.username}
                                    name="nameofuser"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['nameofuser']}
                                    helperText={!!errors['nameofuser'] ? errors['nameofuser'] : Translate.usernameHelper}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="mobile"
                                    label={Translate.mobile}
                                    placeholder={Translate.mobileSample}
                                    name="mobile"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['mobile']}
                                    helperText={errors['mobile'] }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    label={Translate.email}
                                    name="email"
                                    autoComplete="email"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['email']}
                                    helperText={errors['email']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PassField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="password"
                                    label={Translate.password}
                                    type="password"
                                    id="password"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['password']}
                                    helperText={errors['password']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PassField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    name="password_confirmation"
                                    label={Translate.passwordConfirm}
                                    placeholder={Translate.passwordConfirm}
                                    type="password"
                                    id="password_confirmation"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['password_confirmation']}
                                    helperText={errors['password_confirmation']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="left" style={{fontSize:13,marginRight:15}} color="textSecondary">{Translate.passwordStrength}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup aria-label="role" name="role" value={this.state.fields.role} onChange={this.handleChange.bind(this)}>
                                    <Grid container>
                                        <Grid item xs={6} style={{textAlign:"right"}}>
                                            <FormControlLabel
                                                value="expert"
                                                control={<Radio />}
                                                label={Translate.expert}
                                            />

                                        </Grid>
                                        <Grid item xs={6} style={{textAlign:"left"}}>
                                            <FormControlLabel value="user" control={<Radio />} label={Translate.customer} />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:25}}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{width:"100%"}}
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                                success = {success}
                            >
                                {Translate.register}
                            </LoadingButton>
                        </Grid>
                    </form>

                    <Box  fontSize={14}>
                        <Box component='span' mr={2} fontWeight={600}
                        >
                            {Translate.haveAccount}
                        </Box>
                        <Box
                            component='span'
                            color='primary.main'
                            fontWeight={600}
                            className={classes.pointer}>
                            <Link to="/login">
                                {Translate.login}
                            </Link>
                        </Box>
                    </Box>
                </Grid>
            </AuthLayout>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Register))));