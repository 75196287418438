import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";
import {updateRedux} from "../../../../../config/Security";
class MakeExperience extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            position: '',
            place:'',
            description:'',
            duration:null,
        },
        errors:{},
        current:false,
        loading:!!this.props.id,
        SubmitLoading:false,
        id: !!this.props.id ? this.props.id : 0,
        editMode: !!this.props.id
    };
    componentDidMount(){
        if(!!this.props.id){
            this.loadData();
        }
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/experience/get/${this.state.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        position: !!response.data.position ? response.data.position : '',
                        place: !!response.data.place ? response.data.place : '',
                        description: !!response.data.description ? response.data.description : '',
                        duration: !!response.data.duration ? response.data.duration : '',
                    },
                    loading:false,
                    current: response.data.current,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        if(target.name === "duration"){
            if(target.value < 0){
                fields[target.name] = 0;
            }
        }
        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //position
        if(validator.isEmpty(fields.position)) {
            formIsValid = false;
            errors['position'] = Translate.emptyField;
        }
        //place
        if(validator.isEmpty(fields.place)) {
            formIsValid = false;
            errors['place'] = Translate.emptyField;
        }
        //duration
        if(!fields.duration) {
            formIsValid = false;
            errors['duration'] = Translate.emptyField;
        }
        if(!!fields.description ? fields.description.length > 70 : false){
            formIsValid = false;
            errors['description'] = Translate.maxLength70;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,id,current} = this.state;
        let data = new FormData();
        data.append('position', fields.position);
        data.append('place', fields.place);
        data.append('duration', fields.duration);
        data.append('description', fields.description);
        data.append('current', !!current ? 1: 0);
        data.append('id', !!id ? id : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/experience/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                updateRedux();
                this.props.handleDone();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }

    render() {
        const {errors,fields,SubmitLoading,current} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2} justify="center" alignItems="center" >
                        <CssBaseline/>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                value={fields.position}
                                fullWidth
                                label={Translate.position}
                                name="position"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['position']}
                                helperText={errors['position']}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                value={fields.place}
                                fullWidth
                                label={Translate.place}
                                name="place"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['place']}
                                helperText={errors['place']}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                value={fields.duration}
                                fullWidth
                                label={Translate.duration}
                                name="duration"
                                type="number"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['duration']}
                                helperText={errors['duration']}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={current}
                                        onChange={() => this.setState({
                                            current: !this.state.current
                                        })}
                                        name="current"
                                    />
                                }
                                label={Translate.nowWorkingHere}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={fields.description}
                                fullWidth
                                label={Translate.experienceDescription}
                                name="description"
                                rows={3}
                                multiline
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['description']}
                                helperText={errors['description']}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FieldCounterWithoutBar length={fields.description.length} max={70}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeExperience))));