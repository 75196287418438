import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {FieldSkeleton} from "../../../Skeletons/FieldSkeleton";

class LiearCategory extends React.Component {
    state = {
        categories: null,
        loading:false,
        input:this.props.input,
        level:this.props.level,
        prevLevel:this.props.prevLevel
    };
    classes = this.props.classes;
    componentDidMount(){
        let {level,prevLevel} = this.state;
        if(level === 1 || !!prevLevel){
            this.setState({
                loading:true
            });
            this.loadData();
        }
    }
    async componentDidUpdate(prevProps){
        if(this.state.input !== this.props.input){
            await this.setState({
                input: this.props.input
            })
            await this.loadData()
        }
        if(this.state.prevLevel !== this.props.prevLevel){
            if(this.props.prevLevel > 0){
                await this.setState({
                    prevLevel: this.props.prevLevel,
                    loading:true
                });
                await this.loadData()
            }
        }
    }
    async loadData(){
        let {level,prevLevel} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };

            await axios.get(`${defaultVariabls.url}/categories/quickViewGetByParent/${level === 1 ? 0 : prevLevel}`, config)
                .then(response => {

                    this.setState({
                        categories: response.data,
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(value){
        if(value === null){
            this.setState({
                input:''
            })
        }else{
            this.setState({
                input:value.id
            });
            this.props.handleChange(value.id,value.name)
        }
    }
    render(){
        let {categories,loading,level} = this.state;
        return (
            <>
                {
                    loading ? <FieldSkeleton/> :
                        <>
                        {
                            !!categories ? !!categories.length ?
                                <Autocomplete
                                    options={categories}
                                    defaultValue={categories[this.state.input]}
                                    onChange={(event, value) => this.handleChange(value)}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={level === 1 ? Translate.chooseMainCategory : Translate.chooseSubCategory}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                /> : null : null
                        }
                        </>

                }
                </>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(LiearCategory)));