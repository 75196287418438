import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Drawer from "@material-ui/core/Drawer/Drawer";
import Paper from "@material-ui/core/Paper/Paper";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import UnReadNotificationsInfinity from "../../../Menu/UnReadNotificationsInfinity";
import ReadNotificationsInfinity from "../../../Menu/ReadNotificationsInfinity";
import withWidth from "@material-ui/core/withWidth/withWidth";
import withStyles from "@material-ui/core/styles/withStyles";
import {Styles} from "../../../../../config/design/styles";
import {connect} from "trim-redux";
import {withRouter} from 'react-router-dom';
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {Translate} from "../../../../../config/Translate";
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import clsx from "clsx";

class Notifications extends React.Component {
    state = {
        profileDrawerOpen:false,
        TabId: 0,
        countRN:0,
        countURN:0,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
        setInterval(this.loadData.bind(this), 6000);
    }

    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/notifications/count`, config)
                .then(response => {
                    this.setState({
                        countURN: response.data[0],
                        countRN: response.data[1]-response.data[0],
                        countURNews: response.data[2],
                        loading:false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }
    }
    handleNotificationDrawer = () => {
        let count = this.state.countURN;
        let TabId= 0;
        if(count){
            TabId = 1
        }else {
            TabId = 0
        }
        this.setState({
            profileDrawerOpen:!this.state.profileDrawerOpen,
            TabId

        })
    };
    handleTabChange = (event, newValue) => {
        this.setState({
            TabId: newValue
        })
    };
    refresh(){
        this.loadData();
        this.setState({
            profileDrawerOpen:false,
        })
    }
    render(){
        let {profileDrawerOpen,TabId,countRN,countURN} = this.state;
        return (
            <>
                <IconButton
                    aria-label='show 17 new notifications'
                    color='inherit'
                    className={clsx(this.classes.notiBtn, 'notiBtn')}
                    onClick={this.handleNotificationDrawer}
                >
                    <Badge
                        badgeContent={countURN}
                        max={99}
                        className="notif-badge"
                        color='secondary'
                    >
                        {
                            countURN ?
                                <NotificationsActiveIcon
                                    className={clsx(this.classes.notiIcon, 'notiIcon')}
                                />
                                :
                                <NotificationsNoneRoundedIcon
                                    className={clsx(this.classes.notiIcon, 'notiIcon')}
                                />
                        }


                    </Badge>
                </IconButton>
                <Drawer
                    classes={{
                        paper: this.classes.ProfileDrawer,
                    }}
                    anchor="right"
                    open={profileDrawerOpen}
                    onClose={this.handleNotificationDrawer.bind(this)}
                >
                    <Paper square className={this.classes.Tabroot}>
                        <Tabs
                            value={this.state.TabId}
                            onChange={this.handleTabChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                        >
                            <Tab icon={<NotificationsNoneRoundedIcon />} label={Translate.readNotifications}/>
                            <Tab icon={<NotificationsActiveRoundedIcon  />} label={Translate.unreadNotifications} />
                        </Tabs>
                        {
                            TabId ? <UnReadNotificationsInfinity count={countURN}  /> : <ReadNotificationsInfinity count={countRN} refresh={this.refresh.bind(this)}/>
                        }
                    </Paper>
                </Drawer>
            </>
        );
    }

}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withStyles(Styles)(connect(mstp)(Notifications))));
