import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import moment from 'jalali-moment';
import { withSnackbar } from 'notistack';
import Container from "@material-ui/core/Container/Container";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {TableCount, TablePage} from "../../../../config/Permissions";
import EditCommentDialog from "./components/EditCommentDialog";
import AddCommentDialog from "./components/AddCommentDialog";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import {updateRedux} from "../../../../config/Security";

class Comments extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'contentTitle',
                title: Translate.contentTitle,
            },
            {
                title: Translate.realUser,
                field: 'user',
            },
            {title: Translate.comment, field: 'comment',},
            {
                title: Translate.confirmationStatus,
                field: 'confirmed',
                lookup: { 1 : <CheckCircleRoundedIcon style={{color: "#38bf84"}} /> , 0: <CancelRoundedIcon style={{color: "#ef6b2c "}}/>},
            },
            {
                title: Translate.lastUpdate,
                editable: 'never',
                field: 'lastUpdate',
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        id:null,
        replay_comment:null,
        replay_product:null,
        openDialog:false,
        openDialogAdd:false,
        replaying:false,

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange();
        updateRedux();

    }
    async handleReplayClick(data){
        await this.setState({
            replay_comment: data.id,
            replay_product: data.product_id
        })
        await this.toggleDialogAdd();
    }

    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/comment/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.commentRemoved, {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.commentRemovingFailed, {
                    variant: 'error',
                });
                return false
            });
        this.loadData();
    }
    async onDeleteSelected(data){
        await data.map((row) =>(
            this.onDelete(row.id,false)

        ));
        await this.loadData();
        await this.props.enqueueSnackbar(Translate.deleteSelectedComments, {
            variant: 'success',
        });
    }
    handleRowClick(data){
        this.setState({
            id:data.id,
            openDialog:true,
        })
    }
    changePageSize(data){
        setTimeout(function() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 100);

    }
    async toggleDialog(){
        await this.setState({
            openDialog: !this.state.openDialog,
            id:null,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    async toggleDialogAdd(){
        await this.setState({
            openDialogAdd: !this.state.openDialogAdd,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    render(){
        let{openDialog,id,openDialogAdd}= this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                            <MaterialTable
                                    tableRef={this.tableRef}
                                    icons={tableIcons}
                                    onRowClick={((evt, selectedRow) =>  this.handleRowClick(selectedRow))}
                                    onChangeRowsPerPage={(data) => this.changePageSize(data)}
                                    title={" "}
                                    localization={DataTableLocalization}
                                    columns={this.state.columns}
                                    data={query =>
                                        new Promise((resolve, reject) => {
                                            axios.get(`${defaultVariabls.url}/comments/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                                .then(response => {
                                                    let data = [];
                                                    response.data.data.map((row) =>(
                                                        data.push({
                                                            contentTitle: row.content.title,
                                                            content_id: row.content.id,
                                                            user: !!row.user ? row.user.first_name+" "+row.user.last_name : null,
                                                            user_id:!!row.user ? row.user.id : null,
                                                            comment: !!row.comment ? row.comment.slice(0,15)+"..." : null,
                                                            confirmed: row.confirmed,
                                                            id:row.id,
                                                            lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                        })
                                                    ));
                                                    resolve({
                                                        data: data,
                                                        page: response.data.current_page - 1,
                                                        totalCount: response.data.total,
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                    this.props.enqueueSnackbar(Translate.connectionError, {
                                                        variant: 'error',
                                                    });
                                                });
                                        })
                                    }
                                    options={{
                                        selection: true,
                                        pageSize: TablePage,
                                        pageSizeOptions: TableCount,
                                    }}
                                    actions={[
                                        {
                                            tooltip: Translate.delete,
                                            icon: tableIcons.Delete,
                                            onClick: (evt, data) => this.onDeleteSelected(data)
                                        },

                                    ]}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onDelete(oldData.id);
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }}
                                />
                            </Container>

                    }
                {
                    openDialog ? <EditCommentDialog id={id} cancleOperation={() => this.toggleDialog()} handleDone={() => this.toggleDialog()} /> : null
                }
                {
                    openDialogAdd ? <AddCommentDialog replay_comment={this.state.replay_comment} replay_product={this.state.replay_product} id={id} cancleOperation={() => this.toggleDialogAdd()} handleDone={() => this.toggleDialogAdd()} /> : null
                }
            </>

        );
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(Comments)));