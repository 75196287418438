import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Loader from "../../../components/elements/Loader";
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import TableGenerator from "./TableGenerator";
import Title from "../../elements/Title";
import validator from "validator";

class AddTable extends React.Component {
    state = {
        fields:{
            name:"",
        },
        data: [],
        heading:0,
        errors:{},
        loading: false,
        SubmitLoading: false,
        build: false,
    };
    classes = this.props.classes;
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }
    handleRequest(){
        let {fields,heading} = this.state;
        let tableData = this.state.data;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('heading', heading ? 1 : 0);
        data.append('tableData', JSON.stringify(tableData));
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/tables/add`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.tableAdded, {
                    variant: 'success',
                });
                this.props.setTable(response.data)
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.tableAddingFailed, {
                    variant: 'error',
                });
            });
        this.setState({
            loading:false,
            SubmitLoading:false,
        });

    }

    handleValidation(callback){
        let { fields,data } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }
        if(data.length === 0){
            formIsValid = false;
            errors['name'] = Translate.emptyTableCells;
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }
    handleBuild(){
        this.setState({
            build:true
        })
    }
    setData(data,heading){
        this.setState({
            data,
            heading
        })
    }
    render(){
        let {fields,SubmitLoading,build,errors} = this.state;
        return (
            <React.Fragment>

                {
                    this.state.loading ? <Loader/> :
                        <Grid container spacing={2} direction="row" alignItems="center" justify="center">
                            <Grid item md={10} lg={8}>
                                <Title>{Translate.makeTable}</Title>
                            </Grid>
                            {
                                build ?
                                    <Grid item md={10} lg={8}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="name"
                                            label={Translate.tableName}
                                            type="text"
                                            id="name"
                                            value={fields.name}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['name']}
                                            helperText={errors['name']}
                                        />
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <TableGenerator build={this.handleBuild.bind(this)} setData={this.setData.bind(this)}/>

                            </Grid>
                            {
                                build ?
                                    <Grid item  md={10} lg={8}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {Translate.makeTable}
                                        </LoadingButton>
                                    </Grid> : null
                            }
                        </Grid>
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(AddTable)));