import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../config/Translate";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import Typography from "@material-ui/core/Typography/Typography";
import Avatar from "@material-ui/core/Avatar/Avatar";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "./FieldCounterWithoutBar";

class ReportViolation extends React.Component {
    state = {
        open:false,
        error:'',
        report: '',
        data:this.props.data,
        expert:!!this.props.expert,
    };
    handleChange(e){
        let target = e.target;
        let error = "";
        this.setState({
            error,
            report:target.value
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        if(this.state.report.length > 0 &&  this.state.report.length < 500){
            let {report,data} = this.state;
            let formData = new FormData();
            formData.append('report', report);
            formData.append('opp_id', data.id);

            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/sendReport`, formData, config)
                .then(response => {
                    this.setState({
                        SubmitLoading: false,
                        open:false

                    });
                    this.props.enqueueSnackbar(Translate.sendingReportDone, {
                        variant: 'success',

                    });
                })
                .catch(error => {
                    this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                        variant: 'error',
                    });
                    this.setState({
                        SubmitLoading: false,
                    });
                });

        }else{
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                error: !!this.state.report.length ? Translate.maxLength500 : Translate.emptyField,
                SubmitLoading:false,
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {error,open,data,expert,report} = this.state;
        return(
            <>
                {
                    this.props.width !== "xs" ?
                        <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            startIcon={<WarningRoundedIcon/>}
                            onClick={() => this.setState({open: !this.state.open})}
                        >
                            {Translate.reportAViolation}
                        </Button>
                        :
                        <IconButton color="secondary" onClick={() => this.setState({open: !this.state.open})}>
                            <WarningRoundedIcon/>
                        </IconButton>
                }
                <Dialog fullWidth maxWidth="sm" open={open} onClose={() => this.setState({open: !this.state.open})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{Translate.reportAViolation}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>{Translate.describeViolationLong}</Typography>
                            <Typography>
                                <strong>{expert ? Translate.customer : Translate.expert}:</strong>
                                <Avatar component="span" className="avatar-in-report" src={expert ? data.customer_avatar : data.avatar}/>
                                {
                                    expert ? data.customer : data.expert
                                }
                            </Typography>
                        </DialogContentText>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            multiline
                            rows={3}
                            value={report}
                            margin="normal"
                            id="description"
                            label={Translate.describeViolation}
                            name="description"
                            onChange={this.handleChange.bind(this)}
                            error = {!!error}
                            helperText={error}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FieldCounterWithoutBar length={report.length} max={500}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSubmit.bind(this)} variant={"contained"} color="secondary" size="small" startIcon={<SendRoundedIcon/>}>
                            {Translate.send}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(ReportViolation)));