import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {Translate} from "../../../../../config/Translate";
import AppCard from "../../../../components/layouts/components/AppCard";
import MakeTeamDetails from "./MakeTeamDetails";
import ToggleService from "../ToggleService";
import Title from "../../../../components/elements/Title";
import Typography from "@material-ui/core/Typography/Typography";
import Hidden from "@material-ui/core/Hidden/Hidden";

class Teams extends React.Component {
    classes = this.props.classes
    state={
        loading:false,
    };
    render() {
        const {loading} = this.state;
        return (
            <AppCard
                title={Translate.teamsDetails}
                action={
                    <ToggleService service="team"/>
                }>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justify="center" alignItems="center" style={{minHeight:300,paddingBottom:100}}>
                            <CssBaseline/>
                            <Grid item xs={12} md={11}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={9}>
                                        <Hidden smUp>
                                            <img width={80} src="../assets/images/team.png" alt="team" loading="lazy"/>
                                        </Hidden>
                                        <Title align="left" style={{marginTop:10,fontSize:"20px !important"}}>{Translate.areYouReadyToGetTeam}</Title>
                                        <Typography>{Translate.teamsDetailsHelp}</Typography>
                                        <Typography paragraph>{Translate.teamsDetailsHelp2}</Typography>
                                        <MakeTeamDetails/>
                                    </Grid>
                                    <Hidden smDown>
                                    <Grid item xs={12} md={3}>
                                        <img className="max-width" src="../assets/images/team.png" alt="team" loading="lazy"/>
                                    </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Teams))));