import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {Styles} from "../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import {withRouter} from "react-router-dom";
import {connect, getStore} from "trim-redux";
import {getRoleName} from "../../../config/Permissions";
import {Translate} from "../../../config/Translate";
import AppAnimate from "../../components/AppAnimate";
import LaptopRoundedIcon from '@material-ui/icons/LaptopRounded';
import LocalLibraryRoundedIcon from '@material-ui/icons/LocalLibraryRounded';

import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import Projects from "./components/projects/Projects";
import Jobs from "./components/jobs/Jobs";
import Teams from "./components/teams/Teams";
import Teachings from "./components/teaching/Teachings";
import Consultations from "./components/consultations/Consultations";
import Title from "../../components/elements/Title";
import Typography from "@material-ui/core/Typography/Typography";
import CheckForHavingProfile from "../../components/elements/CheckForHavingProfile";


class ServicesManagement extends React.Component{
    classes = this.props.classes;
    state={
        tabValue:"user",
    }
    handleTabChange = (event, newValue) => {
        this.props.history.push(`/service/${newValue}`)
        this.setState({
            tabValue: newValue
        })
    };
    componentDidMount(){
        let user = getStore().user;
        let roleName = getRoleName(user.role.name);
        this.setState({
            tabValue: this.props.section,
            roleName
        })
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.setState({
                tabValue: this.props.section
            })
        }
    }
    getStepContent() {
        let pathname = this.props.location.pathname;
        switch (pathname) {
            case "/service/project":
                return <Projects/>;
            case "/service/teaching":
                return <Teachings/>;
            case "/service/job":
                return <Jobs/>;
            case "/service/team":
                return <Teams/>;
            case "/service/consultation":
                return <Consultations/>;
            default:
                return 'Unknown step';
        }
    }
    render(){
        let {tabValue} = this.state;
        return (
                <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.services}>
                    <Grid container spacing={2} className="service-holder">
                        <CheckForHavingProfile new/>

                        <Grid item xs={12}>
                            <Title align="left">{Translate.services}</Title>
                            <Typography>{Translate.servicesDetails}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                                <div className={this.classes.ProviderManagerRoot}>
                            <Tabs
                                className="service-holder-tabs"
                                style={{marginBottom:20}}
                                value={tabValue}
                                onChange={this.handleTabChange}
                                textColor="secondary"
                                variant={this.props.width !== "xs" ? "fullWidth" : 'scrollable'}
                                scrollButtons={this.props.width !== "xs" ? "" : 'auto'}
                                aria-label="Tab"
                                centered={this.props.width !== "xs"}
                            >
                                <Tab value="project" icon={<LaptopRoundedIcon />} label={Translate.projectsDetails} />
                                <Tab value="teaching" icon={<LocalLibraryRoundedIcon />} label={Translate.teaching} />
                                <Tab value="job" icon={<WorkRoundedIcon />} label={Translate.jobrequest} />
                                <Tab value="team" icon={<PeopleRoundedIcon />} label={Translate.teamUp} />
                                <Tab value="consultation" icon={<HeadsetMicRoundedIcon />} label={Translate.consultation} />
                            </Tabs>
                            <Grid container spacing={2} alignItems="center" justify="center" >
                                <Grid item xs={12} className="mt-20">
                                    {this.getStepContent()}
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </AppAnimate>
        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(ServicesManagement))));