import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../config/Translate';
import {withRouter} from 'react-router-dom';
import Logo from '../../../files/img/logo-dark.png';
import { withSnackbar } from 'notistack';
import Button from "@material-ui/core/Button/Button";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Box from "@material-ui/core/Box/Box";
import AppAnimate from "../AppAnimate/index"
import {defaultVariabls} from "../../../config/index";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

class AuthLayout extends React.Component {
    render() {
        const children = this.props.children;

        const {classes} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200}>
                <Grid container alignItems="center" justify="center" className={classes.containerFull}>
                    <CssBaseline />
                    <Hidden mdDown>
                        <Grid item  md={5}>
                            <Grid container spacing={3} alignItems="center" justify="center"  className={classes.loginContainerText}>
                                <Grid item xs={12}>
                                    <Box mr={2}>
                                        <a href={`${defaultVariabls.website}`}>
                                        <img
                                            className={classes.AuthLogo}
                                            src={Logo}
                                            alt={Translate.appName}
                                            title={Translate.appName}
                                        />
                                        </a>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.headLine}>
                                        {Translate.headLine}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        component='a'
                                        href={defaultVariabls.website}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {Translate.backToHomePage}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Grid item xs={10} md={7} >
                        <Grid container justify="center" spacing={2} alignItems="center" className={classes.loginContainerForm}>
                            <Hidden lgUp>
                            <Grid item xs={12}>
                                <a href={`${defaultVariabls.website}`}>
                                    <img
                                        className={classes.AuthLogoMobile}
                                        src={Logo}
                                        alt={Translate.appName}
                                        title={Translate.appName}
                                    />
                                </a>
                            </Grid>
                            </Hidden>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </AppAnimate>
        );

    }
}
export default withSnackbar(withRouter(withStyles(Styles)(AuthLayout)));