import React,{forwardRef} from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LinkIcon from '@material-ui/icons/Link';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    insert: forwardRef((props, ref) => <GetAppRoundedIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteForeverIcon style={{color: "#ef6b2c "}} {...props} ref={ref} />),
    Remove: forwardRef((props, ref) => <RemoveCircleOutlineIcon {...props} ref={ref} />),
    Link: forwardRef((props, ref) => <LinkIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit style={{color: "#38bf84"}} {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <PrintRoundedIcon {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    View: forwardRef((props, ref) => <VisibilityRoundedIcon {...props} ref={ref} />),
    Duplicate: forwardRef((props, ref) => <FileCopyRoundedIcon {...props} ref={ref} />)
};