import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Loader from "../../../components/elements/Loader";
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import TableGenerator from "./TableGenerator";
import Title from "../../elements/Title";
import validator from "validator";
import {setStore} from "trim-redux";

class EditTable extends React.Component {
    state = {
        fields:{
            name:"",
        },
        data: [],
        heading:0,
        errors:{},
        loading: true,
        SubmitLoading: false,
        id:null,
    };
    classes = this.props.classes;
    id = this.props.table;
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/table/get/${this.id}`, config)
                .then(response => {
                    let data = response.data;
                    this.setState({
                        fields: {
                            name: data.name,
                        },
                        data: JSON.parse(data.data),
                        heading: data.heading,
                        loading:false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }
    handleRequest(){
        let {fields,heading} = this.state;
        let tableData = this.state.data;
        let data = new FormData();
        data.append('id', this.id);
        data.append('name', fields.name);
        data.append('heading', heading ? 1 : 0);
        data.append('tableData', JSON.stringify(tableData));
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/table/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.tableEdited, {
                    variant: 'success',
                });
                this.props.setTable(response.data)
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.tableEditingFailed, {
                    variant: 'error',
                });
            });
        this.setState({
            loading:false,
            SubmitLoading:false,
        });

    }
    handleValidation(callback){
        let { fields,data } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }
        if(data.length === 0){
            formIsValid = false;
            errors['name'] = Translate.emptyTableCells;
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }
    setData(data,heading){
        this.setState({
            data,
            heading
        })
    }
    handleBuild(){
        this.setState({
            build:true
        })
    }
    render(){
        let {fields,SubmitLoading,errors,data,heading} = this.state;
        return (
            <React.Fragment>
                {
                    this.state.loading ? <Loader/> :
                        <Grid container spacing={2} direction="row" alignItems="center" justify="center">
                            <Grid item md={10} lg={8}>
                                <Title>{Translate.EditTable}</Title>
                            </Grid>
                            <Grid item md={10} lg={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="name"
                                    label={Translate.tableName}
                                    type="text"
                                    id="name"
                                    value={fields.name}
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['name']}
                                    helperText={errors['name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TableGenerator edit input={data} heading={heading} build={this.handleBuild.bind(this)} setData={this.setData.bind(this)}/>
                            </Grid>
                            <Grid item  md={10} lg={8}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {Translate.EditTable}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(EditTable)));