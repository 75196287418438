import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import AppCard from "../../components/layouts/components/AppCard";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect, setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import {Translate} from "../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import AppAnimate from "../../components/AppAnimate";
import {Link} from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LoadingButton from "../../components/elements/LoadingButton";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {updateRedux} from "../../../config/Security";
import Title from "../../components/elements/Title";
import Alert from "@material-ui/lab/Alert/Alert";

class ChangeRole extends React.Component {
    classes = this.props.classes;
    state = {
        changeTo:'expert',
        SubmitLoading:false,
    }
    componentDidMount(){
        let {user,dashboard} = this.props;
        if(dashboard === "expert" && user.role.name === 'user'){
            this.setState({
                changeTo:'expert'
            })
        }else if(dashboard === "user" && user.role.name === 'expert'){
            this.setState({
                changeTo:'user'
            })
        }else{
            this.props.history.push(`/dashboard`);
        }
    }
    async handleSubmit(){
        let {changeTo} = this.state;
        this.setState({
            SubmitLoading:true,
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/upgradeRole`, config)
                .then(response => {
                    updateRedux();

                    setStore({
                        dashboard:changeTo
                    })
                    this.setState({
                        SubmitLoading:false,
                    })
                    this.props.enqueueSnackbar(Translate.roleUpgraded, {
                        variant: 'success',
                    });
                    this.props.history.push('/dashboard')

                }).catch(error => {
                    this.setState({
                        SubmitLoading:false,
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleCancel(){
        setStore({
            dashboard: this.props.dashboard === "expert" ? "user" : "expert"
        })
        this.props.history.push('/dashboard')
    }
    render(){
        let {changeTo,SubmitLoading} = this.state;
        return (
            <>
            <Grid container spacing={2} alignItems="center" justify="center">
                <Grid ite mxs={12} md={8}>
                    <Alert color="info" className="mb-15">
                        {changeTo ==="expert" ? Translate.moveToExpertDescription : Translate.moveToUserDescription}
                    </Alert>
                </Grid>
                <Grid item xs={12} md={8}>

                <AppCard>
                    <IconButton
                        style={{position:"absolute"}}
                        onClick={this.handleCancel.bind(this)}
                    >
                        <CloseRoundedIcon/>
                    </IconButton>

                            <Grid container spacing={2} alignItems="center" justify="center">
                                <Grid item xs={12} md={8}>
                                    <AppAnimate animation='transition.fadeIn' delay={500}>
                                        <Link to={this.props.user.role.name !== "user" ? "/create/shop" : null}>
                                            <img src="/assets/images/change-role.png" style={{width:"100%"}}  alt="Change role"/>
                                        </Link>
                                    </AppAnimate>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container spacing={2} alignItems="center" justify="center">
                                        <Grid item xs={12}>
                                            <AppAnimate animation='transition.slideDownIn' delay={600}>
                                                <Title>{changeTo ==="expert" ? Translate.moveToExpert : Translate.moveToUser}</Title>
                                            </AppAnimate>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppAnimate animation='transition.slideDownIn' delay={700}>
                                                <Typography className="text-justify" align="center" variant="subtitle2" >{changeTo ==="expert" ? Translate.doYouNeedExpertAccount : Translate.doYouNeedUserAccount}</Typography>
                                            </AppAnimate>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppAnimate animation='transition.slideDownIn' delay={800}>
                                                <div>
                                                    <LoadingButton
                                                        color="primary"
                                                        onClick={this.handleSubmit.bind(this)}
                                                        loading={SubmitLoading}
                                                        variant="contained"
                                                    >
                                                        {changeTo ==="expert" ? Translate.moveToExpert : Translate.moveToUser}
                                                    </LoadingButton>
                                                </div>
                                            </AppAnimate>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                </AppCard>
                </Grid>
            </Grid>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ChangeRole))));