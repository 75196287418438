import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { Translate } from '../../../../../config/Translate';
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";

class VerifyUser extends React.Component {
    constructor(props){
        super(props);
        this.state={
            fields: {
                token:0,
            },
            errors:{},
            SubmitLoading:false,
            user:this.props.user,
            minutes:1,
            seconds:0
        };
    }
    componentDidMount(){
        this.setState({
            minutes:1,
            seconds:0
        })
        this.makeCount();
    }
    makeCount(){

        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000);
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
        if(fields['token'].length === 5) {
            this.setState({
                SubmitLoading:true
            })
            this.handleRequest();
        }
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //token
        if(validator.isEmpty(fields.token)){
            formIsValid = false;
            errors['token'] = Translate.emptyField;
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    async handleRequest(){
        const {token} = this.state.fields;
        const {user} = this.state;
        let data = new FormData();
        data.append('token', token);
        data.append('userId', user.id);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/forgetPass/checkToken`, data, config)
            .then(response =>{
                this.props.setNextLevel();
                this.setState({
                    SubmitLoading:false

                })
            })
            .catch(error =>{
                this.setState({
                    SubmitLoading:false

                })
                this.props.enqueueSnackbar(Translate.WrongToken, {
                    variant: 'error',
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    async makeToken(){
        const {mobile} = this.props.user;
        let data = new FormData();
        await this.setState({
            SubmitLoading:true
        })
        data.append('mobile', mobile);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/forgetPass/setMobile`, data, config)
            .then(response =>{
                this.setState({
                    SubmitLoading:false,
                    minutes:1,
                    seconds:0
                })
                this.props.enqueueSnackbar(Translate.sendTokenTextResend, {
                    variant: 'info',
                });
                this.makeCount();
            })
            .catch(error =>{
                this.props.enqueueSnackbar(Translate.InvalidMobileNumber, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });
    }
    render() {
        const {classes} = this.props;
        const {errors,SubmitLoading,success,user,minutes,seconds} = this.state;
        return (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="token"
                        variant="outlined"
                        label={Translate.token}
                        name="token"
                        margin="normal"
                        onChange={this.handleChange.bind(this)}
                        autoFocus
                        error = {!!errors['token']}
                        helperText={errors['token']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography  align="left" color="textSecondary"><strong>{user.first_name}</strong> {Translate.dear}، {Translate.sendTokenText1} <strong>{user.mobile}</strong> {Translate.sendTokenText2} </Typography>
                    {
                        minutes !== 0 || seconds !== 0 ? <Typography  align="left" color="textSecondary">{Translate.sendTokenText3}: <strong>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</strong> </Typography> : <Button color="primary" onClick={this.makeToken.bind(this)}>{Translate.sendAgain}</Button>
                    }
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{width:"100%"}}
                        onClick = {this.handleSubmit.bind(this)}
                        loading = {SubmitLoading}
                        success = {success}
                    >
                        {Translate.confirm}
                    </LoadingButton>
                </Grid>
            </form>
        );

    }
}

export default withSnackbar(withRouter(withStyles(Styles)(VerifyUser)));