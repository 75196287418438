import React from 'react';
import {ListItem} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import useStyles from './VerticalNavGroup.style';

const VerticalNavGroup = ({item, level,role}) => {
    const classes = useStyles();
    return (
        <>
            <ListItem
                component='li'
                className={clsx(classes.navItem, 'nav-item nav-item-header')}>
                {item.title}
            </ListItem>

            {item.children && (
                <>
                    {item.children.map((item) => (
                        <React.Fragment key={item.id}>
                            {item.type === 'group' && (
                                <NavVerticalGroup item={item} level={level} />
                            )}

                            {item.type === 'collapse' && (
                                <VerticalCollapse item={item} level={level} />
                            )}
                            {
                                !item.roles || item.roles.indexOf(role.name) !==  -1 ?
                                    !item.permission || role.permission[item.permission] ?
                                        item.type === 'item' && (
                                            <VerticalItem item={item} level={level} />
                                        )
                                        :null : null
                            }

                        </React.Fragment>
                    ))}
                </>
            )}
        </>
    );
};

VerticalNavGroup.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        children: PropTypes.array,
    }),
};

VerticalNavGroup.defaultProps = {};

const NavVerticalGroup = VerticalNavGroup;

export default NavVerticalGroup;
