import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import SingleEducation from "./SingleEducation";
import AddEducationDialog from "../educations/AddEducationDialog";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../../config/Translate";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AppCard from "../../../../components/layouts/components/AppCard";
import {updateRedux} from "../../../../../config/Security";
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import Title from "../../../../components/elements/Title";
import {educations_limit} from "../../../../../config/values";
import withWidth from "@material-ui/core/withWidth/withWidth";

class Educations extends React.Component {
    classes = this.props.classes
    state={
        educations:'',
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/educations/get/${this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    educations:response.data,
                    loading:false
                })
                updateRedux();
            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        const {educations,loading} = this.state;
        return (
            <AppCard
                title={Translate.educationsDetails}
                action={
                    <>
                        {
                            this.props.width === "xs" || this.props.width === "sm" ? null :
                                <IconButton>
                                    <SchoolRoundedIcon color="primary"/>
                                </IconButton>
                        }
                    </>
                }>
                {
                    loading ? <AppCardLoaderFields/>  :
                        <Grid container spacing={2} alignItems="center" justify="center" style={{paddingTop:20,minHeight:300,paddingBottom:100}}>
                            <Grid item xs={12} md={11}>
                                <Grid container spacing={2}>
                                    <CssBaseline/>
                                    {
                                        educations.length === 0 ?
                                            <Grid item xs={12} style={{textAlign:"center"}}>
                                                <img width={120} src="../assets/images/education1.png" alt="education" loading="lazy"/>
                                                <Title paragraph>{Translate.addEducationsDetails}</Title>
                                                <Typography paragraph align="center">{Translate.addEducationsDetailsLong}</Typography>
                                                <AddEducationDialog first length={educations.length} disabled={educations.length >= educations_limit} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        educations.map((education,index) => (
                                            <Grid item xs={12} md={6} lg={4} key={index}>
                                                <SingleEducation data={education} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                        ))
                                    }
                                    {
                                        educations.length === 0 ? null :
                                            <Grid item xs={12} md={6} lg={4}>
                                                <AddEducationDialog disabled={educations.length >= educations_limit} length={educations.length} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                }
            </AppCard>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Educations)))));