import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import DeleteButton from "../../../../components/elements/DeleteButton";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {Translate} from "../../../../../config/Translate";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import Button from "@material-ui/core/Button/Button";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
class SingleSkill extends React.Component {
    classes = this.props.classes
    state={
        skill:this.props.data,
        level:this.props.data.pivot.level,
        loading:0,
    };
    handleDelete(){
        let {skill} = this.state;
        let data = new FormData();
        data.append('id', skill.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/skill/removeFromUsersSkill`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });
    }
    async handleLevel(level){
        await this.setState({
            loading:level
        })
        let {skill} = this.state;
        let data = new FormData();
        data.append('id', skill.id);
        data.append('level', level);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/skill/changeLevel`, data, config)
            .then(response => {
                this.setState({
                    level : level,
                    loading:0
                })
            })
            .catch(error => {
                console.log(error.response)

            });
    }
    render() {
        let {skill,level,loading} = this.state;
        return (
            <Card variant="outlined" style={{boxShadow:'none',height:"100%"}} className={!skill.confirmed ? "pending-card" : null}>
                <DeleteButton handleDelete={this.handleDelete.bind(this)}/>

                <CardContent>
                    <Typography  color="textPrimary" gutterBottom>
                        {skill.name}
                        <span className="pending"> {!skill.confirmed ? "("+Translate.pendingSkill+")" : null}</span>
                    </Typography>
                    <ButtonGroup  aria-label="outlined primary button group">
                        <Button
                            size="small"
                            onClick={() => this.handleLevel(1)}
                            variant={level === 1 ? "contained" : null}
                            startIcon={loading === 1 ? <CircularProgress size={20} color="inherit"/> :  <StarBorderRoundedIcon/>}
                        >
                            {Translate.skillLevel1}
                            </Button>
                        <Button
                            size="small"
                            onClick={() => this.handleLevel(2)}
                            variant={level === 2 ? "contained" : null}
                            color={level === 2 ? "primary" : null}
                            startIcon={loading === 2 ? <CircularProgress size={20} color="inherit"/> :  <StarHalfRoundedIcon/>}
                        >
                            {Translate.skillLevel2}
                            </Button>
                        <Button
                            size="small"
                            onClick={() => this.handleLevel(3)}
                            color={level === 3 ? "secondary" : null}
                            variant={level === 3 ? "contained" : null}
                            startIcon={loading === 3 ? <CircularProgress size={20} color="inherit"/> :  <StarRoundedIcon/>}
                        >
                            {Translate.skillLevel3}
                            </Button>
                    </ButtonGroup>
                </CardContent>
            </Card>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleSkill))));