import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
class SingleSearchResultSkill extends React.Component {
    classes = this.props.classes
    state={
        skill:this.props.skill,
        loading:false,
        hide:false,
    };
    async handleSubmit(){
        await this.setState({
            loading:true
        })
        let {skill} = this.state;
        let data = new FormData();
        data.append('id', skill.id);
        data.append('user_id', this.props.user.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/skills/addToUserSkills`, data, config)
            .then(response => {
                this.props.reload();
                this.setState({
                    hide:true
                })
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    render() {
        let {skill,loading,hide} = this.state;
        return (
            <>
                {
                    hide ? null :
                        <Button
                            type="submit"
                            size="small"
                            disabled={this.props.disabled}
                            variant="outlined"
                            style={{margin:5,minWidth:100}}
                            onClick = {this.handleSubmit.bind(this)}
                            startIcon={loading ? <CircularProgress size={20} color="inherit"/> : <AddRoundedIcon color="inherit"/>}
                        >
                            {skill.name}
                        </Button>
                }
                </>


        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleSearchResultSkill))));