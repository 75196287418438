import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LowPriorityRoundedIcon from '@material-ui/icons/LowPriorityRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import SkillsOrder from "./SkillsOrder";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import {Translate} from "../../../../../config/Translate";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
class OrderSkillsButton extends React.Component {
    state={
        open:false
    }
    classes = this.props.classes;
    handleClose (){
        this.setState({open:false})
        this.props.reload();
    }
    render(){
        let {open} = this.state;
        return (
            <>
                <Tooltip title={Translate.manageSkillsOrder} arrow placement="top">
                <IconButton color="primary" variant="contained" onClick={() => this.setState({open:true})}>
                    <LowPriorityRoundedIcon/>
                </IconButton>
                </Tooltip>

                <Dialog maxWidth="sm" fullWidth onClose={this.handleClose.bind(this)} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose.bind(this)}>
                        {Translate.manageSkillsOrder}
                    </DialogTitle>
                    <DialogContent dividers>
                        <SkillsOrder/>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(OrderSkillsButton)));