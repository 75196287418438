import {green} from "@material-ui/core/colors";


const drawerWidth = 220;
export const  Styles = theme =>( {
    paperLoginPage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding:theme.spacing(3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
        backgroundColor: '#F4F7FE',
        overflowY:'hidden'
    },
    mainContent: {
        flex: 1,
        display: 'flex',
    },
    WelcomRroot:{
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    toolbar: {
        paddingLeft: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        color: "#fff",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    colorWhite:{
        color:"rgba(255, 255, 255, 0.9)",
        width: "0.9em !important",
        height: "0.9em !important",
    },
    titleIcon: {
        float:"right"
    },
    appBar: {
        backgroundColor:"#2b2b2b",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        backgroundColor:"#2b2b2b",

    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: "#2b2b2b",
        color: "#fff",
        position: 'fixed',
        whiteSpace: 'nowrap',
        overflowX:"hidden",
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        borderRadius:10,
        overflow: 'auto',
        flexDirection: 'column',
        boxShadow:"0px 0px 10px rgba(82, 82, 82, 0.21)",
    },
    mainPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom:  theme.spacing(1)
    },
    SidebarPaper:{
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom:  theme.spacing(1)
    },
    fixedHeight: {
        height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    DrawerRoot: {
        width: '100%',
        maxWidth: drawerWidth,

    },
    activeItem: {
        color: "#ffffff",
        backgroundColor: "#4c4c4c",
        borderLeft:"5px solid " + theme.palette.primary.light ,
        display:"block",
        textDecoration: "none"
    },
    activeItemDropDown:{
        color: "#ffffff",
        backgroundColor: "#4c4c4c",
        borderLeft:"5px solid " + theme.palette.primary.light ,

    },
    listItemText: {
        textDecoration: "none"
    },
    Tabroot: {
        flexGrow: 1,
        maxWidth: 320,
        overflow:"hidden"
    },
    ProfileDrawer:{
        backgroundColor: "#424242",
        color: "#fff",
        width:320,
        zIndex:3000000,
    },
    DataTable: {
        backgroundColor:'#ffffff',
        display:"none"
    },
    addButton: {
        textDecoration: "none",
    },

    appBarDialog: {
        position: 'relative',
        zIndex: 99999999,
        backgroundColor:"#22b2b8"
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    FileManagerInput: {
        display:"none"
    },
    ButtonProgress: {
        fontSize:20
    },
    FMThumbnail: {
        width:50,
        borderRadius: '10%',
        cursor:'pointer'

    },
    sticky: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        position:"sticky",
        top: 100,
    },
    galleryRoot: {
        maxWidth: 150,
        margin:10,
        display:"inline"
    },
    litleUrlShower:{
        fontSize: 11,
        color:theme.palette.primary.light,
        fontWeight:700,
        cursor: "pointer",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
    },
    Stepperroot: {
        width: '100%',
        backgroundColor:"#fff",
        padding: theme.spacing(2),
    },
    Stepperbutton: {
        marginRight: theme.spacing(1),
    },
    backStepperbutton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    StepperInstructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    GridAsaPaper:{
        backgroundColor: "#fff",
        borderRadius: 10,
        marginTop:15,
        boxShadow:"0px 0px 10px rgba(82, 82, 82, 0.21)",
        padding: 10
    },
    GridAsaPaperTransParent:{
        padding: 10
    },
    MenuItemDef: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    BreadLink: {
        display: 'flex',
    },
    BreadIcon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    breadCrumb: {
        marginTop: 12,
    },
    LoadingButtonWrapper: {
        position: 'relative',
        margin: theme.spacing(2,0),
        textAlign:'center'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: "#ff9f00",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    LoadingButtonRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    link:{
        color: theme.palette.primary.main
    },
    loginPageLogo:{
        maxHeight:70,
        margin:15
    },
    loginPageContainer:{
        height:"100vh",
        backgroundColor:"#f7f7f7"
    },
    LoginFormGrid:{
        borderRadius:10,
        backgroundColor:'#ffffff',
        padding:"30px !important"
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    mainLogo:{
        maxHeight:50,
        paddingRight:10
    },
    NotificationsHolder:{
        padding:10,
        height:'88%',
        overflow:"auto"
    },
    NotificationAlert: {
        marginTop:10,
        position:"relative"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    ListItemWidth:{
        minWidth: "30px !important"
    },
    activeProfileItem:{
        color: "#2b2b2b",
        fontSize: "10px !important",
        backgroundColor:"#f9f9f9",
        borderLeft:"5px solid " + theme.palette.primary.light ,
        '&:hover': {
            color: theme.palette.text.primary,
        }
    },
    CartBox:{
        backgroundColor: "#f9f9f9",
        borderRadius:4,
        paddingTop:"18px !important"
    },
    CartBoxWithoutPadding: {
        backgroundColor: "#f9f9f9",
        borderRadius:4,
        paddingTop:"0 !important",
        marginTop:'10px !important',
    },
    Step:{
        cursor:"pointer",
    },
    filterContainer:{
        backgroundColor:"#f6f6f6",
        padding:15,
        borderRadius:4,
        position:'relative'
    },
    primaryLink:{
        color:theme.palette.primary.main
    },
    ProviderManagerRoot: {
        flexGrow: 1,
        width: '100%',
    },
    dashboardTitle : {
        borderBottom: "1px solid #bbb",
        paddingTop:15,
        paddingBottom:5,
        fontWeight: 700 ,
        display: "block",
        fontSize:20
    },
    ListRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflowY:"auto",
        height: '100%',
        maxHeight: 450,
        marginBottom: 10
    },
    PassVisibilityIcon: {
        position: "absolute !important",
        right: 10,
        top: "50%",
        transform: "translateY(-50%)"
    },
    GridAsaPaperBaby:{
        borderRadius: 5,
        paddingBottom:20,
        paddingTop:10,
    },
    AuthLogo:{
        position:'absolute',
        top:20,
        left:50,
        maxWidth:150
    },
    textField: {
        width: '100%',
    },
    containerFull: {
        backgroundColor:theme.palette.background.paper
    },
    loginContainerText: {
        backgroundColor:theme.palette.background.grey,
        textAlign: 'center',
        minHeight: '100vh',
    },
    loginContainerForm:{
        backgroundColor:theme.palette.background.grey,
        textAlign: 'center',
        minHeight: '100vh',
        overflow: 'hidden',
        position:'relative',
        zIndex: 0,
        '&::before': {
            background: '#fff',
            display: 'block',
            left:0,
            height: '3000px',
            position: 'absolute',
            width: '3000px',
            zIndex: -1,
            content: " '' ",
            [theme.breakpoints.up('lg')]: {
                borderRadius: '100%',
            },

        },
    },
    headLine: {
        fontWeight:"700 !important",
        fontSize:'45px !important',
        margin: '0 80px !important',
        color:theme.palette.primary.main
    },
    AuthCard: {
        width: '100%',
        padding: "0 32px",
        overflow: 'hidden',
    },
    AuthGrid: {
        textAlign: 'center',
    },
    AuthButton: {
        width: '100%',
        height: 44,
    },
    socialLogin: {
        maxWidth: 27,
    },
    pointer: {
        cursor: 'pointer',
    },
    ListRootWithoutHeight: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflowY:"auto",
        height: '100%',
        maxHeight: 300,
        marginBottom: 10
    },
    GridAsaPaperLive:{
        backgroundColor: "#fff",
        borderRadius: 7,
        paddingBottom:15,
        boxShadow:"0px 0px 3px rgba(129, 128, 128, 0.15)",

    },
    AnalyticsResult: {
        fontSize: 20,
        fontWeight: 700,
        color:" #3e3c3c",
    },
    SuccessAnalytic:{
        float:"right",
        backgroundColor:"rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
        color: "#fff"

    },
    FlatAnalytic:{
        color:"#2b2b2b",
        backgroundColor:"rgba(233, 233, 233, 0.47)",
        padding:"2px 8px",
        borderRadius: 5,
        float:"right"
    },
    ErrorAnalytic:{
        backgroundColor:"rgba(255, 255, 255, 0.47)",
        color: "#ab2a2a",
        padding:"2px 8px",
        borderRadius: 5,
        float:"right"
    },
    ErrorAnalyticLive:{
        backgroundColor:"#ab2a2a",
        color: "#ab2a2a",
        padding:"2px 8px",
        borderRadius: "50%",
    },
    styledImg: {
        height: 430,
        display: 'inline-block',
        [theme.breakpoints.up('lg')]: {
            paddingRight: 40,
        },
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
    },
    AuthLogoMobile:{
        zIndex:10,
        maxWidth:150,
    },

    subLine: {
        fontSize:'20px !important',
        margin: '30px 50px !important',
        color:"#fff",
        textAlign:"left"
    },
    notiBtn: {
        justifyContent: 'flex-start',
        width: '100%',
        height: 56,
        fontSize: 18,
        borderRadius: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        color: theme.palette.grey[800],
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            width: 'auto',
            color: theme.palette.grey[400],
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
        },
    },
    notiIcon: {
        fontSize: 22,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('xl')]: {
            fontSize: 30,
        },
    },
    walletBtn:{
        borderRadius:0,
        color:"rgba(0, 0, 0, 0.54) !important",
        paddingRight:10,
        paddingLeft:10,
        justifyContent: 'flex-end',
        width: '100%',
        height: 56,
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
    },
    NotifcationText:{
        color: "#2f2f2f",
        fontWeight:700,
        fontSize:10,
        marginBottom:0,
        textAlign:'right',
        position: "absolute",
        right: 10,
        top: 0,
    },
    authFormHolder:{
        background: "#fff",
        padding: "20px 40px 40px !important",
        boxShadow: "0 0 10px #ddd",
        borderRadius: 10,
        position:"relative !important",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px 20px !important",

        }
    }
});