import React from 'react';
import List from '@material-ui/core/List';
import routesConfig from '../../../../../config/routes/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import {connect} from "trim-redux";

const Navigation = (props) => {

    const {type,dashboard,user} = props;
    const {role} = user;
    const no_link = ( dashboard === "expert" && user.role.name === "user" ) || ( dashboard === "user" && user.role.name === "expert" );

    return (
        <List className={!!no_link ? "no-link" : null}>
            {routesConfig.map((item) => (
                <>
                    {

                        !item.roles || item.roles.indexOf(role.name) !==  -1 ?
                            !item.permission || role.permission[item.permission] ?
                                !item.for || item.for.indexOf(type) !==  -1 ?
                                <React.Fragment key={item.id}>
                                    {item.type === 'group' && <VerticalNavGroup item={item} level={0} role={role}/>}

                                    {item.type === 'collapse' && (
                                        <VerticalCollapse item={item} level={0} />
                                    )}

                                    {item.type === 'item' && <VerticalItem item={item} level={0} />}
                                </React.Fragment>
                                : null : null : null
                    }
                </>

            ))}
        </List>
    );
};
const mstp = state => ({
    user: state.user,
    dashboard: state.dashboard,
});
export default connect(mstp)(Navigation);
