import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import {TableCount, TablePage} from "../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../config/Translate";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import {updateRedux} from "../../../config/Security";
class Contents extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                title: Translate.thumbnail,
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            { title: Translate.contentTitle, field: 'title',filterPlaceholder: Translate.search},
            { title: Translate.author, field: 'author',filterPlaceholder: Translate.search,hidden: this.props.width === "sm" || this.props.width === "xs"},
            { title: Translate.category, field: 'categories',filterPlaceholder: Translate.search,hidden: this.props.width === "sm" || this.props.width === "xs"},
            { title: Translate.contentConfirmationStatus,
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: Translate.choose,
                hidden: this.props.width === "sm" || this.props.width === "xs",
                lookup: {
                    1 :
                            <Tooltip title={Translate.yourContentIsConfirmed} arrow>
                                <IconButton>
                                    <StarsRoundedIcon style={{color: "#38bf84"}}/>
                                </IconButton>
                            </Tooltip>
                    , 0:
                        <Tooltip title={Translate.yourContentIsNotConfirmed} arrow>
                            <IconButton>
                                <StarBorderRoundedIcon color="textSecondary"/>
                            </IconButton>
                        </Tooltip>
                },
            },
            { title: Translate.publishStatus,
                hidden: this.props.width === "sm" || this.props.width === "xs",
                editable: 'never',
                field: 'publish',
                filterPlaceholder: Translate.choose,
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{Translate.published}</Typography>
                    , 0:
                        <Typography color="textSecondary">{Translate.draft}</Typography>
                },
            },
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate',hidden: this.props.width === "sm" || this.props.width === "xs",},

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    componentDidMount(){
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/content/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.deleteContent, {
                        variant: 'success',
                    });
                }
                updateRedux()
                return true
            })
            .catch(error => {
                this.loadData();
                this.props.enqueueSnackbar(Translate.deleteContentFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    handleRowClick(data){
        this.props.history.push(`/contents/make/${data.id}`);
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.makeContent}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.contents}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.contents}/>
                            }>
                            {
                                this.state.loading ? <AppCardLoaderTable/> :
                                    <Container maxWidth="xl">
                                        <MaterialTable
                                            tableRef={this.tableRef}
                                            icons={tableIcons}
                                            onRowClick={((evt, selectedRow) =>  this.handleRowClick(selectedRow))}
                                            title={
                                            <Link to='/contents/make'>
                                                {
                                                    this.props.width !== "xs" ?
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<AddBox />}
                                                        >
                                                            {Translate.makeContent}
                                                        </Button> :
                                                        <Tooltip title={Translate.makeContent}>
                                                        <IconButton color="primary">
                                                            <AddBox />
                                                        </IconButton>
                                                        </Tooltip>
                                                }
                                            </Link>
                                            }

                                            localization={DataTableLocalization}
                                            columns={this.state.columns}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    axios.get(`${defaultVariabls.url}/contents/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                                        .then(response => {
                                                            let data = [];
                                                            // eslint-disable-next-line
                                                            response.data.data.map((row) => {
                                                                let categories = '';
                                                                // eslint-disable-next-line
                                                                row.categories.map(item => {
                                                                    categories += item.name;
                                                                    categories += " , "
                                                                })
                                                                categories = categories.substring(0, categories.length - 2)
                                                                data.push({
                                                                    thumbnail: `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                                                    title: row.title,
                                                                    id: row.id,
                                                                    author: row.author.first_name + " " + row.author.last_name,
                                                                    publish: row.publish,
                                                                    confirmed: row.confirmed,
                                                                    categories: categories,
                                                                    lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                                })
                                                            });
                                                            resolve({
                                                                data: data,
                                                                page: response.data.current_page - 1,
                                                                totalCount: response.data.total,
                                                            })
                                                        }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                })
                                            }
                                            options={{
                                                pageSize: TablePage,
                                                pageSizeOptions: TableCount,
                                            }}
                                            editable={this.props.width === "sm" || this.props.width === "xs" ? null :{
                                                onRowDelete: oldData =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            this.onDelete(oldData.id);
                                                            this.setState(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                        }, 600);
                                                    }),
                                            }}
                                            detailPanel={this.props.width !== "sm" && this.props.width !== "xs" ? null : [{
                                                render: rowData => {
                                                    return (
                                                        <Grid container spacing={1} className="my-10" justify="center" alignItems="center">
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.author}: <strong>{rowData.author}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.category}: <strong>{rowData.categories}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.contentConfirmationStatus}: <strong>{rowData.confirmed === 1 ? Translate.yourContentIsConfirmed : Translate.yourContentIsNotConfirmed}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.publishStatus}: <strong>{rowData.publish === 1 ? Translate.published : Translate.draft}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.updateTime}: <strong>{rowData.lastUpdate}</strong></Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                },
                                            }
                                            ]}
                                        />
                                    </Container>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Contents)))));