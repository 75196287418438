import React from 'react';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {AlertSkeleton} from "../../../../components/Skeletons/AlertSkeleton";
import InfiniteScroll from "react-infinite-scroller";
import {Link, withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button/Button";
import moment from "jalali-moment";
import Grid from "@material-ui/core/Grid/Grid";
import ChooseDate from "../../../../components/elements/ChooseDate";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {connect} from "trim-redux";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Translate} from "../../../../../config/Translate";

class UsersLog extends React.Component {
    classes = this.props.classes;
    state = {
        logs: [],
        nextPage:1,
        hasMore: true,
        loading: true,
        check: false,
        page: this.props.page,
        search: !!this.props.search ? this.props.search : '',
        beginDate: '',
        endDate: '',
    }
    componentDidUpdate(newProps){
        if(newProps.search !== this.props.search){
            this.setState({
                search:this.props.search
            })
        }
    }
    async handleLoadMore(){
        let loginToken = localStorage.getItem('ACT');
        let {beginDate,endDate,search} = this.state;
        if(loginToken !== null) {
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('beginDate', beginDate);
            data.append('endDate', endDate);
            data.append('search', search);
            await axios.post(`${defaultVariabls.url}/activity_log/view?page=${this.state.nextPage}`,data, config)
                .then(response => {
                    const {current_page, last_page, data} = response.data;
                    this.setState(prevState => ({
                        logs: [...prevState.logs, ...data],
                        hasMore: current_page  !== last_page,
                        nextPage: current_page + 1,
                        check: true,
                        loading: false,
                    }));
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }
    async handleDate(dateFrom,dateTo){
        await this.setState({
            dateFrom,
            dateTo
        })
    }
    handleBeginDate(beginDate){
        this.setState({
            beginDate,
        })

    }
    async handleEndDate(endDate){
        await this.setState({
            endDate
        })
        await this.setState({
            ChangeLoader:true
        })
    }
    async refresh(){
        let {search} = this.state;
        await this.props.history.push(`/manage/activities/${search}`)
        await this.setState({
            logs: [],
            loading:true,
            nextPage:1,
            check: false,
            hasMore: true,
        })
    }
    removeFilter(){
        window.open(`/manage/activities/`,'_self')
    }
    async openUser(name,family){
        let {page} = this.state;
        if(page){
            await  this.setState({
                search:name+ " "+ family
            })
            await this.refresh();

        }else{
            await this.props.history.push(`/manage/activities/${name} ${family}`)
        }
    }
    handleSearch(search){
        this.setState({
            search
        })

    }
    render(){
        let {check,hasMore,logs,page,search,loading,beginDate,endDate} = this.state;
        let count = this.props.count;
        let skeleton = [];
        for(let i = 0 ; i < count && i <= 4 ; i++){
            skeleton.push(<AlertSkeleton key={i}/>)
        }
        return (
            <Grid container spacing={2} alignItems="center" justify="center">
                {
                    page ?
                        <Grid item xs={12}>
                            <Grid container direction="row"  alignItems="center" className={this.classes.filterContainer}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center" direction="row" >
                                        <Grid item xs={6} md={3}>
                                            <ChooseDate handleDate={this.handleBeginDate.bind(this)} label={Translate.chooseBeginDate}/>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <ChooseDate handleDate={this.handleEndDate.bind(this)} label={Translate.chooseEndDate}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                placeholder={Translate.search}
                                                name="search"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchRoundedIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label={Translate.search}
                                                type="text"
                                                value={search}
                                                onChange={(e) => this.handleSearch(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={this.refresh.bind(this)}
                                                color="primary"
                                                variant="contained"
                                            >
                                                {Translate.doFilter}
                                            </Button>
                                            {
                                                !!search || !!beginDate || !!endDate ?
                                                    <Button
                                                        style={{marginLeft:5,marginRight:10}}
                                                        size="small"
                                                        color="secondary"
                                                        onClick={this.removeFilter.bind(this)}
                                                    >
                                                        {Translate.removeFilter}
                                                    </Button>
                                                    : null
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid> : null
                }

                <Grid item xs={12}>
                    {
                        loading ? <LinearProgress  style={{marginBottom:20}}/> : null
                    }
                    <List className={page ? null : this.classes.ListRootWithoutHeight}>
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={this.handleLoadMore.bind(this)}
                            hasMore={hasMore}
                            loader={skeleton}
                            useWindow={false}
                        >
                            {
                                logs.map((log,index) => (
                                    <React.Fragment key={index}>
                                        <>
                                            {
                                                !!log.causer && !!log.subject ?
                                                    <ListItem alignItems="flex-start">
                                                        {
                                                            !!log.causer && !!log.subject ?
                                                                <ListItemAvatar>
                                                                    <IconButton
                                                                        onClick={() => this.openUser(log.causer.name,log.causer.family)}
                                                                    >
                                                                        <Avatar
                                                                            alt={log.causer.name}
                                                                            src={`${defaultVariabls.uploads}/FileManager/${log.causer.avatar.name}_small.${log.causer.avatar.extension}`}
                                                                        />
                                                                    </IconButton>
                                                                </ListItemAvatar>
                                                                : null
                                                        }
                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    {
                                                                        !!log.causer && !!log.subject ?
                                                                            <Typography variant="body2">
                                                                                <Button
                                                                                    onClick={() => this.openUser(log.causer.first_name,log.causer.last_name)}
                                                                                    color="primary">
                                                                                    {log.causer.first_name} {log.causer.last_name}
                                                                                </Button>
                                                                                ({log.causer.role.name})
                                                                                <Typography
                                                                                    color="secondary"
                                                                                    style={{float:"left"}}
                                                                                >
                                                                                    ({
                                                                                    (moment(log.updated_at).locale('fa').fromNow())
                                                                                })
                                                                                </Typography>
                                                                            </Typography>
                                                                            : null
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        className={this.classes.inline}
                                                                        color="textPrimary"
                                                                    >
                                                                        <strong>{log.log_name} / {log.description}</strong>
                                                                        {
                                                                            log.log_name === "user" ?
                                                                                <Link target="_blank" to={`/manage/activities/${log.causer.first_name} ${log.causer.last_name}`}>
                                                                                    <Button color="primary">
                                                                                        {log.subject.name ? log.subject.name+" "+log.subject.family  : "id: "+log.subject.id}
                                                                                    </Button>
                                                                                </Link>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        log.subject ?
                                                                                            <Link target="_blank" to={`/edit/${log.log_name}/${log.subject.id}`}>
                                                                                                <Button color="primary">
                                                                                                    {log.subject.name ? log.subject.name : "id: "+log.subject.id}
                                                                                                </Button>
                                                                                            </Link>
                                                                                            : null
                                                                                    }

                                                                                </>
                                                                        }
                                                                    </Typography>
                                                                    {
                                                                        !!log.properties.old ?
                                                                            Object.keys(log.properties.attributes).map((item,index)=> (
                                                                                <Typography>
                                                                                    <strong>{Object.keys(log.properties.old)[index]}</strong>
                                                                                    {": "}
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                    >
                                                                                        {Object.values(log.properties.old)[index]}
                                                                                    </Typography>
                                                                                    <strong>{" => "}</strong>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="textPrimary"
                                                                                    >
                                                                                        {Object.values(log.properties.attributes)[index]}
                                                                                    </Typography>
                                                                                </Typography>
                                                                            )) : null
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem> : null
                                            }
                                            <Divider variant="inset" component="li" />
                                        </>
                                    </React.Fragment>
                                ))
                            }
                        </InfiniteScroll>
                    </List>
                    {
                        check && logs.length === 0 ?
                            <Typography align="center" color="textSecondary" variant="body2">{Translate.noLogFound}</Typography> : null
                    }
                </Grid>
            </Grid>

        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
});
export default withStyles(Styles)(connect(mstp)(withRouter(UsersLog)));