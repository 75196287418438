import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {connect, setStore} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import {Link} from 'react-router-dom';
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {withSnackbar} from "notistack";
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import OpportunityCounter from "./OpportunityCounter";
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import Button from "@material-ui/core/Button/Button";
const useStyles = makeStyles((theme) => {
    return {
        crUserInfo: {
            background: 'linear-gradient(136deg, rgb(91 162 230) 0%, #337ab7 100%) !important',
            paddingTop: 9,
            paddingBottom: 9,
            minHeight: 56,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                paddingTop: 10,
                paddingBottom: 10,
                minHeight: 70,
            },
        },
        profilePic: {
            fontSize: 24,
        },
        userInfo: {
            width: 'calc(100% - 75px)',
        },
        userName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 16,
            color: '#313541',
        },
        designation: {
            marginTop: -2,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.palette.text.secondary,
        },
        pointer: {
            cursor: 'pointer',
        },
        link:{
            cursor:"pointer"
        }
    };
});

const UserInfo = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
        window.location.replace(`${defaultVariabls.website}/auth/logout`);

        props.enqueueSnackbar(Translate.youLoggedOff, {
            variant: 'info',
        });
    };
    const classes = useStyles();
    const user = props.user;
    return (
        <Box
            px={{xs: 2, xl: 4}}
            className={clsx(classes.crUserInfo, 'cr-user-info')}>
            {
              !!user ?
                  <Grid container spacing={2} alignItems="center" justify="center" style={{position:"relative"}}>
                          <IconButton style={{position:"absolute",top:0,left:0}}>
                              <MoreVertRoundedIcon style={{color:"#fff"}}  onClick={handleClick} />
                          </IconButton>
                      <IconButton style={{position:"absolute",top:0,right:0}} component="a" href={defaultVariabls.website}>
                              <HomeRoundedIcon style={{color:"#fff"}}  />
                          </IconButton>
                          <Menu
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}>
                              <MenuItem onClick={handleClose}><Link to="/account/details"><IconButton size="small"><AccountCircleRoundedIcon fontSize="small"/></IconButton> {Translate.myAccount}</Link></MenuItem>
                              <MenuItem onClick={handleLogout} ><IconButton size="small"><PowerSettingsNewRoundedIcon fontSize="small"/></IconButton> {Translate.logOut}</MenuItem>
                          </Menu>
                      <Grid item xs={12} style={{textAlign:'center'}}>
                          <Link to="/account/details">
                              {
                                  !!user ?
                                      <Avatar style={{width:65,height:65,transform:"translateX(50%)",right:"50%"}} className={classes.profilePic} src={`${defaultVariabls.uploads}/FileManager/${user.avatar.name}_small.${user.avatar.extension}`}  alt={user.name} />
                                      : null
                              }
                          </Link>
                      </Grid>
                      <Grid item xs={12} className="text-center">
                          {
                              user.role.name !== "user" ?
                                  <>
                                      {
                                          !!user.completed ?
                                              <Button
                                                  style={{color:"#fff",width:'100%',display:"inline-block"}}
                                                  component="a"
                                                  href={`${defaultVariabls.website}/${user.name}`}
                                                  startIcon={<LaunchRoundedIcon fontSize="small"/>}
                                              >
                                                  {user.first_name} {user.last_name}
                                              </Button> :
                                              <Button
                                                  style={{color:"#fff",width:'100%',display:"inline-block"}}
                                                  component={Link}
                                                  to="/resume/details"
                                                  startIcon={<LaunchRoundedIcon fontSize="small"/>}
                                              >
                                                  {Translate.createProfile}
                                              </Button>
                                      }
                                  </> :
                                  <Button
                                      style={{color:"#fff",width:'100%',display:"inline-block"}}
                                      component={Link}
                                      to="/details"
                                      startIcon={<LaunchRoundedIcon fontSize="small"/>}
                                  >
                                      {user.first_name} {user.last_name}
                                  </Button>
                          }

                          <OpportunityCounter/>
                      </Grid>
                  </Grid>
                  : null
            }
        </Box>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    cart: state.cart
});
export default withSnackbar(connect(mstp)(UserInfo));
