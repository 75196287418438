import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import AdminDashboard from "./dashboards/admin/AdminDashboard";
import ExpertDashboard from "./dashboards/expert/ExpertDashboard";
import CustomerDashboard from "./dashboards/customer/CustomerDashboard";

class Dashboard extends React.Component {
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);

    render(){
        let {dashboard,admin} = this.props;
        return (
            <React.Fragment>
                {
                    !!admin ? <AdminDashboard/> : dashboard === "expert" ? <ExpertDashboard/> : <CustomerDashboard/>
                }
            </React.Fragment>
        );
    }
}
const mstp = state => ({
    dashboard: state.dashboard,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Dashboard))));