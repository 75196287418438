import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {setStore} from "trim-redux";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)",

    })
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#fbfbfb' : null,
    borderRadius:10
});

class SkillsOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: null,
            loading: true,
            dragLoading: false,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/skills/quickViewForChooser`, config)
                .then(response => {
                    this.setState({
                        skills:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        await  this.setState({
            dragLoading:true
        })

        let source = this.state.skills[result.source.index];
        let destination = this.state.skills[result.destination.index];
        const skills = reorder(
            this.state.skills,
            result.source.index,
            result.destination.index
        );
        this.setState({
            skills
        });
        let loginToken = localStorage.getItem('ACT');
        let data = new FormData();
        data.append('source', source.id);
        data.append('destination', destination.id);
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.post(`${defaultVariabls.url}/skills/changeOrder`,data, config)
                .then(response => {
                    this.setState({
                        dragLoading:false
                    });
                }).catch(error => {
                console.log(error.response)
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }

    }
    classes = this.props.classes;
    render(){
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                {
                                    this.state.dragLoading ?
                                        <LinearProgress />
                                        : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <RootRef rootRef={provided.innerRef}>
                                                <List style={getListStyle(snapshot.isDraggingOver)}>
                                                    {this.state.skills.map((item, index) => (
                                                        <Draggable key={"item-"+item.id} draggableId={"item-"+item.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ListItem
                                                                    ContainerComponent="li"
                                                                    ContainerProps={{ ref: provided.innerRef }}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <ListItemIcon>
                                                                        <DragIndicatorRoundedIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={item.name}
                                                                        secondary={item.description}
                                                                    />
                                                                    <ListItemSecondaryAction>

                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </List>
                                            </RootRef>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </Grid>
                }
            </>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(SkillsOrder)));