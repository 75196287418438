import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import MakeProject from "./MakeProjectPlan";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseIcon from '@material-ui/icons/Close';
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';

class EditProjectPlanDialog extends React.Component {
    state = {
        open:false,
    };
    handleDone(){
        this.setState({
            open:false,
        })
        this.props.reload();
    }
    render(){
        let {open} = this.state;
        return(
            <>
                <IconButton size="small" onClick={() => this.setState({open: !this.state.open})}>
                    <EditRoundedIcon fontSize="small"/>
                </IconButton>

                <Dialog fullWidth  maxWidth="md" open={open} onClose={() => this.setState({open: !this.state.open})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <IconButton>
                            <LocalOfferRoundedIcon color="primary"/>
                        </IconButton>
                        {Translate.editProject}</DialogTitle>
                        <IconButton aria-label="close" className="modal-close-btn"  onClick={() => this.setState({open: !this.state.open})}>
                            <CloseIcon />
                        </IconButton>
                    <DialogContent>
                        <MakeProject id={this.props.id} handleDone={this.handleDone.bind(this)}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(EditProjectPlanDialog)));