import React from 'react';
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import {withSnackbar} from "notistack";
import AppCard from "../../../../../components/layouts/components/AppCard";
import {Translate} from "../../../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button/Button";

const CustomerServices = (props) => {
    return (
        <>
            <Grid container spacing={2}  alignItems="center" justify="center">
                <Grid item xs={12}>
                    <AppCard
                        component={Link}
                        to="/shop"
                        className="customer-services-holder normal"
                    >
                        <Grid container spacing={2} alignItems="center" style={{height:250}}>
                            <Grid item xs={8}>
                                <Typography variant="h3">{Translate.buyOpportunity} </Typography>
                                <Typography paragraph>{Translate.buyOpportunityDetails} </Typography>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    component={Link}
                                    to="/shop"
                                >
                                    {Translate.shop}
                                </Button>
                            </Grid>
                            <Grid item xs={4} className="text-center">
                                <img  src="assets/images/call.png" alt="call" loading="lazy"/>
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>
            </Grid>
        </>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard
});
export default withSnackbar(connect(mstp)(CustomerServices));
