import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Skills from "./Skills";
import withWidth from "@material-ui/core/withWidth/withWidth";

class SkillsPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={12}>
                        <Skills/>
                    </Grid>
                </Grid>
        );
    }
}
export default withWidth()(withSnackbar(withStyles(Styles)(SkillsPage)));
