import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import AppAnimate from "../../../../components/AppAnimate";
import {Translate} from "../../../../../config/Translate";
import ExpertInfoDashboardWidget from "./components/ExpertInfoDashboardWidget";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import UserInformations from "./components/UserInformations";
import PleaseConfirmYourInformation from "../../components/PleaseConfirmYourInformation";
import VisitsChart from "./components/VisitsChart";
import AppCard from "../../../../components/layouts/components/AppCard";
import ExpertsGuidLine from "./components/ExpertsGuidLine";
import BuyOpportunity from "./components/BuyOpportunity";
import InterestedArticles from "../../components/InterestedArticles";

class ExpertDashboard extends React.Component {
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.dashboard}>
                <Box pt={{xl: 4}} clone>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} md={11}>
                            <PleaseConfirmYourInformation/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <ExpertsGuidLine/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <ExpertInfoDashboardWidget/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <BuyOpportunity/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <UserInformations/>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <AppCard
                                title={Translate.visitsChart}
                            >
                                <div style={{height:300,direction:"ltr"}}>
                                    <VisitsChart/>
                                </div>
                            </AppCard>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <InterestedArticles/>
                        </Grid>
                    </Grid>
                </Box>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    dashboard: state.dashboard,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ExpertDashboard))));