import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../config/Translate";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {currencySymbol} from "../../../config/values";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import PaymentIcon from '@material-ui/icons/Payment';
import {defaultVariabls} from "../../../config";
import Title from "../../components/elements/Title";
import Typography from "@material-ui/core/Typography/Typography";
class AddFile extends React.Component {
    state = {
        open:false,
        error:'',
        amount:!!this.props.amount ? this.props.amount > 0 ? this.props.amount : 100000  : 100000,
        amountR:!!this.props.amount ? this.props.amount > 0 ? this.props.amount : 100000  : 100000,
    };
    handleChange(e){
        let target = e.target;
        let amountR = target.value.replace(/,/ig, '');
        let error = "";
        this.setState({
            error,
            amountR,
            amount: target.value
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        if(this.state.amountR > 1000){
            window.open(`${defaultVariabls.website}/wallet/charge/${this.state.amountR}${!!this.props.redirect ? "?r="+this.props.redirect : ""}`,'_self')
        }else{
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });

            this.setState({
                error: Translate.wrongPrice,
                SubmitLoading:false,
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {error,open,amountR} = this.state;
        return(
            <>
                {
                    this.props.width !== "xs" || !!this.props.fullwidth ?
                        <Button
                            variant={!!this.props.variant ? this.props.variant :"contained"}
                            color="primary"
                            fullWidth={this.props.fullwidth}
                            startIcon={<AddBox/>}
                            onClick={() => this.setState({open: !this.state.open})}
                        >
                            {Translate.depositMoney}
                        </Button>
                        :
                        <IconButton color="primary" onClick={() => this.setState({open: !this.state.open})}>
                            <AddBox/>
                        </IconButton>
                }
                <Dialog fullWidth maxWidth="sm" open={open} onClose={() => this.setState({open: !this.state.open})} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <DialogContentText className="text-center">
                            <img width={60} src="../../assets/images/money.png" alt="money" loading="lazy"/>
                            <Title>
                                {Translate.depositMoney}
                            </Title>
                            <Typography align='center'>
                            {Translate.depositMoneyDescription}
                            </Typography>
                        </DialogContentText>
                        <CurrencyTextField
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            currencySymbol={currencySymbol}
                            decimalPlaces={0}
                            value={amountR}
                            id="amount"
                            label={Translate.amount}
                            name="amount"
                            onChange={this.handleChange.bind(this)}
                            error = {!!error}
                            helperText={error}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button style={{margin:"0 auto 10px"}} onClick={this.handleSubmit.bind(this)} variant={"contained"} color="primary" startIcon={<PaymentIcon/>}>
                            {Translate.purchase}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(AddFile)));