import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import OrderSkillsButton from "./components/OrderSkillsButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";

class Skills extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            { title: Translate.skillName, field: 'name',filterPlaceholder: Translate.search},
            { title: Translate.confirmationStatus,
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: Translate.choose,
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{Translate.confirmed}</Typography>
                    , 0:
                        <Typography color="textSecondary">{Translate.unConfirmed}</Typography>
                },
            },
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate'},

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/skill/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.deleteData, {
                        variant: 'success',
                    });
                }
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.loadData();
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    handleRowClick(data){
        this.props.history.push(`/edit/skill/${data.id}`);
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                onRowClick={((evt, selectedRow) =>  this.handleRowClick(selectedRow))}
                                title={
                                    <>
                                    <OrderSkillsButton reload={this.loadData.bind(this)}/>
                                    <Link to='/create/skill'>
                                        {
                                            this.props.width !== "xs" ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AddBox />}
                                                >
                                                    {Translate.makeSkill}
                                                </Button> :
                                                <IconButton color="primary">
                                                    <AddBox />
                                                </IconButton>
                                        }

                                    </Link>
                                    </>
                                }

                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/skills/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        name: row.name,
                                                        id: row.id,
                                                        owner: row.owner.first_name + " " + row.owner.last_name,
                                                        confirmed: row.confirmed,
                                                        lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Skills)))));