import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../../components/elements/FieldCounter";
import Typography from "@material-ui/core/Typography/Typography";
import {setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import LinkIcon from '@material-ui/icons/Link';
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";

class MakeCategory extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            description:"",
            slug:"",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: 1,
        errors:{},
        categories:[],
        loading: true,
        SubmitLoading: false,
        confirmed: true,
        interest: false,
        specialty: false,
        parentId:null,
        defaultCategory:null,
        id:this.props.match.params.id,
        editMode:false
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }
        await this.loadParents()
    }
    async loadParents(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/categories/quickViewForChooser`, config)
                .then(response => {
                    let categories = response.data.filter((item) => {
                        return item.id !== parseInt(this.id)
                    });
                    categories.map((item,index) =>{
                        return item.id === this.state.parentId ? this.setState({
                            defaultCategory: index,
                        }) : null
                    })
                    this.setState({
                        categories: response.data,
                        loading:false,
                        SubmitLoading:false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false,
                        SubmitLoading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false,
                SubmitLoading:false
            });
            this.setState({
                loading:false,
                SubmitLoading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/category/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            slug: response.data.slug,
                            description: !!response.data.description ? response.data.description : '',
                        },
                        thumbnail: response.data.thumbnail.id,
                        parentId: response.data.parent_id,
                        confirmed: response.data.confirmed,
                        interest: response.data.interest,
                        specialty: response.data.specialty,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,thumbnail,content,id,editMode,confirmed,interest,specialty,parentId} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('slug', fields.slug);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('thumbnail', thumbnail);
        data.append('parent_id', parentId);
        data.append('confirmed',  confirmed ? 1 : 0);
        data.append('interest',  interest ? 1 : 0);
        data.append('specialty',  specialty ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/category/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.dataEdited : Translate.dataAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/manage-categories`);
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  Translate.dataEditingFailed : Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleNameToUrl(e){
        let fields = this.state.fields;
        let target = e.target;
        if(target.name === "name" && fields.slug === ''){
            fields["slug"] = target.value.split(' ').join('_');
        }
        this.setState({fields});
    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeinterest(){
        this.setState({
            interest: !this.state.interest,
        })
    }
    handleChangeSpecialty(){
        this.setState({
            specialty: !this.state.specialty,
        })
    }
    handleParent(value){
        if(value === null){
            this.setState({
                parentId:''
            })
        }else{
            this.setState({
                parentId:value.id
            })
        }
    }
    render(){
        let {fields, errors,SubmitLoading,thumbnail,content,editMode,confirmed,interest,specialty,categories} = this.state;
        let CategoryUrl = `${defaultVariabls.website}/articles/${fields.slug}`;

        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? Translate.editCategory : Translate.makeCategory}>
            {

                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={8} lg={9}>
                                <AppCard
                                    title={editMode ? Translate.editCategory : Translate.makeCategory}
                                    action={
                                        <BreadCrumbs singleLevel firstText={editMode ? Translate.editCategory : Translate.makeCategory}/>
                                    }>
                                    {
                                        this.state.loading ? <AppCardLoaderFields/> :
                                            <Grid container spacing={2} justify="center" alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <Alert
                                                        severity="info"
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={confirmed}
                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                        value={confirmed}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                            />
                                                        }
                                                    >
                                                        {Translate.confirmationStatus}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Alert
                                                        severity={!!interest ? "success" : "warning"}
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={interest}
                                                                        onChange={this.handleChangeinterest.bind(this)}
                                                                        value={interest}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={interest ? Translate.yes : Translate.no}
                                                            />
                                                        }
                                                    >
                                                        {Translate.isInterest}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Alert
                                                        severity={!!specialty ? "success" : "warning"}
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={specialty}
                                                                        onChange={this.handleChangeSpecialty.bind(this)}
                                                                        value={specialty}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={specialty ? Translate.yes : Translate.no}
                                                            />
                                                        }
                                                    >
                                                        {Translate.isSpecialty}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="name"
                                                        onBlur={this.handleNameToUrl.bind(this)}
                                                        label={Translate.categoryName}
                                                        type="text"
                                                        id="name"
                                                        value={fields.name}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['name']}
                                                        helperText={errors['name']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Autocomplete
                                                        options={categories}
                                                        defaultValue={categories[this.state.defaultCategory]}
                                                        onChange={(event, value) => this.handleParent(value)}
                                                        getOptionLabel={option => option.name}
                                                        renderInput={params => (
                                                            <TextField
                                                                margin="normal"
                                                                {...params}
                                                                label={Translate.categoryParentName}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <RichEditor ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        multiline
                                                        fullWidth
                                                        name="description"
                                                        label={Translate.seoDescription}
                                                        placeholder={Translate.seoDescription}
                                                        type="text"
                                                        id="description"
                                                        rows="4"
                                                        value={fields.description}
                                                        onChange={this.handleChange.bind(this)}
                                                        onClick={this.handleClickDescription.bind(this)}
                                                        error = {!!errors['description']}
                                                        helperText={errors['description']}
                                                    />
                                                    <FieldCounter length={fields.description.length} max={150}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {editMode ? Translate.editCategory : Translate.makeCategory}
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                    }
                                </AppCard>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} style={{paddingBottom: 0}}>
                                                            <Typography color="textSecondary"
                                                                        style={{marginBottom: 0}}>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{paddingTop: 0}}>
                                                            <LoadingButton
                                                                variant="contained"
                                                                color="primary"
                                                                style={{width: "100%"}}
                                                                onClick={this.handleSubmit.bind(this)}
                                                                loading={SubmitLoading}
                                                            >
                                                                {editMode ? Translate.editCategory : Translate.makeCategory}
                                                            </LoadingButton>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SubTitle>{Translate.ThumbnailTitle}</SubTitle>
                                                            <Thumbnail rounded ref={this.ThumbChild}
                                                                       handleThumbImage={this.handleThumbImage.bind(this)}
                                                                       initial={thumbnail}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                name="slug"
                                                                label={Translate.slug}
                                                                type="text"
                                                                variant="outlined"
                                                                fullWidth
                                                                disabled={editMode}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <LinkIcon color="secondary"/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                value={fields.slug}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['slug']}
                                                                helperText={errors['slug']}
                                                            />
                                                            {
                                                                fields.slug ? <a href={CategoryUrl} target="_blank" rel="noopener noreferrer"><p dir="ltr" className={this.classes.litleUrlShower}>{CategoryUrl}</p></a> : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                }
            </AppAnimate>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(MakeCategory)));