import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import LoadingButton from "../../components/elements/LoadingButton";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {passwordRegx} from '../../../config/Security'
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import PassField from "../../components/fields/PassField";
class ChangePassword extends React.Component {
    state={
        fields: {
            currentPassword:'',
            newPassword: '',
            newPasswordConfirm: '',
        },
        errors:{},
        SubmitLoading:false,
    };
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //old Password
        if(validator.isEmpty(fields.currentPassword)){
            formIsValid = false;
            errors['currentPassword'] = Translate.emptyPassword;
        }
        //Password

        if(validator.isEmpty(fields.newPassword)){
            formIsValid = false;
            errors['newPassword'] = Translate.emptyPassword;
        }else if(!passwordRegx.test(fields.newPassword)){
            formIsValid = false;
            errors['newPassword'] = Translate.weakPassword;
        }


        //Password_confirmation
        if(validator.isEmpty(fields.newPasswordConfirm)){
            formIsValid = false;
            errors['newPasswordConfirm'] = Translate.emptyField;
        }else if (fields.newPasswordConfirm !== fields.newPassword){
            formIsValid = false;
            errors['newPasswordConfirm'] = Translate.noMatchPassword;
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    handleRequest(){
        const {currentPassword,newPassword,newPasswordConfirm} = this.state.fields;
        let data = new FormData();
        data.append('currentPassword', currentPassword);
        data.append('password', newPassword);
        data.append('password_confirmation', newPasswordConfirm);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/changePassword`, data, config)
            .then(response =>{
                this.props.enqueueSnackbar(Translate.passwordChanged, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false,
                    fields: {
                        currentPassword:'',
                        newPassword: '',
                        newPasswordConfirm: '',
                    },
                    errors:{},
                })

            })
            .catch(error =>{
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(Translate.currentPasswordError, {
                    variant: 'error',
                });
            });
    }
    handleSubmit(){
        this.setState({
            SubmitLoading:true
        })
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render() {
        let {fields,errors,SubmitLoading} = this.state
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.changePassword}>
                <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item xs={12} className="text-center">
                        <img alt="lock screen" src="../assets/images/change-pass.png" width={120} className="max-width"/>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8} >
                        <AppCard
                            title={Translate.changePassword}
                            action={
                                <BreadCrumbs  firstText={Translate.myAccount} firstLink="/account/details" secondText={Translate.changePassword}/>
                            }>
                            <Grid container spacing={2} direction="row" justify="center" alignItems="center" style={{marginTop:15}}>
                                <Grid item xs={12} md={8}>
                                    <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                            <Grid item xs={12}>
                                                <PassField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    value={fields.currentPassword}
                                                    name="currentPassword"
                                                    label={Translate.currentPassword}
                                                    id="currentPassword"
                                                    onChange={this.handleChange.bind(this)}
                                                    error={!!errors['currentPassword']}
                                                    helperText={errors['currentPassword']}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PassField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    value={fields.newPassword}
                                                    name="newPassword"
                                                    label={Translate.newPassword}
                                                    id="newPassword"
                                                    onChange={this.handleChange.bind(this)}
                                                    error={!!errors['newPassword']}
                                                    helperText={!!errors['newPassword'] ? errors['newPassword'] : Translate.passwordStrength}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PassField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    value={fields.newPasswordConfirm}
                                                    name="newPasswordConfirm"
                                                    label={Translate.newPasswordConfirm}
                                                    id="newPasswordConfirm"
                                                    onChange={this.handleChange.bind(this)}
                                                    error={!!errors['newPasswordConfirm']}
                                                    helperText={errors['newPasswordConfirm']}
                                                />
                                            </Grid>
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick = {this.handleSubmit.bind(this)}
                                                loading = {SubmitLoading}
                                            >
                                                {Translate.changePassword}
                                            </LoadingButton>
                                        </Grid>
                                    </form>

                                </Grid>
                            </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(ChangePassword)));