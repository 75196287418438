import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import {Translate} from "../../../config/Translate";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import PopOver from "../../components/elements/PopOver";
import Typography from "@material-ui/core/Typography/Typography";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ReportViolation from "../../components/elements/ReportViolation";
class Opportunities extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    is_expert = this.props.dashboard === "expert";
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'customer_avatar',
                editable: 'never',
                filtering: false,
                title: Translate.customer,
                render: rowData => <img alt="avatar" src={rowData.customer_avatar} className={this.classes.FMThumbnail}/>
            },
            {
                field: 'customer',
                editable: 'never',
                filtering: false,
            },
            { title: Translate.specialtyDetails, field: 'specialties',filterPlaceholder: Translate.search,hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.services, field: 'services',filterPlaceholder: Translate.search,hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate',hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.reportAViolation,
                field: 'activity',
                render: rowData =>
                    <ReportViolation data={rowData} expert={true}/>
            },

        ],
        customer_columns: [
            {
                field: 'avatar',
                editable: 'never',
                filtering: false,
                title: Translate.expert,
                render: rowData => <img alt="avatar" src={rowData.avatar} className={this.classes.FMThumbnail}/>
            },
            {
                field: 'expert',
                editable: 'never',
                filtering: false,
                render: rowData =>
                    <PopOver
                        buttonText={rowData.expert}
                        startIcon={<InfoRoundedIcon size="small"/>}
                    >
                        <Typography>{Translate.profile}: <strong><a href={`${defaultVariabls.website}/${rowData.expertName}`} dir="ltr">@{rowData.expertName}</a></strong></Typography>
                    </PopOver>
            },
            {
                title: Translate.mobile,
                field: 'expertMobile',
                hidden: this.props.width === "sm" || this.props.width === "xs",
                filterPlaceholder: Translate.search,
                render: rowData =>
                    <strong><a href={`tel:${rowData.expertMobile}`}>{rowData.expertMobile}</a></strong>
            },
            {
                title: Translate.email,
                field: 'expertEmail',
                hidden: this.props.width === "sm" || this.props.width === "xs",
                filterPlaceholder: Translate.search,
                render: rowData =>
                    <strong><a href={`mailto:${rowData.expertEmail}`}>{rowData.expertEmail}</a></strong>
            },
            { title: Translate.specialtyDetails, field: 'specialties',filterPlaceholder: Translate.search,hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.services, field: 'services',filterPlaceholder: Translate.search,hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate',hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: Translate.reportAViolation,

                field: 'activity',
                render: rowData =>
                    <ReportViolation data={rowData} expert={false}/>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    componentDidMount(){
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRowClick(data){

    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.opportunities}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.opportunities}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.opportunities}/>
                            }>
                            {
                                this.state.loading ? <AppCardLoaderTable/> :
                                    <Container maxWidth="xl">
                                        <MaterialTable
                                            tableRef={this.tableRef}
                                            icons={tableIcons}
                                            title={" "}
                                            localization={DataTableLocalization}
                                            columns={this.is_expert ? this.state.columns : this.state.customer_columns}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    axios.get(`${defaultVariabls.url}/opportunities/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&type=${this.is_expert ? "expert": 'customer'}`, config)
                                                        .then(response => {
                                                            let data = [];
                                                            // eslint-disable-next-line
                                                            response.data.data.map((row) => {
                                                                let specialties = '';
                                                                // eslint-disable-next-line
                                                                row.specialties.map(item => {
                                                                    specialties += item.name;
                                                                    specialties += " , "
                                                                });
                                                                specialties = specialties.substring(0, specialties.length - 2);

                                                                let services = "";
                                                                if(!!row.project) services += Translate.projectsDetails+", ";
                                                                if(!!row.teaching) services += Translate.teachingsDetails+", " ;
                                                                if(!!row.job) services += Translate.jobsDetails+", ";
                                                                if(!!row.team) services += Translate.teamsDetails+", ";
                                                                if(!!row.consultation) services += Translate.consultationsDetails+", ";
                                                                services = services.substring(0, services.length - 2);

                                                                data.push({
                                                                    avatar: `${defaultVariabls.uploads}/FileManager/${row.expert.avatar.name}_small.${row.expert.avatar.extension}`,
                                                                    id: row.id,
                                                                    expert: row.expert.first_name + " " + row.expert.last_name,
                                                                    expertMobile: row.expert.mobile,
                                                                    expertEmail: row.expert.email,
                                                                    expertName: row.expert.name,
                                                                    customer_avatar: `${defaultVariabls.uploads}/FileManager/${row.customer.avatar.name}_small.${row.customer.avatar.extension}`,
                                                                    customer: row.customer.first_name + " " + row.customer.last_name,
                                                                    customerMobile: row.customer.mobile,
                                                                    customerEmail: row.customer.email,
                                                                    specialties: specialties,
                                                                    services: services,
                                                                    lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                                })
                                                            });
                                                            resolve({
                                                                data: data,
                                                                page: response.data.current_page - 1,
                                                                totalCount: response.data.total,
                                                            })
                                                        }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                })
                                            }
                                            options={{
                                                pageSize: TablePage,
                                                pageSizeOptions: TableCount,
                                            }}
                                            detailPanel={this.props.width !== "sm" && this.props.width !== "xs" && !this.is_expert ? null : [{
                                                render: rowData => {
                                                    return (
                                                        <Grid container spacing={1} className="my-10" justify="center" alignItems="center">
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.mobile}: <strong>{rowData.expertMobile}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.email}: <strong>{rowData.expertEmail}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.specialtyDetails}: <strong>{rowData.specialties}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.services}: <strong>{rowData.services}</strong></Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography>{Translate.updateTime}: <strong>{rowData.lastUpdate}</strong></Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                },
                                            }
                                            ]}
                                        />
                                    </Container>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    dashboard: state.dashboard,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Opportunities)))));