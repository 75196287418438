import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../config/Translate';
import {connect} from "trim-redux";
import {withRouter} from 'react-router-dom';
import Logo from '../../../files/img/logo-dark.png';
import { withSnackbar } from 'notistack';
import Paper from "@material-ui/core/Paper/Paper";
import Title from "../../components/elements/Title";
import Avatar from "@material-ui/core/Avatar/Avatar";
import HeaderTags from "../../components/elements/HeaderTags";
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import SubTitle from "../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import Typography from "@material-ui/core/Typography/Typography";

class VerifyEmailPage extends React.Component {
    state = {
        loading:true,
        success:false,
    }
    id = this.props.match.params.id;
    code = this.props.match.params.code;
    componentDidMount(){
        this.handleRequest();
    }
    async handleRequest(){
        let data = new FormData();
        data.append('id', this.id);
        data.append('code', this.code);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/verifyEmail`, data, config)
            .then(response =>{
                    this.setState({
                        loading:false,
                        success:true,
                    })
            })
            .catch(error =>{
                this.setState({
                    loading:false,
                    success:false,
                })
            });
    }
    render() {
        const {classes} = this.props;
        let {loading,success} = this.state;
        return (
            <Grid container component="main" className={classes.loginPageContainer}>
                <HeaderTags title={Translate.VerifyEmail}/>
                <Grid item xs={12} md={6} component={Paper}  square className={classes.loginPageContainer}>
                    <img src={Logo} alt={Translate.appName} className={classes.loginPageLogo}/>

                    <Grid container spacing={2} direction="row" justify="center" alignItems="center" style={{height:"70%"}}>
                        <Grid item xs={12} sm={8}>
                            <CssBaseline/>

                            <Grid container spacing={3} justify="center" alignItems='center'>
                                <Avatar  style={{backgroundColor:"#ffb63e"}}>
                                    <VerifiedUserRoundedIcon />
                                </Avatar>
                                <Grid item xs={12}>

                                    <Title>
                                        {Translate.verifyEmail}
                                    </Title>
                                </Grid>
                                <Grid item xs={10} className={classes.LoginFormGrid}>
                                    {
                                        loading ?
                                            <Grid container spacing={1} direction="row" justify="center" alignItems='center'>
                                                <Grid item xs={2}>
                                                    <CircularProgress />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SubTitle>{Translate.verifingInfos}</SubTitle>
                                                </Grid>
                                            </Grid>
                                            :
                                            <React.Fragment>
                                            {
                                                success ?
                                                    <Grid container spacing={1} direction="row" justify="center" alignItems='center'>
                                                        <Grid item xs={2}>
                                                            <DoneAllRoundedIcon style={{color:"#38bf84",fontSize:'5rem'}}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SubTitle style={{color:"#38bf84"}}>{Translate.EmailVerified}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a href={defaultVariabls.React}>
                                                                <Typography className="withCursor" color="primary" align="center">
                                                                    {Translate.goBackToPanel}
                                                                </Typography>
                                                            </a>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <Grid container spacing={1} direction="row" justify="center" alignItems='center'>
                                                        <Grid item xs={2}>
                                                            <WarningRoundedIcon style={{color:"#ef6b2c",fontSize:'5rem'}} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SubTitle style={{color:"#ef6b2c"}}>{Translate.EmailNotVerified}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a href={defaultVariabls.React}>
                                                                <Typography className="withCursor" color="primary" align="center">
                                                                {Translate.goBackToPanel}
                                                                </Typography>
                                                            </a>
                                                        </Grid>
                                                    </Grid>
                                            }
                                            </React.Fragment>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={false} sm={false} md={6} className={classes.image} />
            </Grid>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(VerifyEmailPage))));