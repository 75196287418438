import React from 'react';
import Button from '@material-ui/core/Button';
import {Translate} from "../../../config/Translate";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover/Popover";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import PopupState from "material-ui-popup-state";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

class DeleteButton extends React.Component{
    render(){
        return (

            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div className="floated-delete-button">
                        <IconButton size="small"><CloseRoundedIcon fontSize="small" {...bindTrigger(popupState)}/></IconButton>
                        <Popover
                            elevation={1}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box p={1}>
                                <Typography>
                                    {Translate.areYouSure}
                                    <Button
                                        style={{color:"#f44336",marginRight:5}}
                                        startIcon={<DeleteRoundedIcon/>}
                                        onClick={() => this.props.handleDelete()}
                                    >
                                        {Translate.deleteIt}
                                    </Button>
                                </Typography>
                            </Box>
                        </Popover>
                    </div>
                )}
            </PopupState>
        );
    }
}
export  default DeleteButton;
