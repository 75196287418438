import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Typography from "@material-ui/core/Typography/Typography";
class Verification extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            bank_name:"",
            sheba:"",
        },
        melli_card_image: 1,
        creditcard_image: 1,
        errors:{},
        loading: true,
        SubmitLoading: false,
        editMode: false,
        confirmed: true,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    handleMelliImage(id){
        let {errors} = this.state;
        errors['melli_card_image'] ='';
        this.setState({
            melli_card_image: id
        })
    }
    handleCreditImage(id){
        let {errors} = this.state;
        errors['creditcard_image'] ='';
        this.setState({
            creditcard_image: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/getVerification`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            bank_name:response.data.bank_name,
                            sheba:response.data.sheba,
                        },
                        melli_card_image: response.data.melli_card.id,
                        creditcard_image: response.data.credit_card.id,
                        confirmed: response.data.user.verified,
                        editMode:true,
                        loading:false

                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields,melli_card_image,creditcard_image } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.bank_name)){
            formIsValid = false;
            errors['bank_name'] = Translate.emptyField;
        }
        //name
        if(validator.isEmpty(fields.sheba)){
            formIsValid = false;
            errors['sheba'] = Translate.emptyField;
        }else if(fields.sheba.length !== 24){
            formIsValid = false;
            errors['sheba'] = Translate.wrongSheba;
        }

        if(melli_card_image === 1){
            formIsValid = false;
            errors['melli_card_image'] = Translate.emptyImage;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,melli_card_image,creditcard_image,confirmed} = this.state;
        let data = new FormData();
        data.append('bank_name', fields.bank_name);
        data.append('sheba', fields.sheba);
        data.append('melli_card_image', melli_card_image);
        data.append('creditcard_image', creditcard_image);
        data.append('confirmed',  confirmed ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/setVerification`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.verificationDataSets, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                window.location.reload();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    render(){
        let {fields, errors,SubmitLoading,confirmed,melli_card_image,creditcard_image,editMode} = this.state;
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.verificationDetails}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={10} lg={8} >
                            <AppCard
                                title={Translate.verificationDetails}
                                action={
                                    <BreadCrumbs singleLevel firstText={Translate.verificationDetails}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12} md={10}>
                                                {
                                                    !editMode ?
                                                        <Alert
                                                            severity="info"
                                                        >
                                                            {Translate.verificationDescription}
                                                        </Alert>
                                                        :
                                                        <>
                                                            {
                                                                !! user.role.permission.manageShops ?
                                                                    <>
                                                                        <Alert
                                                                            severity="info"
                                                                            action={
                                                                                <FormControlLabel
                                                                                    style={{marginRight:10}}
                                                                                    control={
                                                                                        <Switch
                                                                                            checked={confirmed}
                                                                                            onChange={this.handleChangeConfirmed.bind(this)}
                                                                                            value={confirmed}
                                                                                            color="primary"
                                                                                        />
                                                                                    }
                                                                                    label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                                                />
                                                                            }
                                                                        >
                                                                            {Translate.confirmationStatus}
                                                                        </Alert>
                                                                    </>
                                                                    :
                                                                    confirmed ?
                                                                        <Alert  severity="success">
                                                                            {Translate.yourBankInfoIsActive}
                                                                        </Alert>
                                                                        :
                                                                        <Alert severity="warning">
                                                                            {Translate.yourBankInfoIsInchecking}
                                                                        </Alert>
                                                            }
                                                        </>

                                                }
                                            </Grid>
                                            <Grid item xs={12} md={10}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="bank_name"
                                                    label={Translate.bank_name}
                                                    type="text"
                                                    value={fields.bank_name}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['bank_name']}
                                                    helperText={errors['bank_name']}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={10}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="sheba"
                                                    type="number"
                                                    label={Translate.sheba}
                                                    value={fields.sheba}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['sheba']}
                                                    helperText={errors['sheba']}
                                                    className="insta-field"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                IR
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}  md={6}>
                                                <SubTitle required>{Translate.melli_card_image}</SubTitle>
                                                <Thumbnail rounded handleThumbImage={this.handleMelliImage.bind(this)}
                                                           initial={melli_card_image}/>
                                                <Typography
                                                    align="center"
                                                    color="error"
                                                    fontSize="small"
                                                    variant="body2"
                                                >
                                                    {errors['melli_card_image']}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SubTitle required>{Translate.credit_card_image}</SubTitle>
                                                <Thumbnail rounded
                                                           handleThumbImage={this.handleCreditImage.bind(this)}
                                                           initial={creditcard_image}/>
                                                <Typography
                                                    align="center"
                                                    color="error"
                                                    fontSize="small"
                                                    variant="body2"
                                                >
                                                    {errors['creditcard_image']}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {Translate.setDetails}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Verification))));