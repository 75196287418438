import React from 'react';
import {Grid, withStyles} from '@material-ui/core';
import AppAnimate from "../../components/AppAnimate";
import {Translate} from "../../../config/Translate";
import OrderSummary from "./OrderSummary";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import ShowProducts from "../products/ShowProducts";
import Title from "../../components/elements/Title";
import Typography from "@material-ui/core/Typography/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import Fab from "@material-ui/core/Fab/Fab";
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import withWidth from "@material-ui/core/withWidth/withWidth";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Badge from "@material-ui/core/Badge/Badge";

class Shop extends React.Component{
    state = {
        category: this.props.dashboard === "user" ? 'customer-opportunity' : 'opportunity',
        responsiveCardOpen: false

    }
    render(){
        let {category,responsiveCardOpen} = this.state;
        let {dashboard} = this.props;
        return (
            <>
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.shop}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Title align="left">{Translate.shop}</Title>
                        <Typography paragraph>{Translate.shopDetail}</Typography>
                        <Alert severity="info">
                            <AlertTitle><strong>{Translate.shopDetailLongTitle}</strong></AlertTitle>
                            {Translate.shopDetailLong}
                            </Alert>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            <ShowProducts type={dashboard === "expert" ? "expert" : "customer"} category={category}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <OrderSummary />
                    </Grid>
                </Grid>

            </AppAnimate>
                {
                    this.props.width === "xs" || this.props.width === "sm" ?
                        <>
                            <Fab className="fab" color="secondary" aria-label="cart" variant="extended"
                            onClick={() => this.setState({responsiveCardOpen:true})}>
                                <Badge badgeContent={this.props.cart.length} color="primary" anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}>
                                <ShoppingBasketRoundedIcon style={{marginLeft:10}}/>
                                    {Translate.cart}

                                </Badge>
                            </Fab>
                            <Dialog
                                open={responsiveCardOpen}
                                maxWidth="sm"
                                fullWidth
                                onClose={() => this.setState({responsiveCardOpen: false})}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <OrderSummary  />
                            </Dialog>
                        </>: null
                }

                </>

        )
    }
};

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    dashboard: state.dashboard,
    cart: state.cart

});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Shop)))));