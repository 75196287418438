import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import DeleteButton from "../../../../components/elements/DeleteButton";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {Translate} from "../../../../../config/Translate";
import EditProjectDialog from "./EditProjectPlanDialog";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import {currencySymbol, paymentTypesToShow, price} from "../../../../../config/values";

class SingleProjectPlan extends React.Component {
    classes = this.props.classes
    state={
        project:this.props.data,
    };
    handleDelete(){
        let {project} = this.state;
        let data = new FormData();
        data.append('id', project.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/project_plan/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render() {
        let {project} = this.state;
        return (
            <Card variant="outlined" style={{boxShadow:'none',height:"100%"}}>
                <CardMedia
                    style={{height:200}}
                    image={`${defaultVariabls.website}/uploads/FileManager/${project.thumbnail.name}_medium.${project.thumbnail.extension}`}
                    title={project.title}
                />
                <CardContent>
                    <Typography variant="subtitle2"  color="textPrimary" gutterBottom>
                        {project.title}
                    </Typography>
                        {
                        !!project.max_price ?
                            <Typography  gutterBottom>
                                {Translate.price}: <strong>{price(project.min_price)}</strong> {currencySymbol} - <strong>{price(project.max_price)}</strong> {currencySymbol} ({paymentTypesToShow[project.payment_type]})
                            </Typography> :
                            <Typography  gutterBottom>
                                {Translate.price}: <strong>{price(project.min_price)}</strong> {currencySymbol} ({paymentTypesToShow[project.payment_type]})
                            </Typography>
                        }
                    <Typography color="textSecondary">
                        {project.description}
                    </Typography>

                </CardContent>
                <CardActions style={{justifyContent: "flex-end"}}>
                    <EditProjectDialog id={project.id}  reload={() => this.props.reload()}/>
                    <DeleteButton handleDelete={this.handleDelete.bind(this)}/>
                </CardActions>
            </Card>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleProjectPlan))));