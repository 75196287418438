import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol} from "../../../../config/values";
import CurrencyFormat from "react-currency-format";
import Button from "@material-ui/core/Button/Button";
import PopOver from "../../../components/elements/PopOver";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AvatarViewer from "../../../components/elements/AvatarViewer";
import Divider from "@material-ui/core/Divider/Divider";

class Orders extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'buyer',
                editable: 'never',
                filtering: false,
                title: Translate.buyer,
                render: rowData =>
                    <PopOver
                        buttonText={rowData.buyer}
                        startIcon={<InfoRoundedIcon size="small"/>}
                    >
                        <Typography>{Translate.mobile}: <strong><a href={`tel:${rowData.buyerMobile}`}>{rowData.buyerMobile}</a></strong></Typography>
                        <Typography>{Translate.email}: <strong><a href={`mailto:${rowData.buyerMobile}`}>{rowData.buyerEmail}</a></strong></Typography>
                    </PopOver>
            },

            { title: Translate.totalPrice,
                field: 'total_price',
                filterPlaceholder: Translate.search,
                render: rowData => <CurrencyFormat value={rowData.total_price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
            },
            { title: Translate.status,
                editable: 'never',
                field: 'status',
                filterPlaceholder: Translate.choose,
                lookup: {
                    "payed" :
                        <Typography style={{color: "#38bf84"}}>{Translate.is_payed}</Typography>
                    , "pending":
                        <Typography color="textSecondary">{Translate.pending}</Typography>
                    , "not_payed":
                        <Typography color="textSecondary">{Translate.not_payed}</Typography>
                },
            },
            { title: Translate.updateTime,editable: 'never',filtering: false, field: 'lastUpdate'},
            { title: Translate.activity,
                field: 'activity',
                render: rowData => rowData.status === "not_payed" ?
                    <>
                        <Button
                            className="mx-5"
                            onClick={() =>this.handleRemoveItem(rowData.id)}
                            size="small"
                        >
                            {Translate.remove}
                        </Button>
                    </>
                    : null
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRemoveItem(id){
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/order/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.loadData();
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl" className={!!this.props.widget ? "thin-table" : null}>
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                detailPanel={[
                                    {
                                        tooltip: Translate.orderedProducts,
                                        render: rowData => {
                                            return (
                                                <Grid container spacing={2}>
                                                    {
                                                        rowData.products.map((product,key)=>(
                                                            <React.Fragment index={key}>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        <IconButton>
                                                                            <AvatarViewer style={{display:"inline-block"}} id={product.thumbnail} size="small"/>
                                                                        </IconButton>
                                                                        <strong>{product.name}</strong> / <CurrencyFormat value={!!product.discount_price ? product.discount_price : product.price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                                                                        {
                                                                            !!product.discount_price ?
                                                                                <>
                                                                                    &nbsp;(<CurrencyFormat value={product.price - product.discount_price} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} /> {Translate.discount})
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </Grid>
                                            )
                                        },
                                    },
                                ]}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/orders/quickViewForAdmin?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        total_price: row.total_price,
                                                        id: row.id,
                                                        products: row.products,
                                                        buyer: row.user.first_name+" "+row.user.last_name,
                                                        buyerMobile: row.user.mobile,
                                                        buyerEmail: row.user.email,
                                                        status: row.status,
                                                        lastUpdate:`${moment(row.updated_at).locale('fa').fromNow()}`
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                    search:false,
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Orders)))));