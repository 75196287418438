import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import AppCardLoaderSidebar from "../../../../components/Skeletons/AppCardLoaderSidebar";
import SubTitle from "../../../../components/elements/SubTitle";
import Thumbnail from "../../../fileManager/Components/Thumbnail";
import Typography from "@material-ui/core/Typography/Typography";
import FileChooserSingle from "../../../fileManager/Components/FileChooserSingle";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";
import {updateRedux} from "../../../../../config/Security";
class MakePortfolio extends React.Component {

    classes = this.props.classes
    state={
        fields: {
            title: '',
            description:'',
        },
        errors:{},
        current:false,
        thumbnail:1,
        file:0,
        loading:!!this.props.id,
        SubmitLoading:false,
        id: !!this.props.id ? this.props.id : 0,
        editMode: !!this.props.id
    };
    componentDidMount(){
        if(!!this.props.id){
            this.loadData();
        }
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/portfolio/get/${this.state.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        title: !!response.data.title ? response.data.title : '',
                        description: !!response.data.description ? response.data.description : '',
                    },
                    loading:false,
                    thumbnail: response.data.thumbnail.id,
                    file: response.data.file.id,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,

        });
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)) {
            formIsValid = false;
            errors['title'] = Translate.emptyField;
        }
        if(!!fields.description ? fields.description.length > 150 : false){
            formIsValid = false;
            errors['description'] = Translate.maxLength150;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,id,thumbnail,file} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('description', fields.description);
        data.append('thumbnail', thumbnail);
        data.append('file', file);
        data.append('id', !!id ? id : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/portfolio/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                updateRedux();
                this.props.handleDone();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async handleFile(data){
        await this.setState({
            file: data,
        })
    }
    render() {
        const {errors,fields,SubmitLoading,thumbnail,file} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2} >
                        <CssBaseline/>
                        <Grid item xs={12} md={8}>
                            <Grid container  spacing={2} justify="center" >
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        value={fields.title}
                                        fullWidth
                                        label={Translate.titlePortfolio}
                                        name="title"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['title']}
                                        helperText={errors['title']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.description}
                                        fullWidth
                                        label={Translate.portfolioDescription}
                                        name="description"
                                        rows={5}
                                        multiline
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['description']}
                                        helperText={errors['description']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {
                                this.state.loading ? <AppCardLoaderSidebar/> :
                                    <>
                                        <Thumbnail noHelper size={240} rounded ref={this.ThumbChild}
                                                   handleThumbImage={this.handleThumbImage.bind(this)}
                                                   initial={thumbnail}/>
                                    </>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                this.state.loading ? <AppCardLoaderSidebar/> :
                                    <>
                                        <SubTitle align="left">{Translate.chooseFileForPortfo}</SubTitle>
                                        <Typography paragraph variant="body2">{Translate.chooseFileForPortfoDesc}</Typography>
                                        <FileChooserSingle initial={file} onChange={this.handleFile.bind(this)}/>
                                    </>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakePortfolio))));