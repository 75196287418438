import axios from "axios";
import {defaultVariabls} from "./index";
import {setStore} from "trim-redux";

export const secret = 'wHGNFM0ZlJd457GOsJu839ZclZgobhvGx45sn0Ih';
export const loginToken = localStorage.getItem('ACT');

export const passwordRegx = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{5,})");
export const usernameRegx = new RegExp("^(?=.{5,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$");

export let updateRedux = async () => {
    let loginToken = localStorage.getItem('ACT');
    if(loginToken !== null){
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/checkLogin`, config)
            .then(response => {
                setStore({
                    isAuth : true,
                    user : response.data,
                    dashboard : response.data.role.name === "user" ? "user" : "expert",
                });
            }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
    }else {
        setStore({
            isAuth : false,
            user : null,
        });
    }
    return "done";
};